<template>
  <div class="category-page-header" ref="header">
    <div class="category-page-header-top">
      <BaseIconButton
        textColor="content-base-secondary"
        variant="ghost"
        size="md"
        icon="arrow-left"
        @click="$emit('back')"
      />
      <div class="header-info">
        <div class="qrw-label-xs bold qrw-text-content-base-tertiary">
          {{ modeTitle }}
        </div>
        <div class="qrw-label-lg bold qrw-text-content-base-primary" v-if="!loading">
          {{ cafe.name }}
        </div>
        <SkeletonLoader width="120px" height="18px" v-else/>
      </div>
      <BaseIconButton
        textColor="content-base-secondary"
        variant="ghost"
        size="md"
        icon="menu"
        @click="$emit('switchView', 'list')"
      />
    </div>
    <CategoriesScroller view="grid" :loading="loading"/>
  </div>
</template>

<script>
import CategoriesScroller from '@/components/header/CategoriesScroller.vue'

import { updateAdaptiveHeaderHeight } from '@/utils/adaptiveUi.js'

export default {
  components: {
    CategoriesScroller
  },
  props: {
    loading: Boolean
  },
  computed: {
    cafe () {
      return this.$store.state.cafe
    },
    modeTitle () {
      return this.$store.getters.modeTitle
    }
  },
  mounted () {
    updateAdaptiveHeaderHeight(this.$refs.header)
  }
}
</script>

<style scoped>
.category-page-header {
  display: flex;
  padding: 8px 0;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  row-gap: 12px;
  position: fixed;
  top: 0;
  background: var(--qrw-background-base-secondary-08);
  backdrop-filter: blur(20px);
  z-index: 10;
}

.category-page-header-top {
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  align-items: center;
  padding: 0 16px;
}

.header-info {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
</style>
