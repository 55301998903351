import Base from '../base'

export default {
  requestPhoneNumberVerification: (phone, cafeId) => Base.post('/security/phone-number-verification/request', {
    cafe_id: cafeId, phone
  }),
  confirmPhoneNumberVerification: (token, code) => Base.post('/security/phone-number-verification/confirm', {
    token, code
  })
}
