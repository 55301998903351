<template>
  <BaseSimpleModal
    :fullHeight="false"
    :showHeader="false"
    :showFooter="false"
  >
    <div class="error-body">
      <div class="error-header">
        <BaseIcon
          icon="face-frown"
          class="qrw-text-content-error-primary"
          size="sm2"
        />
        <div class="header-title qrw-heading-sm bold qrw-text-content-error-primary">
          {{ $t("error_modal.title_" + type) }}
        </div>
        <div class="qrw-paragraph-sm qrw-text-content-base-tertiary">
          {{ $t('error_modal.error_code') }}: {{ code }}
        </div>
      </div>
      <template v-if="showHint">
        <BaseDivider color="border-base-tertiary" />
        <div class="hint-text qrw-label-md qrw-text-content-base-primary">
          {{ $t('error_modal.please_reload') }}
        </div>
      </template>

    </div>
    <div class="error-footer">
      <BaseDivider color="border-base-tertiary" />
      <div class="error-footer-content">
        <BasePushButton
          variant="ghost"
          textColor="content-base-secondary"
          size="lg"
          @click="doReload"
          class="w-100"
        >
          {{ $t("error_modal.btn_reload") }}
        </BasePushButton>
      </div>

    </div>

    <template #footer>
      <!-- <BasePushButton
          variant="outlined"
          borderColor="border-base-tertiary"
          textColor="content-base-primary"
          size="lg"
          @click="goBack"
          class="w-100"
        >
          {{ $t("error_modal.btn_back") }}
        </BasePushButton> -->

    </template>
  </BaseSimpleModal>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'generic'
    },
    code: {
      type: [String, Number],
      default: '-42'
    },
    showHint: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    doReload() {
      window.location.reload()
    },
    goBack() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.error-body {
  background: var(--qrw-background-error-primary);
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.error-header {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  justify-content: center;
  align-items: center;
}

.hint-text {
  width: 100%;
  text-align: center;
}

.header-title {
  width: 100%;
  text-align: center;
}

.error-footer {
  display: flex;
  flex-direction: column;
  background: var(--qrw-background-error-primary);
}

.error-footer-content {
  padding: 12px 16px;
}
</style>
