<template>
  <div
    class="app-split-payment-navbar"
    :class="{
    'filled': !contentsOnly
  }"
  >
    <div
      class="banner-top"
      @click="extended = !extended"
      v-if="!contentsOnly"
    >
      <div class="qrw-label-sm qrw-text-content-base-tertiary">
        {{ $tc("split_payment.title", splits.length) }}
      </div>
      <BaseIcon
        :icon="extended ? 'x-circle' : 'help-circle'"
        size="xs"
      />
    </div>
    <div
      class="banner-list"
      v-if="extended || contentsOnly"
    >
      <template v-for="(split, index) in splits">
        <div
          class="banner-list-item"
          :class="{
            'qrw-text-content-base-primary': !!split.paid_at,
            'qrw-text-content-base-tertiary': !split.paid_at,
          }"
          :key="index"
        >
          <div class="gap-column-1xs">
            <BaseIcon
              :class="{
                'qrw-text-content-success-primary': !!split.paid_at,
              }"
              :icon="split.paid_at ? 'check-circle-filled' : 'check-circle'"
              size="xs"
            />
            <div class="qrw-label-sm">
              {{ $t("split_payment.payment") }} {{ index + 1 }}
            </div>
          </div>
          <PriceTag :value="split.total" />
        </div>
        <BaseDivider
          color="border-base-tertiary"
          :key="'div' + index"
          dashed
          v-if="index != splits.length - 1"
        />
      </template>
    </div>
    <div
      class="qrw-paragraph-xs qrw-text-content-base-tertiary"
      v-if="extended"
    >
      {{ $t("split_payment.desc") }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    splits: Array,
    contentsOnly: Boolean
  },
  data() {
    return {
      extended: false
    }
  }
}
</script>

<style scoped>
.app-split-payment-navbar {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.app-split-payment-navbar.filled {
  background: var(--qrw-background-base-tertiary-10);
  padding: 12px 16px;
}

.banner-top {
  display: flex;
  justify-content: space-between;
  color: var(--qrw-content-base-tertiary);
}

.banner-list {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.banner-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 16px;
}
</style>
