export default {
  home: {
    about: "عنا",
    address: "عنوان",
    socials: "وسائل التواصل الاجتماعي",
    website: "موقع الكتروني",
    get_directions: "احصل على الاتجاهات",
  },
  contacts: {
    title: "الاتصالات",
    phone: "هاتف",
    mail: "بريد الكتروني",
    website: "موقع الكتروني",
    visit: "زيارة",
    call: "اتصال",
    copy: "نسخ",
  },
  schedule: {
    weekdays: {
      mon: "الاثنين",
      tue: "الثلاثاء",
      wed: "الاربعاء",
      thu: "الخميس",
      fri: "الجمعة",
      sat: "السبت",
      sun: "الاحد",
    },
    open_now: "مفتوح",
    closes_in: "يغلق في",
    closed_now: "مغلق الآن",
    opens_in: "يفتح في",
    day_off: "يوم فرضي",
    schedule: "جدول",
  },
  units: {
    hours: "h.",
    minutes: "m.",
    positions: "0 مناصب | 1 منصب | {count} مناصب",
    items: "0 عناصر | 1 عنصر | {count} عناصر",
    orders: "0 طلبات | 1 طلب | {count} طلبات",
    g: "g",
    kg: "kg",
    l: "l",
    ml: "ml",
    min: "min",
  },
  legals: {
    privacy_policy: "سياسة الخصوصية",
    cookies: "الكوكيز",
    terms_of_use: "شروط الاستخدام",
    public_offert: "عرض عام",
    view: "عرض",
  },
  reviews: {
    home_title: "التعليقات",
    max_value: "من 5",
  },
  menu_grid: {
    list: "قائمة",
  },
  product: {
    description: "الوصف",
  },
  common_actions: {
    confirm: "تأكيد",
    order: "طلب",
    cancel: "إلغاء",
    reserve: "حجز",
    next: "التالى",
    pay: "دفع",
    search: "بحث",
    view_cart: "عرض السلة",
    add: "إضافة",
    remove: "إزالة",
    close: "إغلاق",
    view: "عرض",
  },
  reserve: {
    persons: "الأشخاص",
    reservation: "الحجز",
    thanks_1: "شكرا لك!",
    thanks_2: "لقد تم حجزك",
    date: "التاريخ",
    time: "الوقت",
    download_pdf: "تحميل PDF",
    add_to_calendar: "إضافة للتقويم",
    deposit: "الإيداع",
    paid: "تم الدفع",
  },
  checkout: {
    contact_details: "تفاصيل الاتصال",
    confirmation: "تأكيد",
    name: "اسم",
    first_name: "الاسم الأول",
    last_name: "اسم العائلة",
    phone: "هاتف",
    email: "البريد الالكتروني",
    comment: "تعليق",
    contact: "اتصال",
    service: "خدمة",
    date: "تاريخ",
    time: "وقت",
  },
  promocode: {
    title: "هل لديك كود خصم؟",
    subtitle: "أدخله أدناه",
    placeholder: "أدخل الكود الترويجي",
  },
  total: {
    subtotal: "المجموع الفرعي",
    discount: "خصم",
    service_fee: "رسوم الخدمة",
    delivery_fee: "رسوم التوصيل",
    products_total: "تكلفة المنتجات",
    tip: "الزكاة",
    total: "المجموع",
  },
  comment: {
    title: "ملاحظتك",
  },
  phone_verify_modal: {
    title: "التحقق من رقم الهاتف",
    enter: "أدخل الرمز من الرسالة القصيرة",
    sent_to: "الرمز المرسل إلى ",
    no_code: "لم تتلق الرمز؟",
    resend_btn: "إرسال مرة أخرى",
    error: "رمز غير صالح",
    success: "التحقق",
  },
  online_payment_modal: {
    redirect: "سوف يتم توجيهك إلى صفحة الدفع",
    btn_pay: "انتقال إلى الدفع",
    btn_cancel: "إلغاء",
    redirecting: "جاري التوجيه إلى صفحة الدفع ...",
  },
  pay_methods: {
    cash: "النقد",
    card: "البطاقة",
    online: "أونلاين",
    pay_with_cash: "دفع نقدًا",
    pay_with_card: "دفع ببطاقة الائتمان",
    pay_with_online: "دفع عبر الإنترنت",
  },
  validations: {
    required: "حقل مطلوب",
    phone: "أدخل رقم هاتف صحيح",
    email: "أدخل بريد إلكتروني صالح",
  },
  calendar: {
    date: "تاريخ",
  },
  phone_country_picker: {
    title: "اختر منطقة الهاتف",
    placeholder: "البحث باسم الدولة...",
    select: "تحديد",
  },
  menu_lobby: {
    welcome: "أهلاً بك",
    select_type: "يرجى تحديد نوع الطلب",
    search_ph: "الأطباق، القوائم، الفئات...",
    our_menus: "قوائمنا",
  },
  call_waiter: {
    title: "تحتاج مساعدة؟",
    subtitle: "استدعاء النادل للمساعدة",
    button: "استدعاء النادل",
    sent: "تم إرسال طلبك",
  },
  scan_qrcode: {
    title: "اتصل بطاولتك",
    subtitle: "امسح رمز الاستجابة السريعة على الطاولة",
    button: "مسح",
  },
  verify_location: {
    title: "الموقع الجغرافي",
    subtitle: "نحتاج إلى التحقق من موقعك",
    button: "التحقق",
    verified: "التحقق",
  },
  menu_product: {
    badge_spicy: "حار",
    badge_veggie: "نباتي",
    badge_alcohol: "كحولي",
    variants: "الإختيارات",
    modificators: "التعديلات",
    variants_single: "اختر على الأقل واحد من الإختيارات المقدمة",
    variants_min: "الحد الأدنى",
    variants_max: "الحد الأقصى",
    required: "مطلوب",
    modificators_any: "اختر أي كمية كنت ترغب بها من التعديلات المقدمة",
    comment_title: "تعليقك",
    comment_ph: "اكتب هنا",
    show_more: "عرض المزيد",
    show_less: "عرض أقل",
  },
  cart: {
    title: "سلة التسوق",
    order_type: "نوع الطلب",
    delivery_price: "سعر التوصيل",
    min_order: "الحد الأدنى لطلب المبلغ",
    place_order: "اطلب الآن",
    products: "منتجات",
    unavailable_item: "هذا المنتج غير متوفر في هذا النظام",
    add_more: "طلب المزيد من القائمة",
    comment_title: "المتطلبات الخاصة",
    comment_desc: "هل لديك أي متطلبات خاصة ، او حساسية للأغذية ؟",
    comment_ph: "اكتب هنا...",
    alcohol_alert:
      "أكد أنني فوق 21 سنة ويمكنني طلب المنتجات الذي تحتوي على الكحول",
  },
  geo_verification: {
    title: "التحقق من الموقع الجغرافي",
    desc: "نحتاج للتحقق من موقعك الجغرافي للمتابعة",
    please: "الرجاء إعطاء صلاحية الوصول إلى موقعك الجغرافي",
    verify: "أثبت موقعي الجغرافي",
    dismiss: "تجاهل",
    deny_title: "يبدو أنك نفذت عدم السماح بالوصول إلى موقعك",
    deny_subtitle: "الرجاء إعادة تحميل الصفحة وإعادة المحاولة",
    reload: "إعادة تحميل الصفحة",
    busy: "التحقق من موقعك الجغرافي...",
    confirmed: "تم التأكد من الموقع",
    out_of_range: "الخدمة غير متوفرة في موقعك",
  },
  order_product_card: {
    product_total: "إجمالي المنتج",
    remains: "الباقي:",
  },
  order_page: {
    title: "النظام الحالي",
    your_bill: "فاتورتك",
    select_all: "اختيار الكل",
  },
  order_payment_navbar: {
    none_selected: "اختر المنتجات للدفع",
    slider_disabled: "الدفع",
    slider_active: "انقر للدفع",
    total_to_pay: "المجموع للدفع",
    including_fees: "بما في ذلك الرسوم",
    left_unpaid: "متبقية غير مدفوعة",
  },
  pay_method_modal: {
    title: "طريقة الدفع",
    subtitle: "اختر كيف ستقوم بالدفع",
    descriptions: {
      cash: "ستدفع نقدا في المكان",
      card: "ستدفع ببطاقة في المكان",
      online: "ستدفع اونلاين",
    },
  },
  leave_tips: {
    title: "يمكنك ترك الضفيرة إذا أردت",
    thank_you: "شكراً لك!",
  },
  receipt: {
    total_paid: "المدفوع الكلي",
    including_taxes: "شامل الضرائب",
    service_provider: "مزود الخدمة",
    tech_details: "التفاصيل التقنية",
    order_number: "رقم الطلب",
    order_id: "رقم معرف الطلب",
    date_and_time: "التاريخ والوقت",
  },
  payment_details: {
    title: "تفاصيل الدفع",
    refunded: "استرداد",
    paid: "مدفوع",
    unpaid: "غير مدفوع",
  },
  archive: {
    active_order: "الطلب الحالي",
    archived_order: "الطلب المؤرشف",
    empty_title: "لا شيء هنا بعد الآن",
    empty_subtitle:
      "يمكنك الطلب من القائمة. سيظهر طلبك هنا بعد قبول الكافيه له.",
    open_menu: "فتح القائمة",
    title: "الطلبات",
    subtitle_1: "هنا يمكنك العثور على الطلبات الحالية والمؤرشفة الخاصة بك",
  },
  main_navbar: {
    menu: "القائمة",
    orders: "طلبات",
    home: "الصفحة الرئيسية",
  },
  error_modal: {
    title_generic: "حدث خطأ ما",
    title_payment: "فشل الدفع",
    title_order: "غير قادر على إنشاء طلب",
    title_not_found: "الصفحة غير موجودة",
    error_code: "رمز الخطأ",
    please_reload: "يرجى إعادة تحميل الصفحة وإعادة المحاولة",
    btn_back: "العودة",
    btn_reload: "إعادة تحميل",
    btn_home: "الصفحة الرئيسية",
  },
  split_payment: {
    title: "سيقسم هذا الإيصال إلى {n} دفعات",
    payment: "دفع",
    desc: "سنحتفظ بالأموال حتى يتم الانتهاء من جميع المدفوعات. إذا لم يتم الانتهاء من إجراء جميع الدفعات في غضون 15 دقيقة من وقت الدفعة الأولى، سيتم إعادة جميع الأموال تلقائيا.",
  },
  pending_order: {
    title: "طلب معلق",
    your_order: "طلبك",
    declined: "تم رفضه",
    pending: "معلق",
    desc: "تم إرسال طلبك وينتظر قبوله. بمجرد قبوله، سترى هذا الطلب بين صفحة الطلبات النشطة.",
  },
  cancelled_modal: {
    title: "تم إلغاء طلبك",
    reason: "السبب",
    your_order: "طلبك",
  },
  closed_banner: {
    title_cafe: "الكافيه مغلقة حاليًا",
    title_delivery: "التوصيل غير متوفر حاليًا",
    title_takeaway: "التسليم الآن غير متوفر",
    title_menu: "القائمة مغلقة",
    desc_cafe:
      "الكافيه مغلقة حاليًا، ولكن يمكنك طلب بعد الموعد لمتابعة استخدامها.",
    desc_delivery:
      "طريقة التوصيل غير متاحة حاليًا. من فضلك، حاول مرة أخرى في وقت لاحق.",
    desc_menu:
      "القائمة مغلقة حاليًا، ولكن يمكنك الطلب بعد التاريخ لاستخدامها مرة أخرى.",
  },
  split_payment_timeout: {
    title: "انتهاء توقيت الدفع المقسم",
    desc: "لم تكمل كافة المدفوعات خلال 15 دقيقة من وقت الدفع الأول. سيتم ارجاع كافة الأموال تلقائيًا في دقائق قريبة.",
  },
};
