<template>
  <div class="skeleton-loader-wrapper">
    <div class="app-skeleton-loader" :style="dynamicStyle"></div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: '100%'
    },
    height: {
      type: [Number, String],
      default: '32px'
    }
  },
  computed: {
    dynamicStyle () {
      const toValidCssValue = (arg) =>
        typeof arg === 'string' ? arg : `${arg}px`

      return {
        width: toValidCssValue(this.width),
        height: toValidCssValue(this.height),
        'border-radius': '8px'
      }
    }
  }
}
</script>

<style scoped>
@keyframes loader {
  from {
    background: linear-gradient(90deg, #f2f4f7 0%, #f2f4f7 100%);
  }

  33% {
    background: linear-gradient(90deg, #fafcff 0%, #f2f4f7 14.58%);
  }

  66% {
    background: linear-gradient(
      90deg,
      #f2f4f7 0%,
      #fafcff 58.47%,
      #f2f4f7 100%
    );
  }

  to {
    background: linear-gradient(90deg, #f2f4f7 77.6%, #fafcff 99.77%);
  }
}

.app-skeleton-loader {
  border-radius: 20px;
  animation-duration: 0.6s;
  animation-name: loader;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.skeleton-loader-wrapper {
    width: fit-content;
    height: fit-content;
    padding: 4px 0;
}
</style>
