<template>
  <div class="order-page-header">
    <BaseIconButton
      textColor="content-base-secondary"
      variant="ghost"
      size="md"
      icon="arrow-left"
      @click="goBack"
    />
    <div class="order-page-header-info">
      <div class="qrw-label-md bold qrw-text-content-base-primary">
        {{ $t("order_page.title") }}
      </div>
      <div
        class="gap-column-zero qrw-text-content-base-secondary order-page-header-subtitle"
      >
        <div class="qrw-label-xs">Eat-in</div>
        <BaseIcon size="mxs" icon="dot" />
        <div class="qrw-label-xs">
          {{ insideData.table_name }}, {{ insideData.section_name }}
        </div>
        <BaseIcon size="mxs" icon="dot" />
        <div class="gap-column-2xs">
          <BaseIcon icon="users" size="mxs" />
          <div class="qrw-label-xs">
            {{ order.guests.length }}
          </div>
        </div>
      </div>
    </div>

    <BaseIconButton
      textColor="content-base-secondary"
      variant="ghost"
      size="md"
      icon="share-02"
      @click="shareLink"
    />
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subtitle: String,
    leftIcon: {
      type: String,
      default: 'arrow-left'
    },
    rightIcon: {
      type: String,
      default: 'pencil'
    }
  },
  methods: {
    shareLink () {
      window.navigator.share({
        title: 'Order',
        url: window.location.href
      })
    },
    goBack () {
      // this.$router.push(
      //   this.$store.getters.buildRouterNavigateObject('lobby')
      // )
      this.$router.go(-1)
    }
  },
  computed: {
    cafe () {
      return this.$store.state.cafe
    },
    insideData () {
      return this.$store.state.inside
    },
    order () {
      return this.$store.state.order
    }
  }
}
</script>

<style scoped>
.order-page-header {
  display: flex;
  padding: 12px 16px 10px 16px;
  width: 100%;
  box-sizing: border-box;
  flex-direction: row;
  column-gap: 16px;
  justify-content: space-between;
  align-items: flex-start;
}

.order-page-header-info {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  justify-content: center;
  align-items: center;
  padding: 1px 0;
}

.order-page-header-subtitle {
  align-items: center;
}
</style>
