<template>
  <div class="category-list-card" ref="container">
    <div class="qrw-heading-xs bold qrw-content-base-primary">
      {{ category.name }}
    </div>
    <ServiceGridCard
      :product="product"
      v-for="product in category.products"
      :key="product._id"
      extended
    />
  </div>
</template>

<script>
import ServiceGridCard from '@/components/menu/ServiceGridCard.vue'

export default {
  props: {
    category: Object
  },
  components: {
    ServiceGridCard
  },
  mounted () {
    this.$nextTick(() => {
      this.$store.commit('setCategoryUiOffset', {
        categoryId: this.category._id,
        offset: this.$refs.container.offsetTop,
        view: 'list'
      })
    })
  }
}
</script>

<style scoped>
.category-list-card {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  row-gap: 16px;
}
</style>
