let headerHeight = 0;
let footerHeight = 0;

let isFrozen = false;

let subscribers = [];

function triggerSubscribers() {
  subscribers.forEach((s) => s());
}

export function updateAdaptiveHeaderHeight(elementOrNumber) {
  const newValue =
    typeof elementOrNumber === "number"
      ? elementOrNumber
      : elementOrNumber.offsetHeight;

  if (headerHeight !== newValue) {
    console.log(`Updated adaptive header height to ${headerHeight}px`);
  }

  headerHeight = newValue;

  if (!isFrozen) triggerSubscribers();
}

export function setAdaptiveFrozen(value) {
  isFrozen = value;

  console.log(`AUI is now ${isFrozen ? "frozen" : "unfrozen"}`);
}

export function getAdaptiveStylesUnderHeader() {
  return {
    "padding-top": `${headerHeight}px`,
  };
}

export function updateAdaptiveFooterHeight(elementOrNumber) {
  footerHeight =
    typeof elementOrNumber === "number"
      ? elementOrNumber
      : elementOrNumber.offsetHeight;

  console.log(`Updated adaptive footer height to ${footerHeight}px`);

  if (!isFrozen) triggerSubscribers();
}

export function getAdaptiveHeaderHeight() {
  return headerHeight;
}

export function getAdaptiveFooterHeight() {
  return footerHeight;
}

export function addAdaptiveSubscriber(subscriber) {
  subscribers.push(subscriber);
}

export function removeAdaptiveSubscriber(subscriber) {
  subscribers = subscribers.filter((s) => s !== subscriber);
}

export function clearSubscribers() {
  subscribers = [];
}

export function resetPageScroll() {
  window.document.documentElement.scrollTop = 0;
}
