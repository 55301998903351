<template>
  <div
    class="app-unpaid-order-navbar gap-row-zero"
    @click="openOrder"
  >
    <BaseDivider color="border-base-tertiary" />
    <div class="app-unpaid-order-navbar-content">
      <div class="gap-row-2xs w-100">
        <div class="qrw-label-sm qrw-text-content-base-secondary">
          {{ $tc("units.items", orderItems.length) }} {{ $t('order_payment_navbar.left_unpaid') }}
        </div>
        <PriceTag
          :value="order.total"
          class="qrw-label-md bold qrw-text-content-base-primary"
        />
      </div>
      <BasePushButton
        variant="fill"
        bgColor="background-success-default"
        textColor="content-base-inverse-primary"
        size="md"
        class="pay-button"
      >
        {{ $t("common_actions.pay") }}
      </BasePushButton>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openOrder() {
      this.$router.push(this.$store.getters.buildRouterNavigateObject('order'))
    }
  },
  computed: {
    mode() {
      return this.$store.state.mode
    },
    order() {
      return this.$store.state.order
    },
    orderItems() {
      return this.$store.getters.orderItems
    },
    canOrderBePaid() {
      return this.$store.getters.canOrderBePaid
    }
  }
}
</script>

<style scoped>
.app-unpaid-order-navbar {
  background: var(--qrw-background-success-secondary);
}

.app-unpaid-order-navbar-content {
  width: 100%;
  box-sizing: border-box;

  background: var(--qrw-background-success-secondary);

  padding: 8px 16px;
  column-gap: 16px;

  align-items: center;
  display: flex;
}

.pay-button {
  white-space: nowrap;
  width: 122px;
  min-width: 122px;
}
</style>
