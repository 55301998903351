<template>
  <div
    class="cart-page-header"
    ref="header"
  >
    <div class="cart-page-header-top">
      <BaseIconButton
        textColor="content-base-secondary"
        variant="ghost"
        size="md"
        icon="arrow-left"
        @click="$emit('back')"
      />
      <div class="header-info">
        <div class="qrw-label-md bold qrw-text-content-base-primary">
          {{ $t('cart.title') }}
        </div>
        <div class="gap-column-2xs">
          <div class="qrw-label-xs qrw-text-content-base-tertiary">
            {{ modeTitle }}
          </div>
          <BaseIcon
            icon="dot"
            size="mxs"
            class="qrw-text-content-base-tertiary"
          />
          <div class="qrw-label-xs qrw-text-content-base-tertiary">
            {{ $tc('units.items', cartItemsQuantity) }}
          </div>
          <BaseIcon
            icon="dot"
            size="mxs"
            class="qrw-text-content-base-tertiary"
          />
          <PriceTag
            class="qrw-label-xs qrw-text-content-base-tertiary"
            :value="cartTotal"
          />
        </div>

      </div>
      <BaseIconButton
        textColor="content-base-secondary"
        variant="ghost"
        size="md"
        icon="share-02"
        @click="$emit('share')"
      />
    </div>
    <BaseDivider color="border-base-tertiary" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      mode: 'delivery'
    }
  },
  computed: {
    cafe() {
      return this.$store.state.cafe
    },
    currency() {
      return this.$store.state.currency
    },
    cartItemsQuantity() {
      return this.$store.getters['cart/items'].reduce((acc, item) => acc + item.quantity, 0)
    },
    cartTotal() {
      return this.$store.getters['cart/total']
    },
    modeTitle() {
      return this.$store.getters.modeTitle
    }
  }
}
</script>

<style scoped>
.cart-page-header {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
}

.cart-page-header-top {
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  align-items: flex-start;
  padding: 12px 16px 12px 16px;
}

.header-info {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  row-gap: 4px;
  text-align: center;
  align-items: center;
  overflow: hidden;
  padding: 1px 0;
}

.hidden-icon {
  visibility: hidden;
}

.truncated {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
</style>
