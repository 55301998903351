<template>
  <div class="app-page-loader">
    <img src="https://cdn.qrwaiter.com.ua/assets/logo-v2.svg" />

    <div class="gap-row-xs loading-block">
      <div class="qrw-paragraph-md qrw-text-content-base-secondary">
        {{randomPhrase}}
      </div>
      <BaseProgressBar
        size="lg"
        :value="uiLoadingProgress"
        class="loader-bar"
      />
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      uiLoadingProgress: 0,
      intervalId: null,

      randomPhrase: null
    }
  },
  props: {
    target: {
      type: String,
      default: 'menu'
    }
  },
  methods: {
    increaseProgress() {
      if (this.uiLoadingProgress >= 95) {
        clearInterval(this.intervalId)
        return
      }

      this.uiLoadingProgress += 3
    }
  },
  mounted() {
    this.intervalId = setInterval(this.increaseProgress, 100)

    const trKey = 'system_loader.phrases_' + this.target

    const phrases = this.$t(trKey)

    this.randomPhrase = phrases[Math.floor(Math.random() * phrases.length)]
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
  }
}
</script>

<style scoped>
.app-page-loader {
  width: 100vw;
  height: 100vh;

  z-index: 2000;
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  row-gap: 64px;

  background: var(--qrw-background-base-secondary);
}

.app-page-loader img {
  width: 80px;
  height: 80px;
}

.loader-bar {
  width: 200px !important;
}

.loading-block {
  max-width: 256px;
  text-align: center;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
</style>
