<template>
  <FoundationModal
    :showHeader="false"
    :fullHeight="false"
    style="z-index: 3000"
  >
    <div class="online-payment-body">
      <img :src="gatewayLogoUrl" />

      <BaseProgressBar
        :value="redirectingProgress"
        size="lg"
      />

      <div class="qrw-paragraph-md qrw-text-content-base-secondary">
        {{ $t("online_payment_modal.redirecting") }}
      </div>
    </div>
    <!-- <div class="online-payment-actions">
      <a :href="payLink" class="w-100">
        <BasePushButton
          class="w-100"
          variant="primary"
          bgColor="background-base-inverse-primary"
          textColor="content-base-inverse-primary"
          size="lg"
        >
          {{ $t("online_payment_modal.btn_pay") }}
        </BasePushButton>
      </a>

      <BasePushButton
        variant="ghost"
        bgColor="background-base-inverse-primary"
        textColor="content-base-secondary"
        size="lg"
        @click="$emit('close')"
      >
        {{ $t("online_payment_modal.btn_cancel") }}
      </BasePushButton>
    </div> -->
  </FoundationModal>
</template>

<script>
import FoundationModal from './FoundationModal.vue'

export default {
  props: {
    payLink: String,
    gateway: String
  },
  data() {
    return {
      redirectingProgress: 0
    }
  },
  methods: {
    tick() {
      const redirectDelay = 2 * 1000

      this.redirectingProgress++

      if (this.redirectingProgress >= 100) {
        window.location.href = this.payLink
      } else {
        setTimeout(this.tick.bind(this), redirectDelay / 100)
      }
    }
  },
  computed: {
    gatewayLogoUrl() {
      return `https://cdn.qrwaiter.com.ua/assets/integrations/${this.gateway}.jpg`
    }
  },
  mounted() {
    this.tick()
  },
  components: {
    FoundationModal
  }
}
</script>

<style scoped>
.online-payment-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 32px;
  padding: 16px 16px 48px 16px;
  box-sizing: border-box;
}

.online-payment-body img {
  width: 100%;
  height: 160px;
  max-height: 160px;
  object-fit: cover;
  border-radius: 12px;
}

/* .online-payment-actions {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 24px;
  box-sizing: border-box;
} */
</style>
