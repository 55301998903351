<template>
  <div
    class="app-cafe-schedule"
    :class="isCollapsed ? 'collapsed' : 'extended'"
  >
    <div
      class="schedule-toggle"
      @click="isCollapsed = !isCollapsed"
    >
      <div class="collapsed-schedule-info">
        <div class="gap-row-2xs">
          <div class="qrw-label-lg qrw-text-content-base-primary bold">
            {{ $t("schedule.schedule") }}
          </div>
          <div
            class="qrw-label-sm qrw-text-content-base-tertiary"
            v-if="currentDisplayState.open"
          >
            <span class="qrw-text-content-base-primary">
              {{ $t("schedule.open_now") }}</span>
            ({{ $t("schedule.closes_in") }} {{ currentDisplayState.time }})
          </div>
          <div
            class="qrw-label-sm qrw-text-content-base-tertiary"
            v-else
          >
            <span class="qrw-text-content-error-primary">
              {{ $t("schedule.closed_now") }}</span>
            ({{ $t("schedule.opens_in") }} {{ currentDisplayState.time }})
          </div>
        </div>
      </div>
      <BaseIconButton
        size="md"
        :icon="isCollapsed ? 'chevron-down' : 'chevron-up'"
        variant="ghost"
        textColor="content-base-tertiary"
        hoverColor="transparent"
      />
    </div>
    <transition name="expand">
      <ScheduleContents
        :schedule="schedule"
        v-if="!isCollapsed"
      />
    </transition>
  </div>
</template>

<script>
import { weekDayToWeekdayKey, getEuropeanWeekDaysKeys } from '@/utils'

import ScheduleContents from './ScheduleContents.vue'

import moment from 'moment'

export default {
  props: {
    schedule: Object,
    computedPair: Object,
    schedulePairs: Array
  },
  data() {
    return {
      isCollapsed: true
    }
  },
  methods: {
    formatTime(h, m) {
      return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`
    },
    formatDifference(target) {
      const diffTotalMinutes = Math.abs(moment(target).diff(moment(), 'minutes'))
      const diffHours = Math.floor(diffTotalMinutes / 60)
      const diffMinutes = diffTotalMinutes % 60

      console.log(diffTotalMinutes)

      let final = ''

      if (diffHours > 0) final += `${diffHours} ${this.$t('units.hours')}`
      if (diffMinutes > 0) {
        final += ` ${diffMinutes} ${this.$t('units.minutes')}`
      }

      return final.trim()
    }
  },
  computed: {
    todayWeekdayKey() {
      return weekDayToWeekdayKey(moment().day())
    },
    weekdayKeys() {
      return getEuropeanWeekDaysKeys()
    },
    currentDisplayState() {
      if (moment().isBetween(this.computedPair.start, this.computedPair.end)) {
        return {
          open: true,
          time: this.formatDifference(this.computedPair.end)
        }
      } else {
        if (moment().isBefore(this.computedPair.start)) {
          return {
            open: false,
            time: this.formatDifference(this.computedPair.start)
          }
        }

        return {
          open: false,
          time: this.formatDifference(
            this.schedulePairs.find(pair => pair && pair.start && moment(pair.start).isAfter(this.computedPair.end))
              .start
          )
        }
      }
    }
  },
  components: {
    ScheduleContents
  }
}
</script>

<style>
.schedule-toggle {
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
}

.collapsed-schedule-info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 4px;
  width: 100%;
}

.app-cafe-schedule.extended {
  border-radius: 12px;
}

.app-cafe-schedule {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

@keyframes expand {
  0% {
    height: 0;
    opacity: 0.2;
  }
  100% {
    height: 354px;
    opacity: 1;
  }
}

.expand-enter-active {
  animation: expand 0.35s ease-in-out;
}

.expand-leave-active {
  animation: expand 0.35s reverse ease-in-out;
}
</style>
