<template>
    <div class="app-delivery-tariff-wrapper" v-if="deliveryAddress && deliveryAddress.lat">
        <div class="app-delivery-tariff-card" @click.prevent="modalShown = true">
            <img src="/images/delivery-boxes.svg" class="boxes">
            <div class="qrw-label-lg bold qrw-text-content-base-primary">
                {{ $t('delivery_tariff.title') }}
            </div>
            <div class="combobox" v-if="costMode === 'tariff' && tariffs.length > 1">
                <div class="qrw-label-md bold">
                    {{ selectedTariff ? selectedTariff.name : '' }}
                </div>
                <BaseIcon class="qrw-text-content-base-tertiary" icon="chevron-down" size="sm" />
            </div>
            <div class="qrw-paragraph-sm qrw-text-content-base-secondary" v-else-if="costMode === 'partner'">
                {{ $t('delivery_tariff.partner') }}
            </div>
            <div class="gap-column-sm" v-if="costMode === 'tariff'">
                <div class="qrw-label-md qrw-text-content-base-primary w-100">
                    {{ $t('delivery_tariff.price') }}
                </div>
                <div class="qrw-label-md bold qrw-text-content-base-primary">
                    {{ currency.symbol }}{{ selectedTariff ? selectedTariff.price : 0 }}
                </div>
            </div>
        </div>

        <DeliveryTariffModal v-model="selectedTariffId" :tariffs="tariffs" @close="modalShown = false"
            v-if="modalShown" />
    </div>
</template>

<script>
import DeliveryTariffModal from "@/components/modals/DeliveryTariffModal.vue";

import DeliveryAPI from '@/api/delivery'

export default {
    data () {
        return {
            modalShown: false,
            costMode: 'free',
            tariffs: []
        }
    },
    watch: {
        deliveryAddress () {
            this.fetchTariffs()
        }
    },
    methods: {
        async fetchTariffs () {
            if (!this.deliveryAddress) return;
            if (!this.deliveryAddress.lat) return

            const response = await DeliveryAPI.estimateTariffs(
                this.mixinCafeLinkCode,
                [this.deliveryAddress.street, this.deliveryAddress.city, this.deliveryAddress.country].filter(Boolean).join(', '),
                this.deliveryAddress.lat,
                this.deliveryAddress.lon
            );

            this.tariffs = response.tariffs
            this.costMode = response.type

            // If the tariff is not set or we cannot find the new one - reset it
            if (this.tariffs.length) {
                if (!this.selectedTariffId || !this.selectedTariff) {
                    this.selectedTariffId = this.tariffs[0]._id
                }
            }
        }
    },
    computed: {
        currency () {
            return this.$store.state.currency
        },
        deliveryAddress () {
            return this.$store.state.delivery.address
        },
        selectedTariffId: {
            get () {
                return this.$store.state.delivery.tariff
            },
            set (v) {
                this.$store.commit('setDeliveryTariff', v);
            }
        },
        selectedTariff () {
            if (!this.tariffs) return null
            return this.tariffs.find(t => t._id === this.selectedTariffId)
        }
    },
    components: {
        DeliveryTariffModal
    },
    mounted () {
        this.fetchTariffs();
    }
}
</script>

<style scoped>
.app-delivery-tariff-wrapper {
    padding: 0 16px;
    box-sizing: border-box;
}

.app-delivery-tariff-card {
    padding: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    position: relative;
    overflow: hidden;

    background: var(--qrw-background-base-primary);
    border-radius: 12px;
    box-shadow: var(--qrw-shadow-card-light);
    box-sizing: border-box;
}

.boxes {
    position: absolute;
    top: -8px;
    right: -8px;
}

.combobox {
    background: var(--qrw-background-base-tertiary);
    color: var(--qrw-content-base-secondary);
    border-radius: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
    padding: 10px 12px 10px 20px;
}
</style>