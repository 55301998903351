<template>
  <div
    class="order-page-header qrw-app-top-container"
    v-qrw-is-header
  >
    <BaseIconButton
      textColor="content-base-secondary"
      variant="ghost"
      size="md"
      icon="arrow-left"
      @click="goBack"
    />
    <div class="order-page-header-info">
      <div class="qrw-label-md bold qrw-text-content-base-primary">
        {{modeTitle}}
      </div>
      <div class="gap-column-zero qrw-text-content-base-secondary order-page-header-subtitle">
        <!-- <div class="qrw-label-xs">Eat-in Order</div>
        <BaseIcon size="mxs" icon="dot" /> -->
        <div class="qrw-label-xs">
          {{ headerSubtitleDate }}
        </div>
        <BaseIcon
          size="mxs"
          icon="dot"
        />
        <div class="qrw-label-xs">
          {{ date | formatTime }}
        </div>
      </div>
    </div>

    <BaseIconButton
      textColor="content-base-secondary"
      variant="ghost"
      size="md"
      icon="share-02"
      @click="shareLink"
    />
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    date: [Date, String, Number]
  },
  methods: {
    shareLink() {
      window.navigator.share({
        title: 'Order',
        url: window.location.href
      })
    },
    goBack() {
      this.$router.push(this.$store.getters.buildRouterNavigateObject('lobby'))
    }
  },
  computed: {
    cafe() {
      return this.$store.state.cafe
    },
    insideData() {
      return this.$store.state.inside
    },
    headerSubtitleDate() {
      return moment(this.date).format('DD MMM YYYY')
    },
    modeTitle() {
      return this.$store.getters.modeTitle
    }
  }
}
</script>

<style scoped>
.order-page-header {
  display: flex;
  padding: 12px 16px 10px 16px;
  width: 100%;
  box-sizing: border-box;
  flex-direction: row;
  column-gap: 16px;
  position: fixed;
  top: 0;
  background: var(--qrw-background-base-secondary-08);
  z-index: 20;
  justify-content: space-between;
  align-items: flex-start;
}

.order-page-header-info {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  justify-content: center;
  align-items: center;
  padding: 1px 0;
}

.order-page-header-subtitle {
  align-items: center;
}
</style>
