var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-hero",class:{ 'collapsed': _vm.collapsed }},[_c('div',{staticClass:"cafe-general-info",class:{ collapsed: _vm.collapsed }},[_c('div',{staticClass:"gap-row-xs w-100"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.collapsed),expression:"!collapsed"}],staticClass:"cafe-general-logo-row"},[(_vm.cafe.image)?_c('img',{staticClass:"cafe-logo animate",attrs:{"src":_vm.cafe.image}}):_c('BaseWorkspaceAvatar',{attrs:{"size":72}}),_c('FlagIcon',{on:{"click":function($event){return _vm.$emit('switchLanguage')}}})],1),_c('div',{staticClass:"cafe-titles",class:{
  'gap-row-1xs': !_vm.collapsed,
  'collapsed-titles': _vm.collapsed,
}},[_c('div',{staticClass:"cafe-name-title bold qrw-text-content-base-primary",class:{
  'qrw-heading-sm': !_vm.collapsed,
  'qrw-label-lg': _vm.collapsed,
}},[_vm._v(" "+_vm._s(_vm.cafe.name)+" ")]),_c('div',{staticClass:"gap-column-xs description-row animate"},[(_vm.cafe.description)?_c('div',{staticClass:"qrw-text-content-base-secondary w-100",class:{
  'qrw-label-sm': !_vm.collapsed,
  'qrw-label-xs': _vm.collapsed
}},[_vm._v(" "+_vm._s(_vm.cafe.description.split(" ").slice(0, 3).join())+" ")]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.collapsed && _vm.priceLevelAsNumber),expression:"!collapsed && priceLevelAsNumber"}],staticClass:"qrw-label-sm qrw-text-content-base-primary animate gap-column-3xs"},_vm._l((4),function(n){return _c('span',{key:n,class:n <= _vm.priceLevelAsNumber ? 'qrw-text-content-base-primary' : 'qrw-text-content-base-tertiary'},[_vm._v(" $ ")])}),0)])])]),_c('FlagIcon',{directives:[{name:"show",rawName:"v-show",value:(_vm.collapsed),expression:"collapsed"}],on:{"click":function($event){return _vm.$emit('switchLanguage')}}})],1),_c('CafeCallRow',{directives:[{name:"show",rawName:"v-show",value:(_vm.showContacts && _vm.cafe._id == 567),expression:"showContacts && cafe._id == 567"},{name:"qrw-class-on-scroll",rawName:"v-qrw-class-on-scroll",value:({
  limit: 187,
  returnLimit: 1,
  cssClass: 'h-call-row-scrolled'
}),expression:"{\n  limit: 187,\n  returnLimit: 1,\n  cssClass: 'h-call-row-scrolled'\n}"}],staticClass:"animate"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }