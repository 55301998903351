<template>
  <div
    class="app-order-payment-navbar"
    :class="{
      expanded: isExpanded,
    }"
  >
    <BaseDivider
      color="border-base-tertiary"
      v-if="!isExpanded"
    />
    <div
      class="payment-none qrw-label-sm"
      v-if="!canPay"
    >
      {{ customDisabledText || $t("order_payment_navbar.none_selected") }}
    </div>
    <div
      class="payment-available"
      :class="{
        'with-splits': hasSplitPayment
      }"
      @click="toggleExpandedUI"
      v-else
    >
      <BaseIcon
        :icon="isExpanded ? 'minimize-03' : 'expand-03'"
        size="sm2"
        class="expand-icon"
        v-if="hasAdditionalTotalItems"
      />

      <div class="gap-row-txs w-100">
        <GenericTotal
          :collapsed="!isExpanded"
          :alreadyPaid="!makingNewOrder"
          :value="{
              sum: total.sum,
              tip: total.tip,
              delivery_fee: total.delivery,
              total: total.total
            }"
        />
      </div>
    </div>
    <SplitPaymentBanner
      :splits="splits"
      v-if="hasSplitPayment"
    />
    <div class="payment-slider-container">
      <div
        class="payment-slider"
        ref="slider"
        :class="{ active: canPay }"
      >
        <div class="qrw-label-md">
          {{
            canPay
              ? $t("pay_methods.pay_with_"+payMethod)
              : $t("order_payment_navbar.slider_disabled")
          }}

          <template v-if="hasSplitPayment">
            {{ paidSplitsCount+1 }}/{{ splits.length}}
          </template>
        </div>

        <div
          class="slider-control"
          @touchstart="startSliderMoving"
          @mousedown="startSliderMoving"
          @mouseup="stopSliderMoving"
          :style="sliderControlStyle"
        >
          <BaseIcon
            icon="chevron-right-double"
            size="sm2"
          />
        </div>
      </div>

      <BaseIconButton
        size="lg"
        variant="outlined"
        borderColor="border-base-tertiary"
        :icon="payMethodIcon"
        class="pay-method-button"
        @click="$emit('changePayMethod')"
      />
    </div>
  </div>
</template>

<script>
import SplitPaymentBanner from '@/components/order/SplitPaymentBanner.vue'
import GenericTotal from '@/components/order/GenericTotal.vue'

export default {
  props: {
    total: Object,
    payMethod: String,
    splits: Array,
    customDisabledText: String,
    disabled: Boolean,
    makingNewOrder: Boolean
  },
  data() {
    return {
      sliderMoving: false,
      sliderPos: 0,
      sliderLastTouchPosition: 0,

      isInProcessOfPayment: false,
      isExpanded: false
    }
  },
  watch: {
    isExpanded() {
      this.$emit('expandChanged', this.isExpanded)

      window.document.body.style.overflow = this.isExpanded ? 'hidden' : 'auto'
    }
  },
  methods: {
    onSliderMouseMoved(mouseOrTouchEvent) {
      const delta = mouseOrTouchEvent.changedTouches
        ? mouseOrTouchEvent.changedTouches[0].clientX - this.sliderLastTouchPosition
        : mouseOrTouchEvent.movementX

      this.sliderPos += delta

      this.sliderLastTouchPosition = mouseOrTouchEvent.changedTouches ? mouseOrTouchEvent.changedTouches[0].clientX : 0

      this.sliderPos = Math.max(0, this.sliderPos)

      const controlWidth = 48
      const sliderWidth = this.$refs.slider.clientWidth
      const maxAllowedSliderPos = sliderWidth - controlWidth

      this.sliderPos = Math.min(maxAllowedSliderPos, this.sliderPos)
    },
    startSliderMoving(ev) {
      if (!this.canPay) return
      this.sliderMoving = true

      if (ev.changedTouches) {
        this.sliderLastTouchPosition = ev.changedTouches[0].clientX
      }

      window.addEventListener('mousemove', this.onSliderMouseMoved)
      window.addEventListener('mouseup', this.stopSliderMoving)

      window.addEventListener('touchmove', this.onSliderMouseMoved)
      window.addEventListener('touchend', this.stopSliderMoving)
    },
    stopSliderMoving() {
      const controlWidth = 48
      const sliderWidth = this.$refs.slider.clientWidth
      const confirmingSliderPos = sliderWidth - controlWidth * 1.3

      if (this.sliderPos >= confirmingSliderPos) {
        if (!this.isInProcessOfPayment) this.$emit('confirm')
        // this.isInProcessOfPayment = true
        this.sliderPos = 0
      } else {
        this.sliderPos = 0
      }

      this.sliderMoving = false
      window.removeEventListener('mousemove', this.onSliderMouseMoved)
      window.removeEventListener('mouseup', this.stopSliderMoving)
    },
    toggleExpandedUI() {
      if (this.hasAdditionalTotalItems) this.isExpanded = !this.isExpanded
    }
  },
  computed: {
    canPay() {
      return this.total && this.total.total && !this.disabled
    },
    currency() {
      return this.$store.state.currency
    },
    sliderControlStyle() {
      return {
        left: `${this.sliderPos}px`
      }
    },
    payMethodIcon() {
      return {
        cash: 'bank-note',
        card: 'credit-card',
        online: 'globe'
      }[this.payMethod]
    },
    hasAdditionalTotalItems() {
      return !!this.total.tip || this.total.delivery
    },
    hasSplitPayment() {
      return this.splits && this.splits.length > 1 && this.payMethod === 'online'
    },
    paidSplitsCount() {
      return this.splits && this.splits.filter(split => split.paid_at).length
    }
  },
  components: {
    SplitPaymentBanner,
    GenericTotal
  }
}
</script>

<style scoped>
.app-order-payment-navbar {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.payment-none {
  display: flex;
  justify-content: center;
  padding: 16px 16px 8px 16px;
  color: var(--qrw-content-base-tertiary);
}

.payment-slider-container {
  display: flex;
  padding: 12px 16px;
  column-gap: 16px;

  align-items: center;
}

.payment-slider {
  border-radius: 12px;
  width: 100%;
  border: 1px solid var(--qrw-border-base-tertiary);
  position: relative;
  display: flex;
  justify-content: center;
  box-sizing: border-box;

  height: 48px;
  align-items: center;
  color: var(--qrw-content-base-tertiary);
}

.payment-slider.active {
  color: var(--qrw-content-success-primary);
}

.slider-control {
  border-radius: 12px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--qrw-background-base-inverse-primary-10);
  opacity: 0.4;
  color: var(--qrw-content-base-inverse-primary);
  position: absolute;
  left: 0;
}

.pay-method-button {
  width: 72px;
  min-width: 72px;
  max-width: 72px;
  justify-content: center;
  align-items: center;
}

.payment-slider.active .slider-control {
  opacity: 1;
  background: var(--qrw-background-success-default);
  box-shadow: var(--qrw-shadow-card-light);
}

.payment-available {
  display: flex;
  padding: 16px 16px 4px 16px;
  flex-direction: row;
  column-gap: 16px;
  position: relative;
  justify-content: center;
  transition: 0.35s all;
}

.payment-available.with-splits {
  padding-bottom: 12px;
}

.expanded {
  border-radius: 16px 16px 0 0;
  transition: 0.35s all;
}

.expand-icon {
  position: absolute;
  top: 0;
  color: var(--qrw-content-base-tertiary);
  justify-self: center;
}

.total-tag {
  white-space: nowrap;
}
</style>
