<template>
  <BaseSimpleModal
    :fullHeight="false"
    :showHeader="false"
    :showFooter="false"
    modalClass="qrw-fit-height-modal"
    @backdropClick="$emit('close')"
  >
    <div class="cancelled-body">
      <div class="cancelled-header">
        <BaseIcon
          icon="x-circle"
          class="qrw-text-content-error-primary"
          size="sm2"
        />
        <div class="header-title qrw-heading-xs bold qrw-text-content-error-primary">
          {{ $t("cancelled_modal.title") }}
        </div>
      </div>
      <BaseDivider color="border-error-tertiary" />
      <div class="cancelled-scroll-container">
        <div class="gap-row-1xs">
          <div class="qrw-label-lg bold qrw-text-content-base-primary">
            {{ $t("cancelled_modal.reason") }}
          </div>
          <div class="qrw-paragraph-md qrw-text-content-base-primary">
            {{ reason }}
          </div>
        </div>

        <div class="gap-row-1xs">
          <div class="qrw-label-lg bold qrw-text-content-base-primary">
            {{ $t("cancelled_modal.your_order") }}
          </div>
          <CartItem
            v-for="item in list"
            :item="item"
            :key="item._id"
            readonly
            cancelled
          />
        </div>
      </div>

    </div>

    <div class="cancelled-modal-footer gap-row-zero w-100 qrw-app-bottom-container">
      <BaseDivider color="border-error-tertiary" />
      <div class="button-container">
        <BasePushButton
          variant="ghost"
          borderColor="border-base-tertiary"
          textColor="content-base-secondary"
          size="lg"
          @click="$emit('close')"
          class="w-100"
        >
          {{ $t("common_actions.close") }}
        </BasePushButton>
      </div>
    </div>
  </BaseSimpleModal>
</template>

<script>
import CartItem from '@/components/cart/CartItem.vue'

export default {
  data() {
    return {}
  },
  props: {
    reason: String,
    list: Array
  },
  components: {
    CartItem
  }
}
</script>

<style scoped>
.cancelled-body {
  background: var(--qrw-background-error-primary);
  display: flex;
  flex-direction: column;
}

.cancelled-header {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
}

.cancelled-texts {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 16px;
  text-align: center;
}

.header-title {
  width: 100%;
  text-align: center;
}

.button-container {
  display: flex;
  box-sizing: border-box;
  padding: 12px 16px;
  row-gap: 8px;
}

.cancelled-modal-footer {
  background: var(--qrw-background-error-primary);
}

.cancelled-scroll-container {
  max-height: 65vh;
  overflow-y: auto;
  display: flex;
  row-gap: 24px;
  flex-direction: column;
  padding: 24px 16px;
}
</style>
