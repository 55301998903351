import Base from "../base";

export default {
  prepare: (code, cartId) =>
    Base.get(`/inside/${code}/prepare`, {
      cart_id: cartId,
    }),
  verifyGeolocation: (code, lat, lon) =>
    Base.post(`/inside/${code}/verify-geolocation`, { lat, lon }),
  callWaiter: (code) => Base.post(`/inside/${code}/call-waiter`),
  createOrder: (code, cartId, geolocationToken, comment, visitorId) =>
    Base.post(`/inside/${code}/create`, {
      cart_id: cartId,
      geolocation_token: geolocationToken,
      comment,
      visitor_id: visitorId,
    }),
  payOrder: (orderId, payMethod, selectedItems, tip) =>
    Base.post(`/orders/${orderId}/pay`, {
      pay_method: payMethod,
      selected_items: selectedItems,
      tip,
    }),
  getOrder: (orderId) => Base.get(`/orders/${orderId}`),
  appendOrder: (orderId, cartId, comment, visitorId) =>
    Base.post(`/orders/${orderId}/append`, {
      cart_id: cartId,
      comment,
      visitor_id: visitorId,
    }),
  getArchive: (cafeId, visitorId, mode) =>
    Base.get("/archive", {
      cafe_id: cafeId,
      visitor_id: visitorId,
      mode,
    }),
  estimateSplitPayments: (orderId, items) =>
    Base.post(`/orders/${orderId}/estimate-split-payments`, { items }),
};
