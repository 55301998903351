<template>
  <div class="leave-tips-block" v-if="waiters && waiters.length">
    <div class="title qrw-label-lg bold qrw-text-content-base-primary">
      {{ $t('personal_tips.title') }}
    </div>
    <div class="scrollable-list">
      <div class="card" @click="navigateToTip(waiter)" v-for="(waiter, index) in waiters" :key="index">
        <img :src="waiter.photo" class="avatar" v-if="waiter.photo">
        <div class="no-avatar" v-else>
          <img src="/images/user-placeholder.svg">
        </div>
        <div class="titles">
          <div class="qrw-paragraph-sm bold qrw-text-content-base-primary">
            {{ waiter.name }}
          </div>
          <div class="qrw-paragraph-xs qrw-text-content-base-secondary">
            {{ waiter.role || $t('personal_tips.role_ph') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    waiters () {
      return this.$store.state.tips.waiters;
    }
  },
  methods: {
    navigateToTip (waiter) {
      window.location.href = waiter.tips_link;
    }
  }
}
</script>

<style scoped>
.leave-tips-block {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 100%;
  box-sizing: border-box;
}

.title {
  display: flex;
  padding: 16px 16px 0 16px;
}

.scrollable-list {
  overflow-x: scroll;
  padding: 0 16px 16px 16px;
  display: flex;
  flex-direction: row;
  column-gap: 12px;
}

.card {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 12px;
  row-gap: 8px;
  width: 148px;
  min-width: 148px;
  max-width: 148px;
  box-sizing: border-box;
  background: var(--qrw-background-base-primary);
  align-items: center;
  justify-content: center;
  box-shadow: var(--qrw-shadow-card-default);
}

.titles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.avatar {
  width: 80px;
  height: 80px;
  max-width: 80px;
  object-fit: cover;
  border: 1px solid var(--qrw-border-base-tertiary);
  border-radius: 50%;
  min-height: 80px;
  min-width: 80px;
  max-width: 80px;
}

.no-avatar {
  border: 1px solid var(--qrw-border-base-tertiary);
  border-radius: 50%;
  min-height: 80px;
  min-width: 80px;
  max-width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  background: var(--qrw-background-base-secondary);
}

.no-avatar img {
  width: 40px;
  height: 40px;
}
</style>
