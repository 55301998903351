<template>
  <div class="app-present-cart-navbar gap-row-zero">
    <BaseDivider color="border-base-tertiary" />
    <div class="app-present-cart-navbar-content">
      <div class="gap-row-2xs w-100">
        <div class="qrw-label-sm qrw-text-content-base-secondary">
          {{ $tc("units.items", cartItemsQuantity) }}
        </div>
        <PriceTag
          :value="cartTotal"
          class="qrw-label-md bold qrw-text-content-base-primary"
        />
      </div>
      <BasePushButton
        variant="fill"
        @click="openCart"
        bgColor="background-base-inverse-primary"
        textColor="content-base-inverse-primary"
        size="md"
        class="view-cart-button"
      >
        {{ $t("common_actions.view_cart") }}
      </BasePushButton>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openCart() {
      this.$router.push(this.$store.getters.buildRouterNavigateObject('cart'))
    }
  },
  computed: {
    currency() {
      return this.$store.state.currency
    },
    cartItemsQuantity() {
      return this.$store.getters['cart/items'].reduce((acc, item) => acc + item.quantity, 0)
    },
    cartTotal() {
      return this.$store.getters['cart/total']
    }
  }
}
</script>

<style scoped>
.app-present-cart-navbar {
  background: var(--qrw-background-base-tertiary-09);
}

.app-present-cart-navbar-content {
  /* position: fixed;
  z-index: 30;
  bottom: 0;
  left: 0; */

  width: 100%;
  box-sizing: border-box;

  background: var(--qrw-background-base-tertiary-09);

  padding: 8px 16px;
  column-gap: 16px;

  align-items: center;
  display: flex;
}

.view-cart-button {
  white-space: nowrap;
  width: 122px;
  min-width: 122px;
}
</style>
