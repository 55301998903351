<template>
  <div class="app-reserve-loader" v-if="useMenuLoader">
    <MenuPageHeader loading/>

    <div class="menu-page-content grid" v-qrw-under-header id="globalCategoriesScrollGrid">
      <CategoryGridCard
        :category="null"
        v-for="n in 5"
        :key="n"
      />
    </div>
  </div>
  <PageLoader v-else />
</template>

<script>
import MenuPageHeader from '@/components/header/MenuPageHeader.vue'

import CategoryGridCard from '@/components/menu/CategoryGridCard.vue'

import PageLoader from '@/components/common/PageLoader.vue'

export default {
  data () {
    return {

    }
  },
  methods: {

  },
  computed: {
    useMenuLoader () {
      return this.$route.name === 'reserve_menu' || this.$route.name === 'menu_page'
    }
  },
  components: {
    MenuPageHeader,
    CategoryGridCard,
    PageLoader
  }
}
</script>

<style scoped>

</style>
