import { render, staticRenderFns } from "./ReceiptPage.vue?vue&type=template&id=050fa928&scoped=true&"
import script from "./ReceiptPage.vue?vue&type=script&lang=js&"
export * from "./ReceiptPage.vue?vue&type=script&lang=js&"
import style0 from "./ReceiptPage.vue?vue&type=style&index=0&id=050fa928&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "050fa928",
  null
  
)

export default component.exports