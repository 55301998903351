<template>
  <div class="app-order-page">
    <div
      class="qrw-app-top-sticky"
      v-qrw-class-on-scroll="{
        cssClass: 'qrw-app-top-container',
      }"
      v-qrw-is-header
    >
      <OrderPageHeader />
      <BaseDivider color="border-base-tertiary" />
    </div>

    <div
      class="order-page-container"
      v-qrw-under-header
      v-qrw-above-footer
    >
      <div class="bill-container">
        <div class="bill-content">
          <div class="bill-header">
            <div class="gap-row-2xs">
              <div class="qrw-label-lg bold qrw-text-content-base-primary">
                {{ $t("order_page.your_bill") }}
              </div>
              <PriceTag
                :value="order.total"
                class="qrw-label-md qrw-text-content-base-tertiary"
              />
            </div>

            <div
              class="qrw-label-md bold qrw-text-content-success-primary"
              @click="selectAll"
            >
              {{ $t("order_page.select_all") }}
            </div>
          </div>
          <OrderProductCard
            v-for="item in orderItems"
            :listItem="item"
            :key="item._id"
            :selection="selectedItems"
            @select="(count) => onProductSelected(item, count)"
          />
        </div>
        <BasePushButton
          variant="fill"
          bgColor="background-base-tertiary"
          textColor="content-base-secondary"
          size="md"
          icon="plus"
          @click="addMoreFromMenu"
        >
          {{ $t("cart.add_more") }}
        </BasePushButton>
      </div>
      <BaseDivider color="border-base-tertiary" />
      <LeaveTipsBlock
        :total="totalObject.sum"
        v-model.number="selectedTipPercent"
      />
    </div>

    <div
      class="payment-expanded-backdrop"
      v-if="isNavbarExpanded"
    ></div>

    <div
      class="order-page-footer qrw-app-bottom-container"
      :class="{
        expanded: isNavbarExpanded,
      }"
      v-qrw-is-footer
    >
      <OrderPaymentNavbar
        :payMethod="payMethod"
        :splits="
          order.split_payments && order.split_payments.length
            ? order.split_payments
            : splitPayments
        "
        @expandChanged="(v) => (isNavbarExpanded = v)"
        :total="totalObject"
        @confirm="onPaymentConfirmed"
        :makingNewOrder="false"
        @changePayMethod="payMethodModalIsShown = true"
      />
      <MainNavbar
        activeTab="order"
        :sticky="false"
        @tabClick="onNavbarTabClicked"
      />
    </div>

    <PaymentMethodModal
      :payMethods="cafe.pay_methods"
      v-model="payMethod"
      @close="payMethodModalIsShown = false"
      v-if="payMethodModalIsShown && !isPendingSplitPayment"
    />

    <OnlinePaymentModal
      :gateway="pendingOnlinePayment.gateway"
      :payLink="pendingOnlinePayment.pay_url"
      v-if="pendingOnlinePayment"
    />

    <ErrorModal
      :code="errorCode"
      @close="errorCode = null"
      type="payment"
      v-if="errorCode"
    />

    <SplitPaymentTimeoutModal
      @close="splitPaymentTimeoutModalShown = false"
      v-if="splitPaymentTimeoutModalShown"
    />
  </div>
</template>

<script>
import OrderProductCard from '@/components/order/OrderProductCard.vue'
import OrderPageHeader from '@/components/header/OrderPageHeader.vue'
import MainNavbar from '@/components/navbar/MainNavbar.vue'
import OrderPaymentNavbar from '@/components/navbar/OrderPaymentNavbar.vue'

import PaymentMethodModal from '@/components/modals/PaymentMethodModal.vue'
import LeaveTipsBlock from '@/components/order/LeaveTipsBlock.vue'

import OnlinePaymentModal from '@/components/modals/OnlinePaymentModal.vue'

import InsideAPI from '@/api/inside'

import ErrorModal from '@/components/modals/ErrorModal.vue'

import SplitPaymentTimeoutModal from '@/components/modals/SplitPaymentTimeoutModal.vue'

export default {
  components: {
    OrderProductCard,
    OrderPageHeader,
    MainNavbar,
    OrderPaymentNavbar,
    PaymentMethodModal,
    LeaveTipsBlock,
    OnlinePaymentModal,
    ErrorModal,
    SplitPaymentTimeoutModal
  },
  data() {
    return {
      selectedCount: 0,
      isNavbarExpanded: false,

      payMethod: 'cash',

      selectedTipPercent: 0,

      pendingOnlinePayment: null,

      payMethodModalIsShown: false,

      splitPayments: [],

      selectedItems: {},

      errorCode: null,

      splitPaymentTimeoutModalShown: false
    }
  },
  watch: {
    selectedItems: {
      deep: true,
      handler() {
        this.estimateSplitPaymentsIfNeeded()
      }
    },
    payMethod() {
      this.estimateSplitPaymentsIfNeeded()
    },
    order() {
      if (this.order) this.addMoreFromMenu() // literally go back to menu
    }
  },
  methods: {
    async estimateSplitPaymentsIfNeeded() {
      if (this.order.total === 0) {
        this.splitPayments = []
        return
      }

      if (this.payMethod !== 'online') return

      try {
        const { splits } = await InsideAPI.estimateSplitPayments(this.order._id, this.selectedItems)

        this.splitPayments = splits
      } catch (e) {
        this.splitPayments = []
      }
    },
    onProductSelected(item, count) {
      if (count > 0) {
        this.$set(this.selectedItems, item._id, count)
      } else {
        this.$delete(this.selectedItems, item._id)
      }
    },
    selectAll() {
      const areAllSelected = Object.keys(this.selectedItems).length === this.orderItems.length

      if (areAllSelected) {
        this.selectedItems = {}
        return
      }

      for (const item of this.orderItems) {
        this.$set(this.selectedItems, item._id, item.quantity)
      }
    },
    async payInsideOrder() {
      try {
        const response = await InsideAPI.payOrder(
          this.order._id,
          this.payMethod,
          this.selectedItems,
          this.selectedTipPercent
        )

        if (response.require_online_payment) {
          this.pendingOnlinePayment = response.data
        } else {
          const paymentResult = response.data

          if (paymentResult.unpaid_order) {
            this.$store.commit('setOrder', paymentResult.unpaid_order)
          } else {
            this.$store.commit('setOrder', null)
          }

          this.$router.replace(
            this.$store.getters.buildRouterNavigateObject('receipt', {
              order_id: paymentResult.paid_order._id
            })
          )
        }
      } catch (err) {
        this.errorCode = err.message || err
      }
    },
    onPaymentConfirmed() {
      this.payInsideOrder()
    },
    addMoreFromMenu() {
      this.$router.push(this.$store.getters.buildRouterNavigateObject('lobby'))
    }
  },
  computed: {
    currency() {
      return this.$store.state.currency
    },
    totalObject() {
      const result = {
        sum: 0,
        tip: 0,
        total: 0
      }

      for (const [key, quantity] of Object.entries(this.selectedItems)) {
        const item = this.orderItems.find(i => i._id === key)
        if (!item) continue
        result.sum += (item.full_price || item.price) * quantity
      }

      result.tip = result.sum * (this.selectedTipPercent / 100)

      result.total = result.sum + result.tip

      return result
    },
    order() {
      return this.$store.state.order
    },
    orderItems() {
      return this.$store.getters.orderItems
    },
    cafe() {
      return this.$store.state.cafe
    },
    isPendingSplitPayment() {
      return this.order.split_payments && this.order.split_payments.filter(p => p.paid_at).length > 0
    }
  },
  mounted() {
    if (!this.order) {
      this.$router.replace(this.$store.getters.buildRouterNavigateObject('lobby'))
      return
    }

    if (this.$store.getters.isOrderPending) {
      this.$router.replace(this.$store.getters.buildRouterNavigateObject('orders'))
      return
    }

    // TODO: move to constants
    const firstAvailablePayMethod = ['cash', 'card', 'online'].find(k => this.cafe.pay_methods[k])

    this.payMethod = this.isPendingSplitPayment ? 'online' : firstAvailablePayMethod
    this.selectAll()

    this.$store.commit('setPageWaypoint', {
      pageName: 'order',
      path: this.$route.fullPath
    })
  }
}
</script>

<style scoped>
.bill-container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 16px 16px 24px 16px;
  width: 100%;
  box-sizing: border-box;
}

.bill-content {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.bill-header {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: flex-start;
  justify-content: space-between;
}

.order-page-footer {
  position: fixed;
  bottom: 0;
  z-index: 30;
  background: var(--qrw-background-base-secondary-08);
}

.order-page-footer.expanded {
  border-radius: 16px 16px 0 0;
  background: var(--qrw-background-base-primary-10);
}

.payment-expanded-backdrop {
  background: var(--qrw-background-base-inverse-primary-06);
  backdrop-filter: blur(2px);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 29;
}
</style>
