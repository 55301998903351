import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "@qrwaiter/qrwaiter-ui-kit/dist/qrwaiter-ui-kit.css";

import QRWaiterUIKit from "@qrwaiter/qrwaiter-ui-kit";

import VueI18n from "vue-i18n";

import AppI18n from "./i18n";

import FlagIcon from "@/components/common/FlagIcon.vue";
import SkeletonLoader from "@/components/common/SkeletonLoader.vue";
import SmartPhoneInput from "@/components/common/SmartPhoneInput.vue";
import PriceTag from "@/components/common/PriceTag.vue";

import Vue2TouchEvents from "vue2-touch-events";
import VuePortal from "@linusborg/vue-simple-portal";

import * as moment from "moment";
import "moment/locale/uk";
import "moment/locale/ru";
import "moment/locale/cs";

import {
  clearSubscribers,
  addAdaptiveSubscriber,
  getAdaptiveFooterHeight,
  getAdaptiveHeaderHeight,
  updateAdaptiveFooterHeight,
  updateAdaptiveHeaderHeight,
} from "@/utils/adaptiveUi";

Vue.config.productionTip = false;

Vue.use(QRWaiterUIKit, {
  preloadIcons: [
    "marker-pin",
    "clock",
    "dot",
    "users",
    "star-filled",
    "x-circle",
    "face-frown",
    "x",
    "check-circle",
    "clock-fast-forward",
    "clock-stopwatch",
    "check-verified-filled",
    "info-circle",
    "chevron-right-double",
    "lock",
    "check-verified",
    "home",
    "book-open-filled",
    "order",
    "share-02",
    "plus",
    "minus",
    "search",
    "calendar",
    "x-close",
    "arrow-left",
    "credit-card",
    "globe",
    "arrow-right",
  ],
});

Vue.use(VueI18n);

Vue.use(Vue2TouchEvents);

Vue.use(VuePortal);

Vue.component("FlagIcon", FlagIcon);
Vue.component("SkeletonLoader", SkeletonLoader);
Vue.component("SmartPhoneInput", SmartPhoneInput);
Vue.component("PriceTag", PriceTag);

Vue.mixin({
  computed: {
    mixinCafeLinkCode() {
      return this.$route.params.cafe_link;
    },
  },
});

Vue.filter("formatMoney", (raw) => Math.round(raw * 100) / 100);
Vue.filter("formatWeight", (raw) => Math.round(raw * 10) / 10);
Vue.filter("formatTime", (raw) => moment(raw).format("HH:mm"));
Vue.filter("formatDate", (raw) => moment(raw).format("DD.MM.YYYY"));
Vue.filter("formatDatetime", (raw) => moment(raw).format("DD.MM.YYYY HH:mm"));

Vue.directive("qrw-is-header", {
  inserted: (el, binding) => {
    clearSubscribers();
    updateAdaptiveHeaderHeight(el);

    el.addEventListener(
      "transitionend",
      () => {
        el.qrwTransitionStarted = false;
        updateAdaptiveHeaderHeight(el);
      },
      false
    );

    el.addEventListener(
      "transitionstart",
      () => {
        updateAdaptiveHeaderHeight(el);

        el.qrwTransitionStarted = true;

        let framesTicked = 0;

        // we update the height each animation frame to smooth the transition
        const frameFn = () => {
          if (el.qrwTransitionStarted && framesTicked < 1000) {
            updateAdaptiveHeaderHeight(el);
            window.requestAnimationFrame(frameFn);
          }

          framesTicked++;
        };

        frameFn();
      },
      false
    );
  },
  update: (el, binding) => {
    updateAdaptiveHeaderHeight(el);
  },
});

Vue.directive("qrw-is-footer", {
  update: (el, binding) => {
    updateAdaptiveFooterHeight(el);
  },
  inserted: (el, binding) => {
    updateAdaptiveFooterHeight(el);
  },
  unbind: (el, binding) => {
    updateAdaptiveFooterHeight(0);
  },
});

const updatePaddingFromHeader = (el) => {
  el.style.paddingTop = `${getAdaptiveHeaderHeight()}px`;
};

Vue.directive("qrw-under-header", {
  inserted: (el, binding) => {
    updatePaddingFromHeader(el);

    addAdaptiveSubscriber(() => {
      updatePaddingFromHeader(el);
    });
  },
});

const updatePaddingFromFooter = (el) => {
  el.style.paddingBottom = `${getAdaptiveFooterHeight()}px`;
};

Vue.directive("qrw-above-footer", {
  inserted: (el, binding) => {
    updatePaddingFromFooter(el);

    addAdaptiveSubscriber(() => {
      updatePaddingFromFooter(el);
    });
  },
});

let scrollDependantElements = [];

Vue.directive("qrw-class-on-scroll", {
  inserted: (el, binding) => {
    scrollDependantElements.push({
      el,
      ...binding.value,
    });
  },
  unbind: (el, binding) => {
    scrollDependantElements = scrollDependantElements.filter(
      (item) => item.el !== el
    );
  },
});

window.addEventListener("scroll", () => {
  const scrollPos = window.document.documentElement.scrollTop;

  for (const item of scrollDependantElements) {
    const { el, limit, cssClass, returnLimit } = item;

    const limitValue = limit || 0;
    const returnLimitValue = returnLimit || limitValue;

    const realNewClassList = cssClass.trim().split(" ");

    if (scrollPos > limitValue) {
      for (const className of realNewClassList) {
        el.classList.add(className);
      }
    } else if (scrollPos <= returnLimitValue) {
      for (const className of realNewClassList) {
        if (el.classList.contains(className)) {
          el.classList.remove(className);
        }
      }
    }
  }
});

const i18n = new VueI18n({
  locale: "en",
  messages: AppI18n,
  fallbackLocale: "en",
});

moment.updateLocale("en", {
  week: {
    dow: 1,
    doy: 4,
  },
});

moment.updateLocale("ru", {
  week: {
    dow: 1,
    doy: 4,
  },
});

moment.updateLocale("uk", {
  week: {
    dow: 1,
    doy: 4,
  },
});

moment.updateLocale("cs", {
  week: {
    dow: 1,
    doy: 4,
  },
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
