<template>
  <div class="app-cookies-page">
    <BasePushButton
      @click="$router.go(-1)"
      icon="arrow-left"
      variant="primary"
      size="md"
      textColor="content-base-inverse-primary"
      bgColor="background-base-inverse-primary"
      style="width: fit-content"
    >
      Back
    </BasePushButton>

    <div class="qrw-label-sm qrw-text-content-base-primary">QRWaiter application does not use cookies.</div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.app-cookies-page {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px;
}
</style>
