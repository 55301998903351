<template>
  <BaseSimpleModal
    :showTitle="false"
    :showHeader="false"
    :fullHeight="false"
    :showFooter="false"
    @backdropClick="$emit('close')"
  >
    <div class="address-modal-header">
      <div class="qrw-heading-sm bold qrw-text-content-base-primary">
        {{ $t("select_address.title") }}
      </div>
      <div class="qrw-paragraph-md qrw-text-content-base-secondary">
        {{ $t("select_address.subtitle") }}
      </div>
    </div>

    <div class="addresses">
      <div
        class="address-item"
        :class="{
            active: index === selectedAddressIndex
        }"
        @click="selectedAddressIndex = index"
        v-for="(item,index) in list"
        :key="index"
      >
        <div class="address-icon">
          <BaseIcon
            :icon="item.icon"
            size="sm2"
          />
        </div>
        <div class="gap-row-2xs">
          <div class="qrw-label-md bold qrw-text-content-base-primary">
            {{ $t('address_types.home') }}
          </div>
          <div class="qrw-label-sm qrw-text-content-base-secondary">
            {{item.street}}
          </div>
        </div>
      </div>
      <BasePushButton
        variant="outlined"
        icon="plus"
        textColor="content-base-secondary"
        size="lg"
        @click="addNew"
        class="w-100"
      >
        {{ $t("select_address.add_new") }}
      </BasePushButton>
    </div>

    <div class="addresses-modal-footer qrw-app-bottom-container">
      <BaseDivider color="border-base-tertiary" />
      <div class="gap-column-xs addresses-confirm-buttons w-100">
        <BasePushButton
          variant="ghost"
          textColor="content-base-secondary"
          size="lg"
          @click="$emit('close')"
          class="w-100"
        >
          {{ $t("common_actions.cancel") }}
        </BasePushButton>
        <BasePushButton
          variant="fill"
          textColor="content-base-inverse-primary"
          bgColor="background-base-inverse-primary-10"
          size="lg"
          @click="onConfirm"
          class="w-100"
        >
          {{ $t("common_actions.confirm") }}
        </BasePushButton>
      </div>
    </div>
  </BaseSimpleModal>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      selectedAddressIndex: null,

      list: [
        {
          street: 'st. Highfive, 5',
          icon: 'home'
        },
        {
          street: 'st. Maincopes, 6',
          icon: 'briefcase'
        }
      ]
    }
  },
  methods: {
    onConfirm() {
      this.$emit('close')
    },
    addNew() {
      this.$router.push(this.$store.getters.buildRouterNavigateObject('address'))
    }
  },
  mounted() {
    this.addNew()
  },
  computed: {}
}
</script>

<style scoped>
.address-modal-header {
  display: flex;
  padding: 16px 16px;
  box-sizing: border-box;
  row-gap: 8px;
  background: var(--qrw-background-base-secondary-08);
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.addresses {
  display: flex;
  flex-direction: column;
  padding: 16px;
  row-gap: 16px;
}

.addresses-modal-footer {
  display: flex;
  flex-direction: column;
  row-gap: 0;
  width: 100%;
  box-sizing: border-box;
  background: var(--qrw-background-base-secondary-08);
}

.addresses-confirm-buttons {
  display: flex;
  column-gap: 16px;
  padding: 12px 16px;
  box-sizing: border-box;
  justify-content: space-between;
}

.address-item {
  display: flex;
  padding: 15px 16px;
  border-radius: 12px;
  background: var(--qrw-background-base-primary-10);
  column-gap: 12px;
  border: 1px solid var(--qrw-border-base-tertiary);
}

.address-item.active {
  box-shadow: var(--qrw-shadow-card-light);
  border: 1px solid var(--qrw-border-success-primary);
}

.address-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 8px;
  background: var(--qrw-background-base-tertiary-10);
  color: var(--qrw-content-base-primary);
}
</style>
