<template>
  <BaseSimpleModal
    :showTitle="false"
    :showHeader="false"
    :fullHeight="false"
    :showFooter="false"
    @backdropClick="$emit('close')"
  >
    <div class="address-modal-header">
      <div class="qrw-heading-sm bold qrw-text-content-base-primary">
        {{ $t("select_address_type.title") }}
      </div>
      <div class="qrw-paragraph-md qrw-text-content-base-secondary">
        {{ $t("select_address_type.subtitle") }}
      </div>
    </div>

    <div class="addresses">
      <div
        class="address-item"
        :class="{
            active: item.type === selectedAddressType
        }"
        @click="selectedAddressType = item.type"
        v-for="(item,index) in addressTypes"
        :key="index"
      >
        <BaseIcon
          :icon="item.type === selectedAddressType ? item.icon+'-filled' :  item.icon"
          size="sm2"
        />
        <div
          class="qrw-label-lg qrw-text-content-base-primary"
          :class="{
          bold: item.type === selectedAddressType
        }"
        >
          {{ $t('address_place_types.'+item.type) }}
        </div>
      </div>
    </div>

    <div class="addresses-modal-footer qrw-app-bottom-container">
      <BaseDivider color="border-base-tertiary" />
      <div class="gap-column-xs addresses-confirm-buttons w-100">
        <BasePushButton
          variant="ghost"
          textColor="content-base-secondary"
          size="lg"
          @click="$emit('close')"
          class="w-100"
        >
          {{ $t("common_actions.cancel") }}
        </BasePushButton>
        <BasePushButton
          variant="fill"
          textColor="content-base-inverse-primary"
          bgColor="background-base-inverse-primary-10"
          size="lg"
          @click="onConfirm"
          class="w-100"
        >
          {{ $t("common_actions.confirm") }}
        </BasePushButton>
      </div>
    </div>
  </BaseSimpleModal>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      selectedAddressType: 'house',

      addressTypes: [
        {
          type: 'house',
          icon: 'home'
        },
        {
          type: 'flat',
          icon: 'building'
        },
        {
          type: 'office',
          icon: 'building'
        },
        {
          type: 'other',
          icon: 'leaf'
        }
      ]
    }
  },
  methods: {
    onConfirm() {
      this.$emit('input', this.selectedAddressType)
      this.$emit('close')
    }
  },
  mounted() {},
  computed: {}
}
</script>

<style scoped>
.address-modal-header {
  display: flex;
  padding: 16px 16px;
  box-sizing: border-box;
  row-gap: 8px;
  background: var(--qrw-background-base-secondary-08);
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.addresses {
  display: flex;
  flex-direction: column;
  padding: 16px;
  row-gap: 16px;
}

.addresses-modal-footer {
  display: flex;
  flex-direction: column;
  row-gap: 0;
  width: 100%;
  box-sizing: border-box;
  background: var(--qrw-background-base-secondary-08);
}

.addresses-confirm-buttons {
  display: flex;
  column-gap: 16px;
  padding: 12px 16px;
  box-sizing: border-box;
  justify-content: space-between;
}

.address-item {
  display: flex;
  padding: 11px 24px 11px 22px;
  border-radius: 12px;
  background: var(--qrw-background-base-primary-10);
  column-gap: 12px;
  border: 1px solid var(--qrw-border-base-tertiary);
  justify-content: center;
}

.address-item.active {
  box-shadow: var(--qrw-shadow-card-light);
  padding: 10px 24px 10px 22px;
  border: 2px solid var(--qrw-border-success-primary);
}

.address-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 8px;
  background: var(--qrw-background-base-tertiary-10);
  color: var(--qrw-content-base-primary);
}
</style>
