<template>
  <div
    class="app-main-navbar"
    data-testid="main-navbar"
    :class="{
      sticky,
    }"
  >
    <BaseDivider color="border-base-tertiary" />
    <div class="main-navbar-tabs qrw-transition-animation-sm">
      <div
        class="navbar-tab qrw-transition-animation-sm"
        :class="{
          'qrw-text-content-base-tertiary': item.key !== activeTab,
          'qrw-text-content-base-primary': item.key === activeTab,
          'active': item.key === activeTab,
          'animated': appearAnimated
        }"
        v-for="item in tabs"
        :key="item.key"
        @click="onTabClicked(item)"
      >
        <BaseIcon
          :size="null"
          style="font-size:22px;"
          :icon="activeTab === item.key ? `${item.icon}-filled` : item.icon"
          :balloon="item.badge"
          class="qrw-transition-animation-sm"
        />
        <div
          class="qrw-label-xs qrw-text-content-base-tertiary qrw-transition-animation-sm"
          :class="{
            'qrw-text-content-base-primary bold': item.key === activeTab
          }"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let lastTab = null

export default {
  props: {
    activeTab: {
      type: String,
      default: 'menu'
    },
    sticky: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      appearAnimated: false
    }
  },
  methods: {
    onTabClicked(tab) {
      this.$router.push(
        this.$store.getters.buildRouterNavigateObject(tab.route, {}, this.activeTab === tab.key ? null : tab.key)
      )
    }
  },
  computed: {
    tabs() {
      return [
        {
          key: 'home',
          route: 'home',
          icon: 'home',
          title: this.$t('main_navbar.home')
        },
        {
          key: 'menu',
          route: 'lobby',
          icon: 'book-open',
          title: this.$t('main_navbar.menu'),
          badge:
            this.$store.getters['cart/items'].length > 0 ||
            (this.$store.state.order && this.$store.state.order.status === 'cancelled')
        },
        {
          key: 'order',
          route: 'orders',
          icon: 'order',
          title: this.$t('main_navbar.orders'),
          badge: !!this.$store.state.order && this.$store.getters.canOrderBePaid
        }
      ]
    }
  },
  mounted() {
    if (lastTab && lastTab !== this.activeTab) {
      this.appearAnimated = true
    }

    lastTab = this.activeTab
  }
}
</script>

<style scoped>
.app-main-navbar {
  display: flex;
  flex-direction: column;

  width: 100vw;
  box-sizing: border-box;
  /* background: var(--qrw-background-base-primary); */
  /* background: var(--qrw-background-base-secondary-10); */
}

.main-navbar-tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.app-main-navbar.sticky {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 30;
}

.navbar-tab {
  width: 100%;
  padding: 8px 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 4px;
  box-sizing: border-box;
}

@keyframes appear {
  from {
    opacity: 0.4;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.navbar-tab.active {
  padding: 10px 8px;
}

.navbar-tab.active.animated {
  animation: appear 0.3s ease-in-out;
}
</style>
