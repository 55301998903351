<template>
  <div class="app-orders-page">
    <div
      class="qrw-app-top-sticky"
      v-qrw-class-on-scroll="{
        cssClass: 'qrw-app-top-container',
        limit: 32,
      }"
      v-qrw-is-header
    >
      <ArchiveHeader />
      <BaseDivider color="border-base-tertiary" />
    </div>

    <div
      class="app-orders-page-content"
      v-qrw-above-footer
      v-qrw-under-header
    >
      <div
        class="app-orders-page-list"
        v-if="orders.length || activeOrder"
      >
        <ArchivedCard
          mode="inside"
          :state="activeOrder.status === 'waiting' ? 'pending' : 'active'"
          :total="activeOrder.total"
          @click="openActiveOrder()"
          :date="activeOrder.date"
          v-if="activeOrder && canOrderBePaid"
        />

        <template v-for="(group, index) in orderGroups">
          <BaseTextDivider
            color="border-base-tertiary"
            style="white-space: nowrap"
            :info="group[0]"
            textSize="sm"
            v-if="orders.length"
            :key="index"
          />

          <ArchivedCard
            state="archived"
            :mode="getOrderMode(order)"
            :total="order.total"
            :date="order.date"
            @click="openCard(order)"
            v-for="order in group[1]"
            :key="order._id"
          />
        </template>
      </div>
      <div
        class="app-orders-empty"
        v-else-if="loading"
      >
        <BaseLoaderIcon size="md" />
      </div>
      <div
        class="app-orders-empty"
        v-else
      >
        <div class="gap-row-1xs">
          <div class="qrw-heading-xs bold qrw-text-content-base-tertiary bold">
            {{ $t("archive.empty_title") }}
          </div>
          <div
            class="qrw-paragraph-md qrw-text-content-base-tertiary"
            v-html="$t('archive.empty_subtitle')"
          >
          </div>
        </div>

        <BasePushButton
          @click="openMenu"
          icon="book-open"
          class="empty-cta"
          variant="fill"
          size="md"
          textColor="content-base-inverse-primary"
          bgColor="background-base-inverse-primary"
        >
          {{ $t("archive.open_menu") }}
        </BasePushButton>
      </div>
    </div>

    <div
      class="qrw-app-bottom-sticky qrw-app-bottom-container"
      v-qrw-is-footer
    >
      <MainNavbar
        activeTab="order"
        :sticky="false"
      />
    </div>

    <PendingOrderModal
      @close="pendingModalShown = false"
      v-if="pendingModalShown"
    />
  </div>
</template>

<script>
import ArchivedCard from '@/components/order/ArchivedCard.vue'
import MainNavbar from '@/components/navbar/MainNavbar.vue'

import ArchiveHeader from '@/components/header/ArchiveHeader.vue'

import PendingOrderModal from '@/components/modals/PendingOrderModal.vue'

import InsideAPI from '@/api/inside'

import moment from 'moment'

export default {
  components: {
    ArchivedCard,
    MainNavbar,
    ArchiveHeader,
    PendingOrderModal
  },
  data() {
    return {
      orders: [],
      loading: true,
      pendingModalShown: false
    }
  },
  methods: {
    openCard(order) {
      this.$router.push(
        this.$store.getters.buildRouterNavigateObject('receipt', {
          order_id: order._id
        })
      )
    },
    getOrderMode(order) {
      if (order.order_type === 'inside') return 'inside'
      return order.is_delivery ? 'delivery' : 'takeaway'
    },
    openActiveOrder() {
      if (this.$store.getters.isOrderPending) {
        this.pendingModalShown = true
        return
      }

      this.$router.push(this.$store.getters.buildRouterNavigateObject('order', {}))
    },
    async fetchOrders() {
      try {
        const { orders } = await InsideAPI.getArchive(
          this.$store.state.cafe._id,
          window.localStorage.getItem('visitor_id'),
          this.$store.state.mode
        )

        this.orders = orders
      } catch (err) {
        InsideAPI.getArchive('error', err.message)
        this.openMenu()
      }

      this.loading = false
    },
    openMenu() {
      this.$router.push(this.$store.getters.buildRouterNavigateObject('lobby', {}))
    }
  },
  mounted() {
    this.fetchOrders()

    this.$store.commit('setPageWaypoint', {
      pageName: 'order',
      path: this.$route.fullPath
    })
  },
  computed: {
    activeOrder() {
      return this.$store.state.order
    },
    orderGroups() {
      const groups = {}

      console.log(moment.locale())

      for (const order of this.orders) {
        const key = moment(order.date).format('MMMM YYYY')

        groups[key] = groups[key] || []

        groups[key].push(order)
      }

      return Object.entries(groups)
    },
    canOrderBePaid() {
      return this.$store.getters.canOrderBePaid
    }
  }
}
</script>

<style scoped>
.app-orders-page-list {
  display: flex;
  flex-direction: column;
  padding: 16px;
  row-gap: 16px;
}

.app-orders-empty {
  width: 100%;
  flex-grow: 1;
  display: flex;
  row-gap: 40px;
  flex-direction: column;
  padding: 40px 16px;
  text-align: center;
  justify-content: center;
  align-content: center;
  align-items: center;
  box-sizing: border-box;
}

.empty-cta {
  width: fit-content;
}

.app-orders-page {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.app-orders-page-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
</style>
