<template>
  <div class="app-reserve-service-page">
    <ProductPageHeader @back="$router.go(-1)" />

    <img :src="productImage" class="product-image" />

    <div class="product-info">
      <div class="qrw-label-md bold qrw-text-content-base-tertiary">
        {{ category.name }}
      </div>
      <div class="qrw-heading-sm bold qrw-text-content-base-primary">
        {{ product.name }}
      </div>
      <div class="qrw-heading-xs qrw-text-content-base-primary">
        {{ productTotal }} {{ currency.symbol }}
      </div>
    </div>

    <BaseDivider color="border-base-tertiary" />

    <div class="product-description" v-if="product.description">
      <div class="qrw-heading-xs bold qrw-text-content-base-primary">
        {{ $t("product.description") }}
      </div>
      <div class="qrw-paragraph-md qrw-text-content-base-secondary">
        {{ product.description }}
      </div>
    </div>

    <BaseDivider color="border-base-tertiary" v-if="product.description" />

    <template v-for="(pg, pg_index) in product.price_groups">
      <VariantGroup
        :group="pg"
        v-model="modificatorsBuffers[pg_index]"
        v-if="pg.single_select"
        :key="pg._id"
      />
      <BaseDivider color="border-base-tertiary" :key="'d' + pg._id" />
    </template>

    <div class="persons-count">
      <div class="qrw-heading-xs bold qrw-text-content-base-primary">
        {{ $t("reserve.persons") }}
      </div>

      <BaseCounter size="md" v-model="personsCount" :max="7" :min="1" />
    </div>
    <BaseDivider color="border-base-tertiary" />
    <ReserveCalendar
      locale="en"
      v-model="reservationDate"
      :minDate="minAvailableDate"
      :maxDate="maxReservationDate"
    />
    <BaseDivider color="border-base-tertiary" />
    <div class="date-block">
      <div class="qrw-heading-xs bold qrw-text-content-base-primary">
        {{ $t("reserve.time") }}
      </div>
      <div class="time-grid">
        <BaseSelectButton
          class="time-grid-item"
          v-for="td in timeSlots"
          :key="td.start.toString()"
          size="md"
          v-model="reservationTimeAndDate"
          :selectedValue="td.start.toString()"
        >
          {{ td.start | formatTime }}
        </BaseSelectButton>
      </div>
    </div>

    <SingleButtonNavbar
      :disabled="!canMakeReservation"
      action="reserve"
      @click="makeReservation"
      :sticky="false"
    />
  </div>
</template>

<script>
import ProductPageHeader from '@/components/header/ProductPageHeader.vue'

import VariantGroup from '@/components/menu/VariantGroup.vue'

import SingleButtonNavbar from '@/components/navbar/SingleButtonNavbar.vue'

import ReserveCalendar from '@/components/reserve/ReserveCalendar.vue'

import moment from 'moment'

import { weekDayToWeekdayKey, saveCafeBasedData } from '@/utils'

import ReserveAPI from '@/api/reserve'

export default {
  components: {
    ProductPageHeader,
    VariantGroup,
    SingleButtonNavbar,
    ReserveCalendar
  },
  data () {
    return {
      personsCount: 1,
      reservationDate: new Date(),
      reservationTimeAndDate: null,
      modificatorsBuffers: [],
      timeSlots: [],
      canRequestTimeSlots: false,
      requestingTimeSlots: false
    }
  },
  watch: {
    reservationDate () {
      this.fetchTimeslots()
    },
    modificatorsBuffers () {
      this.fetchTimeslots()
    }
  },
  methods: {
    makeReservation () {
      this.$store.commit('setReserveCart', {
        product: {
          product_id: this.product._id,
          name: this.product.name,
          category: this.product.category,
          category_id: this.product.category_id,
          price: this.productTotal,
          quantity: 1,
          ...this.remappedModificators
        },
        personsCount: this.personsCount,
        reservationDate: this.reservationTimeAndDate.toString()
      })

      saveCafeBasedData(
        'reserve_cart',
        this.mixinCafeLinkCode,
        this.$store.state.reserveCart
      )

      this.$router.push({
        name: 'reserve_checkout',
        params: {
          cafe_link: this.mixinCafeLinkCode
        }
      })
    },
    async fetchTimeslots () {
      if (!this.canRequestTimeSlots) return
      if (this.requestingTimeSlots) return

      this.requestingTimeSlots = true

      try {
        const response = await ReserveAPI.computeTimeslots({
          cafe_id: this.cafe._id,
          date: moment(this.reservationDate).hours(
            moment().hours()
          ).minutes(
            moment().minutes()
          ).toDate(),
          variants: this.modificatorsBuffers.flat(),
          service_id: this.product._id
        })

        this.timeSlots = response.time_slots
      } catch (err) {
        console.error(err)
      }

      this.requestingTimeSlots = false
    }
  },
  computed: {
    canMakeReservation () {
      return !!this.reservationTimeAndDate
    },
    maxReservationDate () {
      return moment().add(1, 'month').toDate()
    },
    product () {
      return this.$store.getters.findProduct(this.$route.params.product_id)
    },
    productImage () {
      if (!this.product.image) {
        return 'https://app.qrwaiter.com.ua/icons/no-dish-exclusive.jpg'
      }

      if (!this.product.image.startsWith('http')) {
        if (window.location.hostname === 'localhost') {
          return `http://localhost:3000${this.product.image}`
        }
      }

      return this.product.image
    },
    category () {
      return this.$store.getters.findCategory(this.product.category_id)
    },
    currency () {
      return this.$store.state.currency
    },
    cafe () {
      return this.$store.state.cafe
    },
    availableTimes () {
      const key = weekDayToWeekdayKey(moment(this.reservationDate).weekday())

      const scheduleItem = this.cafe.schedule[key]

      const endDate = moment(this.reservationDate)
        .hours(scheduleItem.endHour)
        .minute(scheduleItem.endMinute)

      const times = []

      for (
        let i = moment(this.reservationDate)
          .hours(scheduleItem.startHour)
          .minutes(scheduleItem.startMinute);
        i.isBefore(endDate);
        i.add(30, 'minutes')
      ) {
        if (i.isBefore(moment())) continue

        times.push(i.toDate())
      }

      return times
    },
    productTotal () {
      let price = this.product.base_price

      for (let i = 0; i < this.modificatorsBuffers.length; i++) {
        const buffer = this.modificatorsBuffers[i]
        for (const modificatorId of buffer) {
          const modificator = this.product.price_groups[i].items.find(
            (m) => m._id === modificatorId
          )

          if (modificator) {
            price += modificator.price
          }
        }
      }

      return price
    },
    minAvailableDate () {
      if (this.cafe.reserve.allow_same_day) {
        return moment().subtract(1, 'day').endOf('day').toDate()
      } else {
        return moment().endOf('day').toDate()
      }
    },
    // TODO: FIX LATER WITH SERVER SIDE CART!
    remappedModificators () {
      const modificators = []
      const variants = []

      for (let i = 0; i < this.modificatorsBuffers.length; i++) {
        const group = this.modificatorsBuffers[i]

        for (const id of group) {
          const item = this.product.price_groups[i].items.find(
            (it) => it._id === id
          )

          if (item) {
            const targetArray = this.product.price_groups[i].single_select ? modificators : variants
            targetArray.push(item)
          }
        }
      }

      return {
        modificators,
        variants
      }
    }
  },
  created () {
    for (const pg of this.product.price_groups) {
      this.modificatorsBuffers.push(pg.single_select ? [pg.items[0]._id] : [])
    }

    this.$nextTick(() => {
      this.canRequestTimeSlots = true
      this.fetchTimeslots()
    })
  },
  mounted () {
    this.reservationDate = moment().add(1, 'day').toDate()
  }
}
</script>

<style scoped>
.app-reserve-service-page {
  /* padding-bottom: 64px; */
  background: var(--qrw-background-base-secondary);
}

.product-image {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.product-info {
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  row-gap: 8px;
}

.product-description {
  display: flex;
  padding: 24px;
  flex-direction: column;
  row-gap: 8px;
}

.persons-count {
  display: flex;
  padding: 24px;
  flex-direction: row;
  column-gap: 24px;
  justify-content: space-between;
  align-items: center;
}

.date-block {
  display: flex;
  padding: 24px;
  flex-direction: column;
  row-gap: 16px;
}

.time-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.time-grid-item {
  width: calc((100% - 24px) / 3);
}

.reservation-datepicker {
  border-radius: 8px;
}
</style>
