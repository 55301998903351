<template>
  <div
    class="product-preview-navbar"
    data-testid="product-preview-navbar"
  >
    <PriceTag
      class="qrw-label-md bold qrw-text-content-base-primary"
      :value="total"
    />
    <div class="qrw-text-content-base-tertiary qrw-label-sm">
      {{ $t('preview.product_navbar') }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    total: Number
  },
  computed: {}
}
</script>

<style scoped>
.product-preview-navbar {
  padding: 12px 16px;
  display: flex;

  width: 100%;
  box-sizing: border-box;

  row-gap: 4px;

  flex-direction: column;

  align-items: center;
}
</style>
