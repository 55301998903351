export default {
  home: {
    about: "Hakkımızda",
    address: "Adres",
    socials: "Sosyal Medya",
    website: "Web sitesi",
    get_directions: "Yol tarifi al",
  },
  contacts: {
    title: "Kişiler",
    phone: "Telefon",
    mail: "E-posta",
    website: "Web sitesi",
    visit: "Ziyaret etmek",
    call: "Arama yapmak",
    copy: "Kopyalama",
  },
  schedule: {
    weekdays: {
      mon: "Pazartesi",
      tue: "Salı",
      wed: "Çarşamba",
      thu: "Perşembe",
      fri: "Cuma",
      sat: "Cumartesi",
      sun: "Pazar",
    },
    open_now: "Açık",
    closes_in: "Kapanıyor",
    closed_now: "Kapalı",
    opens_in: "Açılıyor",
    day_off: "Kapalı Gün",
    schedule: "Program",
  },
  units: {
    hours: "sa.",
    minutes: "dk.",
    positions: "0 pozisyon | 1 pozisyon | {count} pozisyon",
    items: "0 ürün | 1 ürün | {count} ürün",
    orders: "0 sipariş | 1 sipariş | {count} sipariş",
    g: "g",
    kg: "kg",
    l: "lt.",
    ml: "ml",
    min: "dak.",
  },
  legals: {
    privacy_policy: "Gizlilik Politikası",
    cookies: "Çerezler",
    terms_of_use: "Kullanım Koşulları",
    public_offert: "Halka Açık Teklif",
    view: "Görüntüle",
  },
  reviews: {
    home_title: "Yorumlar",
    max_value: "5 Ten",
  },
  menu_grid: {
    liste: "List",
  },
  product: {
    description: "Açıklama",
  },
  common_actions: {
    confirm: "Onayla",
    order: "Sipariş",
    cancel: "İptal",
    reserve: "Ayır",
    next: "Sonraki",
    pay: "Öde",
    search: "Ara",
    view_cart: "Sepeti görüntüle",
    add: "Ekle",
    remove: "Kaldır",
    close: "Kapat",
    view: "Görüntüle",
  },
  reserve: {
    persons: "Kişiler",
    reservation: "Rezervasyon",
    thanks_1: "Teşekkürler!",
    thanks_2: "Rezervasyonunuz yapıldı",
    date: "Tarih",
    time: "Saat",
    download_pdf: "PDF İndir",
    add_to_calendar: "Takvime Ekle",
    deposit: "Depozito",
    paid: "Ödendi",
  },
  checkout: {
    contact_details: "İletişim bilgileri",
    confirmation: "Onay",
    name: "İsim",
    first_name: "Ad",
    last_name: "Soyad",
    phone: "Telefon",
    email: "E-posta",
    comment: "Yorum",
    contact: "İletişim",
    service: "Hizmet",
    date: "Tarih",
    time: "Saat",
  },
  promocode: {
    title: "Bir promosyon kodun var mı?",
    subtitle: "Aşağıya girin",
    placeholder: "Promosyonu girin",
  },
  total: {
    subtotal: "Ara Toplam",
    discount: "İndirim",
    service_fee: "Hizmet Ücreti",
    delivery_fee: "Teslimat Ücreti",
    products_total: "Ürün Toplamı",
    tip: "İpucu",
    total: "Toplam",
  },
  comment: {
    title: "Notunuz",
  },
  phone_verify_modal: {
    title: "Telefon numarası doğrulama",
    enter: "SMS'den gelen kodu girin",
    sent_to: "Kod şuna gönderildi ",
    no_code: "Kod alamadınız mı?",
    resend_btn: "Tekrar gönder",
    error: "Geçersiz kod",
    success: "Doğrulandı",
  },
  online_payment_modal: {
    redirect: "Ödeme sayfasına yönlendirileceksiniz",
    btn_pay: "Ödemeye geçin",
    btn_cancel: "İptal et",
    redirecting: "Ödeme sayfasına yönlendiriliyor...",
  },
  pay_methods: {
    cash: "Nakit",
    card: "Kart",
    online: "Online",
    pay_with_cash: "Nakit ile öde",
    pay_with_card: "Kart ile öde",
    pay_with_online: "Online olarak öde",
  },
  validations: {
    required: "Gerekli alan",
    phone: "Doğru telefon numarasını girin",
    email: "Geçerli bir e-posta girin",
  },
  calendar: {
    date: "Tarih",
  },
  phone_country_picker: {
    title: "Telefon bölgesi seçin",
    placeholder: "Ülke adına göre arayın...",
    select: "Seçin",
  },
  menu_lobby: {
    welcome: "Hoşgeldiniz",
    select_type: "Lütfen siparişinizi seçiniz",
    search_ph: "Yemekler, menüler, kategoriler...",
    our_menus: "Menülerimiz",
  },
  call_waiter: {
    title: "Yardıma ihtiyacınız mı var?",
    subtitle: "Garsonu arayın",
    button: "Garsonu çağır",
    sent: "İsteğiniz gönderildi",
  },
  scan_qrcode: {
    title: "Masa İle Bağlan",
    subtitle: "Masa üzerindeki QR-kodu tarat",
    button: "Tara",
  },
  verify_location: {
    title: "Konumu Doğrula",
    subtitle: "Konumunuzu doğrulamamız gerekiyor",
    button: "Doğrula",
    verified: "Doğrulandı",
  },
  menu_product: {
    badge_spicy: "Acılı",
    badge_veggie: "Vejetaryen",
    badge_alcohol: "Alkol",
    variants: "Varyantlar",
    modificators: "Modifikatörler",
    variants_single: "Sağlanan varyantlardan en az birini seçin",
    variants_min: "Min",
    variants_max: "Maks",
    required: "Gerekli",
    modificators_any: "Sağlanan modifikasyonlardan istediğiniz miktarda seçin",
    comment_title: "Yorumunuz",
    comment_ph: "Buraya yazın...",
    show_more: "Daha fazla göster",
    show_less: "Daha az göster",
  },
  cart: {
    title: "Sepet",
    order_type: "Sipariş türü",
    delivery_price: "Teslimat ücreti",
    min_order: "En az sipariş tutarı",
    place_order: "Sipariş verin",
    products: "Ürünler",
    unavailable_item: "Bu ürün sistemde mevcut değil",
    add_more: "Menüden daha fazla sipariş edin",
    comment_title: "Özel istekler",
    comment_desc:
      "Herhangi bir özel isteğiniz, alerjiniz veya diyetiniz var mı?",
    comment_ph: "Buraya yazın...",
    alcohol_alert:
      "21 yaşından büyük olduğumu ve alkollü ürünleri sipariş edebileceğimi onaylıyorum",
  },
  geo_verification: {
    title: "Coğrafi Doğrulama",
    desc: "Siparişi tamamlamak için konumunuzu doğrulamamız gerekiyor",
    please: "Lütfen konumunuza erişime izin verin",
    verify: "Konumumu doğrula",
    dismiss: "Reddet",
    deny_title: "Görünüşe göre konumunuza erişim izni reddettiniz",
    deny_subtitle: "Lütfen sayfayı yeniden yükleyin ve tekrar deneyin",
    reload: "Sayfayı yeniden yükle",
    busy: "Konumunuz doğrulanıyor...",
    confirmed: "Konum doğrulandı",
    out_of_range: "Servis konumunuzda kullanılamıyor",
  },
  order_product_card: {
    product_total: "Ürün Toplamı",
    remains: "Kalan:",
  },
  order_page: {
    title: "Şimdiki sipariş",
    your_bill: "Sizin faturanız",
    select_all: "Hepsini seç",
  },
  order_payment_navbar: {
    none_selected: "Ödeme yapmak için ürün seçiniz",
    slider_disabled: "Ödeme Yap",
    slider_active: "Ödeme Yapmak için kaydırın",
    total_to_pay: "Ödenecek Toplam Tutar",
    including_fees: "Komisyon dahil",
    left_unpaid: "Kalanı ödeme",
  },
  pay_method_modal: {
    title: "Ödeme yöntemi",
    subtitle: "Ödemeyi nasıl yapmak istiyorsunuz",
    descriptions: {
      cash: "Yerde nakit ile ödeyeceksiniz",
      card: "Yerde kredi kartı ile ödeyeceksiniz",
      online: "Online olarak ödeyeceksiniz",
    },
  },
  leave_tips: {
    title: "İsterseniz bir bahşiş bırakabilirsiniz",
    thank_you: "Teşekkürler!",
  },
  receipt: {
    total_paid: "Ödenen toplam",
    including_taxes: "Vergiler dahil",
    service_provider: "Servis sağlayıcı",
    tech_details: "Teknik detaylar",
    order_number: "Sipariş numarası",
    order_id: "Sipariş Kimliği",
    date_and_time: "Tarih ve saat",
  },
  payment_details: {
    title: "Ödeme detayları",
    refunded: "İade edildi",
    paid: "Ödendi",
    unpaid: "Ödenmedi",
  },
  archive: {
    active_order: "Geçerli Sipariş",
    archived_order: "Arşivlenmiş Sipariş",
    empty_title: "Henüz bir şey yok",
    empty_subtitle:
      "Menüden sipariş verebilirsiniz.<br>Cafe siparişi kabul ettiğinde burada görünecektir.",
    open_menu: "Menüyü Aç",
    title: "Siparişler",
    subtitle_1: "Burada aktif ve arşivlenmiş siparişlerinizi bulabilirsiniz",
  },
  main_navbar: {
    menu: "Menü",
    orders: "Siparişler",
    home: "Ana Sayfa",
  },
  error_modal: {
    title_generic: "Birşeyler ters gitti",
    title_payment: "Ödeme başarısız",
    title_order: "Sipariş oluşturulamadı",
    title_not_found: "Sayfa bulunamadı",
    error_code: "Hata kodu",
    please_reload: "Lütfen sayfayı yenileyerek tekrar deneyiniz",
    btn_back: "Geri",
    btn_reload: "Yeniden Yükle",
    btn_home: "Ana Sayfa",
  },
  split_payment: {
    title: "Bu fatura {n} taksitlere bölünecek",
    payment: "Ödeme",
    desc: "Tüm ödemelerin tamamlanmasına kadar ödenenleri saklayacağız. İlk ödemeden itibaren 15 dakikadan fazla süre geçmesi halinde, tüm ödenenler otomatik olarak iade edilecektir.",
  },
  pending_order: {
    title: "Bekleyen sipariş",
    your_order: "Siparişiniz",
    pending: "Bekliyor",
    declined: "Reddedildi",
    desc: "Siparişiniz gönderildi ve kabul edilmeyi bekliyor. Kabul edildiğinde, “Siparişler” sayfası içerisinde bunu göreceksiniz.",
  },
  cancelled_modal: {
    title: "Siparişiniz İptal Edildi",
    reason: "Sebep",
    your_order: "Siparişiniz",
  },
};
