var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"header",staticClass:"secondary-page-header",class:{
    fixed: _vm.fixed
  }},[_c('div',{staticClass:"secondary-page-header-top",class:{
    'no-title': _vm.hideSubtitle
  }},[(_vm.leftIcon)?_c('BaseIconButton',{attrs:{"textColor":"content-base-secondary","variant":"ghost","size":"md","icon":_vm.leftIcon},on:{"click":function($event){return _vm.$emit('leftClicked')}}}):_vm._e(),_c('div',{staticClass:"header-info"},[(!_vm.hideSubtitle)?_c('div',{staticClass:"qrw-label-xs bold qrw-text-content-base-tertiary",class:{
        truncated: _vm.truncateTitle
      }},[_vm._v(" "+_vm._s(_vm.subtitle || _vm.cafe.name)+" ")]):_vm._e(),_c('div',{staticClass:"qrw-label-md bold qrw-text-content-base-primary",class:{
        truncated: _vm.truncateTitle
      }},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('BaseIconButton',{class:{
        'hidden-icon': !_vm.rightIcon,
      },attrs:{"textColor":"content-base-secondary","variant":"ghost","size":"md","icon":_vm.rightIcon},on:{"click":function($event){return _vm.$emit('rightClicked')}}})],1),_c('BaseDivider',{attrs:{"color":"border-base-tertiary"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }