<template>
  <div class="app-search-page">
    <div class="qrw-app-top-sticky search-header" v-qrw-class-on-scroll="{
      cssClass: 'qrw-app-top-container',
    }" v-qrw-is-header>
      <div class="search-header-content">
        <BaseSearchInput v-model="query" :placeholder="$t('menu_lobby.search_ph')" size="md" class="w-100"
          ref="searchInput" />
        <BaseIconButton size="md" icon="x-close" textColor="content-base-secondary" variant="ghost" @click="goBack" />
      </div>
      <BaseDivider color="border-base-tertiary" />
    </div>

    <div class="search-content" v-qrw-under-header>
      <div class="empty-state" v-if="!query && !results.length">
        <div class="qrw-paragraph-lg bold qrw-text-content-base-tertiary">
          {{ $t('menu_search.welcome') }}
        </div>
      </div>
      <div class="empty-state" v-else-if="!results.length">
        <div class="qrw-paragraph-lg bold qrw-text-content-base-tertiary">
          {{ $t('menu_search.nothing_found') }}
        </div>
        <div class="qrw-paragraph-md qrw-text-content-base-tertiary">
          {{ $t('menu_search.try_words') }}
        </div>
      </div>
      <div class="search-results">
        <template v-for="(product, index) in results">
          <MenuSearchResult :product="product" :key="product._id + index" @click="openProduct(product)" />
          <div class="divider-block" :key="'div' + product._id + index">
            <BaseDivider color="border-base-tertiary" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import MenuSearchResult from '@/components/menu/MenuSearchResult.vue'

export default {
  data () {
    return {}
  },
  methods: {
    openProduct (product) {
      this.$router.push(
        this.$store.getters.buildRouterNavigateObject('product', {
          menu_id: product.menu_id,
          product_id: product._id
        })
      )
    },
    goBack () {
      this.$router.go(-1)
    }
  },
  computed: {
    results () {
      if (!this.query) return []
      return this.$store.getters.searchProducts(this.query)
    },
    query: {
      get () {
        return this.$store.state.menuSearch
      },
      set (v) {
        this.$store.commit('setMenuSearch', v)
      }
    }
  },
  mounted () {
    this.$refs.searchInput.getInputElement().focus()
  },
  components: {
    MenuSearchResult
  }
}
</script>

<style scoped>
.search-header {
  display: flex;
  flex-direction: column;
}

.search-header-content {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  padding: 12px 16px;
}

.empty-state {
  display: flex;
  flex-direction: column;
  padding: 32px 16px;
  align-items: center;
  justify-content: center;
  row-gap: 8px;
}

.divider-block {
  display: flex;
  padding: 0 16px;
  box-sizing: border-box;
}
</style>
