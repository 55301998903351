<template>
  <div class="single-button-navbar" :class="{sticky}">
    <BasePushButton
      size="md"
      variant="fill"
      class="w-100"
      :disabled="disabled"
      @click="$emit('click')"
      @disabledClick="$emit('click')"
      bgColor="background-base-inverse-primary"
      textColor="content-base-inverse-primary"
    >
      {{ textOverride || $t("common_actions." + action) }}
    </BasePushButton>
  </div>
</template>

<script>
export default {
  props: {
    action: {
      type: String,
      default: 'confirm'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    sticky: {
      type: Boolean,
      default: true
    },
    textOverride: String
  }
}
</script>

<style scoped>
.single-button-navbar {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
}

.sticky {
  position: fixed;
  bottom: 0;
  backdrop-filter: blur(20px);
}
</style>
