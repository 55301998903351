<template>
  <div class="app-preview-system">
    <router-view v-if="loaded" />
    <SystemLoader v-else-if="!errorModalCode" />
    <ErrorModal
      :type="loaded ? 'order' : 'generic'"
      :code="errorModalCode"
      @close="errorModalCode = null"
      v-if="errorModalCode"
    />
  </div>
</template>

<script>
import PreviewAPI from "@/api/preview";

import SystemLoader from "@/components/common/SystemLoader.vue";

import Realtime from "@/api/realtime";

import { useCachedOrGuessBestLanguage, banLanguage } from "@/i18n";

import ErrorModal from "@/components/modals/ErrorModal.vue";

import moment from "moment";

import Telemetry from "@/utils/telemetry";

export default {
  data() {
    return {
      loaded: false,

      errorModalCode: false,
    };
  },
  watch: {
    "$i18n.locale"() {
      this.$store.dispatch("rebuildTranslationDictionary", {
        locale: this.$i18n.locale,
      });

      this.$store.commit("setModeNames", this.$t("mode_names"));
    },
  },
  methods: {
    async initRealtime() {
      await Realtime.init();

      if (this.$store.state.order) {
        Realtime.listen(this.$store.state.order._id);
      }

      Realtime.addRealtimeEventsListener("inside_update", this.onOrderUpdated);
    },
    async fetchData() {
      try {
        const loadingStart = Date.now();

        this.$store.commit("reset");

        const response = await PreviewAPI.prepare(this.mixinCafeLinkCode);

        const { cafe, currency, menus, country, cart } = response;

        this.$store.commit("setMode", "preview");
        this.$store.commit("setCafe", cafe);
        this.$store.commit("setCurrency", currency);
        this.$store.commit("setMenus", menus);
        this.$store.commit("setCountry", country);
        this.$store.dispatch("cart/useCart", cart);
        this.$store.dispatch("buildImagesCache");
        this.$store.commit("setRootSystemUrl", this.$route.fullPath);

        // Ban pidorussian language from Ukraine
        if (country === "UA") {
          banLanguage("ru");
          banLanguage("ru-RU");
        }

        const loadingTime = Date.now() - loadingStart;

        const minimalUiLoadingTime = 1300;

        const loadedTimeoutDelay = Math.max(
          minimalUiLoadingTime - loadingTime,
          0
        );

        setTimeout(() => {
          this.loaded = true;
        }, loadedTimeoutDelay);

        this.$i18n.locale = useCachedOrGuessBestLanguage();

        moment.locale(this.$i18n.locale);

        this.$store.dispatch("rebuildTranslationDictionary", {
          locale: this.$i18n.locale,
        });

        this.$store.commit("setModeNames", this.$t("mode_names"));

        this.initRealtime();

        Telemetry.init(cafe._id, "preview", "superapp");
      } catch (err) {
        console.error(err);
        this.errorModalCode = err.message + " / " + this.mixinCafeLinkCode;
      }
    },
  },
  mounted() {
    this.fetchData();
  },
  components: {
    SystemLoader,
    ErrorModal,
  },
};
</script>

<style scoped>
.app-preview-system {
  display: flex;
  flex-direction: column;
  flex: 1;
}
</style>
