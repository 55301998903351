<template>
  <div
    class="app-archived-card"
    :class="{ active: state != 'archived' }"
    @click="$emit('click')"
  >
    <div class="card-left gap-row-1xs w-100">
      <div class="qrw-label-lg qrw-text-content-base-primary">
        {{ modeDisplayTitle }}
      </div>
      <div
        class="qrw-label-xs bold qrw-text-content-success-primary"
        v-if="state == 'active'"
      >
        {{ $t("archive.active_order") }}
      </div>
      <div
        class="qrw-label-xs bold qrw-text-content-warning-primary"
        v-else-if="state == 'pending'"
      >
        {{ $t("pending_order.title") }}
      </div>
      <div
        class="qrw-label-xs bold qrw-text-content-base-tertiary"
        v-else
      >
        {{ $t("archive.archived_order") }}
      </div>
    </div>

    <div class="card-right gap-row-1xs">
      <PriceTag
        :value="total"
        class="qrw-label-lg qrw-text-content-base-primary"
      />
      <div class="card-date qrw-label-xs bold qrw-text-content-base-tertiary">
        {{ date | formatDatetime }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    date: [Date, Number, String],
    mode: String,
    state: {
      type: String,
      default: 'archived'
    },
    total: Number
  },
  computed: {
    modeDisplayTitle() {
      return this.$store.getters.specificModeTitle(this.mode)
    }
  }
}
</script>

<style scoped>
.app-archived-card {
  padding: 15px 16px;
  display: flex;

  width: 100%;
  box-sizing: border-box;

  column-gap: 16px;

  align-items: center;

  border: 1px solid var(--qrw-border-base-tertiary);
  border-radius: 12px;
}

.card-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.card-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.card-date {
  white-space: nowrap;
}

.app-archived-card.active {
  background: var(--qrw-background-base-primary);
  box-shadow: var(--qrw-shadow-card-light);
  border: 0;
  padding: 16px;
}
</style>
