<template>
  <div class="app-reserve-receipt">
    <SecondaryPageHeader
      :title="'RESERVE'"
      @leftClicked="backToHome"
      @rightClicked="shareReceiptLink"
      leftIcon="home"
      rightIcon="share-02"
    />

    <div class="success-stage" v-qrw-under-header v-if="reservation">
      <div class="thanks-text">
        <div class="qrw-heading-xl qrw-text-content-base-secondary">
          {{ $t("reserve.thanks_1") }}
        </div>
        <div class="qrw-heading-xs qrw-text-content-base-primary">
          {{ $t("reserve.thanks_2") }}
        </div>
      </div>

      <div class="success-details" v-if="reservation">
        <div class="success-details-block">
          <div class="qrw-heading-xs bold qrw-text-content-base-primary">
            {{ reservation.client_first_name }}
            {{ reservation.client_last_name }}
          </div>
          <BaseDivider color="border-base-tertiary" />
          <div class="gap-row-2xs">
            <div class="qrw-label-sm qrw-text-content-base-tertiary">
              {{ $t("checkout.phone") }}
            </div>
            <div class="qrw-label-lg qrw-text-content-base-primary">
              {{ reservation.client_phone }}
            </div>
          </div>
          <BaseDivider color="border-base-tertiary" />
          <div class="gap-row-2xs">
            <div class="qrw-label-sm qrw-text-content-base-tertiary">
              {{ $t("checkout.email") }}
            </div>
            <div class="qrw-label-lg qrw-text-content-base-primary">
              {{ reservation.client_email }}
            </div>
          </div>
        </div>

        <div class="success-details-block">
          <div class="qrw-heading-xs bold qrw-text-content-base-primary">
            {{ cafe.name }}
          </div>
          <BaseDivider color="border-base-tertiary" />
          <div class="gap-row-2xs">
            <div class="qrw-label-sm qrw-text-content-base-tertiary">
              {{ $t("checkout.phone") }}
            </div>
            <div class="qrw-label-lg qrw-text-content-base-primary">
              <a :href="`tel:${cafe.contact_phone}`">{{
                cafe.contact_phone
              }}</a>
            </div>
          </div>
          <BaseDivider color="border-base-tertiary" />
          <div class="gap-row-2xs">
            <div class="qrw-label-sm qrw-text-content-base-tertiary">
              {{ $t("home.address") }}
            </div>
            <div class="qrw-label-lg qrw-text-content-base-primary">
              {{ cafe.address }}
            </div>
          </div>
        </div>
      </div>

      <BaseDivider color="border-base-secondary" />
      <div class="success-details-block">
        <div class="gap-row-2xs">
          <div class="qrw-label-sm qrw-text-content-base-tertiary">
            {{ $t("checkout.service") }}
          </div>
          <div class="qrw-label-lg bold qrw-text-content-base-primary">
            {{ reservation.services[0].name }}
          </div>
        </div>
        <BaseDivider color="border-base-tertiary" />
        <div class="gap-column-txs success-date-block">
          <div class="gap-row-2xs w-100">
            <div class="qrw-label-sm qrw-text-content-base-secondary">
              {{ $t("reserve.date") }}
            </div>
            <div class="qrw-label-lg qrw-text-content-base-primary">
              {{ reservation.reservation_date | formatDate }}
            </div>
          </div>
          <BaseDivider
            color="border-base-tertiary"
            vertical
            style="height: 44px"
          />
          <div class="gap-row-2xs w-100">
            <div class="qrw-label-sm qrw-text-content-base-secondary">
              {{ $t("reserve.time") }}
            </div>
            <div class="qrw-label-lg qrw-text-content-base-primary">
              {{ reservation.reservation_date | formatTime }}
            </div>
          </div>
        </div>
        <BaseDivider color="border-base-tertiary" />
        <div class="gap-row-2xs">
          <div class="qrw-label-sm qrw-text-content-base-secondary">
            {{ $t("reserve.persons") }}
          </div>
          <div class="qrw-label-lg qrw-text-content-base-primary">
            {{ reservation.persons_count }}
          </div>
        </div>
        <BaseDivider color="border-base-tertiary" v-if="reservation.deposit" />
        <div class="gap-row-2xs" v-if="reservation.deposit">
          <div class="qrw-label-sm qrw-text-content-base-secondary">
            {{ $t("reserve.deposit") }}
          </div>
          <div class="gap-column-1xs">
            <div class="qrw-label-lg qrw-text-content-base-primary">
              {{ reservation.deposit | formatMoney }}{{ currency.symbol }}
            </div>
            <div class="paid-badge qrw-label-xs">
              {{ $t("reserve.paid") }}
            </div>
          </div>
        </div>
      </div>

      <UserComment :comment="reservation.comment" v-if="reservation.comment" />

      <div class="gap-row-txs">
        <a
          :href="`/api/superapp/reservations/${reservation._id}/pdf-receipt`"
          target="_blank"
          class="w-100"
        >
          <BasePushButton
            variant="fill"
            class="w-100"
            size="lg"
            textColor="content-base-secondary"
            bgColor="background-base-secondary"
          >
            {{ $t("reserve.download_pdf") }}
          </BasePushButton>
        </a>
        <a
          :href="`/api/superapp/reservations/${reservation._id}/calendar-invite`"
          target="_blank"
          class="w-100"
        >
          <BasePushButton
            variant="fill"
            size="lg"
            class="w-100"
            textColor="content-base-inverse-primary"
            bgColor="background-base-inverse-primary"
          >
            {{ $t("reserve.add_to_calendar") }}
          </BasePushButton>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import SecondaryPageHeader from '@/components/header/SecondaryPageHeader.vue'
import UserComment from '@/components/checkout/UserComment.vue'

import ReserveAPI from '@/api/reserve'

export default {
  data () {
    return {
      reservation: null
    }
  },
  methods: {
    async fetchReceipt () {
      const { reservation } = await ReserveAPI.getReservation(
        this.$route.params.reservation_id
      )

      this.reservation = reservation
    },
    backToHome () {
      this.$router.push({
        name: 'home',
        params: {
          cafe_link: this.mixinCafeLinkCode
        }
      })
    },
    shareReceiptLink () {
      window.navigator.share({
        title: this.cafe.name,
        text: 'Reservation Receipt',
        url: window.location.href
      })
    }
  },
  computed: {
    cafe () {
      return this.$store.state.cafe
    },
    currency () {
      return this.$store.state.currency
    }
  },
  components: {
    SecondaryPageHeader,
    UserComment
  },
  mounted () {
    this.fetchReceipt()
  }
}
</script>

<style scoped>
.app-reserve-receipt {
  background: var(--qrw-background-base-secondary);
  padding-top: 24px;
}

.thanks-text {
  display: flex;
  flex-direction: column;
  padding: 0 12px;
}

.success-details {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.success-details-block {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: var(--qrw-background-base-primary-10);
  overflow: hidden;
  box-shadow: var(--qrw-shadow-foundation-1dp);
  box-sizing: border-box;
  padding: 16px;
  row-gap: 12px;
}

.success-details-contact {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 16px;
  box-sizing: border-box;
  background: var(--qrw-background-base-primary);
}

.success-details-cafe {
  display: flex;
  padding: 16px 16px 24px 16px;
  background: var(--qrw-background-base-secondary);
  row-gap: 8px;
  flex-direction: column;
}

.success-details-info {
  display: flex;
  padding: 16px;
  background: var(--qrw-background-base-secondary);
  row-gap: 12px;
  flex-direction: column;
}

a {
  text-decoration: none;
  color: inherit;
}

.phone-row {
  display: flex;
  align-items: center;
}

.success-date-block {
  align-items: center;
}

.success-stage {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 24px;
  box-sizing: border-box;
}

.paid-badge {
  background: var(--qrw-background-success-default);
  color: var(--qrw-content-base-inverse-primary);
  border-radius: 3px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 1px 6px;
  align-self: center;
}
</style>
