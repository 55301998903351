<template>
  <div class="user-comment">
    <div class="comment-header qrw-label-sm bold">
      <BaseIcon size="xs" icon="message-text-square" />
      {{ $t("comment.title") }}
    </div>
    <div class="qrw-paragraph-md">
      {{ comment }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    comment: String
  }
}
</script>

<style scoped>
.user-comment {
  border-radius: 12px;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  background: var(--qrw-background-warning-primary);
  box-shadow: var(--qrw-shadow-card-light);
  row-gap: 12px;
  box-sizing: border-box;
}

.comment-header {
  display: flex;
  column-gap: 4px;
  color: var(--qrw-content-base-primary);
  align-items: center;
}
</style>
