<template>
  <div class="app-dish-modificators-group">
    <div class="gap-row-1xs">
      <div class="qrw-label-md bold qrw-text-content-base-primary">
        {{ groupName || $t("menu_product.modificators") }}
      </div>
      <div class="qrw-label-sm qrw-text-content-base-tertiary">
        {{ modificatorsHint }}
      </div>
    </div>
    <div class="gap-row-txs">
      <div
        class="modificator-item"
        :class="{
          [getStateForItem(item)]: true,
        }"
        @click="onItemClicked(item)"
        v-for="(item, index) in group.items"
        :key="index"
      >
        <BaseBetaCheckbox
          size="lg"
          cell="plus"
          :value="getStateForItem(item) === 'active'"
        />
        <div class="gap-row-2xs modificator-name-weight w-100">
          <div
            class="qrw-label-sm qrw-text-content-base-secondary"
            :class="{
              'bold qrw-text-content-base-primary':
                getStateForItem(item) === 'active',
            }"
          >
            {{ groupItemNames[index] }}
          </div>
          <div
            class="qrw-label-sm"
            :class="{
              'qrw-text-content-base-secondary': getStateForItem(item) === 'active',
              'qrw-text-content-base-tertiary': getStateForItem(item) !== 'active',
            }"
            v-if="item.weight"
          >
            {{ item.weight}} {{ $t("units." + product.unit) }}
          </div>
        </div>
        <div
          class="qrw-label-sm variant-price qrw-text-content-base-tertiary gap-column-2xs"
          :class="{
            'qrw-text-content-base-primary': getStateForItem(item) === 'active',
          }"
          v-if="item.price"
        >
          +
          <PriceTag :value="item.price" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
    group: Object,
    product: Object
  },
  methods: {
    getStateForItem(item) {
      const itemPresent = this.value.includes(item._id)

      if (itemPresent) return 'active'

      if (this.group.max_count && this.value.length >= this.group.max_count) {
        return 'disabled'
      }

      return 'default'
    },
    onItemClicked(item) {
      const currentState = this.getStateForItem(item)

      if (currentState === 'disabled') return

      if (currentState === 'active') {
        this.$emit(
          'input',
          this.value.filter(id => id !== item._id)
        )
      } else {
        // make sure limits are not exceeded
        if (this.value.length + 1 > this.group.max_count) return

        this.$emit('input', [...this.value, item._id])
      }
    }
  },
  computed: {
    modificatorsHint() {
      if (!this.group.max_count || this.group.max_count > 99) {
        return this.$t('menu_product.modificators_any')
      }

      return `${this.$t('menu_product.variants_max')}: ${this.group.max_count || 0}`
    },
    currency() {
      return this.$store.state.currency
    },
    groupName() {
      return this.$store.getters.localize('price_group_name', this.product._id + ':' + this.group._id)
    },
    groupItemNames() {
      return this.group.items.map(item =>
        this.$store.getters.localize('price_group_item_name', this.product._id + ':' + item._id)
      )
    }
  }
}
</script>

<style scoped>
.app-dish-modificators-group {
  display: flex;
  padding: 16px;
  row-gap: 16px;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
}

.modificator-item {
  display: flex;
  width: 100%;
  padding: 11px 16px 11px 11px;
  border: 1px solid var(--qrw-border-base-tertiary);
  border-radius: 12px;
  align-items: center;
  column-gap: 8px;
  box-sizing: border-box;
  cursor: pointer;
}

.modificator-price {
  white-space: nowrap;
}

.modificator-item.active {
  box-shadow: var(--qrw-shadow-card-light);
  border: 1px solid var(--qrw-border-success-primary);
  background: var(--qrw-background-base-primary-10);
}

.modificator-item.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
