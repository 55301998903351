<template>
  <div class="app-menu-product-page">
    <ScrollTransitionHeader :scrollLimit="64">
      <template #primary>
        <ProductPageHeader
          :imagePresent="!!product.image"
          @back="goBack"
          @share="shareProductLink"
        />
      </template>

      <template #secondary>
        <SecondaryPageHeader
          @leftClicked="goBack"
          @rightClicked="shareProductLink"
          :title="product.name"
          :subtitle="product.category"
          leftIcon="arrow-left"
          rightIcon="share-02"
          truncateTitle
        />
      </template>
    </ScrollTransitionHeader>
    <!-- <ProductPageHeader @back="$router.go(-1)" @share="shareProductLink" /> -->

    <div
      class="app-menu-product-page-content"
      v-qrw-above-footer
      v-qrw-under-header
    >
      <div class="product-image-container">
        <img
          :src="productImage"
          class="product-image"
          @load="onImageLoaded"
        />
      </div>

      <div class="product-scroll-container">
        <div class="product-base-info">
          <div class="product-titles-and-price">
            <div class="gap-row-2xs">
              <div
                class="qrw-label-md qrw-text-content-base-tertiary"
                data-testid="product-category"
              >
                {{ productCategoryName }}
              </div>
              <div
                class="qrw-heading-sm qrw-text-content-base-primary"
                data-testid="product-name"
              >
                {{ productName }}
              </div>
            </div>
            <div
              class="product-price"
              v-if="discountedPrice"
            >
              <div class="qrw-text-error-base-primary qrw-heading-xs bold">
                {{ discountedPrice | formatMoney }} {{ currency.symbol }}
              </div>
              <div class="qrw-label-md qrw-text-content-base-tertiary line-through">
                {{ product.base_price | formatMoney }} {{ currency.symbol }}
              </div>
            </div>
            <div
              class="product-price"
              v-else
            >
              <div class="qrw-text-content-base-primary qrw-heading-xs bold">
                {{ product.base_price | formatMoney }} {{ currency.symbol }}
              </div>
            </div>
          </div>
          <div
            class="product-description gap-row-1xs"
            v-if="productDescription"
          >
            <div
              class="qrw-paragraph-md qrw-text-content-base-secondary"
              :class="{
                'description-truncated':
                  isDescriptionTooLong && !isDescriptionExpanded,
              }"
              v-html="renderableDescriptionHtml"
            >
            </div>

            <div
              class="qrw-label-sm bold qrw-text-content-link-primary"
              @click="isDescriptionExpanded = !isDescriptionExpanded"
              v-if="isDescriptionTooLong"
            >
              {{
                isDescriptionExpanded
                  ? $t("menu_product.show_less")
                  : $t("menu_product.show_more")
              }}
            </div>
          </div>
          <div
            class="product-badges"
            v-if="
              product.is_excise || product.base_weight || product.waiting_time
            "
          >
            <div class="product-badges-container">
              <!-- <div class="product-badge spicy">
                <BaseIcon size="md" icon="pepper" />
                <div class="qrw-label-sm">
                  {{ $t("menu_product.badge_spicy") }}
                </div>
              </div> -->

              <div
                class="product-badge alcohol"
                v-if="product.is_excise"
              >
                <BaseIcon
                  size="md"
                  icon="wine"
                />
                <div class="qrw-label-sm">
                  {{ $t("menu_product.badge_alcohol") }}
                </div>
              </div>

              <!-- <div class="product-badge veggie">
                <BaseIcon size="md" icon="leaf" />
                <div class="qrw-label-sm">
                  {{ $t("menu_product.badge_veggie") }}
                </div>
              </div> -->

              <div
                class="product-badge other"
                v-if="product.base_weight"
              >
                <BaseIcon
                  size="md"
                  icon="dish"
                />
                <div class="qrw-label-sm">
                  {{ product.base_weight | formatWeight }}
                  {{ $t("units." + product.unit) }}
                </div>
              </div>

              <div
                class="product-badge other"
                v-if="product.waiting_time"
              >
                <BaseIcon
                  size="md"
                  icon="clock"
                />
                <div class="qrw-label-sm">
                  {{ product.waiting_time }} {{ $t("units.min") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <BaseDivider color="border-base-tertiary" />
        <template v-for="(group, index) in product.price_groups">
          <DishVariantsGroup
            :key="index"
            :group="group"
            :product="product"
            v-model="uiModificatorsBuffers[index]"
            v-if="group.single_select && checkIfPriceGroupShouldBeShown(group)"
          />
          <DishModificatorsGroup
            :key="index"
            :group="group"
            :product="product"
            v-model="uiModificatorsBuffers[index]"
            v-else-if="checkIfPriceGroupShouldBeShown(group)"
          />
          <BaseDivider
            color="border-base-tertiary"
            :key="`divider${index}`"
            v-if="checkIfPriceGroupShouldBeShown(group)"
          />
        </template>
        <div
          class="comment-block"
          v-if="false"
        >
          <div class="gap-row-1xs">
            <div class="qrw-label-lg qrw-text-content-base-primary">
              {{ $t("cart.comment_title") }}
            </div>
            <div class="qrw-label-sm qrw-text-content-base-tertiary">
              {{ $t("cart.comment_desc") }}
            </div>
          </div>
          <BaseTextarea
            :maxlength="2048"
            autoresize
            :placeholder="$t('menu_product.comment_ph')"
            v-model="cartItem.client_comment"
          />
        </div>
      </div>
    </div>

    <div
      class="qrw-app-bottom-container qrw-app-bottom-sticky"
      v-qrw-is-footer
      v-if="canShowAddToCartAtAll"
    >
      <AlcoholAlert
        v-model="confirmedAge"
        v-if="product.is_excise"
      />
      <AddToCartNavbar
        :quantity="cartItem.quantity"
        @add="onAddToCart"
        @click="onAddNavbarClicked"
        @changeQuantity="onQuantityChanged"
        :total="productTotal"
        :disabled="isAddingDisabled"
      />

      <!-- <MainNavbar activeTab="menu" :sticky="false" v-/> -->
    </div>
    <div
      class="qrw-app-bottom-container qrw-app-bottom-sticky"
      v-qrw-is-footer
      v-else
    >
      <ProductPreviewNavbar :total="productTotal" />
    </div>

    <GenericClosedModal
      type="cafe"
      :schedule="cafe.schedule"
      @close="closedModalShown = false"
      v-if="closedModalShown && isCafeClosed"
    />

    <GenericClosedModal
      type="menu"
      :schedule="cafe.schedule"
      @close="closedModalShown = false"
      v-else-if="closedModalShown && isMenuClosed"
    />

    <GenericClosedModal
      :type="$store.state.mode"
      @close="closedModalShown = false"
      v-else-if="closedModalShown && isSystemClosed"
    />
  </div>
</template>

<script>
import ProductPageHeader from '@/components/header/ProductPageHeader.vue'

import DishVariantsGroup from '@/components/menu/DishVariantsGroup.vue'
import DishModificatorsGroup from '@/components/menu/DishModificatorsGroup.vue'

import AddToCartNavbar from '@/components/navbar/AddToCartNavbar.vue'
// import MainNavbar from '@/components/navbar/MainNavbar.vue'
import AlcoholAlert from '@/components/navbar/AlcoholAlert.vue'

import SecondaryPageHeader from '@/components/header/SecondaryPageHeader.vue'

import ScrollTransitionHeader from '@/components/header/ScrollTransitionHeader.vue'

import { resetPageScroll, updateAdaptiveHeaderHeight, setAdaptiveFrozen } from '@/utils/adaptiveUi'

import * as sanitizeHtml from 'sanitize-html'
import GenericClosedModal from '../components/modals/GenericClosedModal.vue'

import ProductPreviewNavbar from '../components/navbar/ProductPreviewNavbar.vue'

export default {
  components: {
    ProductPageHeader,
    ScrollTransitionHeader,
    DishVariantsGroup,
    DishModificatorsGroup,
    AddToCartNavbar,
    // MainNavbar,
    SecondaryPageHeader,
    AlcoholAlert,
    GenericClosedModal,
    ProductPreviewNavbar
  },
  data() {
    return {
      cartItem: {
        menu_id: null,
        product_id: null,
        variants: [],
        modificators: [],
        weight: 0,
        total_weight: 0,
        guest_index: 0,
        client_comment: null,
        quantity: 1
      },

      confirmedAge: false,

      isDescriptionExpanded: false,

      topHeaderHeight: 0,

      closedModalShown: false,

      uiModificatorsBuffers: []
    }
  },
  methods: {
    onQuantityChanged(newQuantity) {
      this.cartItem.quantity = newQuantity
    },
    shareProductLink() {
      window.navigator.share({
        title: this.product.name,
        url: window.location.href
      })
    },
    checkIfPriceGroupShouldBeShown(priceGroup) {
      if (!priceGroup.show_for || !priceGroup.show_for.length) return true

      return this.uiModificatorsBuffers.flat().some(modificatorId => priceGroup.show_for.includes(modificatorId))
    },
    onAddNavbarClicked() {
      if ((this.isCafeClosed || this.isMenuClosed || this.isSystemClosed) && this.isAddingDisabled) {
        this.closedModalShown = true
        this.cartItem.quantity = 1
      }
    },
    async onAddToCart() {
      // Update cart item
      this.cartItem.menu_id = this.$route.params.menu_id
      this.cartItem.product_id = this.$route.params.product_id
      this.cartItem.weight = this.productWeight

      for (let i = 0; i < this.uiModificatorsBuffers.length; i++) {
        const pairedPriceGroup = this.product.price_groups[i]

        if (!pairedPriceGroup) continue

        // if (!this.checkIfPriceGroupShouldBeShown(pairedPriceGroup)) continue

        if (pairedPriceGroup.single_select) {
          this.cartItem.variants = [...this.cartItem.variants, ...this.uiModificatorsBuffers[i]]
        } else {
          this.cartItem.modificators = [...this.cartItem.modificators, ...this.uiModificatorsBuffers[i]]
        }
      }

      this.cartItem.total_weight = this.productWeight * this.cartItem.quantity

      await this.$store.dispatch('cart/addItem', this.cartItem)
      // this.$router.push({
      //   name: 'cart',
      //   params: {
      //     cafe_link: this.$route.params.cafe_link
      //   }
      // })
      this.goBack()
    },
    onImageLoaded(ev) {
      console.log('image loded')
      this.topHeaderHeight = ev.target.offsetHeight
      updateAdaptiveHeaderHeight(ev.target.offsetHeight)
      setAdaptiveFrozen(true)
    },
    goBack() {
      this.$router.push(
        this.$store.getters.buildRouterNavigateObject('menu', {
          menu_id: this.$route.params.menu_id
        })
      )
    }
  },
  computed: {
    cafe() {
      return this.$store.state.cafe
    },
    isCafeClosed() {
      return this.$store.getters.isCafeClosed
    },
    isMenuClosed() {
      return this.$store.getters.isMenuClosed(this.$route.params.menu_id)
    },
    isSystemClosed() {
      return this.$store.getters.isSystemClosed
    },
    product() {
      const menuId = this.$route.params.menu_id
      const productId = this.$route.params.product_id

      // const menu = this.$store.state.menus

      return this.$store.getters.findProduct(productId, menuId)
    },
    canShowAddToCartAtAll() {
      if (this.$store.state.mode === 'preview') {
        return this.cafe.has_real_inside
      }

      return true
    },
    productImage() {
      return this.$store.getters.getProductCachedImage(this.product._id)
    },
    discountedPrice() {
      return Math.random() < 0.5 ? this.product.price : this.product.price * 0.9
    },
    currency() {
      return this.$store.state.currency
    },
    productTotal() {
      let base = this.product.base_price

      // Calculate modifiers price
      this.product.price_groups.forEach((group, groupIndex) => {
        const selectedModifiers = this.uiModificatorsBuffers[groupIndex]

        if (!selectedModifiers) return

        if (selectedModifiers.length === 0) {
          return
        }

        selectedModifiers.forEach(modifierId => {
          const modifier = group.items.find(item => item._id === modifierId)

          if (modifier) {
            base += modifier.price
          }
        })
      })

      return base * this.cartItem.quantity
    },
    productWeight() {
      let base = this.product.base_weight

      // Calculate modifiers price
      this.product.price_groups.forEach((group, groupIndex) => {
        const selectedModifiers = this.uiModificatorsBuffers[groupIndex]

        if (!selectedModifiers) return

        if (selectedModifiers.length === 0) {
          return
        }

        selectedModifiers.forEach(modifierId => {
          const modifier = group.items.find(item => item._id === modifierId)

          if (modifier) {
            base += modifier.weight || 0
          }
        })
      })

      return base
    },
    isAddingDisabled() {
      if (this.isCafeClosed && this.$store.state.mode === 'inside') return true
      if (this.isMenuClosed) return true
      if (this.isSystemClosed) return true

      return this.product.is_excise && !this.confirmedAge
    },
    isDescriptionTooLong() {
      return this.productDescription.length && this.productDescription.length > 230
    },
    productName() {
      return this.$store.getters.localize('product_name', this.product._id)
    },
    productDescription() {
      return this.$store.getters.localize('product_description', this.product._id)
    },
    productCategoryName() {
      return this.$store.getters.localize('category_name', this.product.category_id)
    },
    renderableDescriptionHtml() {
      if (!this.productDescription) return ''

      return sanitizeHtml(this.productDescription, {
        disallowedTagsMode: 'escape'
      }).replace(/\n/g, '<br>')
    }
  },
  mounted() {
    this.uiModificatorsBuffers = this.product.price_groups.map(group => {
      return group.single_select ? group.items.slice(0, group.min_count || 1).map(it => it._id) : []
    })

    resetPageScroll()

    updateAdaptiveHeaderHeight(
      this.product.image ? 0 : 64 // add some top padding if there is no image so we can see all the content
    )

    this.$store.commit('setPageWaypoint', {
      pageName: 'menu',
      path: this.$route.fullPath
    })
  },
  beforeDestroy() {
    setAdaptiveFrozen(false)
  }
}
</script>

<style scoped>
.product-image-container {
  width: 100%;
  height: auto;

  position: fixed;
  top: 0;
  z-index: 1;
}

.product-image {
  width: 100%;
  height: 100%;

  object-fit: cover;

  display: block;
}

.product-scroll-container {
  display: flex;
  flex-direction: column;

  position: relative;
  z-index: 2;

  background: var(--qrw-background-base-secondary-10);
}

.product-base-info {
  display: flex;
  flex-direction: column;
}

.product-titles-and-price {
  padding: 16px;
  row-gap: 16px;
  display: flex;
  flex-direction: column;
}

.product-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
}

.product-badges {
  display: flex;
  width: 100%;
  overflow-x: scroll;
}

.product-badges-container {
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  padding: 16px;
  padding-bottom: 24px;
}

.product-badge {
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 8px 9px 8px;
  flex-direction: column;
  row-gap: 6px;
  width: 88px;
  min-width: 88px;
  max-width: 88px;
  box-sizing: border-box;
}

.product-badge.spicy {
  border: 1px solid var(--qrw-border-error-tertiary);
  color: var(--qrw-content-error-primary);
}

.product-badge.alcohol {
  border: 1px solid var(--qrw-border-brand-tertiary);
  color: var(--qrw-content-brand-primary);
}

.product-badge.veggie {
  border: 1px solid var(--qrw-border-success-tertiary);
  color: var(--qrw-content-success-primary);
}

.product-badge.other {
  border: 1px solid var(--qrw-border-base-tertiary);
  color: var(--qrw-content-base-secondary);
}

.comment-block {
  display: flex;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  row-gap: 16px;
  flex-direction: column;
}

.description-truncated {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-description {
  padding: 8px 16px;
  box-sizing: border-box;
}
</style>
