<template>
    <BaseSimpleModal :showTitle="false" :showHeader="false" :fullHeight="false" :showFooter="false"
        @backdropClick="$emit('close')">
        <div class="tariff-modal-header">
            <div class="qrw-heading-sm bold qrw-text-content-base-primary">
                {{ $t('delivery_tariff.modal_title') }}
            </div>
            <div class="qrw-paragraph-md qrw-text-content-base-secondary">
                {{ $t('delivery_tariff.modal_subtitle') }}
            </div>
        </div>
        <div class="tariffs">
            <div class="tariff-card" :class="{ active: tariff._id === selectedTariff }" v-for="tariff in tariffs"
                @click="selectedTariff = tariff._id" :key="tariff._id">
                <div class="card-title">
                    <div class="qrw-label-lg" :class="{
                        bold: tariff._id === selectedTariff,
                    }">
                        {{ tariff.name }}
                    </div>
                    <div class="qrw-label-md qrw-text-content-base-secondary">
                        {{ tariff.price }} {{ currency.symbol }}
                    </div>
                </div>
            </div>
        </div>

        <div class="tariff-modal-footer qrw-app-bottom-container">
            <BaseDivider color="border-base-tertiary" />
            <div class="gap-column-xs tariff-confirm-buttons w-100">
                <BasePushButton variant="ghost" textColor="content-base-secondary" size="lg" @click="$emit('close')"
                    class="w-100">
                    {{ $t("common_actions.cancel") }}
                </BasePushButton>
                <BasePushButton variant="fill" textColor="content-base-inverse-primary"
                    bgColor="background-base-inverse-primary-10" :disabled="!selectedTariff" size="lg"
                    @click="onConfirm" class="w-100">
                    {{ $t("common_actions.confirm") }}
                </BasePushButton>
            </div>
        </div>
    </BaseSimpleModal>
</template>

<script>
export default {
    props: {
        tariffs: Array,
        value: String
    },
    data () {
        return {
            selectedTariff: null
        }
    },
    methods: {
        onConfirm () {
            this.$emit('input', this.selectedTariff)
            this.$emit('close')
        }
    },
    mounted () {
        if (this.value) this.selectedTariff = this.value
    },
    computed: {
        currency () {
            return this.$store.state.currency
        }
    }
}
</script>

<style scoped>
.tariff-modal-header {
    display: flex;
    padding: 16px 16px;
    box-sizing: border-box;
    row-gap: 8px;
    background: var(--qrw-background-base-primary-08);
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tariffs {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding: 16px;
    background: var(--qrw-background-base-secondary);
}

.tariff-card {
    border-radius: 12px;
    display: flex;
    padding: 12px;
    row-gap: 8px;
    flex-direction: column;
    border: 1px solid var(--qrw-border-base-tertiary);
    color: var(--qrw-content-base-tertiary);

    position: relative;
}

.tariff-card.active {
    border: 1px solid var(--qrw-border-success-primary);
    box-shadow: var(--qrw-shadow-card-light);
    background-color: var(--qrw-background-base-primary);
}

.tariff-card.active .card-title {
    color: var(--qrw-content-base-primary);
}

.tariff-card.active .card-description {
    color: var(--qrw-content-base-secondary);
}

.card-title {
    display: flex;
    column-gap: 8px;
    align-items: center;
    justify-content: space-between;
    color: var(--qrw-content-base-primary);
}

.tariff-modal-footer {
    display: flex;
    flex-direction: column;
    row-gap: 0;
    width: 100%;
    box-sizing: border-box;
    background: var(--qrw-background-base-secondary-08);
}

.tariff-confirm-buttons {
    display: flex;
    column-gap: 16px;
    padding: 12px 16px;
    box-sizing: border-box;
    justify-content: space-between;
}
</style>