var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-main-navbar",class:{
    sticky: _vm.sticky,
  },attrs:{"data-testid":"main-navbar"}},[_c('BaseDivider',{attrs:{"color":"border-base-tertiary"}}),_c('div',{staticClass:"main-navbar-tabs qrw-transition-animation-sm"},_vm._l((_vm.tabs),function(item){return _c('div',{key:item.key,staticClass:"navbar-tab qrw-transition-animation-sm",class:{
        'qrw-text-content-base-tertiary': item.key !== _vm.activeTab,
        'qrw-text-content-base-primary': item.key === _vm.activeTab,
        'active': item.key === _vm.activeTab,
        'animated': _vm.appearAnimated
      },on:{"click":function($event){return _vm.onTabClicked(item)}}},[_c('BaseIcon',{staticClass:"qrw-transition-animation-sm",staticStyle:{"font-size":"22px"},attrs:{"size":null,"icon":_vm.activeTab === item.key ? `${item.icon}-filled` : item.icon,"balloon":item.badge}}),_c('div',{staticClass:"qrw-label-xs qrw-text-content-base-tertiary qrw-transition-animation-sm",class:{
          'qrw-text-content-base-primary bold': item.key === _vm.activeTab
        }},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }