import Vue from "vue";
import Vuex from "vuex";

import CartModule from "./cart.module";

import moment from "moment";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cafe: null,
    closeRule: null,
    menus: [],
    currency: {},
    mode: "",
    country: "UA",
    inside: {},
    rootSystemUrl: null,
    order: null,
    tips: {},

    menuSearch: null,

    uiCategoriesOffsets: {
      grid: {},
      list: {},
    },

    reserveCart: {},

    imagesCache: {},

    pageWaypoints: {},

    delivery: {
      address: null,
      client: {
        _id: null,
        name: null,
        phone: null,
      },
      timeslots: null,
      targetDate: null,
      tariff: null,
    },

    modeNames: null,

    translationDictionary: {},
  },
  getters: {
    firstMenu(state) {
      return state.menus[0];
    },
    categoriesWithProducts(state) {
      return (menu) => {
        const categories = menu.categories
          .slice()
          .map((cat) => ({ ...cat, products: [] }));

        for (const product of menu.products) {
          const fittingCategory = categories.find(
            (cat) => cat._id === product.category_id
          );

          if (fittingCategory) {
            fittingCategory.products.push(product);
          }
        }

        return categories.filter((c) => c.products.length > 0);
      };
    },
    findProduct(state) {
      return (id, menuId) => {
        for (const menu of state.menus) {
          if (menuId && menu._id !== menuId) continue;

          const foundProduct = menu.products.find((p) => p._id === id);

          if (foundProduct) return foundProduct;
        }

        return null;
      };
    },
    findCategory(state) {
      return (id) => {
        for (const menu of state.menus) {
          const foundCategory = menu.categories.find((p) => p._id === id);

          if (foundCategory) return foundCategory;
        }

        return null;
      };
    },
    searchProducts(state, getters) {
      return (query) => {
        const lowerQuery = query.toLowerCase();

        const nameMatches = [];
        const categoryMatches = [];

        for (const menu of state.menus) {
          for (const product of menu.products) {
            if (product.name) {
              const localizedName = getters.localize(
                "product_name",
                product._id
              );

              if (localizedName.toLowerCase().includes(lowerQuery)) {
                nameMatches.push({
                  ...product,
                  menu_id: menu._id,
                });
              }
            }

            if (product.category) {
              const localizedCategoryName = getters.localize(
                "category_name",
                product.category_id
              );

              if (localizedCategoryName.toLowerCase().includes(lowerQuery)) {
                categoryMatches.push({
                  ...product,
                  menu_id: menu._id,
                });
              }
            }
          }
        }

        return [...nameMatches, ...categoryMatches].slice(0, 50);
      };
    },
    specificModeTitle(state) {
      return (mode) => {
        const targetKey = mode === "preview" ? "inside" : mode;

        const defaultModes = {
          inside: "Eat In",
          preorder: "Takeaway",
          takeaway: "Takeaway",
          delivery: "Delivery",
          reserve: "RESERVE",
        };

        if (state.modeNames) return state.modeNames[targetKey];

        return defaultModes[targetKey] || mode;
      };
    },
    modeTitle(state, getters) {
      return getters.specificModeTitle(state.mode);
    },
    getProductCachedImage(state) {
      return (productId) => state.imagesCache[productId];
    },
    buildRouterNavigateObject(state) {
      return (pageName, params = {}, cacheKey = null) => {
        // if (pageName === 'home') {
        //   return {
        //     name: 'home',
        //     params: {
        //       cafe_link: state.cafe.online_menu_link
        //     }
        //   }
        // }

        const pagesMapping = {
          inside: {
            home: "inside_home",
            lobby: "inside_lobby",
            menu: "inside_menu",
            product: "inside_product",
            cart: "inside_cart",
            order: "inside_order",
            receipt: "inside_receipt",
            orders: "inside_orders",
            search: "inside_search",
          },
          preview: {
            home: "preview_home",
            lobby: "preview_lobby",
            menu: "preview_menu",
            product: "preview_product",
            cart: "preview_cart",
            search: "preview_search",
            orders: "preview_orders",
          },
          delivery: {
            home: "preorder_home",
            lobby: "preorder_lobby",
            menu: "preorder_menu",
            product: "preorder_product",
            cart: "preorder_cart",
            receipt: "preorder_receipt",
            orders: "preorder_orders",
            address: "preorder_address",
            search: "preorder_search",
          },
        };

        pagesMapping.takeaway = pagesMapping.delivery;

        const currentSystemMapping = pagesMapping[state.mode];

        if (cacheKey && state.pageWaypoints[cacheKey]) {
          return state.pageWaypoints[cacheKey];
        }

        if (state.mode === "inside") {
          return {
            name: currentSystemMapping[pageName],
            params: {
              inside_code: state.inside.access_code,
              ...params,
            },
          };
        } else if (
          state.mode === "takeaway" ||
          state.mode === "delivery" ||
          state.mode === "preview"
        ) {
          return {
            name: currentSystemMapping[pageName],
            params: {
              mode: state.mode,
              cafe_link: state.cafe.online_menu_link,
              ...params,
            },
          };
        }
      };
    },
    orderItems(state) {
      if (!state.order) return [];
      const list = state.order.list;

      if (state.order.appends) {
        return list.concat(
          state.order.appends
            .filter((append) => append.status === "waiting")
            .map((append) => append.list)
            .flat()
        );
      }

      return list;
    },
    isOrderPending(state) {
      return (
        state.mode === "inside" &&
        state.order &&
        state.order.status === "waiting"
      );
    },
    canOrderBePaid(state) {
      return (
        state.order &&
        ["serving", "served", "paying"].includes(state.order.status)
      );
    },
    localizeMenuTitle(state) {
      return (menuId, locale) => {
        const menu = state.menus.find((menu) => menu._id === menuId);
        if (!menu) return null;

        if (menu.tag_i18n) {
          return menu.tag_i18n[locale] || menu.tag || menu.title;
        }

        return menu.tag || menu.title;
      };
    },
    localize(state) {
      return (type, key) => {
        return state.translationDictionary[`${type}:${key}`];
      };
    },
    isCafeClosed(state) {
      if (!state.cafe.today_schedule_computed) return true;

      if (
        moment().isBetween(
          state.cafe.today_schedule_computed.start,
          state.cafe.today_schedule_computed.end
        )
      ) {
        return false;
      }

      // const source = state.cafe.today_schedule_computed.schedule_source;

      // TODO: use consistent naming for systems
      return "cafe";
    },
    isSystemClosed(state) {
      return state.closeRule && !state.closeRule.allow_preliminary_orders;
    },
    isMenuClosed(state) {
      return (id) => {
        const menu = state.menus.find((m) => m._id === id);
        if (!menu) return false;

        if (!menu.schedule_pair) return false;

        return !moment().isBetween(
          menu.schedule_pair.start,
          menu.schedule_pair.end
        );
      };
    },
    deliveryPrice(state, getters) {
      const { cafe } = state;
      if (!cafe) return 0;
      if (!cafe.delivery) return 0;
      if (cafe.delivery.cost_type === "free") return 0;
      if (cafe.delivery.cost_type === "tariffed") {
        if (cafe.delivery.tariffication_mode === "partner") return 0;

        const matchingTariff = cafe.delivery.tariffs.find(
          (t) => t._id === state.delivery.tariff
        );

        let hasReachedMaxNonFreeTotal = false;

        if (cafe.delivery.cost_min_check) {
          const cartTotal = getters["cart/total"];

          hasReachedMaxNonFreeTotal = cartTotal > cafe.delivery.cost_min_check;
        }

        return matchingTariff && !hasReachedMaxNonFreeTotal
          ? matchingTariff.price
          : 0;
      }
      if (cafe.delivery.cost_type === "constant") return cafe.delivery.cost;
      if (
        cafe.delivery.cost_type === "conditional" &&
        cafe.delivery.cost_conditional_type === "constant"
      ) {
        const cartTotal = getters["cart/total"];

        return cartTotal > cafe.delivery.cost_min_check
          ? 0
          : cafe.delivery.cost;
      }
      if (cafe.delivery.cost_type === "taxi") return 0;
      return 0;
    },
    hasInsideUnpaidOrder(state, getters) {
      return (
        state.mode === "inside" &&
        state.order &&
        getters.orderItems.length &&
        getters.canOrderBePaid
      );
    },
    activeSystemsCount(state, getters) {
      if (!state.cafe) return 0;
      if (!state.cafe.modes) return 0;

      return (
        !!state.cafe.modes.inside +
        !!state.cafe.modes.delivery +
        !!state.cafe.modes.preorder
      );
    },
  },
  mutations: {
    reset(state) {
      state.cafe = null;
      state.menus = [];
      state.pageWaypoints = {};
      state.closeRule = null;
      state.tips = {};
    },
    setCafe(state, payload) {
      state.cafe = payload;
    },
    setMenus(state, payload) {
      state.menus = payload;
    },
    setCurrency(state, payload) {
      state.currency = payload;
    },
    setMode(state, payload) {
      state.mode = payload;
    },
    setCategoryUiOffset(state, { categoryId, offset, view }) {
      Vue.set(state.uiCategoriesOffsets[view], categoryId, offset);
    },
    setReserveCart(state, payload) {
      state.reserveCart = payload;
    },
    setCountry(state, country) {
      state.country = country;
    },
    setImagesCache(state, payload) {
      state.imagesCache = payload;
    },
    setInsideData(state, payload) {
      state.inside = payload;
    },
    setRootSystemUrl(state, url) {
      state.rootSystemUrl = url;
    },
    setOrder(state, order) {
      state.order = order;
    },
    setTranslationDictionary(state, dictionary) {
      state.translationDictionary = dictionary;
    },
    setDeliveryAddress(state, payload) {
      state.delivery.address = payload;
    },
    setDeliveryClient(state, payload) {
      state.delivery.client = payload;

      if (payload.super_addresses && payload.super_addresses.length) {
        state.delivery.address = payload.super_addresses[0];
      }
    },
    setDeliveryTimeslots(state, payload) {
      state.delivery.timeslots = payload;
    },
    setDeliveryTargetDate(state, payload) {
      state.delivery.targetDate = payload;
    },
    setDeliveryTariff(state, payload) {
      Vue.set(state.delivery, "tariff", payload);
    },
    setModeNames(state, payload) {
      state.modeNames = payload;
    },
    setPageWaypoint(state, { pageName, path }) {
      Vue.set(state.pageWaypoints, pageName, path);
    },
    setMenuSearch(state, payload) {
      state.menuSearch = payload;
    },
    setCloseRule(state, payload) {
      state.closeRule = payload;
    },
    setTips(state, payload) {
      state.tips = payload;
    },
  },
  actions: {
    buildImagesCache({ commit, state }) {
      const cache = {};

      for (const menu of state.menus) {
        for (const product of menu.products) {
          const imageUrl = product.image;

          if (!imageUrl) {
            cache[product._id] = null; // maybe set to some placeholder later
            continue;
          }

          if (imageUrl.includes("joinposter.com")) {
            // imageUrl = imageUrl.replace(/\/\//g, '/')
          }

          cache[product._id] = imageUrl;
        }
      }

      commit("setImagesCache", cache);
    },
    rebuildTranslationDictionary({ commit, state }, { locale }) {
      const dictionary = {};

      const tsStart = Date.now();

      for (const menu of state.menus) {
        for (const category of menu.categories) {
          const categoryMatchedI18n = category.i18n[locale] || null;

          dictionary[`category_name:${category._id}`] =
            categoryMatchedI18n || category.name;
        }

        for (const product of menu.products) {
          const productMatchedI18n =
            product.i18n.find((i) => i.locale === locale) || {};

          dictionary[`product_name:${product._id}`] =
            productMatchedI18n.name || product.name;
          dictionary[`product_description:${product._id}`] =
            productMatchedI18n.description || product.description;
          dictionary[`product_category:${product._id}`] =
            dictionary[`category_name:${product.category_id}`] ||
            product.category;

          for (let index = 0; index < product.price_groups.length; index++) {
            const priceGroup = product.price_groups[index];

            if (
              productMatchedI18n.price_groups &&
              productMatchedI18n.price_groups.length > index
            ) {
              dictionary[`price_group_name:${product._id}:${priceGroup._id}`] =
                productMatchedI18n.price_groups[index].name || priceGroup.name;
            } else {
              dictionary[`price_group_name:${product._id}:${priceGroup._id}`] =
                priceGroup.name;
            }

            for (
              let itemIndex = 0;
              itemIndex < priceGroup.items.length;
              itemIndex++
            ) {
              const item = priceGroup.items[itemIndex];

              if (
                productMatchedI18n.price_groups &&
                productMatchedI18n.price_groups[index] &&
                productMatchedI18n.price_groups[index].items &&
                productMatchedI18n.price_groups[index].items.length > itemIndex
              ) {
                dictionary[`price_group_item_name:${product._id}:${item._id}`] =
                  productMatchedI18n.price_groups[index].items[itemIndex] ||
                  item.name;
              } else {
                dictionary[`price_group_item_name:${product._id}:${item._id}`] =
                  item.name;

                console.log(
                  `Missing translation for item ${item._id}, ${item.name}, group ${index}, product ${product.name}, itemIndex ${itemIndex}`,
                  productMatchedI18n
                );
              }
            }
          }
        }
      }

      const timeSpent = Date.now() - tsStart;

      console.log(
        `Built translations dictionary in ${timeSpent} ms for locale '${locale}'`
      );

      commit("setTranslationDictionary", dictionary);
    },
  },
  modules: {
    cart: CartModule,
  },
});
