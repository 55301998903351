import en from "./en";
import uk from "./uk";
import ru from "./ru";
import cs from "./cs";
import ar from "./ar";
import tr from "./tr";
import lv from "./lv";

const messages = {
  en,
  ru,
  uk,
  cs,
  ar,
  tr,
  lv,
};

const bannedLanguages = [];

export function getSupportedLanguages() {
  return Object.keys(messages).filter((l) => !bannedLanguages.includes(l));
}

export function getBrowserLanguages() {
  return navigator.languages || [navigator.language || navigator.userLanguage];
}

export function useCachedOrGuessBestLanguage() {
  const savedPreferredLanguage =
    window.localStorage.getItem("preferred_locale");

  if (
    savedPreferredLanguage &&
    getSupportedLanguages().includes(savedPreferredLanguage) &&
    !bannedLanguages.includes(savedPreferredLanguage)
  ) {
    return savedPreferredLanguage;
  }

  const browserLanguages = getBrowserLanguages();

  const tokenizedLanguages = browserLanguages
    .filter((l) => !bannedLanguages.includes(l))
    .map((l) => l.toLowerCase().split("-"))
    .flat();

  return tokenizedLanguages.find((l) => messages[l]) || "en";
}

export function banLanguage(lang) {
  bannedLanguages.push(lang);
}

export default messages;
