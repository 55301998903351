var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-order-page"},[_c('div',{directives:[{name:"qrw-class-on-scroll",rawName:"v-qrw-class-on-scroll",value:({
      cssClass: 'qrw-app-top-container',
    }),expression:"{\n      cssClass: 'qrw-app-top-container',\n    }"},{name:"qrw-is-header",rawName:"v-qrw-is-header"}],staticClass:"qrw-app-top-sticky"},[_c('OrderPageHeader'),_c('BaseDivider',{attrs:{"color":"border-base-tertiary"}})],1),_c('div',{directives:[{name:"qrw-under-header",rawName:"v-qrw-under-header"},{name:"qrw-above-footer",rawName:"v-qrw-above-footer"}],staticClass:"order-page-container"},[_c('div',{staticClass:"bill-container"},[_c('div',{staticClass:"bill-content"},[_c('div',{staticClass:"bill-header"},[_c('div',{staticClass:"gap-row-2xs"},[_c('div',{staticClass:"qrw-label-lg bold qrw-text-content-base-primary"},[_vm._v(" "+_vm._s(_vm.$t("order_page.your_bill"))+" ")]),_c('PriceTag',{staticClass:"qrw-label-md qrw-text-content-base-tertiary",attrs:{"value":_vm.order.total}})],1),_c('div',{staticClass:"qrw-label-md bold qrw-text-content-success-primary",on:{"click":_vm.selectAll}},[_vm._v(" "+_vm._s(_vm.$t("order_page.select_all"))+" ")])]),_vm._l((_vm.orderItems),function(item){return _c('OrderProductCard',{key:item._id,attrs:{"listItem":item,"selection":_vm.selectedItems},on:{"select":(count) => _vm.onProductSelected(item, count)}})})],2),_c('BasePushButton',{attrs:{"variant":"fill","bgColor":"background-base-tertiary","textColor":"content-base-secondary","size":"md","icon":"plus"},on:{"click":_vm.addMoreFromMenu}},[_vm._v(" "+_vm._s(_vm.$t("cart.add_more"))+" ")])],1),_c('BaseDivider',{attrs:{"color":"border-base-tertiary"}}),_c('LeaveTipsBlock',{attrs:{"total":_vm.totalObject.sum},model:{value:(_vm.selectedTipPercent),callback:function ($$v) {_vm.selectedTipPercent=_vm._n($$v)},expression:"selectedTipPercent"}})],1),(_vm.isNavbarExpanded)?_c('div',{staticClass:"payment-expanded-backdrop"}):_vm._e(),_c('div',{directives:[{name:"qrw-is-footer",rawName:"v-qrw-is-footer"}],staticClass:"order-page-footer qrw-app-bottom-container",class:{
      expanded: _vm.isNavbarExpanded,
    }},[_c('OrderPaymentNavbar',{attrs:{"payMethod":_vm.payMethod,"splits":_vm.order.split_payments && _vm.order.split_payments.length
          ? _vm.order.split_payments
          : _vm.splitPayments,"total":_vm.totalObject,"makingNewOrder":false},on:{"expandChanged":(v) => (_vm.isNavbarExpanded = v),"confirm":_vm.onPaymentConfirmed,"changePayMethod":function($event){_vm.payMethodModalIsShown = true}}}),_c('MainNavbar',{attrs:{"activeTab":"order","sticky":false},on:{"tabClick":_vm.onNavbarTabClicked}})],1),(_vm.payMethodModalIsShown && !_vm.isPendingSplitPayment)?_c('PaymentMethodModal',{attrs:{"payMethods":_vm.cafe.pay_methods},on:{"close":function($event){_vm.payMethodModalIsShown = false}},model:{value:(_vm.payMethod),callback:function ($$v) {_vm.payMethod=$$v},expression:"payMethod"}}):_vm._e(),(_vm.pendingOnlinePayment)?_c('OnlinePaymentModal',{attrs:{"gateway":_vm.pendingOnlinePayment.gateway,"payLink":_vm.pendingOnlinePayment.pay_url}}):_vm._e(),(_vm.errorCode)?_c('ErrorModal',{attrs:{"code":_vm.errorCode,"type":"payment"},on:{"close":function($event){_vm.errorCode = null}}}):_vm._e(),(_vm.splitPaymentTimeoutModalShown)?_c('SplitPaymentTimeoutModal',{on:{"close":function($event){_vm.splitPaymentTimeoutModalShown = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }