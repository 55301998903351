<template>
  <div class="app-delivery-details-wrapper">
    <div class="app-delivery-details">
      <div class="qrw-label-lg bold qrw-text-content-base-primary">
        {{ $t('delivery_details.title') }}
      </div>
      <div
        class="time-container"
        @click="timeModalShown = true"
      >
        <div class="details-icon">
          <BaseIcon
            icon="clock"
            size="sm2"
            class="qrw-text-content-base-primary"
          />
        </div>
        <div class="gap-row-2xs w-100">
          <div class="qrw-label-md qrw-text-content-base-primary">
            {{ $t('delivery_details.delivery_in') }} {{estimatedDeliveryTime}} {{$t('units.min')}}
          </div>
          <div
            class="gap-column-zero qrw-text-content-base-secondary"
            v-if="cafeClosed && !targetDate"
          >
            {{$t('delivery_details.enter_time')}}
          </div>
          <div
            class="gap-column-zero qrw-text-content-base-secondary"
            v-else
          >
            <div class="qrw-label-sm">
              {{ targetDate ?  $t('order_time_modal.schedule_short') : $t('order_time_modal.asap') }}
            </div>
            <BaseIcon
              icon="dot"
              size="xs"
            />
            <div class="qrw-label-sm">
              {{ estimatedDeliveryDate }}
            </div>
          </div>
        </div>
        <BaseIconButton
          variant="ghost"
          icon="chevron-right"
          size="md"
          textColor="content-base-secondary"
        />
      </div>
      <BaseDivider color="border-base-tertiary" />
      <div class="gap-row-xs">
        <div
          class="address-container"
          @click="addressModalShown = true"
        >
          <div class="details-icon">
            <BaseIcon
              icon="marker-pin"
              size="sm2"
              class="qrw-text-content-base-primary"
            />
          </div>
          <div class="gap-row-2xs w-100">
            <div class="qrw-label-md qrw-text-content-base-primary">
              {{deliveryAddress ? deliveryAddress.street : $t('edit_address.title')}} <span
                class="qrw-text-content-error-primary"
                v-if="!deliveryAddress"
              >
                *
              </span>
            </div>
            <div class="qrw-label-sm qrw-text-content-base-secondary">
              {{ deliveryAddressDetailsRow }}
            </div>
          </div>
          <BaseIconButton
            variant="ghost"
            :icon="deliveryAddress ? 'chevron-right' : 'plus'"
            size="md"
            textColor="content-base-secondary"
          />
        </div>

        <MapFrame
          class="adress-map-frame"
          :zoom="16"
          :location="{ lat: deliveryAddress?.lat, lon: deliveryAddress?.lon }"
          v-if="deliveryAddress"
        />
      </div>
      <div
        class="courier-instructions"
        v-if="deliveryAddress && deliveryAddress.instructions"
      >
        <div class="qrw-label-sm qrw-text-content-base-tertiary">
          {{$t('delivery_details.courier_instructions')}}
        </div>
        <div class="qrw-paragraph-md qrw-text-content-base-primary">
          {{ deliveryAddress.instructions }}
        </div>
      </div>
      <!-- <BaseDivider color="border-base-tertiary" />
      <ToggleRow
        v-model="contactless"
        icon="door"
        :text="$t('delivery_details.contactless')"
      /> -->
    </div>

    <SelectAddressModal
      @close="addressModalShown = false"
      v-if="addressModalShown"
    />

    <OrderTimeModal
      v-model="targetDate"
      @close="timeModalShown = false"
      v-if="timeModalShown"
    />
  </div>

</template>

<script>
import MapFrame from '@/components/common/MapFrame.vue'

// import ToggleRow from '@/components/common/ToggleRow.vue'

import SelectAddressModal from '@/components/modals/SelectAddressModal.vue'
import OrderTimeModal from '@/components/modals/OrderTimeModal.vue'

import moment from 'moment'

export default {
  data() {
    return {
      contactless: false,

      addressModalShown: false,
      timeModalShown: false
    }
  },
  watch: {
    contactless(value) {
      this.$emit('setContactless', value)
    },
    targetDate() {
      this.$store.commit('setDeliveryTargetDate', this.targetDate)
    }
  },
  components: {
    MapFrame,
    // ToggleRow,
    SelectAddressModal,
    OrderTimeModal
  },
  computed: {
    cafe() {
      return this.$store.state.cafe
    },
    cafeClosed() {
      return this.$store.getters.isCafeClosed
    },
    deliveryAddress() {
      return this.$store.state.delivery.address
    },
    deliveryAddressDetailsRow() {
      if (!this.deliveryAddress) {
        return this.$t('edit_address.enter_address')
      }

      const detailKeys = ['entrance', 'door', 'building', 'floor', 'flat']

      const detailValues = []

      for (const key of detailKeys) {
        if (this.deliveryAddress[key]) {
          detailValues.push(`${this.$t('address_place_details.' + key)}: ${this.deliveryAddress[key]}`)
        }
      }

      return detailValues.slice(0, 3).join(', ')
    },
    estimatedDeliveryTime() {
      return this.cafe.delivery.shipping_time + this.cafe.delivery.min_order_time
    },
    estimatedDeliveryDate() {
      if (this.targetDate) {
        if (moment(this.targetDate).isSame(moment(), 'day')) {
          return this.$t('order_time_modal.today') + ' ' + moment(this.targetDate).format('HH:mm')
        }
        if (moment(this.targetDate).isSame(moment().add(1, 'day'), 'day')) {
          return this.$t('order_time_modal.tomorrow') + ' ' + moment(this.targetDate).format('HH:mm')
        }
        return moment(this.targetDate).format('ddd DD.MM, HH:mm')
      }
      return moment().add(this.estimatedDeliveryTime, 'minutes').format('HH:mm')
    },
    targetDate: {
      get() {
        return this.$store.state.delivery.targetDate
      },
      set(v) {
        this.$store.commit('setDeliveryTargetDate', v)
      }
    }
  }
}
</script>

<style scoped>
.app-delivery-details-wrapper {
  padding: 12px 16px;
}

.app-delivery-details {
  padding: 16px;
  border-radius: 12px;

  box-shadow: var(--qrw-shadow-card-light);

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  background: var(--qrw-background-base-primary-10);
}

.time-container,
.address-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 12px;
  width: 100%;
  box-sizing: border-box;
}

.details-icon {
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  box-sizing: border-box;
  background: var(--qrw-background-base-tertiary);
}

.adress-map-frame {
  height: 240px;
  min-height: 240px;
}

.courier-instructions {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  background: var(--qrw-background-base-tertiary);
  padding: 12px 16px;
  border-radius: 12px;
}
</style>
