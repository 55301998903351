<template>
  <div class="generic-total qrw-label-sm">
    <div
      class="generic-total-row"
      v-if="value.sum && requiresComposition"
    >
      <div class="row-values">
        <div class="bold">
          {{ $t("total.products_total") }}
        </div>
        <PriceTag
          class="bold"
          :value="value.sum"
        />
      </div>
      <BaseDivider
        color="border-base-tertiary"
        dashed
      />
    </div>
    <div
      class="generic-total-row"
      v-if="value.service_fee && !collapsed"
    >
      <div class="row-values">
        <div>
          {{ $t("total.service_fee") }}
        </div>
        <PriceTag
          prefix="+"
          :value="value.service_fee"
        />
      </div>
      <BaseDivider
        color="border-base-tertiary"
        dashed
      />
    </div>
    <div
      class="generic-total-row"
      v-if="value.discount"
    >
      <div class="row-values">
        <div>
          {{ $t("total.discount") }} ({{value.discount}}%)
        </div>
        <PriceTag
          prefix="-"
          :value="value.discount_value"
        />
      </div>
      <BaseDivider
        color="border-base-tertiary"
        dashed
      />
    </div>
    <div
      class="generic-total-row"
      v-if="value.delivery_fee && !collapsed"
    >
      <div class="row-values">
        <div>
          {{ $t("total.delivery_fee") }}
        </div>
        <PriceTag
          prefix="+"
          :value="value.delivery_fee"
        />
      </div>
      <BaseDivider
        color="border-base-tertiary"
        dashed
      />
    </div>
    <div
      class="generic-total-row"
      v-if="value.tip && !collapsed"
    >
      <div class="row-values">
        <div>
          {{ $t("total.tip") }}
        </div>
        <PriceTag
          prefix="+"
          :value="value.tip"
        />
      </div>
      <BaseDivider
        color="border-base-tertiary"
        dashed
      />
    </div>
    <div class="generic-total-row final-row">
      <div class="row-values">
        <div class="gap-row-3xs">
          <div class="qrw-label-md bold qrw-text-content-base-primary">
            {{ alreadyPaid ? $t("receipt.total_paid") : $t("order_payment_navbar.total_to_pay") }}
          </div>
          <div class="qrw-label-xs qrw-text-content-base-tertiary">
            {{ $t("order_payment_navbar.including_fees") }}
          </div>
        </div>
        <PriceTag
          class="qrw-label-md qrw-text-content-base-primary bold"
          :value="value.total"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,

    alreadyPaid: Boolean,

    collapsed: Boolean
  },
  computed: {
    requiresComposition() {
      return (
        !this.collapsed && (this.value.tip || this.value.discount || this.value.service_fee || this.value.delivery_fee)
      )
    }
  }
}
</script>

<style scoped>
.generic-total {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  row-gap: 12px;
}

.generic-total-row {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.row-values {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 16px;
  color: var(--qrw-content-base-secondary);
}

.final-row .row-values {
  align-items: flex-start;
}
</style>
