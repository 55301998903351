<template>
  <div id="app">
    <keep-alive :include="['MenuPage', 'CallWaiterBlock', 'MenuLobby', 'CartPage']">
      <router-view />
    </keep-alive>
    <DesktopBlocker v-if="showDesktopBlocker" />
  </div>
</template>

<style>
#app {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none !important;
  -webkit-user-callout: none !important;
  -webkit-user-select: none !important;
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: none !important;

  /* overflow-y: scroll; */
  -webkit-overflow-scrolling: touch;

  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.qrw-app-top-container {
  backdrop-filter: blur(20px);
  box-shadow: var(--qrw-shadow-foundation-2dp);
}

.qrw-app-top-sticky {
  position: fixed;
  top: 0;
  z-index: 20;
  width: 100%;

  background: var(--qrw-background-base-primary-08);
  backdrop-filter: blur(20px);
}

.qrw-app-bottom-container {
  backdrop-filter: blur(20px);
  box-shadow: var(--qrw-shadow-foundation-4dp);
}

.qrw-app-bottom-sticky {
  position: fixed;
  bottom: 0;
  z-index: 20;
  width: 100%;

  background: var(--qrw-background-base-secondary-08);
}

.qrw-price-tag {
  white-space: nowrap;
  width: fit-content;
}

.qrw-transition-animation-sm {
  transition: all 0.35s ease-in-out;
}

.qrw-navbar-pagination-spacer {
  width: 100%;
  height: 24px;
}

.qrw-fit-height-modal {
  height: fit-content;
}

/* General animations */
@keyframes bottom-navbar-appear {
  0% {
    bottom: -64px;
  }

  100% {
    bottom: 0;
  }
}

.bottom-navbar-appear-enter-active {
  animation: bottom-navbar-appear 0.5s;
}

.bottom-navbar-appear-leave-active {
  animation: bottom-navbar-appear 0.5s reverse;
}
</style>

<script>
import { v4 as uuidv4 } from "uuid";

import { useCachedOrGuessBestLanguage } from "@/i18n";

import DesktopBlocker from "@/components/common/DesktopBlocker.vue";

export default {
  components: {
    DesktopBlocker,
  },
  methods: {
    disableMobileZoom () {
      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        const handler = (e) => {
          if (e.scale !== 1) {
            e.preventDefault();
          }
        };

        window.addEventListener("touchmove", handler, { passive: false });
        window.document.addEventListener("touchmove", handler, {
          passive: false,
        });
      }
    },
    initVisitorId () {
      const existing = window.localStorage.getItem("visitor_id");

      if (!existing) {
        window.localStorage.setItem("visitor_id", uuidv4());
      }
    },
  },
  computed: {
    showDesktopBlocker () {
      const isMobile =
        ("ontouchstart" in window || navigator.msMaxTouchPoints) &&
        window.innerWidth < 1280;

      return !isMobile && this.$route.name !== "offert";
    },
  },
  created () {
    this.initVisitorId();
    this.disableMobileZoom();

    this.$i18n.locale = useCachedOrGuessBestLanguage();
  },
};
</script>
