<template>
  <div class="app-dish-variants-group">
    <div class="gap-row-1xs">
      <div class="gap-column-xs group-header">
        <div class="qrw-label-md bold qrw-text-content-base-primary w-100">
          {{ groupName || $t("menu_product.variants") }}
        </div>

        <div class="required-badge-container">
          <BaseLabelBadge
            variant="filled"
            size="xs"
            primaryColor="background-warning-tertiary"
            secondaryColor="content-warning-primary"
          >
            {{ $t("menu_product.required") }}
          </BaseLabelBadge>
        </div>
      </div>

      <div
        class="qrw-label-sm qrw-text-content-base-tertiary"
        v-if="variantsHints && variantsHints.length == 1"
      >
        {{ variantsHints[0] }}
      </div>
      <div
        class="gap-column-3xs"
        v-else-if="variantsHints.length > 1"
      >
        <div class="qrw-label-sm qrw-text-content-base-tertiary">
          {{ variantsHints[0] }}
        </div>
        <BaseIcon
          size="xs"
          icon="dot"
          class="qrw-text-content-base-tertiary"
        />
        <div class="qrw-label-sm qrw-text-content-base-tertiary">
          {{ variantsHints[1] }}
        </div>
      </div>
    </div>
    <div class="gap-row-txs">
      <div
        class="variant-item"
        :class="{
          [getStateForItem(item)]: true,
        }"
        @click="onItemClicked(item)"
        v-for="(item, index) in group.items"
        :key="index"
      >
        <BaseBetaRadioButton
          size="lg"
          :value="getStateForItem(item) === 'active'"
          v-if="canSelectOnlySingleItem"
        />
        <BaseBetaCheckbox
          size="lg"
          :value="getStateForItem(item) === 'active'"
          v-else
        />
        <div class="gap-row-2xs variant-name-weight w-100">
          <div
            class="qrw-label-sm qrw-text-content-base-secondary"
            :class="{
              'bold qrw-text-content-base-primary':
                getStateForItem(item) === 'active',
            }"
          >
            {{ groupItemNames[index] }}
          </div>
          <div
            class="qrw-label-sm"
            :class="{
              'qrw-text-content-base-secondary':
                getStateForItem(item) === 'active',
              'qrw-text-content-base-tertiary':
                getStateForItem(item) !== 'active',
            }"
            v-if="item.weight"
          >
            {{ item.weight }} {{ $t("units." + product.unit) }}
          </div>
        </div>
        <div
          class="qrw-label-sm variant-price qrw-text-content-base-tertiary gap-column-2xs"
          :class="{
            'qrw-text-content-base-primary': getStateForItem(item) === 'active',
          }"
          v-if="item.price"
        >
          +
          <PriceTag :value="item.price" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
    group: Object,
    product: Object
  },
  methods: {
    getStateForItem(item) {
      const itemPresent = this.value.includes(item._id)

      if (itemPresent) return 'active'

      if (!this.canSelectOnlySingleItem && this.value.length >= this.group.max_count) {
        return 'disabled'
      }

      return 'default'
    },
    onItemClicked(item) {
      const currentState = this.getStateForItem(item)

      if (currentState === 'disabled') return

      if (currentState === 'active') {
        if (this.canSelectOnlySingleItem) {
          return
        }

        if (this.value.length - 1 < this.group.min_count) return

        this.$emit(
          'input',
          this.value.filter(id => id !== item._id)
        )
      } else {
        if (this.canSelectOnlySingleItem) {
          // if we can only select 1 item, on click - just set the item as the only one present in the buffer
          this.$emit('input', [item._id])
          return
        }

        // make sure limits are not exceeded
        if (this.value.length + 1 > this.group.max_count) return

        this.$emit('input', [...this.value, item._id])
      }
    }
  },
  mounted() {
    if ((!this.value || !this.value.length) && this.group.items?.length) {
      this.$emit(
        'input',
        this.group.items.slice(0, this.group.min_count || 1).map(item => item._id)
      )
    }
  },
  computed: {
    variantsHints() {
      if (this.canSelectOnlySingleItem) {
        return [this.$t('menu_product.variants_single')]
      }

      return [
        `${this.$t('menu_product.variants_min')}: ${this.group.min_count || 0}`,
        `${this.$t('menu_product.variants_max')}: ${this.group.max_count || 0}`
      ]
    },
    currency() {
      return this.$store.state.currency
    },
    canSelectOnlySingleItem() {
      return this.group.min_count <= 1 && this.group.max_count === 1
    },
    groupName() {
      return this.$store.getters.localize('price_group_name', this.product._id + ':' + this.group._id)
    },
    groupItemNames() {
      return this.group.items.map(item =>
        this.$store.getters.localize('price_group_item_name', this.product._id + ':' + item._id)
      )
    }
  }
}
</script>

<style scoped>
.app-dish-variants-group {
  display: flex;
  padding: 16px;
  row-gap: 16px;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
}

.variant-item {
  display: flex;
  width: 100%;
  padding: 11px 16px 11px 12px;
  border: 1px solid var(--qrw-border-base-tertiary);
  border-radius: 12px;
  align-items: center;
  column-gap: 8px;
  box-sizing: border-box;
  cursor: pointer;
}

.variant-price {
  white-space: nowrap;
}

.required-badge {
  padding: 4px 10px;
  display: flex;
  align-items: center;

  background: var(--qrw-background-error-default);
  border-radius: 0px 0px 6px 6px;
  color: var(--qrw-content-base-inverse-primary);

  position: absolute;

  top: 0;
  right: 24px;
}

.variant-item.active {
  box-shadow: var(--qrw-shadow-card-light);
  border: 1px solid var(--qrw-border-success-primary);
  background: var(--qrw-background-base-primary-10);
}

.variant-item.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.group-header {
  align-items: flex-start;
}

.required-badge-container {
  padding: 2px 0;
}
</style>
