<template>
  <div
    class="secondary-page-header"
    :class="{
      fixed
    }"
    ref="header"
  >
    <div
      class="secondary-page-header-top"
      :class="{
      'no-title': hideSubtitle
    }"
    >
      <BaseIconButton
        textColor="content-base-secondary"
        variant="ghost"
        size="md"
        :icon="leftIcon"
        @click="$emit('leftClicked')"
        v-if="leftIcon"
      />
      <div class="header-info">
        <div
          class="qrw-label-xs bold qrw-text-content-base-tertiary"
          :class="{
          truncated: truncateTitle
        }"
          v-if="!hideSubtitle"
        >
          {{ subtitle || cafe.name }}
        </div>
        <div
          class="qrw-label-md bold qrw-text-content-base-primary"
          :class="{
          truncated: truncateTitle
        }"
        >
          {{ title }}
        </div>
      </div>
      <BaseIconButton
        textColor="content-base-secondary"
        variant="ghost"
        size="md"
        :icon="rightIcon"
        @click="$emit('rightClicked')"
        :class="{
          'hidden-icon': !rightIcon,
        }"
      />
    </div>
    <BaseDivider color="border-base-tertiary" />
  </div>
</template>

<script>
import { updateAdaptiveHeaderHeight } from '@/utils/adaptiveUi.js'

export default {
  props: {
    title: String,
    subtitle: String,
    leftIcon: {
      type: String,
      default: 'arrow-left'
    },
    rightIcon: {
      type: String,
      default: 'pencil'
    },
    truncateTitle: {
      type: Boolean,
      default: false
    },
    hideSubtitle: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    cafe() {
      return this.$store.state.cafe
    }
  },
  mounted() {
    updateAdaptiveHeaderHeight(this.$refs.header)
  }
}
</script>

<style scoped>
.secondary-page-header {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
}

.fixed {
  position: fixed;
  top: 0;
  z-index: 10;
  background: var(--qrw-background-base-secondary-08);
  backdrop-filter: blur(20px);
}

.secondary-page-header-top {
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  align-items: flex-start;
  padding: 12px 16px 12px 16px;
}

.header-info {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  row-gap: 4px;
  text-align: center;
  align-items: center;
  overflow: hidden;
  padding: 1px 0;
}

.category-tab {
  padding: 4px 12px;
  white-space: nowrap;
}

.hidden-icon {
  visibility: hidden;
}

.truncated {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.no-title {
  align-items: center;
}
</style>
