<template>
  <BaseSimpleModal
    :fullHeight="false"
    :showFooter="false"
    :showHeader="false"
    @backdropClick="$emit('close')"
  >
    <div class="scan-modal-body">
      <div class="scan-header">
        <div class="qrw-heading-sm bold qrw-text-content-base-primary">
          {{$t('scan_qrcode.modal_title')}}
        </div>
        <div class="qrw-label-md qrw-text-content-base-tertiary">
          {{$t('scan_qrcode.modal_subtitle')}}
        </div>
      </div>
      <div class="scan-frame-container">
        <img
          src="/images/qr-scanner-overlay.svg"
          class="scan-frame-overlay"
        >
        <video
          class="scan-frame"
          ref="frame"
        ></video>

        <BaseIconButton
          size="md"
          variant="gray"
          class="flash-button"
          bgColor="background-base-tertiary"
          @click="toggleFlash"
          :icon="flashOn ? 'flash-filled' : 'flash-off-filled'"
          v-if="hasFlash"
        />
      </div>
      <div class="scan-alternate">
        <div class="scan-hints">
          <div class="qrw-label-lg bold qrw-text-content-base-secondary">
            {{$t('scan_qrcode.modal_cant_scan')}}
          </div>
          <div class="qrw-label-md qrw-text-content-base-tertiary">
            {{$t('scan_qrcode.modal_enter_code')}}
          </div>

        </div>
        <BaseDigitInputsGroup
          size="lg"
          v-model="shortCode"
          :length="6"
        />
      </div>
    </div>

    <div class="scan-modal-footer gap-row-zero w-100 qrw-app-bottom-container">
      <BaseDivider color="border-base-tertiary" />
      <div class="button-container">
        <BasePushButton
          variant="ghost"
          borderColor="border-base-tertiary"
          textColor="content-base-secondary"
          size="lg"
          @click="$emit('close')"
          class="w-100"
        >
          {{ $t("common_actions.close") }}
        </BasePushButton>
      </div>
    </div>
  </BaseSimpleModal>
</template>

<script>
import QrScanner from 'qr-scanner'

export default {
  data() {
    return {
      scanner: null,
      hasFlash: false,

      flashOn: false,

      shortCode: null
    }
  },
  methods: {
    onScanned(result) {
      if (result.data) {
        const link = result.data

        console.log(`Parsing QR code link: ${link}`)

        if (!window.location.hostname.includes('localhost') && !link.includes('qrwaiter')) return

        if (link.includes('new/') || link.includes('inside/')) {
          // Support both old and new links:
          // https://app.qrwaiter.com.ua/new/123456
          // https://new.qrwaiter.com.ua/inside/123456
          const routePrefix = link.includes('inside/') ? 'inside/' : 'new/'

          const insideCode = link.split(routePrefix)[1]

          this.$router.replace({
            name: 'inside_cart',
            params: {
              inside_code: insideCode
            },
            query: {
              cart_id: this.$store.state.cart.cartId
            }
          })
          return
        }

        window.location.href = link
      }
    },
    toggleFlash() {
      this.flashOn = !this.flashOn
      this.scanner.toggleFlash()
    },
    init() {
      console.log(this.$refs)

      this.scanner = new QrScanner(this.$refs.frame, result => this.onScanned(result), {
        preferredCamera: 'environment',
        maxScansPerSecond: 10,
        returnDetailedScanResult: true
      })

      this.hasFlash = this.scanner.hasFlash()

      this.scanner.start()
    }
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    this.scanner.destroy()
    this.scanner = null
  }
}
</script>

<style scoped>
.scan-header {
  padding: 24px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  box-sizing: border-box;
  width: 100%;

  justify-content: center;
  align-items: center;
}

.scan-modal-body {
  /* padding: 16px; */
}

.scan-frame-container {
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
}

.scan-frame-overlay {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(-50%);
  z-index: 2;
}

.flash-button {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 24px;
  width: fit-content;
}

.scan-alternate {
  display: flex;
  flex-direction: column;
  padding: 16px 16px 24px 16px;
  row-gap: 24px;

  justify-content: center;
  align-items: center;
}

.scan-hints {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  justify-content: center;
  align-items: center;
}

.scan-frame {
  border-radius: 12px;
  /* width: 398px;
  height: 398px; */
  width: 100%;
  height: 49vh;
  object-fit: cover;
}

.button-container {
  display: flex;
  box-sizing: border-box;
  padding: 12px 16px;
  row-gap: 8px;
}
</style>
