var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"qrw-class-on-scroll",rawName:"v-qrw-class-on-scroll",value:({
    cssClass: 'collapsed',
    limit: 32,
  }),expression:"{\n    cssClass: 'collapsed',\n    limit: 32,\n  }"}],staticClass:"app-archive-header"},[_c('div',{staticClass:"bold qrw-text-content-base-primary qrw-transition-animation-sm",class:{
      'qrw-heading-sm': _vm.scrollPos <= 32,
      'qrw-label-lg': _vm.scrollPos > 32,
  }},[_vm._v(" "+_vm._s(_vm.$t("archive.title"))+" ")]),_c('div',{staticClass:"qrw-text-content-base-secondary qrw-transition-animation-sm",class:{
      'qrw-label-md': _vm.scrollPos <= 32,
      'qrw-label-xs': _vm.scrollPos > 32,
  }},[_vm._v(" "+_vm._s(_vm.scrollPos > 32 ? _vm.$tc('units.orders', _vm.count) : _vm.$t("archive.subtitle_1"))+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }