<template>
  <CarouselContainer :slidesCount="renderableSlidesCount">
    <CarouselSlide
      class="qrw-fill-background-base-tertiary-09"
      v-if="shouldShowCartNavbar"
    >
      <PresentCartNavbar />
    </CarouselSlide>
    <CarouselSlide
      class="qrw-fill-background-error-secondary"
      v-if="hasCancelledOrder"
    >
      <CancelledOrderNavbar />
    </CarouselSlide>
    <CarouselSlide
      class="qrw-fill-background-warning-secondary"
      v-if="hasPendingOrder"
    >
      <PendingOrderNavbar />
    </CarouselSlide>
    <CarouselSlide
      class="qrw-fill-background-success-secondary"
      v-if="hasInsideUnpaidOrder"
    >
      <UnpaidOrderNavbar />
    </CarouselSlide>
  </CarouselContainer>
</template>

<script>
import CarouselContainer from '@/components/common/CarouselContainer.vue'
import CarouselSlide from '@/components/common/CarouselSlide.vue'

import PresentCartNavbar from '@/components/navbar/PresentCartNavbar.vue'
import UnpaidOrderNavbar from '@/components/navbar/UnpaidOrderNavbar.vue'
import PendingOrderNavbar from '@/components/navbar/PendingOrderNavbar.vue'
import CancelledOrderNavbar from '@/components/navbar/CancelledOrderNavbar.vue'

export default {
  components: {
    CarouselContainer,
    CarouselSlide,
    UnpaidOrderNavbar,
    PendingOrderNavbar,
    PresentCartNavbar,
    CancelledOrderNavbar
  },
  computed: {
    hasInsideUnpaidOrder() {
      return this.$store.getters.hasInsideUnpaidOrder
    },
    hasPendingOrder() {
      return this.$store.getters.isOrderPending
    },
    order() {
      return this.$store.state.order
    },
    hasCancelledOrder() {
      return this.order && this.order.status === 'cancelled'
    },
    cartItems() {
      return this.$store.getters['cart/items']
    },
    shouldShowCartNavbar() {
      return this.cartItems.length > 0
    },
    renderableSlidesCount() {
      return [
        this.shouldShowCartNavbar,
        this.hasCancelledOrder,
        this.hasPendingOrder,
        this.hasInsideUnpaidOrder
      ].filter(Boolean).length
    }
  }
}
</script>

<style>
</style>
