<template>
  <div class="app-menu-lobby">
    <ScrollTransitionHeader :scrollLimit="1" :heightDifference="88 - 64" useSameComponent>
      <template #primary="slotProps">
        <div class="gap-row-zero" :class="{
      'qrw-app-top-sticky': true,
      'qrw-app-top-container': slotProps.transitionData.isSwitched,
    }" v-qrw-is-header>
          <ClosedBanner type="cafe" v-if="cafeClosedFlag === 'cafe'" />
          <MenuLobbyHeader :collapsed="slotProps.transitionData.isSwitched"
            @switchLanguage="languagePickerShown = true" />
        </div>
      </template>
    </ScrollTransitionHeader>

    <!-- <MenuLobbyHeader @searchClicked="sharePageInsteadOfSearch" /> -->

    <div class="menu-page-content" v-qrw-under-header v-qrw-above-footer>
      <div class="search-section" @click="goToSearch">
        <div class="qrw-label-lg qrw-text-content-base-primary bold">
          {{ $t("common_actions.search") }}
        </div>
        <BaseSearchInput :placeholder="$t('menu_lobby.search_ph')" size="md" />
      </div>

      <div class="menu-cards-section">
        <div class="qrw-label-lg bold qrw-text-content-base-primary">
          {{ $t("menu_lobby.our_menus") }}
        </div>
        <div class="menu-cards-list">
          <MenuLobbyCard :menu="menu" v-for="menu in menus" :key="menu._id" @click="openMenu(menu)" />
        </div>
      </div>

      <!-- <LobbyPromocodeBlock /> -->
      <LeavePersonalTips v-if="canLeavePersonalTips" />
      <BaseDivider color="border-base-tertiary" v-if="mode === 'preview'" />
      <ScanCodeBlock noBackground v-if="mode === 'preview' && cafe.modes.inside && cafe.has_real_inside" />
      <CallWaiterBlock noBackground v-if="isInside" />
    </div>

    <div class="qrw-app-bottom-sticky qrw-app-bottom-container" v-qrw-is-footer>
      <MenuCarouselNavbar />
      <MainNavbar activeTab="menu" @tabClick="onNavbarTabClicked" :sticky="false" />
    </div>

    <LanguagePickerModal @close="languagePickerShown = false" v-if="languagePickerShown" />
  </div>
</template>

<script>
import MenuLobbyHeader from "@/components/header/MenuLobbyHeader.vue";
import MainNavbar from "@/components/navbar/MainNavbar.vue";

import MenuLobbyCard from "@/components/menu/MenuLobbyCard.vue";
// import LobbyPromocodeBlock from '@/components/menu/PromocodeBlock.vue'
import CallWaiterBlock from "@/components/menu/CallWaiterBlock.vue";
import ScanCodeBlock from "@/components/menu/ScanCodeBlock.vue";

import ScrollTransitionHeader from "@/components/header/ScrollTransitionHeader.vue";
// import LobbyStaticHeader from '@/components/header/LobbyStaticHeader.vue'

import LanguagePickerModal from "@/components/modals/LanguagePickerModal.vue";

import MenuCarouselNavbar from "@/components/navbar/MenuCarouselNavbar.vue";

import ClosedBanner from "@/components/header/ClosedBanner.vue";

import LeavePersonalTips from "@/components/tips/LeavePersonalTips.vue";

export default {
  components: {
    MenuLobbyHeader,
    MainNavbar,
    MenuLobbyCard,
    // LobbyPromocodeBlock,
    CallWaiterBlock,
    ScanCodeBlock,
    ScrollTransitionHeader,
    LanguagePickerModal,
    MenuCarouselNavbar,
    // LobbyStaticHeader
    // LobbyStaticHeader,
    ClosedBanner,
    LeavePersonalTips
  },
  data () {
    return {
      languagePickerShown: false,
    };
  },
  methods: {
    onNavbarTabClicked (tab) {
      if (tab === "home") {
        this.$router.push(
          this.$store.getters.buildRouterNavigateObject("home")
        );
      }
    },
    sharePageInsteadOfSearch () {
      window.navigator.share({
        title: this.cafe.name,
        text: "Menu",
        url: window.location.href,
      });
    },
    openMenu (menu) {
      this.$router.push(
        this.$store.getters.buildRouterNavigateObject("menu", {
          menu_id: menu._id,
        })
      );
    },
    goToSearch () {
      this.$router.push(
        this.$store.getters.buildRouterNavigateObject("search")
      );
    },
    checkIfOnlyOneMenu () {
      // We check for the waypoint cache to avoid entering the loop of opening the menu
      if (this.menus.length === 1 && this.$store.getters.activeSystemsCount === 1 && !this.$store.state.pageWaypoints.menu) {
        this.openMenu(this.menus[0]);
      }
    }
  },
  mounted () {
    this.checkIfOnlyOneMenu();

    this.$store.commit("setPageWaypoint", {
      pageName: "menu",
      path: this.$route.fullPath,
    });
  },
  computed: {
    cafe () {
      return this.$store.state.cafe;
    },
    menus () {
      return this.$store.state.menus;
    },
    isInside () {
      return this.$store.state.mode === "inside";
    },
    mode () {
      return this.$store.state.mode;
    },
    cafeClosedFlag () {
      return this.$store.getters.isCafeClosed;
    },
    canLeavePersonalTips () {
      return this.$store.state.tips && this.$store.state.tips.enabled && this.$store.state.tips.mode === 'personal';
    }
  },
};
</script>

<style scoped>
.search-section {
  display: flex;
  padding: 16px;
  flex-direction: column;
  row-gap: 12px;
}

.menu-cards-section {
  display: flex;
  padding: 16px 16px;
  flex-direction: column;
  row-gap: 16px;

  /* height: calc(100vh - 200px); */
}

.menu-cards-list {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
</style>
