<template>
  <div class="app-delivery-system">
    <router-view v-if="loaded" />
    <SystemLoader v-else-if="!errorModalCode" />

    <CancelledOrderModal v-if="cancelModal.shown" :reason="cancelModal.reason" :list="cancelModal.list"
      @close="cancelModal.shown = false" />

    <ErrorModal :type="loaded ? 'order' : 'generic'" :code="errorModalCode" @close="errorModalCode = null"
      v-if="errorModalCode" />
  </div>
</template>

<script>
import DeliveryAPI from "@/api/delivery";
import ClientsAPI from "@/api/clients";

import SystemLoader from "@/components/common/SystemLoader.vue";

import Realtime from "@/api/realtime";

import { useCachedOrGuessBestLanguage, banLanguage } from "@/i18n";

import moment from "moment";

import CancelledOrderModal from "@/components/modals/CancelledOrderModal.vue";

import ErrorModal from "@/components/modals/ErrorModal.vue";

import Telemetry from "@/utils/telemetry";

export default {
  data () {
    return {
      loaded: false,

      cancelModal: {
        reason: null,
        list: [],
        shown: false,
      },

      errorModalCode: null
    };
  },
  watch: {
    "$i18n.locale" () {
      this.$store.dispatch("rebuildTranslationDictionary", {
        locale: this.$i18n.locale,
      });

      this.$store.commit("setModeNames", this.$t("mode_names"));
    },
    mode (newValue, oldValue) {
      if (!oldValue) return;

      if (newValue !== oldValue) {
        this.fetchData();
      }
    },
  },
  methods: {
    onOrderUpdated (preorder) {
      if (preorder.status === "cancelled") {
        this.cancelModal.shown = true;
        this.cancelModal.reason = preorder.reason;
        this.cancelModal.list = preorder.list;
      }

      this.$store.commit("setOrder", null);
    },
    async initRealtime () {
      await Realtime.init();

      Realtime.addRealtimeEventsListener(
        "preorder_update",
        this.onOrderUpdated
      );
    },
    async fetchData () {
      this.loaded = false;
      const loadingStart = Date.now();

      this.$store.commit("reset");
      try {
        const response = await DeliveryAPI.prepare(
          this.mixinCafeLinkCode,
          this.$route.params.mode,
          this.$store.state.cart.cartId
        );

        if (response.returnToGeneralLink) {
          this.$router.push({
            name: 'home',
            params: {
              cafe_link: this.mixinCafeLinkCode,
            }
          });
          return;
        }

        const { cafe, currency, menus, country, cart, timeslots } = response;

        this.$store.commit("setMode", this.$route.params.mode);
        this.$store.commit("setCafe", cafe);
        this.$store.commit("setCurrency", currency);
        this.$store.commit("setMenus", menus);
        this.$store.commit("setCountry", country);
        this.$store.dispatch("cart/useCart", cart);
        this.$store.dispatch("buildImagesCache");
        this.$store.commit("setDeliveryTimeslots", timeslots);
        this.$store.commit("setCloseRule", response.close_rule);
        this.$store.commit("setRootSystemUrl", this.$route.fullPath);

        const clientId = window.localStorage.getItem("client_id");

        if (clientId) {
          const { client } = await ClientsAPI.getProfile(clientId);

          if (client) {
            this.$store.commit("setDeliveryClient", client);
          }
        }

        const loadingTime = Date.now() - loadingStart;

        const minimalUiLoadingTime = 1300;

        const loadedTimeoutDelay = Math.max(
          minimalUiLoadingTime - loadingTime,
          0
        );

        // Ban pidorussian language from Ukraine
        if (country === "UA") {
          banLanguage("ru");
          banLanguage("ru-RU");
        }

        this.$i18n.locale = useCachedOrGuessBestLanguage();

        moment.locale(this.$i18n.locale);

        this.$store.dispatch("rebuildTranslationDictionary", {
          locale: this.$i18n.locale,
        });

        this.$store.commit("setModeNames", this.$t("mode_names"));

        await this.initRealtime();

        setTimeout(() => {
          this.loaded = true;
        }, loadedTimeoutDelay);

        Telemetry.init(cafe._id, this.$route.params.mode, "superapp");
      } catch (err) {
        this.errorModalCode = err.message;
      }
    },
  },
  mounted () {
    this.fetchData();
  },
  computed: {
    mode () {
      return this.$store.state.mode;
    },
  },
  components: {
    SystemLoader,
    CancelledOrderModal,
    ErrorModal
  },
};
</script>

<style scoped>
.app-delivery-system {
  display: flex;
  flex-direction: column;
  flex: 1;
}
</style>
