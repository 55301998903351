<template>
  <div class="smart-phone-input">
    <BasePhoneInput
      :size="size"
      class="w-100"
      :value="value"
      @filled="(phone) => $emit('input', phone)"
      :defaultCountry="selectedCountry || defaultCountry"
      @openPicker="countryModalShown = true"
    />

    <PhonePickerModal
      :country="selectedCountry || defaultCountry"
      :phone="value"
      @close="countryModalShown = false"
      @selected="v => selectedCountry = v"
      v-if="countryModalShown"
    />
  </div>
</template>

<script>
import PhonePickerModal from '@/components/modals/PhonePickerModal.vue'

export default {
  props: {
    size: String,
    value: String,
    defaultCountry: String
  },
  data () {
    return {
      countryModalShown: false,
      selectedCountry: null
    }
  },
  components: {
    PhonePickerModal
  }
}
</script>

<style></style>
