import io from "socket.io-client";

let subscribers = [];

function addRealtimeEventsListener(eventType, handler) {
  subscribers.push({ eventType, handler });
}

function removeRealtimeEventsListener(eventType, handler) {
  subscribers = subscribers.filter((s) => s.handler !== handler);
}

let Socket = null;

function init() {
  return new Promise((resolve) => {
    if (Socket !== null && Socket.disconnect) {
      console.log("Disconnecting previous realtime socket...");
      Socket.disconnect();
    }

    Socket = io("/order", {
      transports: ["websocket"],
    });

    Socket.on("update", (data, mode) => {
      subscribers.forEach((s) => {
        if (s.eventType === "inside_update" && mode === "order") {
          s.handler(data);
        }

        if (s.eventType === "preorder_update" && mode === "preorder") {
          s.handler(data);
        }
      });
    });

    Socket.on("connect", () => {
      resolve();
    });
  });
}

function listen(orderId) {
  console.log(`Listening to order ${orderId}`);
  Socket.emit("listen", orderId);
}

function listenPreorder(orderId) {
  console.log(`Listening to preorder ${orderId}`);
  Socket.emit("listen_preorder", orderId);
}

export default {
  addRealtimeEventsListener,
  removeRealtimeEventsListener,
  listen,
  init,
  listenPreorder,
};
