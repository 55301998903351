<template>
  <div class="app-cafe-page" v-if="cafe">
    <!-- <ScrollTransitionHeader
      :scrollLimit="253 - 64"
      :heightDifference="253 - 64"
      useSameComponent
      useStatic
      @switch="(state) => (headerTransitioned = state)"
    >
      <template #primary="slotProps">
        <div class="gap-row-zero hp-top-container" v-qrw-is-header>
          <HomePageHeader
            :collapsed="slotProps.transitionData.isSwitched"
            :showContacts="!shouldShowNavbar"
          />
          <BaseDivider color="border-base-tertiary" />
        </div>
      </template>
</ScrollTransitionHeader> -->
    <div class="gap-row-zero hp-top-container" v-qrw-class-on-scroll="{
    limit: 187,
    returnLimit: 1,
    cssClass: 'qrw-app-top-sticky qrw-app-top-container',
  }">
      <HomePageHeader :collapsed="scrollPos > 187" :showContacts="!shouldShowNavbar"
        @switchLanguage="languagePickerShown = true" />
      <BaseDivider color="border-base-tertiary" />
    </div>

    <div class="app-cafe-page-content" id="scrollTransitionHeader" v-qrw-above-footer v-qrw-class-on-scroll="{
    limit: 187,
    returnLimit: 1,
    cssClass: 'home-page-scrolled',
  }">
      <div class="home-page-content">
        <div class="cafe-about" v-if="cafe.description">
          <div class="qrw-label-lg qrw-text-content-base-primary bold">
            {{ $t("home.about") }}
          </div>
          <div class="qrw-paragraph-md qrw-text-content-base-primary">
            {{ cafe.description }}
          </div>
        </div>

        <div class="cafe-schedule">
          <ScheduleDropdown :schedule="cafe.schedule" :computedPair="cafe.today_schedule_computed"
            :schedulePairs="cafe.schedule_pairs" />
        </div>

        <CafeSocials :socials="cafe.social" />
        <CafeContacts :cafe="cafe" />

        <CafeAddress :address="cafe.address" :location="cafe.address_location" />

        <div class="cafe-legals">
          <div class="legal-row">
            <div class="legal-row-title">
              <BaseIcon icon="lock" size="sm" />
              <div class="qrw-text-content-base-primary qrw-label-md">
                {{ $t("legals.privacy_policy") }}
              </div>
            </div>

            <a href="https://qrwaiter.com.ua/privacy_policy" target="_blank">
              <BasePushButton variant="stroke" borderColor="border-base-secondary" size="sm">
                {{ $t("legals.view") }}
              </BasePushButton>
            </a>
          </div>
          <BaseDivider color="border-base-tertiary" />
          <div class="legal-row">
            <div class="legal-row-title">
              <BaseIcon icon="cookies" size="sm" />
              <div class="qrw-text-content-base-primary qrw-label-md">
                {{ $t("legals.cookies") }}
              </div>
            </div>
            <a href="https://qrwaiter.com.ua/cookies" target="_blank">
              <BasePushButton variant="stroke" borderColor="border-base-secondary" size="sm">
                {{ $t("legals.view") }}
              </BasePushButton>
            </a>
          </div>
          <BaseDivider color="border-base-tertiary" />
          <div class="legal-row">
            <div class="legal-row-title">
              <BaseIcon icon="file" size="sm" />
              <div class="qrw-text-content-base-primary qrw-label-md">
                {{ $t("legals.terms_of_use") }}
              </div>
            </div>

            <a href="https://qrwaiter.com.ua/terms" target="_blank">
              <BasePushButton variant="stroke" borderColor="border-base-secondary" size="sm">
                {{ $t("legals.view") }}
              </BasePushButton>
            </a>
          </div>
          <template v-for="(link, lindex) in publicOffertLinks">
            <BaseDivider color="border-base-tertiary" :key="'d' + lindex" />
            <div class="legal-row" :key="lindex">
              <div class="legal-row-title">
                <BaseIcon icon="file" size="sm" />
                <div class="qrw-text-content-base-primary qrw-label-md">
                  {{ $t("legals.public_offert") }}
                  {{ lindex > 0 ? `(${lindex + 1})` : "" }}
                </div>
              </div>

              <a :href="link" target="_blank">
                <BasePushButton variant="stroke" borderColor="border-base-secondary" size="sm">
                  {{ $t("legals.view") }}
                </BasePushButton>
              </a>
            </div>
          </template>
          <!-- <div class="legal-row">
        <div class="legal-row-title">
          <BaseIcon icon="file-text-bold" size="sm" />
          <div class="qrw-text-content-base-primary bold">
            {{ $t("legals.public_offert") }}
          </div>
        </div>
        <BasePushButton
          variant="stroke"
          borderColor="border-base-secondary"
          @click="$router.push('/public_offert')"
          size="sm"
        >
          {{ $t("legals.view") }}
        </BasePushButton>
      </div> -->
        </div>
      </div>
    </div>
    <div class="home-footer-container qrw-app-bottom-sticky qrw-app-bottom-container" v-qrw-is-footer>
      <transition name="contacts-appear">
        <div class="bottom-contacts" v-if="!shouldShowNavbar || cafe._id == 567">
          <CafeCallRow class="call-row" v-qrw-class-on-scroll="{
    limit: 187,
    returnLimit: 1,
    cssClass: 'call-row-scrolled',
  }" />
        </div>
      </transition>
      <MainNavbar activeTab="home" :sticky="false" @tabClick="onNavbarTabClicked" v-if="shouldShowNavbar" />
    </div>
    <LanguagePickerModal @close="languagePickerShown = false" v-if="languagePickerShown" />
  </div>
  <div v-else>
    <PageLoader v-if="!errorModalCode" />
    <ErrorModal :type="'generic'" :code="errorModalCode" @close="errorModalCode = null" v-if="errorModalCode" />
  </div>
</template>

<script>
import ScheduleDropdown from "@/components/home/ScheduleDropdown.vue";
import PageLoader from "@/components/common/PageLoader.vue";

import MainNavbar from "@/components/navbar/MainNavbar.vue";

import HomepageAPI from "@/api/homepage";
// import DeliveryAPI from '@/api/delivery'

import CafeSocials from "@/components/home/CafeSocials.vue";
import CafeContacts from "@/components/home/CafeContacts.vue";
import CafeAddress from "@/components/home/CafeAddress.vue";
// import ScrollTransitionHeader from '@/components/header/ScrollTransitionHeader.vue'
// import LobbyStaticHeader from '@/components/header/LobbyStaticHeader.vue'
import HomePageHeader from "@/components/header/HomePageHeader.vue";

import CafeCallRow from "@/components/home/CafeCallRow.vue";

import LanguagePickerModal from "@/components/modals/LanguagePickerModal.vue";

import { useCachedOrGuessBestLanguage } from "@/i18n";

import moment from "moment";

import {
  resetPageScroll,
  updateAdaptiveHeaderHeight,
} from "@/utils/adaptiveUi";

import ErrorModal from "@/components/modals/ErrorModal.vue";

export default {
  data () {
    return {
      cafe: null,

      selectedLanguage: "en",

      showLanguageModal: false,

      headerTransitioned: false,

      languagePickerShown: false,

      scrollPos: 0,

      errorModalCode: null,
    };
  },
  methods: {
    bookATable () {
      this.$router.push("/menu");
    },
    navigateToReserve () {
      this.$router.push({
        name: "reserve_menu",
        params: {
          cafe_link: this.mixinCafeLinkCode,
        },
      });
    },
    async prepareMode () {
      // const apiMethod = DeliveryAPI.prepare

      const sortedModes = ["delivery", "takeaway", "preview"];

      const firstMode = sortedModes.find((mode) => {
        if (mode === "preview") {
          return this.cafe.modes[mode] || this.cafe.modes.inside;
        }

        if (mode === "takeaway") {
          return this.cafe.modes.preorder;
        }

        return this.cafe.modes[mode];
      });

      try {
        // const { cafe, menus } = await apiMethod(this.mixinCafeLinkCode, firstMode)

        // this.$store.commit('setCafe', cafe)
        // this.$store.commit('setMenus', menus)
        this.$store.commit("setMode", firstMode);

        this.$router.push(
          this.$store.getters.buildRouterNavigateObject("lobby")
        );
      } catch (err) {
        // TODO: add error handler
        console.log(err);
        this.errorModalCode = err;
      }
    },
    async fetchData () {
      if (this.$store.state.cafe) {
        this.cafe = this.$store.state.cafe;
        resetPageScroll();
        updateAdaptiveHeaderHeight(0);
      } else {
        this.$i18n.locale = useCachedOrGuessBestLanguage();

        moment.locale(this.$i18n.locale);
      }

      try {
        const { cafe } = await HomepageAPI.getHomepage(
          this.cafe ? this.cafe.online_menu_link : this.mixinCafeLinkCode
        );

        if (!this.cafe) {
          this.$store.commit("setCafe", cafe);
        }

        this.cafe = cafe;

        if (this.hasAnyOnlineSystemsEnabled && !this.$store.state.mode) {
          this.prepareMode();
        }

        resetPageScroll();
        updateAdaptiveHeaderHeight(0);
      } catch (err) {
        // TODO: add error handler
        console.log(err);
        this.errorModalCode = err;
      }
    },
    callCafe () {
      window.location.href = `tel:${this.cafe.contact_phone}`;
    },
    onNavbarTabClicked (tab) {
      if (tab === "menu") {
        this.$router.push(
          this.$store.getters.buildRouterNavigateObject("lobby")
        );
      }

      if (tab === "order") {
        this.$router.push(
          this.$store.getters.buildRouterNavigateObject("order")
        );
      }
    },
    onWindowScroll () {
      this.scrollPos = window.document.documentElement.scrollTop;
    },
  },
  mounted () {
    this.fetchData();

    window.addEventListener("scroll", this.onWindowScroll);
  },
  beforeDestroy () {
    window.removeEventListener("scroll", this.onWindowScroll);
  },
  components: {
    ScheduleDropdown,
    PageLoader,
    MainNavbar,
    CafeSocials,
    CafeAddress,
    CafeContacts,
    // ScrollTransitionHeader,
    // LobbyStaticHeader,
    HomePageHeader,
    CafeCallRow,
    LanguagePickerModal,
    ErrorModal,
  },
  computed: {
    shouldShowNavbar () {
      return (
        this.$store.state.mode === "inside" || this.hasAnyOnlineSystemsEnabled
      );
    },
    hasAnyOnlineSystemsEnabled () {
      return (
        this.cafe.modes &&
        (this.cafe.modes.delivery ||
          this.cafe.modes.preorder ||
          this.cafe.modes.preview ||
          this.cafe.modes.inside)
      );
    },
    publicOffertLinks () {
      if (!this.cafe) return null;

      const menus = this.$store.state.menus;

      if (!menus || !menus.length) return false;

      const links = [];

      const ids = new Set();

      for (const menu of menus) {
        if (!menu.pay_receiver_id) {
          continue;
        }

        if (ids.has(menu.pay_receiver_id)) {
          continue;
        }

        ids.add(menu.pay_receiver_id);

        const link = `/offert/${this.cafe._id}/${menu._id}`;
        if (!links.includes(link)) links.push(link);
      }

      return links;
    },
    // publicOffertLink() {
    //   if (!this.cafe) return null;

    //   const menus = this.$store.state.menus;

    //   if (!menus || !menus.length) return false;

    //   const first = menus.find((m) => !!m.pay_receiver_id);

    //   if (!first) return null;

    //   return `https://app.qrwaiter.com.ua/offert_view/${this.cafe._id}/${first._id}`;
    // },
  },
};
</script>

<style scoped>
.app-cafe-page,
.app-cafe-page-content {
  display: flex;
  flex-direction: column;
  /* padding-bottom: calc(24px + 80px); */
}

.cafe-about {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 16px;
}

.cafe-schedule {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
}

.get-directions-btn {
  min-width: fit-content;
  white-space: nowrap;
}

.legal-row {
  display: flex;
  flex-direction: row;
  column-gap: 24px;
  align-items: center;
}

.legal-row-title {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.reviews-star-bar-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.review-summary {
  width: 100%;
}

.rating-bar-stars {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.rating-bar {
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background: var(--qrw-content-base-secondary);
}

.review-card {
  padding: 10px;
  width: 380px;
  display: flex;
  padding: 16px;
  flex-direction: column;
  row-gap: 12px;
  background: var(--qrw-background-base-tertiary);
  border-radius: 10px;
}

.review-cards-list {
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  position: relative;
  left: -4px;
  overflow-x: scroll;
}

.stars-array {
  color: var(--foundation-yellow-400-color);
}

.cafe-legals {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px;

  background: var(--qrw-background-base-tertiary-10);
}

@keyframes contacts-appear {
  0% {
    opacity: 0;
    height: 50%;
  }

  100% {
    opacity: 1;
    height: 100%;
  }
}

.contacts-appear-enter-active {
  animation: contacts-appear 0.3s ease-in-out;
}

.contacts-appear-leave-active {
  animation: contacts-appear 0.3s ease-in-out reverse;
}

.bottom-contacts {
  background: var(--qrw-background-base-tertiary-10);
  padding: 12px 16px;
  width: 100%;
  box-sizing: border-box;

  transform-origin: 0 100%;
}

.home-page-scrolled {
  padding-top: 188px;
}

.call-row {
  opacity: 0;
  display: none;
  transition: 0.3s all;
}

.call-row-scrolled {
  opacity: 1;
  display: flex;
  transition: 0.3s all;
}
</style>
