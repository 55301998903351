<template>
  <div
    class="app-cart-item"
    :class="{
      unavailable: isItemUnavailable,
      cancelled
    }"
  >
    <div
      class="unavailable-banner qrw-label-sm"
      v-if="isItemUnavailable"
    >
      {{ $t("cart.unavailable_item") }} {{modeTitle}}
    </div>

    <div
      class="cart-item-card"
      :class="{
      unavailable: isItemUnavailable,
    }"
    >

      <div class="cart-card-header">
        <div class="gap-row-1xs w-100">
          <div class="gap-row-3xs">
            <div class="qrw-label-xs qrw-text-content-base-tertiary">
              {{ categoryName }}
            </div>
            <div class="qrw-label-lg qrw-text-content-base-primary">
              {{ productName }}
            </div>
          </div>

          <PriceTag
            :value="item.base_price"
            class="qrw-label-sm qrw-text-content-base-secondary"
          />
        </div>
        <img
          class="cart-item-image"
          :src="cartItemImage"
          v-if="cartItemImage"
        />
      </div>

      <template v-if="hasAnyModifications">
        <BaseDivider
          color="border-base-tertiary"
          dashed
        />
        <div class="cart-item-modifications">
          <template v-for="(group, index) in modificationsGroups">
            <div
              class="modification-group"
              :key="index"
            >
              <div class="qrw-label-xs qrw-text-content-base-tertiary">
                {{ group.title }}
              </div>
              <div class="modification-group-items">
                <template v-for="(modification, item_index) in group.items">
                  <div
                    class="modification-item"
                    :key="item_index"
                  >
                    <div class="qrw-label-sm qrw-text-content-base-primary">
                      {{ getPriceGroupItemName(modification) || modification.name }}
                    </div>
                    <div class="qrw-label-sm qrw-text-content-base-tertiary qrw-price-tag">
                      + {{ modification.price }}{{ currency.symbol }}
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <BaseDivider
              dashed
              color="border-base-tertiary"
              :key="`div_${index}`"
              v-if="index != modificationsGroups.length - 1"
            />
          </template>
        </div>
      </template>
    </div>

    <div
      class="cart-item-footer"
      v-if="!isItemUnavailable"
    >
      <div class="footer-totals">
        <!-- <div class="qrw-label-xs qrw-text-content-base-tertiary">
          {{ $t("total.total") }}
        </div> -->
        <PriceTag
          :value="item.total"
          class="qrw-label-md bold qrw-text-content-base-primary"
        />
      </div>

      <div
        class="footer-counter"
        :class="{readonly}"
      >
        <BaseIconButton
          variant="ghost"
          textColor="content-error-primary"
          icon="x"
          size="md"
          @click="changeQuantity(-1)"
          v-if="item.quantity <= 1 && !readonly"
        />
        <BaseIconButton
          variant="ghost"
          textColor="content-base-secondary"
          icon="minus"
          size="md"
          v-else-if="!readonly"
          @click="changeQuantity(-1)"
        />

        <div class="qrw-label-md bold qrw-text-content-base-primary quantity">
          {{ readonly ? 'x' : ''}} {{ item.quantity }}
        </div>

        <BaseIconButton
          variant="ghost"
          :disabled="item.quantity >= 30"
          textColor="content-base-secondary"
          icon="plus"
          size="md"
          v-if="!readonly"
          @click="changeQuantity(1)"
        />
      </div>
    </div>
    <div
      class="unavailable-footer"
      v-else
    >
      <PriceTag
        :value="item.total"
        class="qrw-label-md bold qrw-text-content-base-primary"
      />

      <BasePushButton
        variant="ghost"
        @click="removeItem"
        size="md"
        textColor="content-error-primary"
      >
        {{ $t("common_actions.remove") }}
      </BasePushButton>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    readonly: Boolean,
    cancelled: Boolean
  },
  methods: {
    changeQuantity(delta) {
      this.$store.dispatch('cart/changeQuantity', {
        item_id: this.item._id,
        new_quantity: this.item.quantity + delta
      })
    },
    removeItem() {
      this.$store.dispatch('cart/changeQuantity', {
        item_id: this.item._id,
        new_quantity: 0
      })
    },
    getPriceGroupItemName(item) {
      return this.$store.getters.localize('price_group_item_name', `${this.item.product_id}:${item.item_id}`)
    }
  },
  computed: {
    cartItemImage() {
      return this.$store.getters.getProductCachedImage(this.item.product_id)
    },
    currency() {
      return this.$store.state.currency
    },
    hasAnyModifications() {
      return this.item.variants.length || this.item.modificators.length
    },
    isItemUnavailable() {
      // TODO: add real logic
      return (
        this.item.name === '__qrw_unavailable_item' ||
        !this.$store.state.menus.some(menu => menu._id === this.item.menu_id)
      )
    },
    modeTitle() {
      return this.$store.getters.modeTitle
    },
    categoryName() {
      return this.$store.getters.localize('product_category', this.item.product_id)
    },
    productName() {
      return this.$store.getters.localize('product_name', this.item.product_id)
    },
    modificationsGroups() {
      const groups = []

      if (this.item.variants.length) {
        groups.push({
          title: this.$t('menu_product.variants'),
          items: this.item.variants
        })
      }

      if (this.item.modificators.length) {
        groups.push({
          title: this.$t('menu_product.modificators'),
          items: this.item.modificators
        })
      }

      return groups
    }
  }
}
</script>

<style scoped>
.app-cart-item {
  border-radius: 12px;

  width: 100%;
  box-sizing: border-box;
  background: var(--qrw-background-base-tertiary-10);

  display: flex;
  flex-direction: column;

  position: relative;
}

.app-cart-item.unavailable {
  background: var(--qrw-background-error-secondary);
}

.cart-item-header {
  padding: 16px;
  display: flex;
  column-gap: 12px;
}

.cart-item-footer {
  padding: 4px 4px 4px 16px;
  display: flex;
  column-gap: 16px;
}

.cart-item-image {
  display: block;
  object-fit: cover;
  width: 84px;
  height: 64px;
  max-width: 84px;
  min-height: 64px;
  max-height: 64px;
  min-width: 84px;
  border-radius: 6px;
  box-shadow: var(--qrw-shadow-card-light);
}

.header-texts {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
  overflow: hidden;
}

.shorten {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}

.footer-totals {
  display: flex;
  row-gap: 6px;
  width: 100%;
  align-items: center;
}

.footer-counter {
  display: flex;
  column-gap: 4px;
  align-items: center;
  width: fit-content;
}

.footer-counter.readonly {
  padding: 10px 16px;
}

.quantity {
  width: 40px;
  text-align: center;
}

.cart-item-modifications {
  display: flex;
  row-gap: 16px;
  flex-direction: column;
}

.modification-item {
  display: flex;
  column-gap: 16px;
  justify-content: space-between;
}

.modification-group-items {
  display: flex;
  row-gap: 12px;
  flex-direction: column;
}

.modification-group {
  display: flex;
  row-gap: 12px;
  flex-direction: column;
}

.unavailable-banner {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  color: var(--qrw-content-error-primary);

  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 8px 16px;
}

.banner-content {
  padding: 16px;
}

.remove-button {
  width: fit-content;
}

.cart-item-card {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  border-radius: 12px;
  background: var(--qrw-background-base-primary-10);
  padding: 16px;
  position: relative;
  box-shadow: var(--qrw-shadow-card-light);
}

.cart-item-card.unavailable {
  border: 1px solid var(--qrw-border-error-primary);
}

.cart-card-header {
  display: flex;
  column-gap: 12px;
}

.unavailable-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 4px 4px 16px;
  box-sizing: border-box;
}

.cancelled {
  background: var(--qrw-background-error-secondary);
}
</style>
