<template>
  <div class="app-menu-page">
    <ReserveMenuPageHeader
      @back="goBack"
      @switchView="switchView"
      v-if="currentView == 'grid'"
    />
    <CategoryPageHeader
      @switchView="switchView"
      @back="currentView = 'grid'"
      v-else
    />

    <div
      class="menu-page-content grid"
      v-qrw-under-header
      id="globalCategoriesScrollGrid"
      v-if="currentView === 'grid'"
    >
      <CategoryGridCard
        :category="category"
        v-for="category in categoriesWithProducts"
        :key="category._id"
      />
    </div>

    <div
      class="menu-page-content list"
      v-qrw-under-header
      id="globalCategoriesScrollList"
      v-if="currentView === 'list'"
    >
      <template v-for="(category, index) in categoriesWithProducts">
        <ReserveCategoryListCard :category="category" :key="category._id" />

        <BaseDivider
          color="border-base-tertiary"
          :key="`divider-${category._id}`"
          v-if="index != categoriesWithProducts.length - 1"
        />
      </template>
    </div>

    <MainNavbar v-if="false" />
  </div>
</template>

<script>
import ReserveMenuPageHeader from '@/components/header/ReserveMenuPageHeader.vue'

import MainNavbar from '@/components/navbar/MainNavbar.vue'

import CategoryPageHeader from '@/components/header/CategoryPageHeader.vue'

import CategoryGridCard from '@/components/menu/CategoryGridCard.vue'
import ReserveCategoryListCard from '@/components/menu/ReserveCategoryListCard.vue'

export default {
  data () {
    return {
      currentView: 'grid'
    }
  },
  methods: {
    switchView (newView) {
      this.currentView = newView

      this.$router.push({
        name: 'reserve_menu',
        params: {
          cafe_link: this.mixinCafeLinkCode
        },
        query: {
          view: newView
        }
      })
    },
    goBack () {
      this.$router.go(-1)
    }
  },
  components: {
    ReserveMenuPageHeader,
    MainNavbar,
    CategoryPageHeader,
    CategoryGridCard,
    ReserveCategoryListCard
  },
  mounted () {
    this.switchView(this.$route.query.view || 'grid')
  },
  computed: {
    menu () {
      return this.$store.getters.firstMenu
    },
    categoriesWithProducts () {
      return this.$store.getters.categoriesWithProducts(this.menu)
    }
  }
}
</script>

<style scoped>
.app-menu-page {
  background: var(--qrw-background-base-secondary);
}
</style>
