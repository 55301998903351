<template>
  <div
    class="app-carousel"
    v-if="slidesCount === 1"
  >
    <slot>

    </slot>
  </div>

  <div
    class="app-carousel splide"
    ref="container"
    aria-labelledby="carousel-container"
    v-else
  >
    <div class="splide__track">
      <ul
        class="splide__list"
        :class="{
        paginated: requiresPagination
      }"
      >
        <slot />
      </ul>
    </div>

    <div
      class="pagination"
      v-if="requiresPagination"
    >
      <div
        class="dot"
        v-for="n in totalSlides"
        :key="n"
        :class="{active: currentSlide === n-1}"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      carouselVm: null,

      totalSlides: 3,
      currentSlide: 0
    }
  },
  props: {
    slidesCount: Number
  },
  watch: {
    '$slots.default'() {
      this.carouselVm.refresh()
      this.updatePaging()
    },
    slidesCount() {
      /*
        Please do not ask me what the fuck is this

        We need to make sure the bottom navigation paging bar is shown correctly
        The Slides.getLength() approach seems to be working bad

        so we do all possible magic here to get it working
      */
      if (this.slidesCount === 1 && !this.carouselVm) {
        this.initCarousel()
      }

      if (!this.carouselVm) return
      this.carouselVm.go(0, true)
      this.carouselVm.refresh()
      this.totalSlides = this.slidesCount
      if (this.currentSlide > this.totalSlides - 1) {
        this.currentSlide = this.totalSlides - 1
      }
      this.updatePaging()
    }
  },
  methods: {
    updatePaging() {
      if (!this.carouselVm) return

      const { Slides, Controller } = this.carouselVm.Components

      this.currentSlide = Controller.getIndex()
      this.totalSlides = this.slidesCount || Slides.getLength()
    },
    initCarousel() {
      const Splide = window.Splide

      this.carouselVm = new Splide(this.$refs.container, {
        autoWidth: true,
        autoHeight: true,
        rewindByDrag: true,
        arrows: false,
        pagination: false,
        drag: true,
        width: '100%',
        fixedWidth: '100vw'
      })

      this.carouselVm.on('moved', () => this.updatePaging())
      this.carouselVm.on('ready', () => this.updatePaging())
      this.carouselVm.on('dragged', () => this.updatePaging())
      this.carouselVm.on('refresh', () => this.updatePaging())

      this.$nextTick(() => {
        this.carouselVm.mount()
      })
    }
  },
  computed: {
    requiresPagination() {
      return this.totalSlides > 1
    }
  },
  mounted() {
    if (this.slidesCount > 1) {
      this.initCarousel()
    }
  },
  beforeDestroy() {
    if (this.carouselVm) {
      this.carouselVm.destroy()
    }
  }
}
</script>

<style>
.splide__list.paginated .slide-spacer {
  display: block !important;
}
</style>

<style scoped>
.app-carousel {
  display: flex;
  flex-direction: column;
}

.pagination {
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  align-items: center;
  justify-content: center;

  width: 100%;

  padding: 8px 16px;

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 5;

  box-sizing: border-box;
}

.dot {
  display: block;
  border-radius: 3px;
  width: 8px;
  height: 8px;
  background: var(--qrw-content-base-tertiary);
}

.dot.active {
  background: var(--qrw-content-base-primary);
}
</style>
