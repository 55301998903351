<template>
  <div
    class="verify-geo-block"
    :class="{
      'with-background': !noBackground,
    }"
  >
    <div class="gap-row-1xs w-100">
      <div class="qrw-label-md bold qrw-text-content-base-primary">
        {{ $t("verify_location.title") }}
        <span class="qrw-text-content-error-primary" v-if="required">*</span>
      </div>
      <div class="qrw-paragraph-md qrw-text-content-base-secondary">
        {{ $t("verify_location.subtitle") }}
      </div>
    </div>
    <BasePushButton
      class="cta-button"
      icon="marker-pin"
      borderColor="border-base-secondary"
      variant="outlined"
      size="md"
      textColor="content-base-primary"
      @click="$emit('verify')"
      v-if="!verified"
    >
      {{ $t("verify_location.button") }}
    </BasePushButton>
    <BaseLabelBadge
      primaryColor="background-success-default"
      secondaryColor="content-base-inverse-primary"
      size="lg"
      variant="filled"
      v-else
    >
      {{ $t("verify_location.verified") }}
    </BaseLabelBadge>
  </div>
</template>

<script>
export default {
  props: {
    noBackground: Boolean,
    verified: Boolean,
    required: Boolean
  }
}
</script>

<style scoped>
.verify-geo-block {
  display: flex;
  padding: 16px;
  flex-direction: row;
  column-gap: 24px;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
}

.verify-geo-block.with-background {
  background: var(--qrw-background-base-tertiary-10);
}

.cta-button {
  width: 132px;
  min-width: 132px;
}
</style>
