import Vue from "vue";
import VueRouter from "vue-router";
import HomePage from "../views/HomePage.vue";

import ReserveSystem from "../views/ReserveSystem.vue";
import ReserveServicePage from "../views/reserve/ReserveServicePage.vue";
import ReserveCheckoutPage from "../views/reserve/ReserveCheckoutPage.vue";
import ReserveMenuPage from "../views/reserve/ReserveMenuPage.vue";
import ReserveReceiptPage from "../views/reserve/ReserveReceiptPage.vue";

import MenuLobby from "../views/MenuLobby.vue";
import MenuPage from "../views/MenuPage.vue";
import MenuProduct from "../views/MenuProduct.vue";
import CartPage from "../views/CartPage.vue";
import OrderPage from "../views/OrderPage.vue";
import ReceiptPage from "../views/ReceiptPage.vue";
import OrdersPage from "../views/OrdersPage.vue";

import InsideSystem from "../views/InsideSystem.vue";
import PreviewSystem from "../views/PreviewSystem.vue";
import PreorderSystem from "../views/PreorderSystem.vue";
import MenuSearch from "../views/MenuSearch.vue";

import ManageDeliveryAddress from "../views/ManageDeliveryAddress.vue";

import CookiesPage from "../views/legals/CookiesPage.vue";

import OffertView from "../views/OffertView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/cookies",
    name: "cookies",
    component: CookiesPage,
  },
  {
    path: "/offert/:cafe_id/:menu_id",
    name: "offert",
    component: OffertView,
  },
  {
    path: "/:cafe_link/preview",
    name: "preview",
    component: PreviewSystem,
    children: [
      {
        path: "",
        name: "preview_lobby",
        component: MenuLobby,
      },
      {
        path: "home",
        name: "preview_home",
        component: HomePage,
      },
      {
        path: "menu/:menu_id",
        name: "preview_menu",
        component: MenuPage,
      },
      {
        path: "menu/:menu_id/:product_id",
        name: "preview_product",
        component: MenuProduct,
      },
      {
        path: "cart",
        name: "preview_cart",
        component: CartPage,
      },
      {
        path: "orders",
        name: "preview_orders",
        component: OrdersPage,
      },
      {
        path: "search",
        name: "preview_search",
        component: MenuSearch,
      },
    ],
  },
  {
    path: "/inside/:inside_code",
    name: "inside",
    component: InsideSystem,
    children: [
      {
        path: "",
        name: "inside_lobby",
        component: MenuLobby,
      },
      {
        path: "home",
        name: "inside_home",
        component: HomePage,
      },
      {
        path: "menu/:menu_id",
        name: "inside_menu",
        component: MenuPage,
      },
      {
        path: "menu/:menu_id/:product_id",
        name: "inside_product",
        component: MenuProduct,
      },
      {
        path: "cart",
        name: "inside_cart",
        component: CartPage,
      },
      {
        path: "order",
        name: "inside_order",
        component: OrderPage,
      },
      {
        path: "receipt/:order_id",
        name: "inside_receipt",
        component: ReceiptPage,
      },
      {
        path: "orders",
        name: "inside_orders",
        component: OrdersPage,
      },
      {
        path: "search",
        name: "inside_search",
        component: MenuSearch,
      },
    ],
  },
  {
    path: "/:cafe_link",
    name: "home",
    component: HomePage,
  },
  {
    path: "/:cafe_link/menu",
    name: "menu_lobby",
    component: MenuLobby,
  },
  {
    path: "/:cafe_link/menu/:menu_id",
    name: "menu_page",
    component: MenuPage,
  },
  {
    path: "/:cafe_link/menu/:menu_id/:product_id",
    name: "menu_product",
    component: MenuProduct,
  },
  {
    path: "/:cafe_link/cart",
    name: "cart",
    component: CartPage,
  },
  {
    path: "/:cafe_link/order",
    name: "order",
    component: OrderPage,
  },
  {
    path: "/:cafe_link/receipt/:order_id",
    name: "receipt",
    component: ReceiptPage,
  },
  {
    path: "/new/:code",
    name: "old_redirect",
    component: {
      created() {
        this.$router.push(`/inside/${this.$route.params.code}`);
      },
    },
  },
  {
    path: "/:cafe_link/reserve",
    name: "reserve",
    component: ReserveSystem,
    children: [
      {
        path: "menu",
        name: "reserve_menu",
        component: ReserveMenuPage,
      },
      {
        path: "menu/:product_id",
        name: "reserve_menu_product",
        component: ReserveServicePage,
      },
      {
        path: "checkout",
        name: "reserve_checkout",
        component: ReserveCheckoutPage,
      },
      {
        path: "receipt/:reservation_id",
        name: "reserve_receipt",
        component: ReserveReceiptPage,
      },
    ],
  },
  {
    path: "/:cafe_link/:mode",
    name: "preorder",
    component: PreorderSystem,
    children: [
      {
        path: "",
        name: "preorder_lobby",
        component: MenuLobby,
      },
      {
        path: "home",
        name: "preorder_home",
        component: HomePage,
      },
      {
        path: "menu/:menu_id",
        name: "preorder_menu",
        component: MenuPage,
      },
      {
        path: "menu/:menu_id/:product_id",
        name: "preorder_product",
        component: MenuProduct,
      },
      {
        path: "cart",
        name: "preorder_cart",
        component: CartPage,
      },
      {
        path: "receipt/:order_id",
        name: "preorder_receipt",
        component: ReceiptPage,
      },
      {
        path: "orders",
        name: "preorder_orders",
        component: OrdersPage,
      },
      {
        path: "address",
        name: "preorder_address",
        component: ManageDeliveryAddress,
      },
      {
        path: "search",
        name: "preorder_search",
        component: MenuSearch,
      },
    ],
  },
];

const storedPositions = {};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    const saveableRoutes = [
      "inside_menu",
      "inside_cart",
      "reserve_menu",
      "inside_home",
      "inside_lobby",
      "inside_orders",
      "inside_order",
      "preorder_cart",
      "preorder_menu",
      "preorder_lobby",
    ];

    if (to.name && saveableRoutes.includes(to.name)) {
      return { x: 0, y: storedPositions[to.path] || 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const scrollPos =
    document.documentElement.scrollTop || document.body.scrollTop || 0;

  storedPositions[from.path] = scrollPos;

  next();
});

export default router;
