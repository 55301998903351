<template>
  <BaseSimpleModal
    :fullHeight="false"
    :showHeader="false"
    :showFooter="false"
    @backdropClick="$emit('close')"
  >
    <div class="closed-body">
      <div class="closed-header">
        <div class="qrw-heading-xs bold qrw-text-content-base-primary">
          {{$t('closed_banner.title_'+type)}}
        </div>
        <div class="header-title qrw-paragraph-md qrw-text-content-base-secondary">
          {{$t('closed_banner.desc_'+type, {
            date: untilDate
          })}}
        </div>
      </div>
      <BaseDivider
        color="border-error-tertiary"
        v-if="schedule"
      />
      <div
        class="schedule-container"
        v-if="schedule"
      >
        <ScheduleContents :schedule="schedule" />
      </div>
    </div>

    <div class="closed-modal-footer gap-row-zero w-100 qrw-app-bottom-container">
      <BaseDivider color="border-error-tertiary" />
      <div class="button-container">
        <BasePushButton
          variant="ghost"
          borderColor="border-base-tertiary"
          textColor="content-base-secondary"
          size="lg"
          @click="$emit('close')"
          class="w-100"
        >
          {{ $t("common_actions.close") }}
        </BasePushButton>
      </div>
    </div>
  </BaseSimpleModal>
</template>

<script>
import ScheduleContents from '@/components/home/ScheduleContents.vue'

import moment from 'moment'

export default {
  data() {
    return {}
  },
  props: {
    type: String,
    schedule: Object
  },
  components: {
    ScheduleContents
  },
  computed: {
    untilDate() {
      if (this.type === 'delivery' || this.type === 'takeaway') {
        return moment(this.$store.state.closeRule.end_date).format('DD.MM HH:mm')
      }

      return null
    }
  }
}
</script>

<style scoped>
.closed-body {
  background: var(--qrw-background-error-primary);
  display: flex;
  flex-direction: column;
}

.closed-header {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
}

.closed-texts {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 16px;
  text-align: center;
}

.header-title {
  width: 100%;
  text-align: center;
}

.button-container {
  display: flex;
  box-sizing: border-box;
  padding: 12px 16px;
  row-gap: 8px;
}

.closed-modal-footer {
  background: var(--qrw-background-error-primary);
}

.schedule-container {
  padding: 16px;
  box-sizing: border-box;
}
</style>
