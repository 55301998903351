<template>
  <div class="category-grid-card" ref="container">
    <div class="card-header">
      <div class="qrw-heading-xs bold qrw-text-content-base-primary" v-if="!isLoadingState">
        {{ category.name }}
      </div>
      <SkeletonLoader width="120px" height="20px" v-else/>
    </div>

    <div class="category-scroll">
      <div class="category-contents">
        <ServiceGridCard
          :product="product"
          v-for="(product,index) in visibleProducts"
          :key="index"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ServiceGridCard from '@/components/menu/ServiceGridCard.vue'

export default {
  props: {
    category: Object
  },
  components: {
    ServiceGridCard
  },
  computed: {
    isLoadingState () {
      return !this.category
    },
    visibleProducts () {
      return this.isLoadingState ? [null, null, null, null, null] : this.category.products
    }
  },
  mounted () {
    if (this.category) {
      this.$nextTick(() => {
        this.$store.commit('setCategoryUiOffset', {
          categoryId: this.category._id,
          offset: this.$refs.container.offsetTop,
          view: 'grid'
        })
      })
    }
  }
}
</script>

<style scoped>
.category-grid-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.category-contents {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  width: fit-content;
}

.category-scroll {
  overflow-x: scroll;
  width: 100%;
  display: flex;
  padding: 16px 24px 24px 24px;
  box-sizing: border-box;
}

.card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 24px 0 24px;
}

.list-button {
  display: flex;
  align-self: flex-end;
  column-gap: 6px;
}
</style>
