<template>
  <div class="app-reserve-calendar">
    <div class="calendar-header">
      <div class="qrw-heading-xs bold qrw-text-content-base-primary">
        {{ $t("calendar.date") }}
      </div>
      <div class="qrw-label-md bold qrw-text-content-base-tertiary">
        {{ currentMonthName }}
      </div>
    </div>
    <div class="calendar-weekdays">
      <div class="weekday" v-for="day in weekdaysNames" :key="day">
        {{ day }}
      </div>
    </div>
    <div class="calendar-cells">
      <div
        class="cell qrw-label-lg"
        @click="selectCell(cell)"
        :class="{
          disabled: cell.disabled,
          selected: cell.selected,
          today: cell.today,
          bold: cell.selected,
        }"
        v-for="cell in cells"
        :key="cell.unix"
      >
        {{ cell.text }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    locale: {
      type: String,
      default: 'en'
    },
    value: {
      type: [String, Number, Date],
      default: () => new Date()
    },
    minDate: {
      type: [String, Number, Date],
      default: null
    },
    maxDate: {
      type: [String, Number, Date],
      default: null
    }
  },
  methods: {
    selectCell (cell) {
      if (cell.disabled) return

      this.$emit('input', cell.date)
    }
  },
  computed: {
    weekdaysNames () {
      return {
        en: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
      }[this.locale]
    },
    currentMonthName () {
      const cellMonths = this.cells.filter(
        cell => !cell.disabled
      ).map(cell => moment(cell.date).locale(this.locale).format('MMMM YYYY'))

      return Array.from(new Set(cellMonths)).join(' - ')
    },
    cells () {
      const cells = []

      const today = moment()
      const valueMoment = moment(this.value)

      window.moment = moment

      for (
        let pointer = moment().startOf('isoWeek');
        pointer.isBefore(moment().add(1, 'month').endOf('isoWeek'));
        pointer.add(1, 'day')
      ) {
        const cell = {
          date: pointer.toDate(),
          text: pointer.date().toString(),
          disabled:
            (this.minDate && pointer.isBefore(this.minDate)) ||
            (this.maxDate && pointer.isAfter(this.maxDate)),
          unix: pointer.unix(),
          today: pointer.isSame(today, 'day'),
          selected: pointer.isSame(valueMoment, 'day')
        }

        cells.push(cell)
      }

      return cells
    }
  }
}
</script>

<style scoped>
.app-reserve-calendar {
  padding: 24px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  row-gap: 16px;
}

.calendar-header {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  justify-content: space-between;
  align-items: center;
}

.calendar-weekdays {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
}

.weekday {
  width: 100%;
  color: var(--qrw-content-base-tertiary);
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar-cells {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.cell {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 11px 7px;
  border: 1px solid transparent;
  border-radius: 12px;
  width: calc((100% - 48px) / 7);
  cursor: pointer;
}

.cell.disabled {
  color: var(--qrw-content-base-secondary);
  opacity: 0.4;
}

.cell.today {
  color: var(--qrw-content-error-primary);
}

.cell.selected {
  box-shadow: var(--qrw-shadow-foundation-1dp);
}
</style>
