<template>
  <div
    class="app-menu-category-card"
    ref="container"
  >
    <div class="category-title qrw-label-md bold qrw-text-content-base-primary">
      {{ categoryName }}
    </div>
    <div class="divider-block">
      <BaseDivider color="border-base-tertiary" />
    </div>
    <div class="menu-category-products">
      <template v-for="(product, index) in products">
        <ProductListCard
          @buy="(v) => $emit('productBuy', v)"
          @remove="(v) => $emit('productRemove', v)"
          :product="product"
          @openProductPage="openProductPage"
          :key="product._id"
        />
        <div
          class="divider-block"
          :key="`div_${product._id}`"
          v-if="index != products.length - 1"
        >
          <BaseDivider color="border-base-tertiary" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ProductListCard from '@/components/menu/ProductListCard.vue'

export default {
  props: {
    menu: Object,
    category: Object
  },
  computed: {
    products() {
      return this.menu.products.filter(product => product.category_id === this.category._id)
    },
    categoryName() {
      return this.$store.getters.localize('category_name', this.category._id)
    }
  },
  methods: {
    openProductPage(product) {
      this.$router.push(
        this.$store.getters.buildRouterNavigateObject('product', {
          menu_id: this.menu._id,
          product_id: product._id
        })
      )
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.products.length) {
        this.$store.commit('setCategoryUiOffset', {
          categoryId: this.category._id,
          offset: this.$refs.container.offsetTop,
          view: 'list'
        })
      }
    })
  },
  components: {
    ProductListCard
  }
}
</script>

<style scoped>
.category-title {
  display: flex;
  padding: 16px 16px 12px 16px;
}

.divider-block {
  display: flex;
  padding: 0 16px;
}

.menu-category-products {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.app-menu-category-card {
  background: var(--qrw-background-base-primary-10);
  box-shadow: var(--qrw-shadow-card-light);
}
</style>
