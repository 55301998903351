<template>
  <div class="app-reserve-system">
    <router-view v-if="loaded" />
    <SystemLoader v-else />
  </div>
</template>

<script>
import ReserveAPI from '@/api/reserve'

import SystemLoader from '@/components/common/SystemLoader.vue'

import { useCachedOrGuessBestLanguage } from '@/i18n'

import moment from 'moment'

export default {
  data() {
    return {
      loaded: false
    }
  },
  methods: {
    async fetchData() {
      const loadingStart = Date.now()

      this.$store.commit('reset')

      const response = await ReserveAPI.prepare(this.mixinCafeLinkCode)

      const { cafe, currency, menu, country } = response

      this.$store.commit('setMode', 'reserve')
      this.$store.commit('setCafe', cafe)
      this.$store.commit('setCurrency', currency)
      this.$store.commit('setMenus', [menu])
      this.$store.commit('setCountry', country)

      this.$i18n.locale = useCachedOrGuessBestLanguage()

      moment.locale(this.$i18n.locale)

      const loadingTime = Date.now() - loadingStart

      const minimalUiLoadingTime = 1300

      const loadedTimeoutDelay = Math.max(minimalUiLoadingTime - loadingTime, 0)

      this.$store.dispatch('rebuildTranslationDictionary', {
        locale: this.$i18n.locale
      })

      this.$store.commit('setModeNames', this.$t('mode_names'))

      setTimeout(() => {
        this.loaded = true
      }, loadedTimeoutDelay)
    }
  },
  mounted() {
    this.fetchData()
  },
  components: {
    SystemLoader
  }
}
</script>

<style></style>
