import { render, staticRenderFns } from "./ArchivedCard.vue?vue&type=template&id=1896a020&scoped=true&"
import script from "./ArchivedCard.vue?vue&type=script&lang=js&"
export * from "./ArchivedCard.vue?vue&type=script&lang=js&"
import style0 from "./ArchivedCard.vue?vue&type=style&index=0&id=1896a020&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1896a020",
  null
  
)

export default component.exports