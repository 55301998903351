<template>
  <BaseSimpleModal
    :showTitle="false"
    :showHeader="false"
    :fullHeight="false"
    :showFooter="false"
    @backdropClick="$emit('close')"
    modalClass="qrw-fit-height-modal"
  >
    <div class="delivery-time-modal-header">
      <div class="qrw-heading-sm bold qrw-text-content-base-primary">
        {{ $t("order_time_modal.title_" + mode) }}
      </div>
      <div class="qrw-paragraph-md qrw-text-content-base-secondary">
        {{ $t("order_time_modal.subtitle_" + mode) }}
      </div>
    </div>

    <div class="options">
      <div
        class="delivery-time-item"
        :class="{
          active: selectedTimeMode === 'asap',
        }"
        @click="selectedTimeMode = 'asap'"
        v-if="canShowAsap"
      >
        <div class="delivery-time-icon">
          <BaseIcon icon="clock-fast-forward" size="sm2" />
        </div>
        <div class="gap-row-2xs w-100">
          <div class="qrw-label-md option-title bold">
            {{ $t("order_time_modal.asap") }}
          </div>
          <div class="qrw-label-sm option-subtitle">
            {{ $t("order_time_modal.in") }} {{ minOrderTime }}-{{
              minOrderTime + 10
            }}
            {{ $t("units.min") }}
          </div>
        </div>
        <BaseIcon
          icon="check-circle"
          size="sm2"
          class="qrw-text-content-success-primary"
          v-if="selectedTimeMode === 'asap'"
        />
      </div>
      <div
        class="delivery-time-item"
        :class="{
          active: selectedTimeMode === 'scheduled',
        }"
        @click="selectedTimeMode = 'scheduled'"
        :key="index"
      >
        <div class="delivery-time-icon">
          <BaseIcon icon="calendar" size="sm2" />
        </div>
        <div class="gap-row-2xs w-100">
          <div class="qrw-label-md option-title bold">
            {{ $t("order_time_modal.scheduled_time") }}
          </div>
          <div class="qrw-label-sm option-subtitle">
            {{ selectedDate | formatDatetime }}
          </div>
        </div>
        <BaseIcon
          icon="check-circle"
          size="sm2"
          class="qrw-text-content-success-primary"
          v-if="selectedTimeMode === 'scheduled'"
        />
      </div>
    </div>

    <BaseDrumTimepicker
      v-model="selectedDate"
      :choices="timeslots"
      v-if="selectedTimeMode === 'scheduled'"
    />

    <div class="delivery-modal-footer qrw-app-bottom-container">
      <BaseDivider color="border-base-tertiary" />
      <div class="gap-column-xs delivery-confirm-buttons w-100">
        <BasePushButton
          variant="ghost"
          textColor="content-base-secondary"
          size="lg"
          @click="$emit('close')"
          class="w-100"
        >
          {{ $t("common_actions.cancel") }}
        </BasePushButton>
        <BasePushButton
          variant="fill"
          textColor="content-base-inverse-primary"
          bgColor="background-base-inverse-primary-10"
          size="lg"
          @click="onConfirm"
          class="w-100"
        >
          {{ $t("common_actions.confirm") }}
        </BasePushButton>
      </div>
    </div>
  </BaseSimpleModal>
</template>

<script>
import moment from "moment";

export default {
  props: {
    value: [String, Number, Date],
  },
  data() {
    return {
      selectedTimeMode: "asap",
      selectedDate: null,
    };
  },
  methods: {
    onConfirm() {
      this.$emit(
        "input",
        this.selectedTimeMode === "asap" ? null : this.selectedDate
      );
      this.$emit("close");
    },
  },
  mounted() {
    if (this.value || this.isCafeClosed || !this.canShowAsap) {
      this.selectedTimeMode = "scheduled";
    }
    this.selectedDate = this.value || Object.values(this.timeslots)[0][0];
  },
  computed: {
    cafe() {
      return this.$store.state.cafe;
    },
    mode() {
      return this.$store.state.mode;
    },
    minOrderTime() {
      return this.mode === "delivery"
        ? this.cafe.delivery.min_order_time + this.cafe.delivery.shipping_time
        : this.cafe.preorder.min_order_time || 0;
    },
    isCafeClosed() {
      return this.$store.getters.isCafeClosed;
    },
    canShowAsap() {
      return !this.isCafeClosed && !this.$store.state.closeRule;
    },
    timeslots() {
      const input = this.$store.state.delivery.timeslots;

      const localized = {};

      for (const key in input) {
        const now = moment(key, "YYYY-MM-DD");

        if (now.isSame(moment(), "day")) {
          localized[this.$t("order_time_modal.today")] = input[key];
        } else if (now.isSame(moment().add(1, "day"), "day")) {
          localized[this.$t("order_time_modal.tomorrow")] = input[key];
        } else {
          localized[now.format("ddd, DD.MM")] = input[key];
        }
      }

      return localized;
    },
  },
};
</script>

<style scoped>
.delivery-time-modal-header {
  display: flex;
  padding: 16px 16px;
  box-sizing: border-box;
  row-gap: 8px;
  background: var(--qrw-background-base-secondary-08);
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.options {
  display: flex;
  flex-direction: column;
  padding: 16px;
  row-gap: 16px;
}

.delivery-modal-footer {
  display: flex;
  flex-direction: column;
  row-gap: 0;
  width: 100%;
  box-sizing: border-box;
  background: var(--qrw-background-base-secondary-08);
}

.delivery-confirm-buttons {
  display: flex;
  column-gap: 16px;
  padding: 12px 16px;
  box-sizing: border-box;
  justify-content: space-between;
}

.delivery-time-item {
  display: flex;
  padding: 15px 16px;
  border-radius: 12px;
  background: var(--qrw-background-base-primary-10);
  column-gap: 12px;
  border: 1px solid var(--qrw-border-base-tertiary);
  align-items: center;
}

.option-title {
  color: var(--qrw-content-base-secondary);
}

.option-subtitle {
  color: var(--qrw-content-base-tertiary);
}

.delivery-time-item.active .option-title {
  color: var(--qrw-content-base-primary);
}
.delivery-time-item.active .option-subtitle {
  color: var(--qrw-content-base-secondary);
}

.delivery-time-item.active {
  box-shadow: var(--qrw-shadow-card-light);
  border: 1px solid var(--qrw-border-success-primary);
}

.delivery-time-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 8px;
  background: var(--qrw-background-base-tertiary-10);
  color: var(--qrw-content-base-secondary);
}

.delivery-time-item.active .delivery-time-icon {
  color: var(--qrw-content-base-primary);
}
</style>
