<template>
  <BaseSimpleModal
    :fullHeight="false"
    :showHeader="false"
    :showFooter="false"
    modalClass="qrw-fit-height-modal"
    @backdropClick="$emit('close')"
  >
    <div class="pending-body">
      <div class="pending-header">
        <BaseIcon
          icon="clock-stopwatch"
          class="qrw-text-content-base-primary"
          size="sm2"
        />
        <div class="header-title qrw-heading-xs qrw-text-content-pending-primary">
          {{ timeLeft }}
        </div>
      </div>
      <BaseDivider color="border-warning-tertiary" />
      <div class="pending-texts">
        <div class="qrw-heading-sm bold qrw-text-content-warning-primary">
          {{ $t("pending_order.title") }}
        </div>
        <div class="qrw-paragraph-md qrw-text-content-base-secondary">
          {{ $t("pending_order.desc") }}
        </div>
      </div>
    </div>

    <div class="pending-modal-footer gap-row-zero w-100 qrw-app-bottom-container">
      <BaseDivider color="border-warning-tertiary" />
      <div class="button-container">
        <BasePushButton
          variant="ghost"
          borderColor="border-base-tertiary"
          textColor="content-base-secondary"
          size="lg"
          @click="$emit('close')"
          class="w-100"
        >
          {{ $t("common_actions.close") }}
        </BasePushButton>
      </div>
    </div>
  </BaseSimpleModal>
</template>

<script>
import { getPrettyTimeLeftUntil } from '@/utils'

export default {
  data() {
    return {
      timeLeft: '15:00',
      intervalId: null
    }
  },
  methods: {
    tickTimer() {
      if (!this.$store.getters.isOrderPending) return

      this.timeLeft = getPrettyTimeLeftUntil(this.$store.state.order.auto_cancel_at)
    }
  },
  mounted() {
    this.intervalId = setInterval(this.tickTimer, 1000)
    this.tickTimer()
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
  }
}
</script>

<style scoped>
.pending-body {
  background: var(--qrw-background-warning-primary);
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.pending-header {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  justify-content: center;
  align-items: center;
}

.pending-texts {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 8px;
  text-align: center;
}

.header-title {
  width: 100%;
  text-align: center;
}

.button-container {
  display: flex;
  box-sizing: border-box;
  padding: 12px 16px;
  row-gap: 8px;
}

.pending-modal-footer {
  background: var(--qrw-background-warning-primary);
}
</style>
