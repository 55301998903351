<template>
  <div class="product-page-header">
    <BaseIconButton
      variant="fill"
      icon="arrow-left"
      size="md"
      :textColor="buttonStyles.text"
      :bgColor="buttonStyles.bg"
      @click="$emit('back')"
      :class="{
          'header-button-blurred': imagePresent
      }"
    />

    <BaseIconButton
      variant="fill"
      icon="share-02"
      size="md"
      :textColor="buttonStyles.text"
      :bgColor="buttonStyles.bg"
      @click="$emit('share')"
       :class="{
          'header-button-blurred': imagePresent
      }"
    />
  </div>
</template>

<script>
export default {
  props: {
    imagePresent: Boolean
  },
  computed: {
    buttonStyles () {
      return this.imagePresent
        ? {
            text: 'content-base-inverse-primary',
            bg: 'background-overlay-b20'
          }
        : {
            text: 'content-base-secondary',
            bg: 'background-base-tertiary-10'
          }
    }
  }
}
</script>

<style scoped>
.product-page-header {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  box-sizing: border-box;

  position: fixed;
  top: 0;
  z-index: 10;

  width: 100%;
}

.header-button-blurred {
  backdrop-filter: blur(20px);
}
</style>
