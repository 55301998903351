export default {
  home: {
    about: "O nás",
    address: "Adresa",
    socials: "Sociální sítě",
    website: "Webové stránky",
    get_directions: "Zobrazit trasu",
  },
  contacts: {
    title: "Kontakty",
    phone: "Telefon",
    mail: "E-mail",
    website: "Webová stránka",
    visit: "Navštívit",
    call: "Volat",
    copy: "Kopírovat",
  },
  schedule: {
    weekdays: {
      mon: "Pondělí",
      tue: "Úterý",
      wed: "Středa",
      thu: "Čtvrtek",
      fri: "Pátek",
      sat: "Sobota",
      sun: "Neděle",
    },
    open_now: "Otevřeno",
    closes_in: "Zavře se za",
    closed_now: "Zavřeno",
    opens_in: "Otevře se za",
    day_off: "Volno",
    schedule: "Rozvrh",
  },
  units: {
    hours: "h.",
    minutes: "m.",
    positions: "0 pozic | 1 pozice | {count} pozicí",
    items: "0 položek | 1 položka | {count} položek",
    orders: "0 objednávek | 1 objednávka | {count} objednávek",
    g: "g",
    kg: "kg",
    l: "l",
    ml: "ml",
    min: "min",
  },
  legals: {
    privacy_policy: "Zásady ochrany osobních údajů",
    cookies: "Cookies",
    terms_of_use: "Podmínky používání",
    public_offert: "Veřejná nabídka",
    view: "Zobrazit",
  },
  reviews: {
    home_title: "Recenze",
    max_value: "Z pěti",
  },
  menu_grid: {
    list: "Seznam",
  },
  product: {
    description: "Popis",
  },
  common_actions: {
    confirm: "Potvrdit",
    order: "Objednat",
    cancel: "Zrušit",
    reserve: "Rezervovat",
    next: "Další",
    pay: "Zaplatit",
    search: "Hledat",
    view_cart: "Košík",
    add: "Přidat",
    remove: "Odstranit",
    close: "Zavřít",
    view: "Zobrazit",
  },
  reserve: {
    persons: "Osoby",
    reservation: "Rezervace",
    thanks_1: "Děkujeme!",
    thanks_2: "Vaše rezervace byla vytvořena",
    date: "Datum",
    time: "Čas",
    download_pdf: "Stáhnout PDF",
    add_to_calendar: "Přidat do kalendáře",
    deposit: "Záloha",
    paid: "Zaplaceno",
  },
  checkout: {
    contact_details: "Kontaktní údaje",
    confirmation: "Potvrzení",
    name: "Jméno",
    first_name: "Křestní jméno",
    last_name: "Příjmení",
    phone: "Telefon",
    email: "E-mail",
    comment: "Komentář",
    contact: "Kontakt",
    service: "Služba",
    date: "Datum",
    time: "Čas",
  },
  promocode: {
    title: "Máte slevový kod?",
    subtitle: "Vložte jej níže",
    placeholder: "Vložte promo",
  },
  total: {
    subtotal: "Mezisoučet",
    discount: "Sleva",
    service_fee: "Služební poplatek",
    delivery_fee: "Poplatek za doručení",
    products_total: "Celkem za zboží",
    tip: "Spropitné",
    total: "Celkem",
  },
  comment: {
    title: "Vaše poznámka",
  },
  phone_verify_modal: {
    title: "Ověření telefonního čísla",
    enter: "Zadejte kód z SMS",
    sent_to: "Kód byl odeslán na ",
    no_code: "Nedostal jste kód?",
    resend_btn: "Poslat znovu",
    error: "Neplatný kód",
    success: "Ověřeno",
  },
  online_payment_modal: {
    redirect: "Budete přesměrováni na stránku pro platbu",
    btn_pay: "Pokračovat k platbě",
    btn_cancel: "Zrušit",
    redirecting: "Přesměrovávám na stránku pro platbu...",
  },
  pay_methods: {
    cash: "Hotovost",
    card: "Karta",
    online: "Online",

    pay_with_cash: "Zaplatit hotově",
    pay_with_card: "Zaplatit kartou",
    pay_with_online: "Zaplatit online",
  },
  validations: {
    required: "Povinné pole",
    phone: "Zadejte správné číslo telefonu",
    email: "Zadejte platnou e-mailovou adresu",
  },
  calendar: {
    date: "Datum",
  },
  phone_country_picker: {
    title: "Vyberte telefonní oblast",
    placeholder: "Vyhledávání podle názvu země...",
    select: "Vybrat",
  },
  menu_lobby: {
    welcome: "Vítejte",
    select_type: "Prosím, vyberte si typ své objednávky",
    search_ph: "Pokrmy, menu, kategorie...",
    our_menus: "Naše menu",
  },
  call_waiter: {
    title: "Potřebujete pomoc?",
    subtitle: "Zavolejte číšníkovi pro pomoc",
    button: "Zavolejte",
    sent: "Vaše žádost byla odeslána",
  },
  scan_qrcode: {
    title: "Připojte Vaší stůl",
    subtitle: "Naskenujte QR-kód na stole",
    button: "Skenovat",
  },
  verify_location: {
    title: "Geo-lokace",
    subtitle: "Potřebujeme ověřit vaši polohu",
    button: "Ověřit",
    verified: "Ověřeno",
  },
  menu_product: {
    badge_spicy: "Pikantní",
    badge_veggie: "Vegetariánské",
    badge_alcohol: "Obsahuje alkohol",
    variants: "Varianty",
    modificators: "Modifikátory",
    variants_single: "Vyberte alespoň jednu možnost",
    variants_min: "Minimální počet",
    variants_max: "Maximální počet",
    required: "Povinné",
    modificators_any: "Vyberte libovolný počet modifikátorů",
    comment_title: "Váš komentář",
    comment_ph: "Sem napište...",
    show_more: "Zobrazit více",
    show_less: "Zobrazit méně",
  },
  cart: {
    title: "Košík",
    order_type: "Typ objednávky",
    delivery_price: "Cena doručení",
    min_order: "Minimální objednací částka",
    place_order: "Objednejte si",
    products: "Produkty",
    unavailable_item: "Tento produkt není k dispozici",
    add_more: "Objednat si další produkty z menu",
    comment_title: "Zvláštní požadavky",
    comment_desc: "Máte nějaké zvláštní požadavky, alergie nebo diety?",
    comment_ph: "Napište zde...",

    alcohol_alert:
      "Potvrzuji, že jsem dost starý na to, abych si objednal položky obsahující alkohol",
  },
  geo_verification: {
    title: "Geo-ověření",
    desc: "Potřebujeme ověřit Vaši geolokaci pro dokončení objednávky.",
    please: "Prosíme, poskytněte přístup k Vaší geolokaci",
    verify: "Ověřit moji geolokaci",
    dismiss: "Zrušit",
    deny_title: "Vypadá to, že jste odmítli přístup k Vaší lokaci",
    deny_subtitle: "Prosíme, znovu načtěte stránku a zkuste to znovu",
    reload: "Znovu načíst stránku",
    busy: "Ověřuji Vaši geolokaci...",
    confirmed: "Lokace potvrzena",
    out_of_range: "Služba není k dispozici ve Vaší oblasti",
  },
  order_product_card: {
    product_total: "Celkem produktů",
    remains: "Zbývá:",
  },
  order_page: {
    title: "Aktuální objednávka",
    your_bill: "Váš účet",
    select_all: "Vybrat všechny",
  },
  order_payment_navbar: {
    none_selected: "Vyberte produkty k platbě",
    slider_disabled: "Zaplatit",
    slider_active: "Tažením zaplatit",
    total_to_pay: "Celkem k zaplacení",
    including_fees: "Včetně poplatků",

    left_unpaid: "zbývá zaplatit",
  },
  pay_method_modal: {
    title: "Způsob platby",
    subtitle: "Vyberte, jak chcete zaplatit",
    descriptions: {
      cash: "Zaplatíte hotovostí na místě",
      card: "Zaplatíte kartou na místě",
      online: "Zaplatíte online",
    },
  },
  leave_tips: {
    title: "Chcete-li, můžete dát spropitné",
    thank_you: "Děkujeme!",
  },
  personal_tips: {
    title: "Dát spropitné",
    role_ph: "Číšník",
  },
  receipt: {
    total_paid: "Celkem zaplaceno",
    including_taxes: "Včetně daně",
    service_provider: "Poskytovatel služby",
    tech_details: "Technické údaje",
    order_number: "Číslo objednávky",
    order_id: "ID objednávky",
    date_and_time: "Datum a čas",
  },
  payment_details: {
    title: "Platné detaily",
    refunded: "Vráceno",
    paid: "Zaplaceno",
    unpaid: "Nezaplaceno",
  },
  archive: {
    active_order: "Aktuální objednávka",
    archived_order: "Archivovaná objednávka",
    empty_title: "Zatím tu nic není",
    empty_subtitle:
      "Můžete objednat z menu.<br>Vaše objednávka se zde objeví po tom, co kavárna ji přijme.",
    open_menu: "Otevřít menu",
    title: "Objednávky",
    subtitle_1: "Zde naleznete své aktuální a archivované objednávky",
  },
  main_navbar: {
    menu: "Menu",
    orders: "Objednávky",
    home: "Domů",
  },
  error_modal: {
    title_generic: "Něco se pokazilo",
    title_payment: "Nepodařilo se dokončit platbu",
    title_order: "Nepodařilo se dokončit objednávku",
    title_not_found: "Nepodařilo se najít stránku",

    error_code: "Kód chyby",

    please_reload: "Prosíme, znovu načtěte stránku a zkuste to znovu",

    btn_back: "Zpět",
    btn_reload: "Znovu načíst",
    btn_home: "Domů",
  },
  split_payment: {
    title: "Tento účet bude rozdělen na {n} platby",
    payment: "Platba",
    desc: "Budeme držet peníze, dokud nebudou všechny platby dokončeny. Pokud nebudou všechny platby dokončeny během 15 minut po první platbě, všechny peníze budou automaticky vráceny.",
  },
  pending_order: {
    title: "Čekající objednávka",
    your_order: "Vaše objednávka",
    pending: "Čekající",
    declined: "Byla zamítnuta",
    desc: "Vaše objednávka byla odeslána a čeká na potvrzení. Jakmile bude potvrzena, objeví se v sekci „Objednávky“.",
  },
  cancelled_modal: {
    title: "Objednávka byla zrušena",
    reason: "Důvod",
    your_order: "Vaše objednávka",
  },
  mode_names: {
    inside: "Uvnitř",
    takeaway: "S sebou",
    preorder: "S sebou",
    delivery: "Doručení",
    reserve: "Rezervace",
  },
};
