<template>
  <div class="cart-client-details-wrapper">
    <div
      class="cart-client-details"
      v-if="!client || !client._id"
    >
      <div class="qrw-label-lg bold qrw-text-content-base-primary">
        {{$t('client_details.title')}}
      </div>
      <div class="gap-row-txs">
        <div class="qrw-label-md qrw-text-content-base-primary">
          {{$t('client_details.name')}} <span class="qrw-text-content-error-primary">
            *
          </span>
        </div>
        <BaseTextInput
          v-model="fields.name"
          :placeholder="$t('client_details.name_ph')"
          autocomplete="name"
          size="md"
        />
      </div>
      <BaseDivider color="border-base-tertiary" />
      <div class="gap-row-txs">
        <div class="qrw-label-md qrw-text-content-base-primary">
          {{$t('client_details.phone')}} <span class="qrw-text-content-error-primary">
            *
          </span>
        </div>
        <SmartPhoneInput
          :defaultCountry="cafeCountry"
          size="md"
          v-model="fields.phone"
          class="w-100"
        />
      </div>
      <!-- <BaseDivider color="border-base-tertiary" />
      <div class="gap-row-txs">
        <div class="qrw-label-md qrw-text-content-base-primary">
          {{$t('client_details.email')}} <span class="qrw-text-content-error-primary">
            *
          </span>
        </div>
        <BaseTextInput
          type="email"
          v-model="fields.email"
          size="md"
        />
      </div> -->
    </div>
    <div
      class="cart-client-details"
      v-else
    >
      <BaseLabelBadge
        size="sm"
        class="verified-badge"
        primaryColor="background-success-secondary"
        secondaryColor="content-success-primary"
        icon="check-verified"
      >
        {{$t('client_details.verified')}}
      </BaseLabelBadge>
      <div class="gap-row-1xs">
        <div class="qrw-label-lg qrw-text-content-base-primary bold">
          {{client.name}}
        </div>
        <div class="qrw-label-md qrw-text-content-base-primary">
          {{client.phone}}
        </div>
        <div class="qrw-label-md qrw-text-content-base-primary">
          {{client.email}}
        </div>
      </div>
      <BaseDivider color="border-base-tertiary" />
      <BasePushButton
        variant="outlined"
        size="md"
        textColor="content-base-primary"
        borderColor="border-base-secondary"
        @click="$emit('clear')"
      >
        {{$t('client_details.change')}}
      </BasePushButton>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    client: Object,
    initialFields: Object
  },
  data() {
    return {
      fields: {
        name: null,
        phone: null,
        email: null
      }
    }
  },
  watch: {
    fields: {
      handler() {
        this.$emit('input', { ...this.fields })
      },
      deep: true
    }
  },
  computed: {
    cafeCountry() {
      return this.$store.state.country
    }
  },
  mounted() {
    // if (this.initialFields) {
    //   this.fields = { ...this.initialFields }
    // }
  }
}
</script>

<style scoped>
.cart-client-details-wrapper {
  padding: 12px 16px;
  display: flex;
}

.cart-client-details {
  display: flex;
  background: var(--qrw-background-base-primary-10);
  box-shadow: var(--qrw-shadow-card-light);
  border-radius: 12px;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.verified-badge {
  position: absolute;
  top: 16px;
  right: 16px;
  width: fit-content;
}
</style>
