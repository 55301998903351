<template>
  <div
    class="add-to-cart-navbar"
    data-testid="add-to-cart-navbar"
    @click="$emit('click')"
  >
    <BaseCounter
      size="md"
      :min="1"
      :max="30"
      :disabled="disabled"
      :value="quantity"
      @input="(val) => $emit('changeQuantity', val)"
    />

    <BasePushButton
      @click="$emit('add')"
      size="md"
      variant="fill"
      :disabled="disabled"
      bgColor="background-base-inverse-primary"
      data-testid="add-to-cart-button"
      textColor="content-base-inverse-primary"
      class="w-100"
    >
      <span style="display: flex">{{ $t("common_actions.add") }} (
        <PriceTag :value="total" />)
      </span>
    </BasePushButton>
  </div>
</template>

<script>
export default {
  props: {
    total: Number,
    quantity: Number,
    disabled: Boolean
  },
  computed: {
    currency() {
      return this.$store.state.currency
    }
  }
}
</script>

<style scoped>
.add-to-cart-navbar {
  padding: 12px 16px;
  display: flex;

  width: 100%;
  box-sizing: border-box;

  column-gap: 16px;

  align-items: center;
}
</style>
