<template>
  <div class="app-pending-order-navbar-wrapper">
    <BaseDivider color="border-warning-tertiary" />
    <div class="app-pending-order-navbar">
      <div class="gap-row-2xs">
        <div class="qrw-label-sm qrw-text-content-warning-primary">
          {{ $t("pending_order.your_order") }}
        </div>
        <div class="gap-column-1xs">
          <div class="qrw-label-md bold qrw-text-content-warning-primary">
            {{ $t('pending_order.pending') }}
          </div>
          <BaseDivider
            color="border-warning-secondary"
            vertical
            style="height: 24px"
          />
          <div class="qrw-label-md bold qrw-text-content-warning-primary">
            {{ timeLeft }}
          </div>
        </div>

      </div>

      <BasePushButton
        variant="fill"
        @click="showPendingOrderModal"
        bgColor="background-warning-default"
        textColor="content-base-inverse-primary"
        size="md"
        class="view-order-button"
      >
        {{ $t("common_actions.view") }}
      </BasePushButton>
    </div>

    <portal>
      <PendingOrderModal
        :timeLeft="timeLeft"
        @close="modalShown = false"
        v-if="modalShown"
      />
    </portal>
  </div>
</template>

<script>
import { getPrettyTimeLeftUntil } from '@/utils'

import PendingOrderModal from '@/components/modals/PendingOrderModal.vue'

export default {
  data() {
    return {
      timeLeft: '15:00',
      modalShown: false
    }
  },
  watch: {
    hasPendingOrder() {
      if (this.hasPendingOrder) this.tickTimer()
    }
  },
  methods: {
    tickTimer() {
      if (!this.hasPendingOrder) return

      this.timeLeft = getPrettyTimeLeftUntil(this.order.auto_cancel_at)

      setTimeout(this.tickTimer, 1000)
    },
    showPendingOrderModal() {
      this.modalShown = true
    }
  },
  computed: {
    order() {
      return this.$store.state.order
    },
    hasPendingOrder() {
      return this.$store.getters.isOrderPending
    }
  },
  mounted() {
    this.tickTimer()
  },
  components: {
    PendingOrderModal
  }
}
</script>

<style scoped>
.app-pending-order-navbar-wrapper {
  display: flex;
  flex-direction: column;
}

.view-order-button {
  width: 122px;
  min-width: 122px;
}

.app-pending-order-navbar {
  display: flex;
  background: var(--qrw-background-warning-secondary);
  padding: 8px 16px;
  align-items: center;
  column-gap: 24px;
  justify-content: space-between;
}
</style>
