<template>
  <div class="app-inside-system">
    <router-view v-if="loaded" />
    <SystemLoader v-else-if="!errorModalCode" />
    <ErrorModal :type="loaded ? 'order' : 'generic'" :code="errorModalCode" @close="errorModalCode = null"
      v-if="errorModalCode" />
  </div>
</template>

<script>
import InsideAPI from "@/api/inside";

import SystemLoader from "@/components/common/SystemLoader.vue";

import Realtime from "@/api/realtime";

import { useCachedOrGuessBestLanguage, banLanguage } from "@/i18n";

import ErrorModal from "@/components/modals/ErrorModal.vue";

import moment from "moment";

import Telemetry from "@/utils/telemetry";

export default {
  data () {
    return {
      loaded: false,

      cancelModal: {
        reason: null,
        list: [],
        shown: false,
      },

      errorModalCode: false,
    };
  },
  watch: {
    "$i18n.locale" () {
      this.$store.dispatch("rebuildTranslationDictionary", {
        locale: this.$i18n.locale,
      });

      this.$store.commit("setModeNames", this.$t("mode_names"));
    },
  },
  methods: {
    onOrderUpdated (newOrder) {
      // const currentOrder = this.$store.state.order

      this.$store.commit("setOrder", newOrder);

      console.log(
        `Order updated via socket: ${newOrder._id} / ${newOrder.status}`
      );

      if (newOrder.external_pos_error) {
        this.errorModalCode = newOrder.external_pos_error;
        this.$store.commit("setOrder", null);
      }
    },
    async initRealtime () {
      await Realtime.init();

      if (this.$store.state.order) {
        Realtime.listen(this.$store.state.order._id);
      }

      Realtime.addRealtimeEventsListener("inside_update", this.onOrderUpdated);
    },
    async fetchData () {
      try {
        const loadingStart = Date.now();

        this.$store.commit("reset");

        const response = await InsideAPI.prepare(
          this.$route.params.inside_code,
          this.$route.query.cart_id
        );

        const {
          cafe,
          currency,
          menus,
          country,
          inside,
          cart,
          redirectToPreview,
          link,
          sourceInside,
          tips
        } = response;

        if (redirectToPreview && link) {
          // Set the inside data from server so we can show the table number in Preview mode
          this.$store.commit("setInsideData", sourceInside);
          this.$router.replace({
            name: "preview_lobby",
            params: {
              cafe_link: link,
            }
          });
          return;
        }

        this.$store.commit("setMode", "inside");
        this.$store.commit("setCafe", cafe);
        this.$store.commit("setCurrency", currency);
        this.$store.commit("setMenus", menus);
        this.$store.commit("setCountry", country);
        this.$store.commit("setInsideData", inside);
        this.$store.dispatch("cart/useCart", cart);
        this.$store.dispatch("buildImagesCache");
        this.$store.commit("setRootSystemUrl", this.$route.fullPath);
        this.$store.commit('setTips', tips);

        if (response.active_order) {
          this.$store.commit("setOrder", response.active_order);
        }

        const loadingTime = Date.now() - loadingStart;

        const minimalUiLoadingTime = 1300;

        const loadedTimeoutDelay = Math.max(
          minimalUiLoadingTime - loadingTime,
          0
        );

        setTimeout(() => {
          this.loaded = true;
        }, loadedTimeoutDelay);

        // Ban pidorussian language from Ukraine
        if (country === "UA") {
          banLanguage("ru");
          banLanguage("ru-RU");
        }

        this.$i18n.locale = useCachedOrGuessBestLanguage();

        moment.locale(this.$i18n.locale);

        this.$store.dispatch("rebuildTranslationDictionary", {
          locale: this.$i18n.locale,
        });

        this.$store.commit("setModeNames", this.$t("mode_names"));

        this.initRealtime();

        Telemetry.init(cafe._id, "inside", "superapp");
      } catch (err) {
        this.errorModalCode =
          err.message + " / " + this.$route.params.inside_code;
      }
    },
  },
  mounted () {
    this.fetchData();
  },
  components: {
    SystemLoader,
    ErrorModal,
  },
};
</script>

<style scoped>
.app-inside-system {
  display: flex;
  flex-direction: column;
  flex: 1;
}
</style>
