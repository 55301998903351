<template>
  <div
    class="home-cafe-socials"
    v-if="hasAnySocial"
  >
    <div class="qrw-label-lg bold qrw-text-content-base-primary">
      {{ $t("home.socials") }}
    </div>
    <div class="social-buttons">
      <BasePushButton
        class="social-button"
        v-if="socials.facebook"
        @click="openSocial(socials.instagram)"
        variant="primary"
        size="md"
        textColor="content-base-secondary"
        bgColor="background-base-tertiary-10"
        icon="instagram"
      >
        Instagram
      </BasePushButton>
      <BasePushButton
        class="social-button"
        v-if="socials.facebook"
        @click="openSocial(socials.facebook)"
        variant="primary"
        size="md"
        textColor="content-base-secondary"
        bgColor="background-base-tertiary-10"
        icon="facebook"
      >
        Facebook
      </BasePushButton>
      <!-- <BasePushButton
        class="social-button"
        v-if="socials.website"
        @click="openSocial(socials.website)"
        variant="primary"
        size="md"
        textColor="content-base-secondary"
        bgColor="background-base-tertiary-10"
        icon="globe"
      >
        {{ $t('home.website') }}
      </BasePushButton> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    socials: Object
  },
  methods: {
    openSocial(link) {
      window.location.href = link
    }
  },
  computed: {
    hasAnySocial() {
      return this.socials.facebook || this.socials.instagram || this.socials.website
    }
  }
}
</script>

<style scoped>
.home-cafe-socials {
  display: flex;
  flex-direction: column;
  padding: 16px;
  row-gap: 16px;
}

.social-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
}

.social-button {
  width: calc(50% - 8px);
}
</style>
