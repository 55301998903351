import axios from "axios";

const root = axios.create({
  baseURL: "/api",
  timeout: 60 * 1000,
});

function initSession(data) {
  return root.post(`telemetry/init`, data);
}

function writeGestures(id, gestures) {
  return root.post(`telemetry/gestures/${id}`, {
    gestures,
  });
}

function writeEvent(id, type, payload, timestamp) {
  return root.post(`telemetry/event/${id}`, {
    type,
    payload,
    timestamp,
  });
}

function changeTarget(id, cafeId, mode) {
  return root.post(`telemetry/target/${id}`, {
    cafe_id: cafeId,
    mode,
  });
}

function setMilestone(id, type) {
  return root.post(`telemetry/milestone/${id}/${type}`);
}

export default {
  initSession,
  writeEvent,
  writeGestures,
  changeTarget,
  setMilestone,
};
