<template>
  <div class="flag-icon">
    <img
      :src="iconUrl"
      @click="$emit('click')"
    >
  </div>

</template>

<script>
export default {
  props: {
    countryName: String
  },
  computed: {
    realCountryName() {
      const localesMapping = {
        en: 'united-kingdom',
        ru: 'russia', // fuck it
        uk: 'ukraine',
        cs: 'czech-republic',
        ar: 'saudi-arabia',
        tr: 'turkey'
      }

      return this.countryName || localesMapping[this.$i18n.locale]
    },
    iconUrl() {
      return `https://cdn.qrwaiter.com.ua/assets/flags/v2-wavy/${this.realCountryName.toLowerCase()}.png`
    }
  }
}
</script>

<style scoped>
img {
  width: 20px;
  height: 20px;
}

.flag-icon {
  display: flex;
  box-sizing: border-box;
  padding: 10px;
  justify-content: center;
  align-items: center;
}
</style>
