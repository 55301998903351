<template>
  <div class="gap-column-xs">
    <BasePushButton
      size="md"
      class="w-100"
      variant="outlined"
      borderColor="border-base-secondary"
      bgColor="background-base-secondary"
      textColor="content-base-primary"
      icon="phone-call"
      @click="callCafe"
    >
      Call
    </BasePushButton>

    <BasePushButton
      class="w-100"
      size="md"
      variant="primary"
      bgColor="background-base-inverse-primary"
      textColor="content-base-inverse-primary"
      icon="calendar-filled"
      @click="navigateToReserve"
    >
      Reserve
    </BasePushButton>
  </div>
</template>

<script>
export default {
  methods: {
    callCafe () {
      window.location.href = `tel:${this.cafe.contact_phone}`
    },
    navigateToReserve () {
      this.$router.push({
        name: 'reserve_menu',
        params: {
          cafe_link: this.mixinCafeLinkCode
        }
      })
    }
  },
  computed: {
    cafe () {
      return this.$store.state.cafe
    }
  }
}
</script>

<style></style>
