<template>
  <div
    class="app-search-result"
    @click="$emit('click')"
  >
    <div class="product-titles">
      <div class="product-names gap-row-2xs">
        <div class="qrw-text-content-base-tertiary qrw-label-xs">
          {{ productCategoryName }}
        </div>
        <div class="qrw-text-content-base-primary qrw-label-md">
          {{ productName }}
        </div>
      </div>
      <PriceTag
        :value="product.base_price"
        class="qrw-label-sm bold qrw-text-content-base-primary"
      />
    </div>

    <img
      class="product-image"
      :src="productImage"
      v-if="productImage"
    >
  </div>
</template>

<script>
export default {
  props: {
    product: Object
  },
  computed: {
    productName() {
      return this.$store.getters.localize('product_name', this.product._id)
    },
    productDescription() {
      return this.$store.getters.localize('product_description', this.product._id)
    },
    productCategoryName() {
      return this.$store.getters.localize('category_name', this.product.category_id)
    },
    productImage() {
      return this.$store.getters.getProductCachedImage(this.product._id)
    }
  }
}
</script>

<style scoped>
.app-search-result {
  padding: 16px;
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: flex-start;
}

.product-titles {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
}

.product-names {
  width: 100%;
}

.product-image {
  width: 83px;
  height: 64px;
  border-radius: 6px;
  object-fit: cover;
  max-width: 83px;
  max-height: 64px;
  min-width: 83px;
  min-height: 64px;
}
</style>
