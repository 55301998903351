<template>
  <div class="app-manage-delivery-address">
    <div
      class="qrw-app-top-sticky"
      v-qrw-class-on-scroll="{
        limit: 0,
        cssClass: 'qrw-app-top-container'
      }"
      v-qrw-is-header
    >
      <SecondaryPageHeader
        :title="$t('edit_address.title')"
        :hideSubtitle="true"
        leftIcon="arrow-left"
        rightIcon="x-close"
        @leftClicked="goBack"
        :fixed="false"
        @rightClicked="goBack"
      />
    </div>

    <div
      class="app-manage-address-body"
      :style="{'padding-top': '65px'}"
      v-qrw-above-footer
      v-qrw-under-header
    >
      <div class="pad-block">
        <div class="qrw-label-lg bold qrw-text-content-base-primary">
          {{ $t('edit_address.title') }}
        </div>
        <BaseTextInput
          @click.native="searchingAddressMode = 'street'"
          size="md"
          v-model="form.street_name"
          readonly
          :placeholder="$t('edit_address.street_ph')"
        />
        <BaseTextInput
          @click.native="searchingAddressMode = 'house'"
          size="md"
          v-model="form.house_number"
          readonly
          :placeholder="$t('edit_address.house_ph')"
          v-if="form.street_name"
        />
      </div>
      <div
        class="pad-block"
        @click="typeModalShown = true"
      >
        <div class="gap-row-1xs">
          <div class="qrw-label-lg bold qrw-text-content-base-primary">
            {{ $t('edit_address.place_type') }}
          </div>
          <div class="qrw-paragraph-sm qrw-text-content-base-secondary">
            {{$t('edit_address.place_type_desc')}}
          </div>
        </div>

        <!-- <BaseBetaSelect
          :placeholder="$t('edit_address.place_type_ph')"
          v-model="form.place_type"
          :choices="{
            'house': $t('address_place_types.house'),
            'office': $t('address_place_types.office'),
            'flat': $t('address_place_types.flat'),
            'other': $t('address_place_types.other')
          }"
          readonly
          disabled
          size="md"
        /> -->
        <!-- <BaseTextInput
          readonly
          size="md"
          :value="form.place_type ? $t('address_place_types.'+form.place_type) :$t('edit_address.place_type_ph')"
        /> -->
        <BasePulldownButton
          size="md"
          variant="outlined"
          borderColor="border-base-tertiary"
          style="justify-content: flex-start; text-align: left;"
          :textColor="form.place_type ? 'content-base-primary' : 'content-base-tertiary'"
        >
          <div class="w-100">{{form.place_type ? $t('address_place_types.'+form.place_type) :$t('edit_address.place_type_ph')}}</div>

        </BasePulldownButton>
      </div>
      <div
        class="pad-block"
        v-if="form.place_type && form.house_number"
      >
        <div class="gap-row-1xs">
          <div class="qrw-label-lg bold qrw-text-content-base-primary">
            {{ $t('edit_address.address_details') }}
          </div>
          <div class="qrw-paragraph-sm qrw-text-content-base-secondary">
            {{$t('edit_address.address_details_desc')}}
          </div>
        </div>
        <BaseTextInput
          v-model="form[detail]"
          v-for="detail in addressTypeDetails"
          :key="detail"
          size="md"
          :placeholder="$t('address_place_details.'+detail)"
        />
        <template v-if="form.place_type === 'flat'">
          <div class="qrw-label-lg bold">
            {{$t('address_place_details.how_to_enter')}}
          </div>
          <BaseBetaRadioButton
            v-model="form.flat_enter_type"
            checkedValue="bell"
            size="md"
          >
            {{$t('address_place_details.enter_bell')}}
          </BaseBetaRadioButton>
          <BaseBetaRadioButton
            v-model="form.flat_enter_type"
            checkedValue="code"
            size="md"
          >
            {{$t('address_place_details.enter_code')}}
          </BaseBetaRadioButton>
          <BaseBetaRadioButton
            v-model="form.flat_enter_type"
            checkedValue="open"
            size="md"
          >
            {{$t('address_place_details.enter_open')}}
          </BaseBetaRadioButton>
          <BaseBetaRadioButton
            v-model="form.flat_enter_type"
            checkedValue="other"
            size="md"
          >
            {{$t('address_place_details.enter_other')}}
          </BaseBetaRadioButton>
          <div class="gap-row-1xs">
            <BaseTextInput
              size="md"
              :placeholder="$t('address_place_details.door_code')"
              v-model="form.door_code"
            />
            <div class="qrw-label-xs qrw-text-content-base-tertiary">
              {{$t('address_place_details.optional')}}
            </div>
          </div>
        </template>
        <template v-if="form.place_type === 'office'">
          <div class="qrw-label-lg bold">
            {{$t('address_place_details.where_to_deliver')}}
          </div>
          <BaseBetaRadioButton
            v-model="form.deliver_to"
            checkedValue="office"
            size="md"
          >
            {{$t('address_place_details.deliver_office')}}
          </BaseBetaRadioButton>
          <BaseBetaRadioButton
            v-model="form.deliver_to"
            checkedValue="reception"
            size="md"
          >
            {{$t('address_place_details.deliver_reception')}}
          </BaseBetaRadioButton>
          <BaseTextInput
            size="md"
            :placeholder="$t('address_place_details.floor')"
            v-model="form.floor"
          />

          <BaseTextInput
            size="md"
            :placeholder="$t('address_place_details.company_name')"
            v-model="form.company_name"
          />
        </template>
        <div class="gap-row-1xs">
          <BaseTextarea
            autoresize
            size="md"
            :placeholder="$t('address_place_details.instructions')"
            v-model="form.instructions"
          />
          <div class="qrw-label-xs qrw-text-content-base-tertiary">
            {{$t('address_place_details.optional')}}
          </div>
        </div>
      </div>
    </div>

    <div
      class="qrw-app-bottom-sticky qrw-app-bottom-container"
      v-qrw-is-footer
    >
      <BaseDivider color="border-base-tertiary" />
      <div class="edit-address-footer gap-row-xs">
        <BasePushButton
          variant="fill"
          textColor="content-base-inverse-primary"
          bgColor="background-base-inverse-primary-10"
          size="lg"
          @click="onConfirm(true)"
          :disabled="!isFormFilled"
          class="w-100"
        >
          {{ $t("common_actions.confirm") }}
        </BasePushButton>
        <!-- <BasePushButton
          variant="ghost"
          textColor="content-base-secondary"
          size="lg"
          :disabled="!isFormFilled"
          @click="onConfirm(false)"
          class="w-100"
        >
          {{ $t("edit_address.use_once") }}
        </BasePushButton> -->

      </div>
    </div>

    <AddressSearch
      :street="form.street"
      :form="form"
      @input="setInitialAddress"
      :mode="searchingAddressMode"
      @close="searchingAddressMode = null"
      v-if="searchingAddressMode"
    />

    <SelectAddressTypeModal
      v-model="form.place_type"
      @close="typeModalShown = false"
      v-if="typeModalShown"
    />
  </div>
</template>

<script>
import SecondaryPageHeader from '@/components/header/SecondaryPageHeader.vue'

import AddressSearch from '@/components/delivery/AddressSearch.vue'
import SelectAddressTypeModal from '@/components/modals/SelectAddressTypeModal.vue'

export default {
  components: {
    SecondaryPageHeader,
    AddressSearch,
    SelectAddressTypeModal
  },
  data() {
    return {
      form: {
        street: null,
        street_name: null,
        house_number: null,
        place_type: null,
        entrance: null,
        door: null,
        building: null,
        instructions: null,
        floor: null,
        flat: null,

        flat_enter_type: 'bell',

        company_name: null,
        door_code: null,

        deliver_to: 'office',

        is_reachable: false,

        city: null,
        country: null,

        lat: null,
        lon: null
      },
      searchingAddressMode: null,
      typeModalShown: false
    }
  },
  methods: {
    onConfirm(shouldSave) {
      this.$store.commit('setDeliveryAddress', this.form)
      this.goBack()
    },
    goBack() {
      this.$router.push(this.$store.getters.buildRouterNavigateObject('cart'))
    },
    setInitialAddress(place) {
      this.form.street = place.street
      this.form.lat = place.location.lat
      this.form.lon = place.location.lon
      this.form.city = place.city
      this.form.is_reachable = place.is_reachable
      this.form.country = place.country
      this.form.street_name = place.street_name
      this.form.house_number = place.house_number
    }
  },
  computed: {
    addressTypeDetails() {
      if (!this.form.place_type) return []

      return {
        house: ['entrance', 'door'],
        flat: ['building', 'entrance', 'floor', 'flat'],
        office: ['building', 'entrance'],
        other: ['details']
      }[this.form.place_type]
    },
    isFormFilled() {
      return this.form.street && this.form.place_type && this.form.house_number
    }
  },
  mounted() {
    if (this.$store.state.delivery.address) {
      this.form = { ...this.$store.state.delivery.address }
    }
  }
}
</script>

<style scoped>
.app-manage-delivery-address {
}

.app-manage-address-body {
  display: flex;
  flex-direction: column;
}

.pad-block {
  display: flex;
  padding: 16px;
  flex-direction: column;
  row-gap: 16px;
}
.edit-address-footer {
  padding: 12px 16px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>
