<template>
  <div
    class="service-grid-card qrw-box-shadow-foundation-1dp"
    :class="{ extended }"
    @click="openProduct"
  >
    <img class="card-image" :src="productImage" />
    <div class="service-info">
      <div class="service-text" v-if="product">
        <div class="qrw-heading-xs qrw-text-content-base-primary product-name">
          {{ product.name }}
        </div>
        <div class="qrw-label-md qrw-text-content-base-secondary">
          {{ firstProductPrice | formatMoney }}{{ currency.symbol }}
        </div>
      </div>
      <div class="service-text" v-else>
        <SkeletonLoader width="100px" height="20px"/>
        <SkeletonLoader width="40px" height="16px"/>
      </div>
      <SkeletonLoader width="40px" height="40px" v-if="!product"/>
      <BaseIconButton
        variant="tertiary"
        size="md"
        icon="arrow-right"
        bgColor="background-base-inverse-primary"
        textColor="content-base-secondary"
        class="card-list-button"
        v-else-if="!extended"
      />
      <BasePushButton
        variant="fill"
        size="md"
        bgColor="background-base-inverse-primary"
        @click="openProduct"
        textColor="content-base-inverse-primary"
        v-else
      >
        {{ $t("common_actions.reserve") }}
      </BasePushButton>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object
    },
    extended: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openProduct () {
      if (this.product) {
        this.$router.push({
          name: 'reserve_menu_product',
          params: {
            product_id: this.product._id
          }
        })
      }
    }
  },
  computed: {
    currency () {
      return this.$store.state.currency
    },
    productImage () {
      if (!this.product) return '/skeleton-image.png'

      if (!this.product.image) {
        return 'https://app.qrwaiter.com.ua/icons/no-dish-exclusive.jpg'
      }

      if (!this.product.image.startsWith('http')) {
        if (window.location.hostname === 'localhost') {
          return `http://localhost:3000${this.product.image}`
        }
      }

      return this.product.image
    },
    firstProductPrice () {
      if (!this.product) return 0

      return (
        this.product.base_price +
        this.product.price_groups
          .filter((pg) => pg.single_select)
          .reduce((acc, pg) => acc + (pg.items[0] || { price: 0 }).price, 0)
      )
    }
  }
}
</script>

<style scoped>
.service-grid-card {
  display: flex;
  flex-direction: column;

  border-radius: 12px;
  background: var(--qrw-background-base-primary);
  width: 280px;
  overflow: hidden;
}

.service-grid-card.extended {
  width: 100%;
}

.service-grid-card .card-image {
  width: 280px;
  height: 160px;
  max-width: 280px;
  min-height: 160px;

  object-fit: cover;
}

.service-grid-card.extended .card-image {
  width: 100%;
  height: 216px;

  object-fit: cover;
  max-width: 100%;
}

.service-info {
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
}

.service-text {
  display: flex;
  flex-direction: column;
}

.card-list-button {
  width: 40px;
  height: 40px;
}

.product-name {
  max-width: 190px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  overflow: hidden;
  box-orient: vertical;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
</style>
