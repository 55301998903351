<template>
  <div class="variant-group">
    <div class="qrw-heading-xs bold qrw-content-base-primary">
      {{ group.name || "Variants" }}
    </div>
    <template v-for="(item, index) in group.items">
      <BaseBetaRadioButton
        v-model="selectedValue"
        :checkedValue="item._id"
        :key="item._id"
      >
        {{ item.name }}
      </BaseBetaRadioButton>
      <BaseDivider
        color="border-base-tertiary"
        :key="`d${item._id}`"
        v-if="index !== group.items.length - 1"
      />
    </template>
  </div>
</template>

<script>
export default {
  data () {
    return {
      selectedValue: 1
    }
  },
  props: {
    value: Array,
    group: Object
  },
  watch: {
    selectedValue () {
      this.$emit('input', [this.selectedValue])
    }
  },
  methods: {},
  mounted () {
    this.$nextTick(() => {
      if (this.value && this.value.length) {
        this.selectedValue = this.value[0]
      }
    })
  }
}
</script>

<style scoped>
.variant-group {
  display: flex;
  flex-direction: column;
  padding: 16px;
  row-gap: 16px;

  align-items: flex-start;
}
</style>
