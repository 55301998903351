var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-split-payment-navbar",class:{
  'filled': !_vm.contentsOnly
}},[(!_vm.contentsOnly)?_c('div',{staticClass:"banner-top",on:{"click":function($event){_vm.extended = !_vm.extended}}},[_c('div',{staticClass:"qrw-label-sm qrw-text-content-base-tertiary"},[_vm._v(" "+_vm._s(_vm.$tc("split_payment.title", _vm.splits.length))+" ")]),_c('BaseIcon',{attrs:{"icon":_vm.extended ? 'x-circle' : 'help-circle',"size":"xs"}})],1):_vm._e(),(_vm.extended || _vm.contentsOnly)?_c('div',{staticClass:"banner-list"},[_vm._l((_vm.splits),function(split,index){return [_c('div',{key:index,staticClass:"banner-list-item",class:{
          'qrw-text-content-base-primary': !!split.paid_at,
          'qrw-text-content-base-tertiary': !split.paid_at,
        }},[_c('div',{staticClass:"gap-column-1xs"},[_c('BaseIcon',{class:{
              'qrw-text-content-success-primary': !!split.paid_at,
            },attrs:{"icon":split.paid_at ? 'check-circle-filled' : 'check-circle',"size":"xs"}}),_c('div',{staticClass:"qrw-label-sm"},[_vm._v(" "+_vm._s(_vm.$t("split_payment.payment"))+" "+_vm._s(index + 1)+" ")])],1),_c('PriceTag',{attrs:{"value":split.total}})],1),(index != _vm.splits.length - 1)?_c('BaseDivider',{key:'div' + index,attrs:{"color":"border-base-tertiary","dashed":""}}):_vm._e()]})],2):_vm._e(),(_vm.extended)?_c('div',{staticClass:"qrw-paragraph-xs qrw-text-content-base-tertiary"},[_vm._v(" "+_vm._s(_vm.$t("split_payment.desc"))+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }