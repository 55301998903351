<template>
  <div class="app-cart-page">
    <div class="qrw-app-top-sticky" style="z-index: 1500;" v-qrw-class-on-scroll="{
      cssClass: 'qrw-app-top-container',
    }" v-qrw-is-header>
      <CartHeader @back="goBackToMenu" @share="sharePage" />
    </div>

    <div class="app-cart-content" v-qrw-under-header v-qrw-above-footer>
      <div class="order-type-block" v-if="!isModeLikeInside">
        <div class="gap-row-xs" v-if="cafe.modes.delivery && cafe.modes.preorder">
          <div class="qrw-heading-xs bold qrw-text-content-base-primary">
            {{ $t("cart.order_type") }}
          </div>

          <BaseSwitcherBar
            style="border-radius:10px; background:var(--qrw-background-base-tertiary); padding: 4px; width: 100%;"
            size="sm" v-model="mode" tabWidth="100%" :tabs="[
              { text: $store.getters.specificModeTitle('delivery'), value: 'delivery' },
              { text: $store.getters.specificModeTitle('takeaway'), value: 'takeaway' },
            ]" />
        </div>

        <div class="gap-row-1xs" v-if="minCheckAmount || totalObject.delivery">
          <div class="gap-column-1xs" v-if="totalObject.delivery">
            <div class="w-100 qrw-label-md qrw-text-content-base-secondary">
              {{ $t("cart.delivery_price") }}
            </div>
            <PriceTag :value="totalObject.delivery" class="qrw-label-md bold qrw-text-content-base-secondary" />
          </div>
          <BaseDivider dashed color="border-base-tertiary" v-if="false" />
          <div class="gap-column-1xs" v-if="minCheckAmount">
            <div class="w-100 qrw-label-md qrw-text-content-base-secondary">
              {{ $t("cart.min_order") }}
            </div>
            <PriceTag :value="minCheckAmount" class="qrw-label-md bold qrw-text-content-base-secondary" />
          </div>
        </div>
      </div>

      <BaseDivider color="border-base-tertiary" v-if="false" />
      <div class="cart-products-wrapper">
        <div class="cart-products-list">
          <div class="qrw-heading-xs bold qrw-text-content-base-primary">
            {{ $t("cart.products") }}
          </div>

          <CartItem :data-testid="`cart-item-${index}`" :item="item" v-for="(item, index) in cartItems"
            :key="item._id" />

        </div>

        <BasePushButton variant="fill" bgColor="background-base-tertiary" textColor="content-base-secondary" size="md"
          icon="plus" data-testid="add-more-button" @click="addMore">
          {{ $t("cart.add_more") }}
        </BasePushButton>
      </div>

      <CartComment v-model="comment" :utensils="utensils" @setUtensils="utensils = $event"
        :askForUtensils="mode === 'delivery'" />
      <DeliveryDetails :value="targetDate" @setContactless="v => isContactless = v" @setTargetDate="v => targetDate = v"
        v-if="mode === 'delivery'" />
      <DeliveryTariff />
      <TakeawayDetails :targetDate="targetDate" @setTargetDate="v => targetDate = v" v-if="mode === 'takeaway'" />
      <CartClientDetails :client="$store.state.delivery.client" :initialFields="$store.state.delivery.client"
        @clear="resetClient" @input="newClient => $store.commit('setDeliveryClient', newClient)"
        v-if="!isModeLikeInside" />
      <BaseDivider color="border-base-tertiary" v-if="isGeolocationVisible" />
      <BaseDivider color="border-base-tertiary" v-if="isModeLikeInside" />
      <ScanCodeBlock noBackground ref="scanCodeBlock" v-if="mode === 'preview'" />
      <VerifyLocationBlock :verified="!!geoVerificationToken" :required="isGeolocationRequired"
        @verify="geoVerificationShown = true" noBackground v-if="isGeolocationVisible" />
    </div>

    <div class="qrw-app-bottom-container qrw-app-bottom-sticky" style="z-index: 1000" v-qrw-is-footer>
      <SingleButtonNavbar :disabled="!canCreateOrder" @click="placeOrder" v-qrw-is-footer :sticky="false"
        :textOverride="uiOrderButtonText" v-if="isModeLikeInside" />
      <OrderPaymentNavbar :makingNewOrder="true" :total="totalObject" :customDisabledText="creationDisabledText"
        @confirm="placeOrder" :disabled="!canCreateOrder" :payMethod="selectedPayMethod"
        @changePayMethod="payMethodModalIsShown = true" v-else />
      <!-- <MainNavbar activeTab="menu" :sticky="false" /> -->
    </div>

    <!-- <PhonePickerModal @close="isTesting = false" v-if="isTesting"/> -->

    <GeoVerificationModal @close="geoVerificationShown = false" @confirmed="(token) => (geoVerificationToken = token)"
      v-if="geoVerificationShown" />

    <ErrorModal :code="errorCode" type="order" @close="errorCode = null" v-if="errorCode" />

    <PhoneVerificationModal :phone="client.phone" @success="token => (phoneVerificationToken = token)"
      @close="onPhoneModalClosed" v-if="verifyingPhone" />

    <OnlinePaymentModal :gateway="pendingOnlinePayment.gateway" :payLink="pendingOnlinePayment.pay_url"
      v-if="pendingOnlinePayment" />

    <PaymentMethodModal :payMethods="modePayMethods" v-model="selectedPayMethod" @close="payMethodModalIsShown = false"
      v-if="payMethodModalIsShown" />

    <PageLoader target="order" v-if="busy" />
  </div>
</template>

<script>
import CartHeader from '@/components/header/CartHeader.vue'

import SingleButtonNavbar from '@/components/navbar/SingleButtonNavbar.vue'

// import PromocodeBlock from '@/components/menu/PromocodeBlock.vue'

import CartItem from '@/components/cart/CartItem.vue'

import ScanCodeBlock from '@/components/menu/ScanCodeBlock.vue'
import VerifyLocationBlock from '@/components/menu/VerifyLocationBlock.vue'

// import PhonePickerModal from '@/components/modals/PhonePickerModal.vue'

import PhoneVerificationModal from '@/components/modals/PhoneVerificationModal.vue'

import GeoVerificationModal from '../components/modals/GeoVerificationModal.vue'

import InsideAPI from '@/api/inside'
import DeliveryAPI from '@/api/delivery'
import ClientsAPI from '@/api/clients'
import Realtime from '@/api/realtime'

import ErrorModal from '@/components/modals/ErrorModal.vue'

import DeliveryDetails from '@/components/delivery/DeliveryDetails.vue'
import TakeawayDetails from '@/components/takeaway/TakeawayDetails.vue'
import CartComment from '@/components/cart/CartComment.vue'
import CartClientDetails from '@/components/cart/CartClientDetails.vue'
import OrderPaymentNavbar from '@/components/navbar/OrderPaymentNavbar.vue'
import OnlinePaymentModal from '@/components/modals/OnlinePaymentModal.vue'
import DeliveryTariff from '@/components/delivery/DeliveryTariff.vue'

import PaymentMethodModal from '@/components/modals/PaymentMethodModal.vue'

import PageLoader from '@/components/common/PageLoader.vue'

// import MainNavbar from '@/components/navbar/MainNavbar.vue'

export default {
  components: {
    CartHeader,
    SingleButtonNavbar,
    CartItem,
    CartComment,
    TakeawayDetails,
    ScanCodeBlock,
    DeliveryTariff,
    // MainNavbar,
    // PromocodeBlock,
    GeoVerificationModal,
    VerifyLocationBlock,
    ErrorModal,
    DeliveryDetails,
    CartClientDetails,
    OrderPaymentNavbar,
    PhoneVerificationModal,
    OnlinePaymentModal,
    PaymentMethodModal,
    PageLoader
    // PhonePickerModal
  },
  data () {
    return {
      comment: null,
      utensils: 0,
      busy: false,

      geoVerificationShown: false,

      geoVerificationToken: null,

      phoneVerificationToken: null,
      verifyingPhone: false,

      selectedPayMethod: 'cash',
      payMethodModalIsShown: false,

      pendingOnlinePayment: null,

      errorCode: null,
      isContactless: false
    }
  },
  methods: {
    sharePage () {
      window.navigator.share({
        title: this.$t('cart.title'),
        url: window.location.href
      })
    },
    addMore () {
      this.$router.push(this.$store.getters.buildRouterNavigateObject('lobby', {}, 'menu'))
    },
    resetClient () {
      this.$store.commit('setDeliveryClient', {
        name: null,
        phone: null,
        _id: null
      })
    },
    onPhoneModalClosed () {
      this.verifyingPhone = false

      if (this.phoneVerificationToken) {
        this.placeOrder()
      }
    },
    async createPreorder () {
      this.busy = true

      try {
        const { order, payment } = await DeliveryAPI.create(this.mixinCafeLinkCode, {
          cart_id: this.$store.state.cart.cartId,
          comment: this.comment,
          utensils: this.utensils,
          is_delivery: this.mode === 'delivery',
          target_date: this.targetDate,
          address: this.$store.state.delivery.address,
          pay_method: this.selectedPayMethod,
          client_id: this.client._id,
          is_contactless: this.isContactless,
          visitor_id: window.localStorage.getItem('visitor_id'),
          delivery_tariff_id: this.$store.state.delivery.tariff
        })

        this.pendingOnlinePayment = payment

        this.$store.dispatch('cart/clearCart')

        this.$store.commit('setDeliveryTargetDate', null)

        if (!this.pendingOnlinePayment) {
          Realtime.listenPreorder(order._id)

          this.$router.push(
            this.$store.getters.buildRouterNavigateObject('receipt', {
              order_id: order._id
            })
          )
        }
      } catch (err) {
        this.errorCode = err.message || err
      }

      this.busy = false
    },
    async verifyClientForPreorder () {
      if (!this.client._id && this.cafe.client_app.verify_client_phone && !this.phoneVerificationToken) {
        this.verifyingPhone = true
        return
      }

      this.busy = true

      try {
        const { client } = await ClientsAPI.create({
          ...this.client,
          verificationToken: this.phoneVerificationToken,
          cafeId: this.cafe._id,
          address: this.$store.state.delivery.address || null,
          clientId: this.client._id || null
        })

        this.$store.commit('setDeliveryClient', client)

        window.localStorage.setItem('client_id', client._id)
      } catch (err) {
        this.errorCode = `ECLFAILED: ${err.message || err}`
      }

      await this.createPreorder()
    },
    async createInsideOrder () {
      this.busy = true

      try {
        let order = null

        if (!this.$store.state.order) {
          const response = await InsideAPI.createOrder(
            this.$store.state.inside.access_code,
            this.$store.state.cart.cartId,
            this.geoVerificationToken,
            this.comment,
            window.localStorage.getItem('visitor_id')
          )

          order = response.order

          Realtime.listen(order._id)
        } else {
          const response = await InsideAPI.appendOrder(
            this.$store.state.order._id,
            this.$store.state.cart.cartId,
            this.comment,
            window.localStorage.getItem('visitor_id')
          )

          order = response.order
        }

        this.$store.commit('setOrder', order)

        this.$store.dispatch('cart/clearCart')

        if (order.status === 'waiting') {
          this.$router.push(this.$store.getters.buildRouterNavigateObject('lobby'))
        } else {
          this.$router.push(this.$store.getters.buildRouterNavigateObject('order'))
        }
      } catch (err) {
        this.errorCode = err.message || err
      }

      this.busy = false
    },
    placeOrder () {
      if (this.mode === 'preview') {
        this.$refs.scanCodeBlock.showModal()
        return
      }

      if (this.mode === 'inside') {
        if (this.isGeolocationRequired && !this.geoVerificationToken) {
          this.geoVerificationShown = true
          return
        }

        this.createInsideOrder()
      } else {
        this.verifyClientForPreorder()
      }
    },
    goBackToMenu () {
      this.$router.push(this.$store.getters.buildRouterNavigateObject('lobby', {}, 'menu'))
    }
  },
  computed: {
    cafe () {
      return this.$store.state.cafe
    },
    client () {
      return this.$store.state.delivery.client
    },
    modeTitle () {
      return this.$store.getters.modeTitle
    },
    currency () {
      return this.$store.state.currency
    },
    modeSettings () {
      return this.mode === 'takeaway' ? this.cafe.preorder : this.cafe.delivery
    },
    cartTotal () {
      return this.$store.getters['cart/total']
    },
    cartItems () {
      return this.$store.getters['cart/items']
    },
    uiOrderButtonText () {
      return `${this.$t('cart.place_order')} (${this.cartTotal}${this.currency.symbol})`
    },
    isGeolocationRequired () {
      return this.mode === 'inside' && this.cafe.geolocation_mode === 'general'
    },
    isGeolocationVisible () {
      return this.mode === 'inside' && this.cafe.geolocation_mode !== 'disabled'
    },
    isModeLikeInside () {
      return this.mode === 'inside' || this.mode === 'preview'
    },
    totalObject () {
      const obj = {
        sum: this.cartTotal
      }

      const deliveryPrice = this.$store.getters.deliveryPrice

      if (this.mode === 'delivery' && deliveryPrice) {
        obj.delivery = deliveryPrice
      }

      obj.total = obj.sum + (obj.delivery || 0)

      return obj
    },
    mode: {
      get () {
        return this.$store.state.mode
      },
      set (v) {
        this.$router.replace({
          name: 'preorder_cart',
          params: {
            cafe_link: this.mixinCafeLinkCode,
            mode: v
          }
        })
        this.$store.commit('setMode', v)
      }
    },
    minCheckAmount () {
      return this.mode === 'inside' ? 0 : this.modeSettings.min_check || 0
    },
    canCreateOrder () {
      if (!this.cartItems.length) return false
      if (this.busy) return false

      if (this.mode === 'delivery' || this.mode === 'takeaway') {
        if (!this.client.phone) return false
        if (this.minCheckAmount > this.cartTotal) return false

        if (this.mode === 'delivery') {
          if (!this.$store.state.delivery.address) return false
        }

        if (this.$store.getters.isCafeClosed && !this.targetDate) return false

        if (this.cartItems.find(item => !this.$store.state.menus.some(menu => menu._id === item.menu_id))) return false

        // if (!this.client.email || !this.client.email.includes('@')) return false
      }

      if (this.mode === 'preview') return false

      return true
    },
    creationDisabledText () {
      if (this.canCreateOrder) return ''

      if (this.$store.state.delivery.address && !this.$store.state.delivery.address.is_reachable) {
        return this.$t('order_payment_navbar.out_of_range')
      }

      return this.$t('order_payment_navbar.fill_in')
    },
    modePayMethods () {
      if (this.isModeLikeInside) return this.cafe.pay_methods
      return this.mode === 'delivery' ? this.cafe.delivery.pay_methods : this.cafe.preorder.pay_methods
    },
    targetDate: {
      get () {
        return this.$store.state.delivery.targetDate
      },
      set (v) {
        this.$store.commit('setDeliveryTargetDate', v)
      }
    }
  },
  mounted () {
    if (!this.isModeLikeInside) {
      this.selectedPayMethod = ['cash', 'card', 'online'].find(k => this.modePayMethods[k])
    }
  }
}
</script>

<style scoped>
.app-cart-page {
  display: flex;
  flex-direction: column;
}

.order-type-block {
  display: flex;
  flex-direction: column;
  padding: 24px 16px 16px 16px;
  row-gap: 16px;
}

.cart-products-list {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.cart-comment-block {
  display: flex;
  flex-direction: column;
  padding: 16px;
  row-gap: 16px;
}

.cart-products-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 16px;
}
</style>
