<template>
  <div class="app-takeaway-details-wrapper">
    <div class="app-takeaway-details">
      <div class="qrw-label-lg bold qrw-text-content-base-primary">
        {{ $t('takeaway_details.title') }}
      </div>
      <div
        class="time-container"
        @click="timeModalShown = true"
      >
        <div class="details-icon">
          <BaseIcon
            icon="clock"
            size="sm2"
            class="qrw-text-content-base-primary"
          />
        </div>
        <div class="gap-row-2xs w-100">
          <div class="qrw-label-md qrw-text-content-base-primary">
            {{ $t('takeaway_details.takeaway_in') }} {{estimatedTakeawayTime}} {{$t('units.min')}}
          </div>
          <div class="gap-column-zero qrw-text-content-base-secondary">
            <div class="qrw-label-sm">
              {{ targetDate ?  $t('order_time_modal.schedule_short') : $t('order_time_modal.asap') }}
            </div>
            <BaseIcon
              icon="dot"
              size="xs"
            />
            <div class="qrw-label-sm">
              {{ estimatedTakeawayDate }}
            </div>
          </div>
        </div>
        <BaseIconButton
          variant="ghost"
          icon="chevron-right"
          size="md"
          textColor="content-base-secondary"
        />
      </div>
      <BaseDivider color="border-base-tertiary" />
      <MapFrame
        style="height: 240px"
        :location="cafe.address_location"
        markerIcon="map-cafe-marker"
      />
      <div class="gap-row-1xs">
        <div class="qrw-label-md bold qrw-text-content-base-primary">
          {{cafe.name}}
        </div>
        <div class="qrw-label-sm qrw-text-content-base-secondary">
          {{cafe.address}}
        </div>
      </div>
    </div>

    <OrderTimeModal
      :value="targetDate"
      @input="targetDate = $event"
      @close="timeModalShown = false"
      v-if="timeModalShown"
    />
  </div>

</template>

<script>
import MapFrame from '@/components/common/MapFrame.vue'

import OrderTimeModal from '@/components/modals/OrderTimeModal.vue'

import moment from 'moment'

export default {
  data() {
    return {
      timeModalShown: false
    }
  },
  components: {
    MapFrame,
    OrderTimeModal
  },
  computed: {
    cafe() {
      return this.$store.state.cafe
    },
    estimatedTakeawayTime() {
      return this.cafe.preorder.min_order_time || 10
    },
    estimatedTakeawayDate() {
      if (this.targetDate) {
        if (moment(this.targetDate).isSame(moment(), 'day')) {
          return this.$t('order_time_modal.today') + ' ' + moment(this.targetDate).format('HH:mm')
        }
        if (moment(this.targetDate).isSame(moment().add(1, 'day'), 'day')) {
          return this.$t('order_time_modal.tomorrow') + ' ' + moment(this.targetDate).format('HH:mm')
        }
        return moment(this.targetDate).format('ddd DD.MM, HH:mm')
      }
      return moment().add(this.estimatedTakeawayTime, 'minutes').format('HH:mm')
    },
    targetDate: {
      get() {
        return this.$store.state.delivery.targetDate
      },
      set(v) {
        this.$store.commit('setDeliveryTargetDate', v)
      }
    }
  }
}
</script>

<style scoped>
.app-takeaway-details-wrapper {
  padding: 12px 16px;
}

.app-takeaway-details {
  padding: 16px;
  border-radius: 12px;

  box-shadow: var(--qrw-shadow-card-light);

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  background: var(--qrw-background-base-primary-10);
}

.time-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 12px;
  width: 100%;
  box-sizing: border-box;
}

.details-icon {
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  box-sizing: border-box;
  background: var(--qrw-background-base-tertiary);
}

.adress-map-frame {
  height: 240px;
  min-height: 240px;
}

.courier-instructions {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  background: var(--qrw-background-base-tertiary);
  padding: 12px 16px;
  border-radius: 12px;
}
</style>
