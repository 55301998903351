var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-order-payment-navbar",class:{
    expanded: _vm.isExpanded,
  }},[(!_vm.isExpanded)?_c('BaseDivider',{attrs:{"color":"border-base-tertiary"}}):_vm._e(),(!_vm.canPay)?_c('div',{staticClass:"payment-none qrw-label-sm"},[_vm._v(" "+_vm._s(_vm.customDisabledText || _vm.$t("order_payment_navbar.none_selected"))+" ")]):_c('div',{staticClass:"payment-available",class:{
      'with-splits': _vm.hasSplitPayment
    },on:{"click":_vm.toggleExpandedUI}},[(_vm.hasAdditionalTotalItems)?_c('BaseIcon',{staticClass:"expand-icon",attrs:{"icon":_vm.isExpanded ? 'minimize-03' : 'expand-03',"size":"sm2"}}):_vm._e(),_c('div',{staticClass:"gap-row-txs w-100"},[_c('GenericTotal',{attrs:{"collapsed":!_vm.isExpanded,"alreadyPaid":!_vm.makingNewOrder,"value":{
            sum: _vm.total.sum,
            tip: _vm.total.tip,
            delivery_fee: _vm.total.delivery,
            total: _vm.total.total
          }}})],1)],1),(_vm.hasSplitPayment)?_c('SplitPaymentBanner',{attrs:{"splits":_vm.splits}}):_vm._e(),_c('div',{staticClass:"payment-slider-container"},[_c('div',{ref:"slider",staticClass:"payment-slider",class:{ active: _vm.canPay }},[_c('div',{staticClass:"qrw-label-md"},[_vm._v(" "+_vm._s(_vm.canPay ? _vm.$t("pay_methods.pay_with_"+_vm.payMethod) : _vm.$t("order_payment_navbar.slider_disabled"))+" "),(_vm.hasSplitPayment)?[_vm._v(" "+_vm._s(_vm.paidSplitsCount+1)+"/"+_vm._s(_vm.splits.length)+" ")]:_vm._e()],2),_c('div',{staticClass:"slider-control",style:(_vm.sliderControlStyle),on:{"touchstart":_vm.startSliderMoving,"mousedown":_vm.startSliderMoving,"mouseup":_vm.stopSliderMoving}},[_c('BaseIcon',{attrs:{"icon":"chevron-right-double","size":"sm2"}})],1)]),_c('BaseIconButton',{staticClass:"pay-method-button",attrs:{"size":"lg","variant":"outlined","borderColor":"border-base-tertiary","icon":_vm.payMethodIcon},on:{"click":function($event){return _vm.$emit('changePayMethod')}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }