<template>
  <div class="qrw-price-tag" :style="{
    'display': 'flex',
    'column-gap': spacing + 'px',
  }">
    <span class="price-tag-prefix" v-if="prefix">{{ prefix }}</span>

    <span class="price-tag-value">{{ value | formatMoney }}</span>

    <span class="price-tag-currency">{{ currency.symbol }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: [Number, String],
    spacing: {
      type: Number,
      default: 2
    },
    prefix: String
  },
  computed: {
    currency () {
      return this.$store.state.currency
    }
  }
}
</script>

<style>

</style>
