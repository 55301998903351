<template>
  <div class="desktop-blocker">
    <div class="left">
      <img
        src="https://cdn.qrwaiter.com.ua/assets/logo-v2.svg"
        class="logo"
      />
      <div class="gap-row-xs">
        <div class="qrw-label-lg qrw-text-content-base-primary">
          {{ $t('desktop_blocker.title')}}
        </div>
        <div class="qrw-label-md qrw-text-content-base-secondary">
          {{ $t('desktop_blocker.description')}}
        </div>
      </div>

    </div>

    <img
      :src="qrSrc"
      class="qr-code"
    >
  </div>
</template>

<script>
export default {
  computed: {
    qrSrc() {
      return `https://api.qrserver.com/v1/create-qr-code/?size=256x256&data=${window.location.href}`
    }
  }
}
</script>

<style>
.desktop-blocker {
  width: 100vw;
  height: 100vh;

  background: var(--qrw-background-base-primary);

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: row;
  column-gap: 64px;

  position: fixed;
  top: 0;
  left: 0;

  z-index: 10000;
}

.qr-code {
  width: 256px;
  height: 256px;
  border-radius: 2px;
  max-width: 256px;
  max-height: 256px;
}

.left {
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  row-gap: 32px;
}

.logo {
  width: 64px;
  height: 64px;
  max-width: 64px;
  max-height: 64px;
}
</style>
