<template>
  <div class="gap-row-1xs days-list">
    <template v-for="day in weekdayKeys">
      <BaseDivider
        v-if="day != 'mon'"
        color="border-base-tertiary"
        :key="`d-${day}`"
      />
      <div
        class="gap-row-1xs"
        :key="day"
      >
        <div
          class="schedule-row"
          :class="{
                today: todayWeekdayKey === day,
              }"
        >

          <div
            class="qrw-label-md"
            :class="{
                  'bold today': todayWeekdayKey === day
                }"
          >
            <div
              class="schedule-dot-badge"
              v-if="todayWeekdayKey === day"
            ></div>
            {{ $t("schedule.weekdays." + day) }}
          </div>
          <div
            class="gap-column-xs schedule-time-block"
            v-if="schedule[day].workday"
          >
            <div class="schedule-time-cell left qrw-label-md">
              {{
                    formatTime(
                      schedule[day].startHour,
                      schedule[day].startMinute
                    )
                  }}
            </div>
            <div class="qrw-label-md right schedule-time-cell">
              {{
                    formatTime(schedule[day].endHour, schedule[day].endMinute)
                  }}
            </div>
          </div>
          <div
            class="qrw-text-content-error-primary day-off qrw-label-md"
            v-else
          >
            {{ $t("schedule.day_off") }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { weekDayToWeekdayKey, getEuropeanWeekDaysKeys } from '@/utils'

import moment from 'moment'

export default {
  props: {
    schedule: Object
  },
  computed: {
    weekdayKeys() {
      return getEuropeanWeekDaysKeys()
    },
    todayWeekdayKey() {
      return weekDayToWeekdayKey(moment().day())
    }
  },
  methods: {
    formatTime(h, m) {
      return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`
    }
  }
}
</script>

<style scoped>
.schedule-row {
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  padding: 8px 16px;
  color: var(--qrw-content-base-secondary);
  align-items: center;
}

.schedule-row.today {
  color: var(--qrw-content-base-primary);
}

.schedule-time-cell {
  width: 40px;
  max-width: 40px;
  min-width: 40px;
  align-items: center;
  display: flex;
}

.schedule-time-cell.left {
  justify-content: flex-end;
}

.schedule-time-cell.right {
  justify-content: flex-end;
}

.schedule-time-block {
  align-items: center;
}

.schedule-dot-badge {
  width: 4px;
  height: 4px;

  /* transform: translateX(-12px); */

  background: var(--qrw-background-error-default);
  border-radius: 50%;
}

.days-list {
  overflow: hidden;
}

.day-off {
  display: flex;
  align-items: center;
}

.today {
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding-left: 2px;
}
</style>
