export default {
  home: {
    about: "Par mums",
    address: "Adrese",
    socials: "Sociālie tīkli",
    website: "Mājas lapa",
    get_directions: "Palūgt norādes",
  },
  contacts: {
    title: "Kontakti",
    phone: "Tālrunis",
    mail: "E-pasts",
    website: "Mājaslapu",
    visit: "Apmeklēt",
    call: "Zvanīt",
    copy: "Kopēt",
  },
  schedule: {
    weekdays: {
      mon: "Pirmdiena",
      tue: "Otrdiena",
      wed: "Trešdiena",
      thu: "Ceturtdiena",
      fri: "Piektdiena",
      sat: "Sestdiena",
      sun: "Svētdiena",
    },
    open_now: "Atvērts",
    closes_in: "Aizvērsies pēc",
    closed_now: "Slēgts",
    opens_in: "Atvērsies pēc",
    day_off: "Brīvdiena",
    schedule: "Grafiks",
  },
  units: {
    hours: "h.",
    minutes: "m.",
    positions: "0 pozīcijas | 1 pozīcija | {count} pozīcijas",
    items: "0 priekšmeti | 1 priekšmets | {count} priekšmeti",
    orders: "0 pasūtījumi | 1 pasūtījums | {count} pasūtījumi",
    g: "g",
    kg: "kg",
    l: "l",
    ml: "ml",
    min: "min",
  },
  legals: {
    privacy_policy: "Privātuma politika",
    cookies: "Sīkfaili",
    terms_of_use: "Lietošanas noteikumi",
    public_offert: "Publiskais piedāvājums",
    view: "Skatīt",
  },
  reviews: {
    home_title: "Atsauksmes",
    max_value: "No 5",
  },
  menu_grid: {
    list: "Saraksts",
  },
  product: {
    description: "Apraksts",
  },
  common_actions: {
    confirm: "Apstiprināt",
    order: "Pasūtīt",
    cancel: "Atcelt",
    reserve: "Rezervēt",
    next: "Nākamais",
    pay: "Maksāt",
    search: "Meklēt",
    view_cart: "Apskatīt grozu",
    add: "Pievienot",
    remove: "Noņemt",
    close: "Aizvērt",
    view: "Skatīt",
  },
  reserve: {
    persons: "Personas",
    reservation: "Rezervēšanas",
    thanks_1: "Paldies!",
    thanks_2: "Jūsu rezervācija ir veikta",
    date: "Datums",
    time: "Laiks",
    download_pdf: "Lejupielādēt PDF",
    add_to_calendar: "Pievienot Kalendāram",
    deposit: "Depozīts",
    paid: "Samaksāts",
  },
  checkout: {
    contact_details: "Kontaktinformācija",
    confirmation: "Apstiprinājums",
    name: "Vārds",
    first_name: "Vārds",
    last_name: "Uzvārds",
    phone: "Tālrunis",
    email: "E-pasts",
    comment: "Komentārs",
    contact: "Kontakti",
    service: "Serviss",
    date: "Datums",
    time: "Laiks",
  },
  promocode: {
    title: "Vai Tev ir promo kods?",
    subtitle: "Ievadi to zemāk",
    placeholder: "Ievadi promo",
  },
  total: {
    subtotal: "Kopsumma",
    discount: "Atlaide",
    service_fee: "Servisa maksa",
    delivery_fee: "Piegādes maksa",
    products_total: "Produktu kopsumma",
    tip: "Nosaukums",
    total: "Kopsumma",
  },
  comment: {
    title: "Jūsu piezīme",
  },
  phone_verify_modal: {
    title: "Telefona numura verifikācija",
    enter: "Ievadiet kodu no SMS",
    sent_to: "Kods nosūtīts uz ",
    no_code: "Nesaņēmāt kodu?",
    resend_btn: "Nosūtīt vēlreiz",
    error: "Nederīgs kods",
    success: "Verificēts",
  },
  online_payment_modal: {
    redirect: "Jūs tiksiet novirzīti uz maksājumu lapu",
    btn_pay: "Turpināt uz maksājumu",
    btn_cancel: "Atcelt",
    redirecting: "Novirzīšana uz maksājumu lapu...",
  },
  pay_methods: {
    cash: "Nauda",
    card: "Karte",
    online: "Tiešsaistē",
    pay_with_cash: "Maksāt ar naudu",
    pay_with_card: "Maksāt ar karti",
    pay_with_online: "Maksāt tiešsaistē",
  },
  validations: {
    required: "Nepieciešamais lauks",
    phone: "Ievadiet pareizu tālruņa numuru",
    email: "Ievadiet derīgu e-pasta adresi",
  },
  calendar: {
    date: "Datums",
  },
  phone_country_picker: {
    title: "Izvēlēties tālruņa reģionu",
    placeholder: "Meklēt pēc valsts nosaukuma...",
    select: "Izvēlēties",
  },
  menu_lobby: {
    welcome: "Sveiciens",
    select_type: "Lūdzu, izvēlieties savu pasūtījuma veidu",
    search_ph: "Valdi, ēdienkartes, kategorijas...",
    our_menus: "Mūsu ēdienkartes",
  },
  call_waiter: {
    title: "Vai nepieciešama palīdzība?",
    subtitle: "Zvanīt pavēlniekam, lai saņemtu palīdzību",
    button: "Zvanīt pavēlniekam",
    sent: "Jūsu pieprasījums ir nosūtīts",
  },
  scan_qrcode: {
    title: "Savieno galdu",
    subtitle: "Noskenē QR-kodu pie galda",
    button: "Noskanēt",
  },
  verify_location: {
    title: "Ģeo-atrašanās vieta",
    subtitle: "Mums ir jāpārbauda jūsu atrašanās vieta",
    button: "Pārbaudīt",
    verified: "Pārbaudīts",
  },
  menu_product: {
    badge_spicy: "Pikants",
    badge_veggie: "Vegetārijs",
    badge_alcohol: "Alkohols",
    variants: "Varianti",
    modificators: "Modifikatori",
    variants_single: "Norādiet vismaz vienu no dotajiem variantiem",
    variants_min: "Min",
    variants_max: "Max",
    required: "Nepieciešams",
    modificators_any: "Atļaut jebkuru skaitu no dotajiem modifikatoriem",
    comment_title: "Jūsu komentārs",
    comment_ph: "Ierakstiet šeit...",
    show_more: "Rādīt vairāk",
    show_less: "Rādīt mazāk",
  },
  cart: {
    title: "Grozs",
    order_type: "Pasūtījuma veids",
    delivery_price: "Piegādes cena",
    min_order: "Minimālais pasūtījuma summa",
    place_order: "Veikt pasūtījumu",
    products: "Preces",
    unavailable_item: "Šis produkts šajā sistēmā nav pieejams",
    add_more: "Pasūtīt vairāk no ēdienkartes",
    comment_title: "Īpašas prasības",
    comment_desc:
      "Vai ir kādas īpašas prasības, alerģijas vai uztura ierobežojumi?",
    comment_ph: "Ieraksti šeit...",
    alcohol_alert:
      "Apstiprinu, ka esmu 21+ gadus vecs un varu pasūtīt alkoholu saturošus priekšmetus",
  },
  geo_verification: {
    title: "Ģeogrāfiskās pārbaudes",
    desc: "Mums ir nepieciešams pārbaudīt jūsu ģeolokāciju, lai turpinātu pasūtījumu",
    please: "Lūdzu, nodrošiniet piekļuvi jūsu ģeolokācijai",
    verify: "Apstiprināt manu ģeolokāciju",
    dismiss: "Atmest",
    deny_title: "Šķiet, ka jūs noliedzat piekļuvi savai vietai",
    deny_subtitle: "Lūdzu, pārlādējiet lapu un mēģiniet vēlreiz",
    reload: "Pārlādēt lapu",
    busy: "Pārbauda jūsu ģeolokāciju...",
    confirmed: "Vieta apstiprināta",
    out_of_range: "Pakalpojums jūsu vietā nav pieejams",
  },
  order_product_card: {
    product_total: "Produkta kopsumma",
    remains: "Atlicis:",
  },
  order_page: {
    title: "Pašreizējā pasūtījums",
    your_bill: "Jūsu rēķins",
    select_all: "Atzīmēt visu",
  },
  order_payment_navbar: {
    none_selected: "Izvēlēties produktus, lai veiktu maksājumu",
    slider_disabled: "Maksāt",
    slider_active: "Pārslīdēt, lai maksātu",
    total_to_pay: "Kopā jāmaksā",
    including_fees: "Iekļaujot maksas",
    left_unpaid: "atstāts neapmaksāts",
  },
  pay_method_modal: {
    title: "Maksājuma veids",
    subtitle: "Izvēlieties, kā Jūs vēlētos samaksāt",
    descriptions: {
      cash: "Maksājiet skaidrā naudā vietā",
      card: "Maksājiet ar karti vietā",
      online: "Maksājiet tiešsaistē",
    },
  },
  leave_tips: {
    title: "Jūs varat atstāt apalvojumu, ja vēlaties",
    thank_you: "Paldies!",
  },
  personal_tips: {
    title: "Atstājiet padomu",
    role_ph: "Waiter",
  },
  receipt: {
    total_paid: "Kopā maksāts",
    including_taxes: "Iekļaujot nodokļus",
    service_provider: "Pakalpojuma sniedzējs",
    tech_details: "Tehniskās detaļas",
    order_number: "Pasūtījuma numurs",
    order_id: "Pasūtījuma ID",
    date_and_time: "Datums un laiks",
  },
  payment_details: {
    title: "Maksājuma detaļas",
    refunded: "Atmaksāts",
    paid: "Apmaksāts",
    unpaid: "Nepieskaitīts",
  },
  archive: {
    active_order: "Pašreizējā pasūtījums",
    archived_order: "Arhivētais pasūtījums",
    empty_title: "Vēl nekas nav",
    open_menu: "Atvērt izvēlni",
    empty_subtitle:
      "Jūs varat pasūtīt no ēdienkartes. <br> Jūsu pasūtījums parādīsies šeit pēc tam, kad kafejnīca to apstiprinās.",
    title: "Pasūtījumi",
    subtitle_1: "Šeit varat atrast savus aktīvos un arhivētos pasūtījumus",
  },
  main_navbar: {
    menu: "Izvēlne",
    orders: "Pasūtījumi",
    home: "Mājas",
  },
  error_modal: {
    title_generic: "Kaut kas nogāja greizi",
    title_payment: "Maksājums neizdevās",
    title_order: "Nav iespējams veidot pasūtījumu",
    title_not_found: "Lapa nepastāv",
    error_code: "Kļūdas kods",
    please_reload: "Lūdzu, pārlādējiet lapu un mēģiniet vēlreiz",
    btn_back: "Atpakaļ",
    btn_reload: "Pārlādēt",
    btn_home: "Mājas",
  },
  split_payment: {
    title: "Šis rēķins tiks izdalīts {n} daļās",
    payment: "Maksājums",
    desc: "Mēs turēsim naudu, līdz ir veiktas visas maksājumi. Ja visi maksājumi netiek veikti 15 minūšu laikā no pirmā maksājuma laika, visas naudas tiek automātiski atgrieztas.",
  },
  pending_order: {
    title: "Gaidošais pasūtījums",
    your_order: "Jūsu pasūtījums",
    pending: "Gaida",
    declined: "Atcelts",
    desc: "Jūsu pasūtījums ir nosūtīts un gaida apstiprinājumu. Reizē, kad tas tiks apstiprināts, js redzēsit to aktīvu iekš “Pasūtījumi” lapas.",
  },
  cancelled_modal: {
    title: "Jūsu pasūtījums ir atcelts",
    reason: "Iemesls",
    your_order: "Jūsu pasūtījums",
  },
};
