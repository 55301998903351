<template>
  <div
    class="app-archive-header"
    v-qrw-class-on-scroll="{
      cssClass: 'collapsed',
      limit: 32,
    }"
  >
    <div class="bold qrw-text-content-base-primary qrw-transition-animation-sm" :class="{
        'qrw-heading-sm': scrollPos <= 32,
        'qrw-label-lg': scrollPos > 32,
    }">
      {{ $t("archive.title") }}
    </div>
    <div class="qrw-text-content-base-secondary qrw-transition-animation-sm" :class="{
        'qrw-label-md': scrollPos <= 32,
        'qrw-label-xs': scrollPos > 32,
    }">
      {{ scrollPos > 32 ? $tc('units.orders', count) : $t("archive.subtitle_1") }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    count: Number
  },
  data () {
    return {
      scrollPos: 0
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      this.scrollPos = window.document.documentElement.scrollTop
    }
  }
}
</script>

<style scoped>
.app-archive-header {
  width: 100%;

  padding: 16px;
  row-gap: 8px;

  justify-items: center;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;
}

.app-archive-header.collapsed {
  padding: 12px 16px 10px 16px;
  row-gap: 4px;
}
</style>
