var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-dish-variants-group"},[_c('div',{staticClass:"gap-row-1xs"},[_c('div',{staticClass:"gap-column-xs group-header"},[_c('div',{staticClass:"qrw-label-md bold qrw-text-content-base-primary w-100"},[_vm._v(" "+_vm._s(_vm.groupName || _vm.$t("menu_product.variants"))+" ")]),_c('div',{staticClass:"required-badge-container"},[_c('BaseLabelBadge',{attrs:{"variant":"filled","size":"xs","primaryColor":"background-warning-tertiary","secondaryColor":"content-warning-primary"}},[_vm._v(" "+_vm._s(_vm.$t("menu_product.required"))+" ")])],1)]),(_vm.variantsHints && _vm.variantsHints.length == 1)?_c('div',{staticClass:"qrw-label-sm qrw-text-content-base-tertiary"},[_vm._v(" "+_vm._s(_vm.variantsHints[0])+" ")]):(_vm.variantsHints.length > 1)?_c('div',{staticClass:"gap-column-3xs"},[_c('div',{staticClass:"qrw-label-sm qrw-text-content-base-tertiary"},[_vm._v(" "+_vm._s(_vm.variantsHints[0])+" ")]),_c('BaseIcon',{staticClass:"qrw-text-content-base-tertiary",attrs:{"size":"xs","icon":"dot"}}),_c('div',{staticClass:"qrw-label-sm qrw-text-content-base-tertiary"},[_vm._v(" "+_vm._s(_vm.variantsHints[1])+" ")])],1):_vm._e()]),_c('div',{staticClass:"gap-row-txs"},_vm._l((_vm.group.items),function(item,index){return _c('div',{key:index,staticClass:"variant-item",class:{
        [_vm.getStateForItem(item)]: true,
      },on:{"click":function($event){return _vm.onItemClicked(item)}}},[(_vm.canSelectOnlySingleItem)?_c('BaseBetaRadioButton',{attrs:{"size":"lg","value":_vm.getStateForItem(item) === 'active'}}):_c('BaseBetaCheckbox',{attrs:{"size":"lg","value":_vm.getStateForItem(item) === 'active'}}),_c('div',{staticClass:"gap-row-2xs variant-name-weight w-100"},[_c('div',{staticClass:"qrw-label-sm qrw-text-content-base-secondary",class:{
            'bold qrw-text-content-base-primary':
              _vm.getStateForItem(item) === 'active',
          }},[_vm._v(" "+_vm._s(_vm.groupItemNames[index])+" ")]),(item.weight)?_c('div',{staticClass:"qrw-label-sm",class:{
            'qrw-text-content-base-secondary':
              _vm.getStateForItem(item) === 'active',
            'qrw-text-content-base-tertiary':
              _vm.getStateForItem(item) !== 'active',
          }},[_vm._v(" "+_vm._s(item.weight)+" "+_vm._s(_vm.$t("units." + _vm.product.unit))+" ")]):_vm._e()]),(item.price)?_c('div',{staticClass:"qrw-label-sm variant-price qrw-text-content-base-tertiary gap-column-2xs",class:{
          'qrw-text-content-base-primary': _vm.getStateForItem(item) === 'active',
        }},[_vm._v(" + "),_c('PriceTag',{attrs:{"value":item.price}})],1):_vm._e()],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }