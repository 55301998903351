<template>
  <div class="app-bill-order-card">
    <div class="bill-card-header">
      <div class="gap-row-2xs w-100">
        <div class="qrw-label-sm qrw-text-content-base-tertiary">
          {{ product.category }}
        </div>
        <div class="qrw-label-md qrw-text-content-base-primary">
          {{ product.name }}
        </div>
      </div>
      <div class="qrw-label-sm bold price-tag qrw-text-content-base-tertiary">
        {{ product.price}} {{ currency.symbol }}
      </div>
    </div>
    <BaseDivider
      dashed
      color="border-base-tertiary"
      v-if="modificatorGroups.length"
    />
    <template v-for="(group, index) in modificatorGroups">
      <div
        class="gap-row-2xs"
        :key="index"
      >
        <div class="qrw-text-content-base-tertiary qrw-label-xs">
          {{ group.name }}
        </div>
        <div
          class="gap-column-xs"
          v-for="(item, item_index) in group.items"
          :key="item_index"
        >
          <div class="qrw-text-content-base-primary qrw-label-sm w-100">
            {{ item.name }}
          </div>
          <div class="qrw-label-sm qrw-text-content-base-tertiary price-tag">
            +{{ item.price }} {{ currency.symbol }}
          </div>
        </div>
      </div>
      <BaseDivider
        color="border-base-tertiary"
        dashed
        :key="`divider_${index}`"
      />
    </template>
    <div
      class="gap-column-xs"
      v-if="modificatorGroups.length"
    >
      <div class="qrw-label-sm bold qrw-text-content-base-tertiary w-100">
        {{ $t("order_product_card.product_total") }}
      </div>
      <PriceTag
        :value="product.total"
        class="qrw-label-sm bold qrw-text-content-base-tertiary price-tag"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: Object
  },
  computed: {
    currency() {
      return this.$store.state.currency
    },
    // TODO: move to store
    modificatorGroups() {
      const combined = [
        ...this.product.variants.map(it => ({ ...it, from: 'variants' })),
        ...this.product.modificators.map(it => ({
          ...it,
          from: 'modificators'
        }))
      ]

      return Object.entries(
        combined.reduce((acc, item) => {
          const key = item.group_name || (item.from === 'variants' ? 'Variants' : 'Additions')

          acc[key] = acc[key] || []
          acc[key].push(item)

          return acc
        }, {})
      ).map(entry => ({
        name: entry[0],
        items: entry[1]
      }))
    }
  }
}
</script>

<style scoped>
.app-bill-order-card {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 16px;
  background: var(--qrw-background-base-primary-10);
}

.bill-card-header {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: flex-end;
  justify-content: space-between;
}

.price-tag {
  white-space: nowrap;
  width: fit-content;
}
</style>
