<template>
  <div
    class="scan-qrcode-block"
    :class="{
      'with-background': !noBackground,
    }"
  >
    <div class="gap-row-1xs w-100">
      <div class="qrw-label-md bold qrw-text-content-base-primary">
        {{ $t("scan_qrcode.title") }}
        <span class="qrw-text-content-error-primary">*</span>
      </div>
      <div class="qrw-paragraph-md qrw-text-content-base-secondary">
        {{ $t("scan_qrcode.subtitle") }}
      </div>
    </div>
    <BasePushButton
      class="cta-button"
      icon="scan"
      borderColor="border-base-secondary"
      variant="outlined"
      size="md"
      textColor="content-base-primary"
      @click="modalShown = true"
    >
      {{ $t("scan_qrcode.button") }}
    </BasePushButton>

    <ScanQRModal
      v-if="modalShown"
      @close="modalShown = false"
    />
  </div>
</template>

<script>
import ScanQRModal from '@/components/modals/ScanQRModal.vue'

export default {
  props: {
    noBackground: Boolean
  },
  data() {
    return {
      modalShown: false
    }
  },
  methods: {
    showModal() {
      this.modalShown = true
    }
  },
  components: {
    ScanQRModal
  }
}
</script>

<style scoped>
.scan-qrcode-block {
  display: flex;
  padding: 16px;
  flex-direction: row;
  column-gap: 16px;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
}

.scan-qrcode-block.with-background {
  background: var(--qrw-background-base-tertiary-10);
}

.cta-button {
  width: 132px;
  min-width: 132px;
}
</style>
