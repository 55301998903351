<template>
  <div class="app-cart-comment-wrapper">
    <div class="cart-comment-block">
      <div class="gap-row-1xs">
        <div class="qrw-label-lg bold qrw-text-content-base-primary">
          {{ $t("cart.comment_title") }}
        </div>
        <div class="qrw-paragraph-sm qrw-text-content-base-tertiary">
          {{ $t("cart.comment_desc") }}
        </div>
      </div>

      <BaseTextarea
        autoresize
        :value="value"
        @input="$emit('input', $event)"
        size="md"
        :placeholder="$t('cart.comment_ph')"
      />

      <template v-if="askForUtensils">
        <BaseDivider color="border-base-tertiary" />
        <ToggleRow
          v-model="activeUtensilts"
          :text="$t('cart.utensils')"
          icon="utensils"
          activeIcon="utensils-filled"
        />
        <div
          class="gap-column-xs utensils-count"
          v-if="activeUtensilts"
        >
          <div class="qrw-label-md qrw-text-content-base-primary w-100">
            {{$t('cart.amount')}}
          </div>
          <BaseCounter
            :value="utensils"
            @input="nv =>$emit('setUtensils', nv)"
            size="md"
            :min="1"
            :max="50"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ToggleRow from '@/components/common/ToggleRow.vue'
export default {
  data() {
    return {
      activeUtensilts: false
    }
  },
  watch: {
    activeUtensilts() {
      if (this.activeUtensilts) {
        this.$emit('setUtensils', 1)
      }
    }
  },
  props: {
    askForUtensils: {
      type: Boolean,
      default: false
    },
    value: String,
    utensils: Number
  },
  components: {
    ToggleRow
  }
}
</script>

<style scoped>
.app-cart-comment-wrapper {
  display: flex;
  padding: 12px 16px;
}

.cart-comment-block {
  display: flex;
  background: var(--qrw-background-base-primary-10);
  box-shadow: var(--qrw-shadow-card-light);
  border-radius: 12px;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
}

.utensils-count {
  align-items: center;
}
</style>
