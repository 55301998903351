<template>
  <div class="app-reserve-checkout-page">
    <SecondaryPageHeader
      :title="'RESERVE'"
      @leftClicked="leftHeaderButtonPressed"
      :leftIcon="headerIcons[0]"
      :rightIcon="headerIcons[1]"
    />

    <div
      class="checkout-content details"
      v-qrw-under-header
      v-if="stage == 'details' && !busy"
    >
      <div class="progress-tabs">
        <div class="qrw-label-lg qrw-text-content-base-primary">
          {{ $t("checkout.contact_details") }}
        </div>
        <div class="gap-column-txs">
          <div class="progress-tab active"></div>
          <div class="progress-tab"></div>
        </div>
      </div>
      <div class="contact-form">
        <div class="form-field">
          <div class="qrw-label-sm bold qrw-text-content-base-tertiary">
            {{ $t("checkout.first_name") }}
            <span class="qrw-text-content-error-primary">*</span>
          </div>
          <BaseTextInput
            size="lg"
            v-model="fields.first_name"
            class="w-100"
            :invalid="!field_validations.first_name"
          />
          <div
            class="qrw-paragraph-md qrw-text-content-error-primary"
            v-if="!field_validations.first_name"
          >
            {{ $t("validations.required") }}
          </div>
        </div>
        <div class="form-field">
          <div class="qrw-label-sm bold qrw-text-content-base-tertiary">
            {{ $t("checkout.last_name") }}
            <span class="qrw-text-content-error-primary">*</span>
          </div>
          <BaseTextInput
            size="lg"
            v-model="fields.last_name"
            class="w-100"
            :invalid="!field_validations.last_name"
          />
          <div
            class="qrw-paragraph-md qrw-text-content-error-primary"
            v-if="!field_validations.last_name"
          >
            {{ $t("validations.required") }}
          </div>
        </div>
        <div class="form-field">
          <div class="qrw-label-sm bold qrw-text-content-base-tertiary">
            {{ $t("checkout.phone") }}
            <span class="qrw-text-content-error-primary">*</span>
          </div>
          <SmartPhoneInput
            :defaultCountry="cafeCountry"
            size="lg"
            v-model="fields.phone"
            class="w-100"
          />
          <div
            class="qrw-paragraph-md qrw-text-content-error-primary"
            v-if="!field_validations.phone"
          >
            {{ $t("validations.phone") }}
          </div>
        </div>
        <div class="form-field">
          <div class="qrw-label-sm bold qrw-text-content-base-tertiary">
            {{ $t("checkout.email") }}
            <span class="qrw-text-content-error-primary">*</span>
          </div>
          <BaseTextInput
            type="email"
            size="lg"
            v-model="fields.email"
            class="w-100"
            :invalid="!field_validations.email"
          />
          <div
            class="qrw-paragraph-md qrw-text-content-error-primary"
            v-if="!field_validations.email"
          >
            {{ $t("validations.email") }}
          </div>
        </div>
        <BaseDivider color="border-base-tertiary" />
        <div class="form-field">
          <div class="qrw-label-sm bold qrw-text-content-base-tertiary">
            {{ $t("checkout.comment") }}
          </div>
          <BaseTextarea
            :rows="4"
            autoresize
            size="lg"
            v-model="fields.comment"
            class="w-100"
          />
        </div>
      </div>
    </div>

    <div
      class="checkout-content confirm"
      v-qrw-under-header
      v-if="stage == 'confirmation'"
    >
      <div class="progress-tabs">
        <div class="qrw-label-lg qrw-text-content-base-primary">
          {{ $t("checkout.confirmation") }}
        </div>
        <div class="gap-column-txs">
          <div class="progress-tab active"></div>
          <div class="progress-tab active"></div>
        </div>
      </div>
      <div class="contact-form">
        <div class="confirmation-block">
          <div class="qrw-heading-xs bold qrw-text-content-base-primary">
            {{ $t("checkout.contact") }}
          </div>
          <div class="gap-row-2xs">
            <div class="qrw-label-sm qrw-text-content-base-tertiary">
              {{ $t("checkout.name") }}
            </div>
            <div class="qrw-label-lg qrw-text-content-base-primary">
              {{ fields.first_name }} {{ fields.last_name }}
            </div>
          </div>
          <BaseDivider color="border-base-tertiary" />
          <div class="gap-row-2xs">
            <div class="qrw-label-sm qrw-text-content-base-tertiary">
              {{ $t("checkout.email") }}
            </div>
            <div class="qrw-label-lg qrw-text-content-base-primary">
              {{ fields.email }}
            </div>
          </div>
          <BaseDivider color="border-base-tertiary" />
          <div class="gap-row-2xs phone-row">
            <div class="qrw-label-sm qrw-text-content-base-tertiary">
              {{ $t("checkout.phone") }}
            </div>

            <div class="gap-column-1xs w-100">
              <div class="qrw-label-lg qrw-text-content-base-primary w-100">
                {{ fields.phone }}
              </div>
              <BaseIcon
                icon="check-verified"
                class="qrw-text-content-success-primary"
                size="sm2"
                v-if="phoneVerificationToken"
              />
            </div>
          </div>
        </div>
        <div class="confirmation-block">
          <div class="qrw-heading-xs bold qrw-text-content-base-primary">
            {{ $t("reserve.reservation") }}
          </div>
          <div class="gap-row-2xs">
            <div class="qrw-label-sm qrw-text-content-base-tertiary">
              {{ $t("checkout.service") }}
            </div>
            <div class="qrw-label-lg qrw-text-content-base-primary">
              {{ reservationCart.product.name }}
            </div>
          </div>

          <BaseDivider color="border-base-tertiary" />
          <div class="gap-row-2xs">
            <div class="qrw-label-sm qrw-text-content-base-tertiary">
              {{ $t("reserve.persons") }}
            </div>
            <div class="qrw-label-lg qrw-text-content-base-primary">
              {{ reservationCart.personsCount }} persons
            </div>
          </div>
          <BaseDivider color="border-base-tertiary" />
          <div class="gap-row-2xs">
            <div class="qrw-label-sm qrw-text-content-base-tertiary">
              {{ $t("reserve.date") }}
            </div>
            <div class="qrw-label-lg qrw-text-content-base-primary">
              {{ confirmationDatetime.join(", ") }}
            </div>
          </div>
          <BaseDivider color="border-base-tertiary" />
          <div class="gap-row-2xs">
            <div class="qrw-label-sm qrw-text-content-base-tertiary">
              {{ $t("reserve.time") }}
            </div>
            <div class="qrw-label-lg qrw-text-content-base-primary">
              {{ reservationCart.reservationDate | formatTime }}
            </div>
          </div>
        </div>
        <div class="confirmation-block promocode-block" v-if="false">
          <div class="gap-row-2xs">
            <div class="qrw-heading-xs bold qrw-text-content-base-primary">
              {{ $t("promocode.title") }}
            </div>
            <div class="qrw-label-sm qrw-text-content-base-secondary">
              {{ $t("promocode.subtitle") }}
            </div>
          </div>
          <BaseTextInput
            size="md"
            v-model="fields.promocode"
            :placeholder="$t('promocode.placeholder')"
          />
        </div>
      </div>
      <div
        class="total-block"
        :class="{
          'total-block-filled': depositSize != 0,
        }"
      >
        <div class="gap-row-xs" v-if="depositSize">
          <!-- <div class="total-row">
            <div class="qrw-label-md qrw-text-content-base-secondary">
              {{ $t("total.subtotal") }}
            </div>
            <div class="qrw-label-md qrw-text-content-base-secondary">
              99.99$
            </div>
          </div>
          <BaseDivider color="border-base-secondary" dashed />
          <div class="total-row">
            <div class="qrw-label-md qrw-text-content-error-primary">
              {{ $t("total.discount") }} (20%)
            </div>
            <div class="qrw-label-md qrw-text-content-error-primary">
              99.99$
            </div>
          </div>
          <div class="total-row">
            <div class="qrw-label-md qrw-text-content-base-secondary">
              {{ $t("total.service_fee") }} (20%)
            </div>
            <div class="qrw-label-md qrw-text-content-base-secondary">
              99.99$
            </div>
          </div>
          <BaseDivider color="border-base-secondary" dashed /> -->
          <div class="total-row" v-if="depositSize">
            <div class="qrw-heading-xs bold qrw-text-content-base-primary">
              {{ $t("total.total") }}
            </div>
            <div class="qrw-heading-xs bold qrw-text-content-base-primary">
              {{ depositSize }}{{ currency.symbol }}
            </div>
          </div>
        </div>

        <div class="qrw-label-badge qrw-label-md" v-if="depositSize">
          <BaseIcon icon="wallet" size="sxs" />
          {{ $t("pay_methods.online") }}
        </div>

        <BasePushButton
          variant="fill"
          bgColor="background-base-inverse-primary"
          textColor="content-base-inverse-primary"
          size="lg"
          :disabled="busy"
          @click="onPayPressed"
        >
          {{
            depositSize == 0
              ? $t("common_actions.confirm")
              : $t("common_actions.pay")
          }}
        </BasePushButton>
      </div>
    </div>

    <SingleButtonNavbar
      action="next"
      @click="onNextPressed"
      :disabled="!canProceedWithContacts"
      :sticky="false"
      v-if="stage == 'details'"
    />

    <PhoneVerificationModal
      :phone="fields.phone"
      @success="onPhoneVerified"
      @close="isPhoneVerificationModalShown = false"
      v-if="isPhoneVerificationModalShown"
    />
    <OnlinePaymentModal
      :gateway="paymentGateway"
      :payLink="paymentLink"
      @close="isPaymentModalShown = false"
      v-if="isPaymentModalShown"
    />
  </div>
</template>

<script>
import SecondaryPageHeader from '@/components/header/SecondaryPageHeader.vue'
import SingleButtonNavbar from '@/components/navbar/SingleButtonNavbar.vue'

import PhoneVerificationModal from '@/components/modals/PhoneVerificationModal.vue'

import OnlinePaymentModal from '@/components/modals/OnlinePaymentModal.vue'

import moment from 'moment'

import ReserveAPI from '@/api/reserve'

import {
  getCafeBasedData,
  saveCafeBasedData,
  eraseCafeBasedData
} from '@/utils'

export default {
  components: {
    SecondaryPageHeader,
    SingleButtonNavbar,
    PhoneVerificationModal,
    OnlinePaymentModal
  },
  data () {
    return {
      stage: 'details',
      reservation: {},
      fields: {
        first_name: null,
        last_name: null,
        phone: null,
        email: null,
        comment: null,
        promocode: null
      },
      field_validations: {
        first_name: true,
        last_name: true,
        phone: true,
        email: true
      },
      busy: false,
      was_invalid: false,

      isPhoneVerificationModalShown: false,
      phoneVerificationToken: null,

      isPaymentModalShown: false,
      paymentGateway: null,
      paymentLink: null
    }
  },
  watch: {
    fields: {
      deep: true,
      handler () {
        if (this.was_invalid) {
          this.validateForm()
        }

        this.persistFormInCache()
      }
    }
  },
  methods: {
    leftHeaderButtonPressed () {
      if (this.stage === 'confirmation') {
        this.stage = 'details'
        return
      }

      if (this.stage === 'success') {
        this.$router.push({
          name: 'home',
          params: {
            cafe_link: this.mixinCafeLinkCode
          }
        })
        return
      }

      if (this.stage === 'details') {
        this.$router.go(-1)
      }
    },
    async createReservation () {
      if (this.busy) return

      this.busy = true

      const { reservation } = await ReserveAPI.create({
        cart_item: this.reservationCart.product,
        persons_count: this.reservationCart.personsCount,
        client_first_name: this.fields.first_name,
        client_last_name: this.fields.last_name,
        client_phone: this.fields.phone,
        client_email: this.fields.email,
        comment: this.fields.comment,
        reservation_date: moment(
          this.reservationCart.reservationDate
        ).toISOString(),
        cafe_id: this.cafe._id,
        phone_token: this.phoneVerificationToken
      })

      this.reservation = reservation
      if (this.canShowReceipt) {
        this.openReceipt()
      } else {
        const { form } = await ReserveAPI.getPaymentLink(this.reservation._id)
        this.paymentGateway = form.gateway
        this.paymentLink = form.url
        this.isPaymentModalShown = true
      }
      this.busy = false
    },
    openReceipt () {
      eraseCafeBasedData('reserve_checkout')

      this.$router.push({
        name: 'reserve_receipt',
        params: {
          cafe_link: this.mixinCafeLinkCode,
          reservation_id: this.reservation._id
        }
      })
    },
    onPhoneVerified (token) {
      this.phoneVerificationToken = token
      this.stage = 'confirmation'
    },
    validateForm () {
      let valid = true

      if (!this.fields.first_name) {
        this.field_validations.first_name = false
        valid = false
      } else {
        this.field_validations.first_name = true
      }

      if (!this.fields.last_name) {
        this.field_validations.last_name = false
        valid = false
      } else {
        this.field_validations.last_name = true
      }

      if (!this.fields.phone) {
        this.field_validations.phone = false
        valid = false
      } else {
        this.field_validations.phone = true
      }

      if (!this.fields.email || this.fields.email.split('@').length < 2) {
        this.field_validations.email = false
        valid = false
      } else {
        this.field_validations.email = true
      }

      return valid
    },
    persistFormInCache () {
      saveCafeBasedData('reserve_checkout', this.mixinCafeLinkCode, {
        fields: this.fields,
        stage: this.stage
      })
    },
    checkAndLoadFormCache () {
      const cachedData = getCafeBasedData(
        'reserve_checkout',
        this.mixinCafeLinkCode
      )
      if (cachedData) {
        this.fields = cachedData.fields
        this.stage = cachedData.stage
        this.validateForm()
      }
    },
    onNextPressed () {
      if (!this.validateForm()) {
        this.was_invalid = true
        return
      }

      if (this.cafe.reserve.use_phone_verification) {
        this.isPhoneVerificationModalShown = true
      } else {
        this.stage = 'confirmation'
      }
    },
    onPayPressed () {
      this.createReservation()
    }
  },
  computed: {
    cafe () {
      return this.$store.state.cafe
    },
    cafeCountry () {
      return this.$store.state.country
    },
    headerIcons () {
      switch (this.stage) {
        case 'details':
          return ['arrow-left', 'pencil']
        case 'confirmation':
          return ['arrow-left', null]
        case 'success':
          return ['home', 'share']
        default:
          return ['arrow-left']
      }
    },
    canProceedWithContacts () {
      return (
        this.fields.first_name &&
        this.fields.last_name &&
        this.fields.phone &&
        this.fields.email
      )
    },
    confirmationDatetime () {
      const capitalize = (str) =>
        str.substring(0, 1).toUpperCase() + str.substring(1)

      return [
        capitalize(
          moment(this.reservationCart.reservationDate)
            .locale(this.$i18n.locale)
            .format('dddd DD')
        ),
        capitalize(
          moment(this.reservationCart.reservationDate)
            .locale(this.$i18n.locale)
            .format('MMMM YYYY')
        )
      ]
    },
    reservationCart () {
      return this.$store.state.reserveCart
    },
    currency () {
      return this.$store.state.currency
    },
    depositSize () {
      if (this.cafe.reserve.deposit_enabled) {
        if (this.cafe.reserve.deposit_type === 'full') {
          return this.reservationCart.product.price
        }

        if (this.cafe.reserve.deposit_type === 'constant') {
          return this.cafe.reserve.deposit_value
        }

        if (this.cafe.reserve.deposit_type === 'percent') {
          return (
            Math.round(
              (this.cafe.reserve.deposit_percent / 100) *
                this.reservationCart.product.price *
                100
            ) / 100
          )
        }

        return 0
      } else {
        return 0
      }
    },
    canShowReceipt () {
      return this.reservation && this.reservation.status !== 'pending'
    }
  },
  created () {
    this.busy = true
  },
  mounted () {
    this.checkAndLoadFormCache()

    const cachedCart = getCafeBasedData('reserve_cart', this.mixinCafeLinkCode)

    if (cachedCart) {
      this.$store.commit('setReserveCart', cachedCart)
    }

    if (!this.reservationCart.product) {
      this.$router.push({
        name: 'home',
        params: {
          cafe_link: this.mixinCafeLinkCode
        }
      })
    }
    this.busy = false
  }
}
</script>

<style scoped>
.app-reserve-checkout-page {
  background: var(--qrw-background-base-secondary);
}

.progress-tabs {
  row-gap: 6px;
  display: flex;
  padding: 4px 24px 12px 24px;
  flex-direction: column;
}

.checkout-content.details {
  /* padding-bottom: 80px; */
}

.checkout-content.confirm {
  padding-bottom: 212px;
}

.progress-tab {
  width: 100%;
  height: 2px;
  min-height: 2px;
  border-radius: 4px;
  background: var(--qrw-border-base-tertiary);
}

.progress-tab.active {
  background: var(--qrw-border-base-primary);
}

.contact-form {
  display: flex;
  row-gap: 24px;
  flex-direction: column;
  padding: 16px 24px 24px 24px;
}

.form-field {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.confirmation-block {
  display: flex;
  padding: 14px 16px 16px 16px;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  flex-direction: column;
  row-gap: 16px;
  box-sizing: border-box;
  background: var(--qrw-background-base-primary-10);
  box-shadow: var(--qrw-shadow-foundation-1dp);
}

.total-block {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  padding: 24px;
  justify-content: flex-start;
  border-radius: 16px 16px 0 0;

  z-index: 10;

  position: fixed;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;

  box-shadow: var(--qrw-shadow-card-hard);

  background: var(--qrw-background-base-primary-10);
}

.total-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.promocode-block {
  background: var(--qrw-background-base-secondary);
}

a {
  text-decoration: none;
  color: inherit;
}

.phone-row {
  display: flex;
}

.qrw-label-badge {
  background: var(--qrw-background-base-tertiary);
  color: var(--qrw-content-base-secondary);
  column-gap: 6px;
  padding: 2px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  align-self: center;
  justify-self: center;
  border-radius: 6px;
}

.success-date-block {
  padding: 16px;
  align-items: center;
}
</style>
