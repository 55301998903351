<template>
  <div class="leave-tips-block">
    <div class="qrw-label-sm qrw-text-content-base-tertiary">
      {{ $t("leave_tips.title") }}
    </div>
    <div class="qrw-heading-sm bold qrw-text-content-base-primary">
      {{ percentEmoji }} {{ $t("leave_tips.thank_you") }}
    </div>

    <div class="tip-buttons">
      <div
        class="tip-button-container"
        :class="{ active: selected === n }"
        v-for="n in percents"
        :key="n"
        @click="onPercentClicked(n)"
      >
        <div
          class="qrw-base-select-button tip-button qrw-label-md"
          :class="{
            'active bold': selected === n,
          }"
        >
          {{ n }}%
        </div>
        <div
          class="sum-badge bold qrw-label-sm qrw-text-content-base-primary"
          :style="{
            visibility: selected === n ? 'visible' : 'hidden'
          }"
        >
          {{ absolutePercentValue | formatMoney }} {{ currency.symbol }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      percents: [5, 10, 15, 20],

      selected: 0
    }
  },
  props: {
    total: Number,
    value: Number
  },
  watch: {
    selected() {
      this.$emit('input', this.selected)
    }
  },
  methods: {
    onPercentClicked(percent) {
      if (this.selected === percent) {
        this.selected = 0
        return
      }

      this.selected = percent
    }
  },
  computed: {
    currency() {
      return this.$store.state.currency
    },
    absolutePercentValue() {
      return this.total * (this.selected / 100)
    },
    percentEmoji() {
      return {
        0: '',
        5: '😊',
        10: '😄',
        15: '😍',
        20: '🤩'
      }[this.selected]
    }
  }
}
</script>

<style scoped>
.leave-tips-block {
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
}

.tip-buttons {
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  align-items: flex-start;
  width: 100%;
}

.qrw-base-select-button.tip-button {
  padding: 9px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid var(--qrw-border-base-tertiary);
}

.qrw-base-select-button.tip-button.active {
  border: 2px solid var(--qrw-border-base-primary);
  background: var(--qrw-background-base-primary);
  padding: 8px 19px;
  box-shadow: var(--qrw-shadow-card-light);
}

.tip-button-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 100%;
}

.tip-button-container.active {
  background: var(--qrw-background-base-tertiary-10);
}

.sum-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}
</style>
