<template>
  <div class="menu-page-header" ref="header">
    <div class="menu-page-header-top">
      <BaseIconButton textColor="content-base-secondary" variant="ghost" size="md" icon="arrow-left"
        @click="$emit('back')" />
      <div class="header-info">
        <div class="qrw-label-xs bold qrw-text-content-base-tertiary">
          {{ modeTitle }}
        </div>
        <div class="qrw-label-md bold qrw-text-content-base-primary menu-page-header-title" v-if="!loading">
          {{ menuTitle }}
        </div>
        <SkeletonLoader width="120px" height="18px" v-else />
      </div>
      <!-- <BaseIconButton textColor="content-base-secondary" variant="ghost" size="md" icon="search"
        @click="$emit('action')" /> -->
      <FlagIcon @click="$emit('switchLanguage')" />
    </div>
    <CategoriesScroller :menu="menu" view="list" :loading="loading" v-if="hasMoreThanOneCategory" />
    <BaseDivider class="hidden" color="border-base-tertiary" v-qrw-class-on-scroll="{
          cssClass: 'visible',
          limit: 20
        }" />
  </div>
</template>

<script>
import CategoriesScroller from '@/components/header/CategoriesScroller.vue'

export default {
  components: {
    CategoriesScroller
  },
  props: {
    loading: Boolean,
    menu: Object
  },
  data () {
    return {}
  },
  computed: {
    cafe () {
      return this.$store.state.cafe
    },
    modeTitle () {
      const baseTitle = this.$store.getters.modeTitle

      if (this.$store.state.mode === 'inside') {
        return `${baseTitle} (${this.$store.state.inside.section_name} ${this.$store.state.inside.table_name})`
      }

      return baseTitle
    },
    menuTitle () {
      return this.$store.getters.localizeMenuTitle(this.menu._id, this.$i18n.locale)
    },
    hasMoreThanOneCategory () {
      const uniqueCategoryIds = new Set([...this.menu.products].map(product => product.category_id))

      return uniqueCategoryIds.size > 1
    }
  },
  methods: {}
}
</script>

<style scoped>
.menu-page-header {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
}

.menu-page-header-top {
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  align-items: center;
  padding: 12px 16px 12px 16px;
}

.header-info {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  text-align: center;
  align-items: center;
  row-gap: 4px;
  overflow: hidden;
  padding: 1px 0;
}

.menu-page-header-title {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hidden {
  display: none;
}

.visible {
  display: block;
}

/* .menu-categories-scroller {

} */
</style>
