// import Vuex from 'vuex'

import { getCafeBasedData, saveCafeBasedData } from "../utils";

import CartAPI from "@/api/cart";

const state = {
  cart: {},
  cartId: null,
};

const mutations = {
  setCartId(state, cartId) {
    state.cartId = cartId;
  },

  setCart(state, cart) {
    state.cart = cart;
  },
};

const getters = {
  total(state) {
    if (!state.cart) return 0;
    return state.cart.total || 0;
  },
  items(state) {
    if (!state.cart) return [];
    return state.cart.items || [];
  },
  getProductCountInCart(state) {
    return (product) => {
      return state.cart.items
        .filter((cartItem) => {
          return cartItem.product_id === product._id;
        })
        .reduce((acc, item) => acc + item.quantity, 0);
    };
  },
};

const actions = {
  lookupCache({ commit, state }) {
    const cached = getCafeBasedData("cart", "global");

    if (cached) {
      commit("setCartId", cached.cartId);
    }
  },
  useCart({ commit, state }, cart) {
    commit("setCart", cart);
    commit("setCartId", cart._id);

    saveCafeBasedData("cart", "global", {
      cartId: cart._id,
    });
  },
  async addItem({ commit, state }, item) {
    const { cart } = await CartAPI.addItem(state.cartId, item);

    commit("setCart", cart);
  },
  async changeQuantity({ commit, state }, data) {
    const { cart } = await CartAPI.changeQuantity(state.cartId, data);

    commit("setCart", cart);
  },
  async clearCart({ state, commit }) {
    const { cart } = await CartAPI.clearCart(state.cartId);

    commit("setCart", cart);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
