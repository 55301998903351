<template>
  <BaseSimpleModal
    :showFooter="false"
    :showHeader="false"
    :fullHeight="false"
    :title="$t('phone_verify_modal.title')"
    @close="$emit('close')"
    @backdropClick="$emit('close')"
  >
    <div
      class="phone-verification-container"
      v-show="verifyToken"
    >
      <div class="inputs-container">
        <div class="gap-row-1xs">
          <div class="qrw-heading-sm bold qrw-text-content-base-primary">
            {{ $t("phone_verify_modal.enter") }}
          </div>
          <div class="qrw-label-md qrw-text-content-base-tertiary">
            {{ $t("phone_verify_modal.sent_to") }} {{ phone }}
          </div>
        </div>

        <BaseTextInput
          v-model="userCode"
          placeholder="0000"
          size="lg"
          :disabled="success"
          :invalid="!!error"
          inputmode="numeric"
          class="phone-confirm-input"
        />

        <div
          class="qrw-label-lg bold qrw-text-content-error-primary"
          v-if="!!error"
        >
          {{ $t("phone_verify_modal.error") }}
        </div>

        <div
          class="qrw-text-content-success-primary bold success-row"
          v-if="success"
        >
          <BaseIcon
            icon="check-verified-filled"
            size="sm4"
          />
          <div class="qrw-heading-xs">
            {{ $t("phone_verify_modal.success") }}
          </div>
        </div>
      </div>
      <BaseDivider
        color="border-base-tertiary"
        v-if="!success"
      />
      <div
        class="buttons-container"
        v-if="!success"
      >
        <div class="qrw-label-lg qrw-text-content-base-secondary">
          {{ $t("phone_verify_modal.no_code") }}
        </div>
        <BasePushButton
          variant="outlined"
          textColor="content-base-primary"
          borderColor="border-base-tertiary"
          size="md"
          :disabled="resendCountdown > 0"
          @click="resendCode"
        >
          {{
            resendCountdown
              ? resendCountdownVisualValue
              : $t("phone_verify_modal.resend_btn")
          }}
        </BasePushButton>
      </div>
      <BaseDivider color="border-base-tertiary" />
      <BasePushButton
        variant="ghost"
        textColor="content-base-secondary"
        size="lg"
        @click="$emit('close')"
        class="w-100"
      >
        {{ $t("common_actions.close") }}
      </BasePushButton>
    </div>
  </BaseSimpleModal>
</template>

<script>
// import FoundationModal from './FoundationModal.vue'

import moment from 'moment'

import SecurityAPI from '@/api/security'

export default {
  components: {
    // FoundationModal
  },
  data() {
    return {
      userCode: '',

      error: null,
      success: false,

      verifyToken: null,

      resendCountdown: 0
    }
  },
  props: {
    phone: String
  },
  watch: {
    userCode() {
      if (this.userCode.length === 4) {
        this.confirmCode()
      }
    }
  },
  methods: {
    tickCountdown() {
      this.resendCountdown--

      if (this.resendCountdown > 0) {
        setTimeout(this.tickCountdown, 1000)
      }
    },
    resendCode() {
      this.resendCountdown = 60
      this.tickCountdown()

      this.requestCode()
    },
    async requestCode() {
      const response = await SecurityAPI.requestPhoneNumberVerification(this.phone, this.cafe._id)

      this.verifyToken = response.token

      if (response.already_verified) {
        this.$emit('success', this.verifyToken)
        this.$emit('close')
      }
    },
    async confirmCode() {
      const { valid } = await SecurityAPI.confirmPhoneNumberVerification(this.verifyToken, this.userCode)

      if (valid) {
        this.success = true
        this.error = false
        this.$emit('success', this.verifyToken)
        setTimeout(() => {
          this.$emit('close')
        }, 2000)
      } else {
        this.error = true
        this.userCode = ''
      }
    }
  },
  computed: {
    resendCountdownVisualValue() {
      return moment().add(this.resendCountdown, 'seconds').subtract(moment()).format('mm:ss')
    },
    cafe() {
      return this.$store.state.cafe
    }
  },
  mounted() {
    this.requestCode()
  }
}
</script>

<style>
.phone-confirm-input input {
  text-align: center;
}
</style>

<style scoped>
.inputs-container {
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
  row-gap: 24px;
  text-align: center;

  box-sizing: border-box;
}

.buttons-container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
}

.success-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
}

.phone-verification-container {
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  row-gap: 32px;
}
</style>
