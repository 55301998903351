<template>
  <div
    class="closed-banner"
    :class="{
      rounded: !!rounded
    }"
    @click="modalShown = true"
    v-if="isBannerShown"
  >
    <div class="qrw-label-sm qrw-text-content-error-primary">
      {{$t('closed_banner.title_'+type)}}
    </div>

    <portal>
      <GenericClosedModal
        :type="type"
        v-if="modalShown"
        :schedule="scheduleObject"
        @close="modalShown = false"
      />
    </portal>
  </div>
</template>

<script>
import GenericClosedModal from '@/components/modals/GenericClosedModal.vue'

export default {
  props: {
    type: String,
    rounded: Boolean
  },
  data() {
    return {
      modalShown: false
    }
  },
  components: {
    GenericClosedModal
  },
  computed: {
    isBannerShown() {
      if (this.type === 'cafe') {
        return this.$store.getters.isCafeClosed
      }

      if (this.type === 'delivery' || this.type === 'takeaway') {
        return this.$store.getters.isSystemClosed
      }

      if (this.type === 'menu') {
        return true
      }

      return false
    },
    scheduleObject() {
      if (this.type === 'cafe') {
        return this.$store.state.cafe.schedule
      }

      if (this.type === 'system') {
        return null
      }

      return null
    }
  }
}
</script>

<style scoped>
.closed-banner {
  background: var(--qrw-background-error-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 8px;
  border-bottom: 1px solid var(--qrw-border-error-tertiary);
  padding-bottom: 7px;
}

.closed-banner.rounded {
  border-radius: 8px;
  border: 1px solid var(--qrw-border-error-tertiary);
  padding: 7px;
  width: 100%;
}
</style>
