<template>
  <div
    class="app-order-product-card-wrapper"
    :class="{
      selected: isSelected,
    }"
  >
    <div
      class="app-order-product-card"
      :class="{
        selected: isSelected,
      }"
      @click="onCardClicked"
    >
      <div class="product-main-info">
        <div class="gap-row-1xs w-100">
          <div class="qrw-label-sm qrw-text-content-base-tertiary">
            {{ categoryName }}
          </div>
          <div class="qrw-label-md qrw-text-content-base-primary">
            {{ productName }}
          </div>
        </div>
        <div
          class="qrw-label-sm price-tag"
          :class="{
            'qrw-text-content-base-primary bold': modificatorGroups.length === 0,
            'qrw-text-content-base-tertiary': modificatorGroups.length > 0,
          }"
        >
          {{ listItem.price }} {{ currency.symbol }}
        </div>
      </div>

      <BaseDivider
        color="border-base-tertiary"
        dashed
        v-if="modificatorGroups.length > 0"
      />

      <template v-for="(group, index) in modificatorGroups">
        <div
          class="gap-row-1xs"
          :key="index"
        >
          <div class="qrw-text-content-base-tertiary qrw-label-xs">
            {{ getModifierGroupName(group) || group.name }}
          </div>
          <div
            class="gap-column-xs"
            v-for="(item, item_index) in group.items"
            :key="item_index"
          >
            <div class="qrw-text-content-base-primary qrw-label-sm w-100">
              {{ getModifierItemName(item) || item.name }}
            </div>
            <PriceTag
              prefix="+"
              :value="item.price"
              class="qrw-label-sm qrw-text-content-base-tertiary"
              v-if="item.price"
            />
          </div>
        </div>
        <BaseDivider
          color="border-base-tertiary"
          dashed
          :key="`divider_${index}`"
        />
      </template>

      <div
        class="gap-column-xs"
        v-if="modificatorGroups.length"
      >
        <div class="qrw-label-sm bold qrw-text-content-base-primary w-100">
          {{ $t("order_product_card.product_total") }}
        </div>
        <div class="qrw-label-sm bold qrw-text-content-base-primary price-tag">
          {{ listItem.total }} {{ currency.symbol }}
        </div>
      </div>

      <BaseBetaCheckbox
        class="order-card-checkbox"
        size="lg"
        cell="check"
        @click="onCardClicked"
        :value="isSelected"
      />
    </div>
    <div
      class="app-order-card-quantity"
      v-if="!isSelected && listItem.quantity > 1"
    >
      <div class="qrw-label-md qrw-text-content-base-tertiary">
        x {{ listItem.quantity }}
      </div>
      <PriceTag
        :value="listItem.total"
        class="qrw-label-md qrw-text-content-base-tertiary"
      />
    </div>
    <div
      class="app-order-card-remains"
      v-else-if="isSelected && listItem.quantity > 1"
    >
      <div class="qrw-label-md qrw-text-content-base-tertiary">
        {{ $t("order_product_card.remains") }} {{ listItem.quantity - selectedQuantity}}
      </div>

      <div class="quantity-counter">
        <BaseIconButton
          textColor="content-base-tertiary"
          size="md"
          variant="ghost"
          icon="minus"
          @click="changeQuantity(-1)"
        />
        <div class="qrw-label-md bold qrw-text-content-base-primary quantity-value">
          {{ selectedQuantity }}
        </div>
        <BaseIconButton
          textColor="content-base-tertiary"
          size="md"
          variant="ghost"
          icon="plus"
          :disabled="selectedQuantity >= listItem.quantity"
          @click="changeQuantity(1)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    listItem: Object,
    selection: Object
  },
  watch: {
    selectedQuantity() {
      this.$emit('select', this.selectedQuantity)
    }
  },
  methods: {
    onCardClicked() {
      if (!this.isSelected) {
        this.$emit('select', this.listItem.quantity)
      } else {
        if (this.selectedQuantity === this.listItem.quantity) {
          this.$emit('select', 0)
        }
      }
    },
    changeQuantity(delta) {
      this.$emit('select', this.selectedQuantity + delta)
    },
    getModifierItemName(item) {
      return (
        this.$store.getters.localize('price_group_item_name', this.listItem.product_id + ':' + item.item_id) ||
        item.name
      )
    },
    getModifierGroupName(group) {
      return (
        this.$store.getters.localize('price_group_name', this.listItem.product_id + ':' + group.group_id) || group.name
      )
    }
  },
  computed: {
    categoryName() {
      return this.$store.getters.localize('product_category', this.listItem.product_id) || this.listItem.category
    },
    productName() {
      return this.$store.getters.localize('product_name', this.listItem.product_id) || this.listItem.name
    },
    selectedQuantity() {
      return this.selection[this.listItem._id]
    },
    isSelected() {
      return this.selectedQuantity > 0
    },
    currency() {
      return this.$store.state.currency
    },
    modificatorGroups() {
      const combined = [
        ...this.listItem.variants.map(it => ({ ...it, from: 'variants' })),
        ...this.listItem.modificators.map(it => ({
          ...it,
          from: 'modificators'
        }))
      ]

      return Object.entries(
        combined.reduce((acc, item) => {
          const key = item.group_id || (item.from === 'variants' ? 'Variants' : 'Additions')

          acc[key] = acc[key] || []
          acc[key].push(item)

          return acc
        }, {})
      ).map(entry => ({
        group_id: entry[0],
        items: entry[1],
        name: entry[1][0] ? entry[1][0].group_name : null
      }))
    }
  }
}
</script>

<style scoped>
.app-order-product-card-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
}

.app-order-product-card-wrapper.selected {
  background: var(--qrw-background-base-tertiary-10);
}

.app-order-product-card {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 16px;
  row-gap: 12px;
  border: 1px solid var(--qrw-border-base-tertiary);
  background: var(--qrw-background-base-secondary-10);
  box-sizing: border-box;

  position: relative;
}

.app-order-product-card.selected {
  border: 1px solid var(--qrw-border-success-primary);
  box-shadow: var(--qrw-shadow-card-light);
}

.order-card-checkbox {
  position: absolute;
  top: 8px;
  right: 8px;
}

.product-main-info {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: flex-end;
}

.price-tag {
  white-space: nowrap;
  width: fit-content;
}

.app-order-card-quantity {
  padding: 14px 16px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.app-order-card-remains {
  padding: 4px 4px 4px 16px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.quantity-counter {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.quantity-value {
  width: 40px;
  text-align: center;
}
</style>
