export default {
  home: {
    about: "Про заклад",
    address: "Адреса",
    socials: "Соцмережі",
    website: "Веб-сайт",
    get_directions: "Прокласти маршрут",
  },
  contacts: {
    title: "Контакти",
    phone: "Телефон",
    mail: "Пошта",
    website: "Вебсайт",
    visit: "Перейти",
    call: "Виклик",
    copy: "Копіювати",
  },
  schedule: {
    weekdays: {
      mon: "Понеділок",
      tue: "Вівторок",
      wed: "Середа",
      thu: "Четвер",
      fri: "П'ятниця",
      sat: "Субота",
      sun: "Неділя",
    },
    open_now: "Відкрито",
    closes_in: "Закривається через",
    closed_now: "Зачинено",
    opens_in: "Відкриється за",
    day_off: "Вихідний",
    schedule: "Графік роботи",
  },
  units: {
    hours: "год.",
    minutes: "хв.",
    positions: "0 позиції | 1 позиція | {count} позицій",
    items: "0 елементів | 1 елемент | {count} елементів",
    orders: "0 замовлення | 1 замовлення | {count} замовлень",
    g: "г",
    kg: "кг",
    l: "л",
    ml: "мл",
    min: "хв",
  },
  legals: {
    privacy_policy: "Політика конфіденційності",
    cookies: "Cookies",
    terms_of_use: "Умови використання",
    public_offert: "Публічна оферта",
    view: "Переглянути",
  },
  reviews: {
    home_title: "Відгуки",
    max_value: "З 5",
  },
  menu_grid: {
    list: "Список",
  },
  product: {
    description: "Опис",
  },
  common_actions: {
    confirm: "Підтвердити",
    order: "Замовляти",
    cancel: "Скасувати",
    reserve: "Зарезервувати",
    next: "Далі",
    pay: "Сплатити",
    search: "Шукати",
    view_cart: "Кошик",
    add: "Додати",
    remove: "Видалити",
    close: "Закрити",
    view: "Переглянути",
  },
  reserve: {
    persons: "Особи",
    reservation: "Бронювання",
    thanks_1: "Дякуємо!",
    thanks_2: "Ваше бронювання було зроблено",
    date: "Дата",
    time: "Час",
    download_pdf: "Завантажити PDF",
    add_to_calendar: "Додати до календаря",
    deposit: "Депозит",
    paid: "Оплачено",
  },
  checkout: {
    contact_details: "Деталі контакту",
    confirmation: "Підтвердження",
    name: "Ім'я",
    first_name: "Ім'я",
    last_name: "Прізвище",
    phone: "Телефон",
    email: "Електронна пошта",
    comment: "Коментар",
    contact: "Контакт",
    service: "Сервіс",
    date: "Дата",
    time: "Час",
  },
  promocode: {
    title: "Маєте промокод?",
    subtitle: "Введіть його нижче",
    placeholder: "Введіть промо",
  },
  total: {
    subtotal: "Підсумок",
    discount: "Знижка",
    service_fee: "Плата за послуги",
    delivery_fee: "Вартість доставки",
    products_total: "Вартість страв",
    tip: "Чайові",
    total: "Всього",
  },
  comment: {
    title: "Ваша нотатка",
  },
  phone_verify_modal: {
    title: "Перевірка номеру телефону",
    enter: "Введіть код з SMS",
    sent_to: "Код відправлено на ",
    no_code: "Не отримали код?",
    resend_btn: "Надіслати знову",
    error: "Невірний код",
    success: "Перевірено",
  },
  online_payment_modal: {
    redirect: "Ви будете перенаправлені на сторінку оплати",
    btn_pay: "Перейти до оплати",
    btn_cancel: "Скасувати",
    redirecting: "Перенаправлення на сторінку оплати...",
  },
  pay_methods: {
    cash: "Готівка",
    card: "Карта",
    online: "Онлайн",
    pay_with_cash: "Оплатити готівкою",
    pay_with_card: "Оплатити карткою",
    pay_with_online: "Оплатити онлайн",
  },
  validations: {
    required: "Обов'язкове поле",
    phone: "Введіть правильний номер телефону",
    email: "Введіть дійсну адресу електронної пошти",
  },
  calendar: {
    date: "Дата",
  },
  phone_country_picker: {
    title: "Виберіть регіон телефону",
    placeholder: "Пошук за назвою країни...",
    subtitle: "Оберіть код вашого номеру телефону з списку нижче",
    select: "Вибрати",
  },
  menu_lobby: {
    welcome: "Ласкаво просимо",
    select_type: "Будь ласка, виберіть тип замовлення",
    search_ph: "Страви, меню, категорії...",
    our_menus: "Наші меню",
  },
  call_waiter: {
    title: "Потрібна допомога?",
    subtitle: "Викличіть офіціанта для допомоги",
    button: "Викликати",
    sent: "Ваш запит був відправлений",
  },
  scan_qrcode: {
    title: "Приєднайте свій стіл",
    subtitle: "Скануйте QR-код з столу",
    button: "Сканувати",

    modal_title: "Відскануйте QR-код",
    modal_subtitle: "QR код розташований на столі",

    modal_cant_scan: `Не можете відсканувати QR-код?`,
    modal_enter_code: "Введіть цифровий код нижче",
  },
  verify_location: {
    title: "Гео-розташування",
    subtitle: "Нам потрібно перевірити ваше розташування",
    button: "Перевірити",
    verified: "Підтверджено",
  },
  menu_product: {
    badge_spicy: "Гострий",
    badge_veggie: "Веганський",
    badge_alcohol: "Алкоголь",
    variants: "Варіанти",
    modificators: "Модифікатори",
    variants_single: "Оберіть один з наданих варіантів",
    variants_min: "Мін",
    variants_max: "Макс",
    required: "Обов'язково",
    modificators_any: "Оберіть будь-яку кількість наданих додатків",
    comment_title: "Ваш коментар",
    comment_ph: "Напишіть тут...",
    show_more: "Показати більше",
    show_less: "Показати менше",
  },
  cart: {
    title: "Кошик",
    order_type: "Тип замовлення",
    delivery_price: "Вартість доставки",
    min_order: "Мінімальна сума замовлення",
    place_order: "Створити замовлення",
    products: "Товари",
    unavailable_item: "Цей товар недоступний в ",
    add_more: "Замовити ще щось",
    comment_title: "Особливі вимоги",
    comment_desc: "Чи є у Вас особливі вимоги, алергії або дієтичні обмеження?",
    comment_ph: "Пишіть тут...",
    utensils: "Прибори",
    amount: "Кількість",

    alcohol_alert:
      "Підтверджую, що мені 21+ рік і я маю право замовляти алкогольні напої та страви",
  },
  geo_verification: {
    title: "Гео-перевірка",
    desc: "Нам потрібно підтвердити Ваше місцезнаходження, щоб продовжити замовлення",
    please: "Будь ласка, надайте доступ до Вашого місцезнаходження",
    verify: "Підтвердити місцезнаходження",
    dismiss: "Відхилити",
    deny_title: "Схоже, Ви відмовилися надавати доступ до Вашого місця",
    deny_subtitle: "Будь ласка, перезавантажте сторінку і спробуйте знову",
    reload: "Перезавантажити сторінку",
    busy: "Перевірка Вашого місцезнаходження...",
    confirmed: "Місцезнаходження підтверджено",
    out_of_range: "Сервіс недоступний в Вашому місці",
  },
  order_product_card: {
    product_total: "Загальна вартість",
    remains: "Залишок:",
  },
  order_page: {
    title: "Поточне замовлення",
    your_bill: "Ваш рахунок",
    select_all: "Вибрати все",
  },
  order_payment_navbar: {
    none_selected: "Оберіть товари для оплати",
    out_of_range: "Ваша адреса знаходиться поза зоною доставки",
    fill_in: "Будь ласка, заповніть всі обов'язкові поля",
    slider_disabled: "Сплатити",
    slider_active: "Проведіть, щоб оплатити",
    total_to_pay: "Загальна вартість",
    including_fees: "Враховуючи комісії",
    left_unpaid: "несплачено",
  },
  pay_method_modal: {
    title: "Спосіб оплати",
    subtitle: "Оберіть, як ви хочете заплатити",
    descriptions: {
      cash: "Ви заплатите готівкою на місці",
      card: "Ви заплатите карткою на місці",
      online: "Ви заплатите онлайн",
    },
  },
  leave_tips: {
    title: "Ви можете залишити чайові, якщо бажаєте",
    thank_you: "Дякуємо!",
  },
  personal_tips: {
    title: "Залишити чайові",
    role_ph: "Офіціант",
  },
  receipt: {
    total_paid: "Всього сплачено",
    total_to_pay: "Всього до сплати",
    including_taxes: "З урахуванням податків",
    service_provider: "Постачальник послуг",
    tech_details: "Технічні деталі",
    order_number: "Номер замовлення",
    order_id: "ID замовлення",
    date_and_time: "Дата і час",
  },
  payment_details: {
    title: "Деталі платежу",
    refunded: "Повернуто",
    paid: "Оплачено",
    unpaid: "Не оплачено",
  },
  archive: {
    active_order: "Поточне замовлення",
    archived_order: "Архівоване замовлення",
    empty_title: "Тут ще нічого немає",
    open_menu: "Відкрити меню",
    empty_subtitle:
      "Ви можете замовити з меню.<br>Ваше замовлення з'явиться тут після того, як заклад прийме його.",
    title: "Замовлення",
    subtitle_1: "Тут ви можете знайти свої активні та архівовані замовлення",
  },
  main_navbar: {
    menu: "Меню",
    orders: "Замовлення",
    home: "Головна",
  },
  error_modal: {
    title_generic: "Щось пішло не так",
    title_payment: "Оплата не вдалась",
    title_order: "Не вдалось створити замовлення",
    title_not_found: "Сторінка не існує",
    error_code: "Код помилки",
    please_reload: "Будь-ласка, перезавантажте сторінку і спробуйте ще раз",
    btn_back: "Назад",
    btn_reload: "Перезавантажити",
    btn_home: "Головна",
  },
  split_payment: {
    title: "Цей рахунок буде розділено на {n} платежа",
    payment: "Платіж",
    desc: "Ми утримаємо кошти до тих пір, поки всі платежі не будуть зроблені. Якщо всі платежі не будуть завершені протягом 15 хвилин після першого платежу, всі кошти будуть автоматично повернені.",
  },
  pending_order: {
    title: "Очікуюче замовлення",
    your_order: "Ваше замовлення",
    pending: "Очікує",
    declined: "Відхилено",
    desc: "Ваше замовлення було відправлено і очікує на прийняття. Як тільки воно буде прийняте, ви побачите його активним на сторінці «Замовлення».",
  },
  cancelled_modal: {
    title: "Ваше замовлення скасовано",
    reason: "Причина",
    your_order: "Ваше замовлення",
    reason_of_cancel: "Причина скасування",
  },
  closed_banner: {
    title_cafe: "Заклад наразі зачинено",
    title_delivery: "Доставка зараз недоступна",
    title_takeaway: "Takeway зараз недоступний",
    title_menu: "Меню не доступне",
    desc_cafe:
      "Заклад зараз зачинено, але Ви можете зробити передзамовлення на момент відкриття.",
    desc_delivery:
      "Режим доставки тимчасово недоступний (до {date}). Спробуйте знову пізніше.",
    desc_takeaway:
      "Режим Takeaway тимчасово недоступний (до {date}). Спробуйте знову пізніше.",
    desc_menu:
      "Меню зараз зачинено, але Ви можете зробити передзамовлення на момент відкриття закладу.",
    until: "до",
  },
  split_payment_timeout: {
    title: "Час автоматичного скасування роздільного платежу",
    desc: "Ви не завершили всі платежі у проміжку 15 хвилин з моменту першого платежу. Всі кошти будуть автоматично повернуті протягом ближайших хвилин.",
  },
  delivery_details: {
    title: "Деталі доставки",
    courier_instructions: "Інструкції для кур'єра",
    contactless: "Безконтактно",
    delivery_in: "Доставка за",
    enter_time: "Вкажіть час",
  },
  takeaway_details: {
    title: "Деталі самовивозу",
    takeaway_in: "Самовивіз за",
  },
  client_details: {
    title: "Ваші контакти",
    name: "Ім'я",
    phone: "Телефон",
    email: "Електронна пошта",
    verified: "Підтверджено",
    change: "Змінити",
    name_ph: "Тарас",
  },
  select_address: {
    title: "Адреса доставки",
    subtitle: "Виберіть правильну адресу для доставки",
    add_new: "Додати адресу",
  },
  address_types: {
    home: "Домашнє",
    work: "Робоче",
    other: "Інше",
  },
  edit_address: {
    title: "Адреса доставки",
    address: "Адреса",
    street_ph: "Вулиця",
    house_ph: "Номер будинку",
    place_type: "Тип місця",
    place_type_desc: "Тип місця допоможе кур`єру знайти адресу",
    place_type_ph: "Оберіть тип місця",
    address_details: "Деталі адреси",
    address_details_desc:
      "Детальна інформація допоможе нам швидше доставити замовлення",
    search_ph: "Знайти адресу",
    search_house_ph: "Знайти будинок на ",
    empty_text: "Введіть зверху для початку пошуку",
    no_results_title: "Нічого не знайдено",
    no_results_subtitle: "Будь ласка, спробуйте іншу адресу",
    enter_address: "Введіть адресу",
    save: "Зберегти адресу",
    use_once: "Використати як одноразову адресу",
    out_of_range: "Поза зоною доставки",
  },
  address_place_types: {
    house: "Будинок",
    flat: "Квартира",
    office: "Офіс",
    other: "Інше",
  },
  address_place_details: {
    entrance: "Вхід/Сходи",
    door: "Назва/Номер у дверці",
    instructions: "Інструкції для кур'єра",
    floor: "Поверх",
    flat: "Квартира",
    details: "Деталі адреси",
    building: "Назва будівлі",
    how_to_enter: "Як пройти до будівлі?",
    enter_bell: "Дверний дзвінок/домофон",
    enter_code: "Дверний код",
    enter_open: "Двері відкриті",
    enter_other: "Інше",
    door_code: "Назва/номер на відгукному пристрої",
    where_to_deliver: "Куди доставляти замовлення?",
    deliver_reception: "На реєстрацію",
    deliver_office: "До офісу",
    company_name: "Назва компанії",
    optional: "Додатково",
  },
  select_address_type: {
    title: "Зберегти адресу",
    subtitle: "Виберіть правильну мітку для вашої адреси",
  },
  order_time_modal: {
    title_delivery: "Час доставки",
    title_takeaway: "Час виносу",
    subtitle_delivery: "Оберіть потрібний час для доставки",
    subtitle_takeaway: "Оберіть потрібний час виносу",
    asap: "Якнайшвидше",
    in: "в",
    scheduled_time: "Запланований час",
    today: "Cьогодні",
    tomorrow: "Завтра",
    schedule_short: "Заплановано",
  },
  mode_names: {
    inside: "В закладі",
    takeaway: "Самовивіз",
    preorder: "Самовивіз",
    delivery: "Доставка",
    reserve: "Бронювання",
  },
  system_loader: {
    phrases_menu: [
      "Завантаження...",
      "Завантаження меню...",
      "Підготовка меню...",
      "Створення фотографій страв...",
      "Друк меню...",
      "Завантаження чогось...",
      "Зачекайте, ми дещо робимо...",
      "Друкуємо меню саме для вас...",
    ],
    phrases_order: [
      "Завантаження...",
      "Створення замовлення...",
      "Обробка замовлення...",
      "Відправлення замовлення...",
    ],
  },
  menu_search: {
    welcome: "Введіт запит вище, щоб розпочати пошук",
    nothing_found: "Нічого не знайдено",
    try_words: "Будь-ласка, спробуйте інші ключові слова",
  },
  preview: {
    product_navbar: "Ви можете зробити замовлення в самому закладі.",
  },
  desktop_blocker: {
    title: "Ця сторінка доступна тільки на мобільних пристроях",
    description:
      "Будь-ласка, відскануйте QR-код з мобільного пристрою щоб продовжити",
  },
  delivery_tariff: {
    title: "Доставка",
    price: "Ціна:",

    partner: `Це замовлення буде доставлено третьою стороною-партнером. Вартість доставки буде розрахована окремо згідно тарифів партнера. Вам необхідно буде сплатити вартість доставки готівкою при отриманні.`,

    modal_title: "Спосіб доставки",
    modal_subtitle: "Оберіть, як ви хочете оплатити",
  },
};
