export default {
  home: {
    about: "О нас",
    address: "Адрес",
    socials: "Социальные сети",
    website: "Веб-сайт",
    get_directions: "Проложить маршрут",
  },
  contacts: {
    title: "Контакты",
    phone: "Телефон",
    mail: "Почта",
    website: "Веб-сайт",
    visit: "Посетить",
    call: "Позвонить",
    copy: "Копировать",
  },
  schedule: {
    weekdays: {
      mon: "Понедельник",
      tue: "Вторник",
      wed: "Среда",
      thu: "Четверг",
      fri: "Пятница",
      sat: "Суббота",
      sun: "Воскресенье",
    },
    open_now: "Открыто",
    closes_in: "Закрытие через",
    closed_now: "Закрыто",
    opens_in: "Открытие через",
    day_off: "Выходной",
    schedule: "График работы",
  },
  units: {
    hours: "ч.",
    minutes: "мин.",
    positions: "0 позиций | 1 позиция | {count} позиций",
    items: "0 товаров | 1 товар | {count} товара",
    orders: "0 заказов | 1 заказ | {count} заказов",
    g: "г",
    kg: "кг",
    l: "л",
    ml: "мл",
    min: "мин",
  },
  legals: {
    privacy_policy: "Политика конфиденциальности",
    cookies: "Cookies",
    terms_of_use: "Условия использования",
    public_offert: "Публичная офферта",
    view: "Просмотр",
  },
  reviews: {
    home_title: "Отзывы",
    max_value: "Из 5",
  },
  menu_grid: {
    list: "Список",
  },
  product: {
    description: "Описание",
  },
  common_actions: {
    confirm: "Подтвердить",
    order: "Заказ",
    cancel: "Отмена",
    reserve: "Бронировать",
    next: "Далее",
    pay: "Оплатить",
    search: "Поиск",
    view_cart: "Корзина",
    add: "Добавить",
    remove: "Удалить",
    close: "Закрыть",
    view: "Просмотр",
  },
  reserve: {
    persons: "Персоны",
    reservation: "Бронирование",
    thanks_1: "Спасибо!",
    thanks_2: "Ваше бронирование сделано",
    date: "Дата",
    time: "Время",
    download_pdf: "Загрузить PDF",
    add_to_calendar: "Добавить в календарь",
    deposit: "Депозит",
    paid: "Оплачено",
  },
  checkout: {
    contact_details: "Контактные данные",
    confirmation: "Подтверждение",
    name: "Имя",
    first_name: "Имя",
    last_name: "Фамилия",
    phone: "Телефон",
    email: "Электронная почта",
    comment: "Комментарий",
    contact: "Контакт",
    service: "Сервис",
    date: "Дата",
    time: "Время",
  },
  promocode: {
    title: "У вас есть промокод?",
    subtitle: "Введите его ниже",
    placeholder: "Введите промо",
  },
  total: {
    subtotal: "Сумма",
    discount: "Скидка",
    service_fee: "Сервисный сбор",
    delivery_fee: "Стоимость доставки",
    products_total: "Сумма блюд",
    tip: "Чаевые",
    total: "Итого",
  },
  comment: {
    title: "Ваша заметка",
  },
  phone_verify_modal: {
    title: "Проверка номера телефона",
    enter: "Введите код из SMS",
    sent_to: "Код отправлен на ",
    no_code: "Не получили код?",
    resend_btn: "Отправить снова",
    error: "Неверный код",
    success: "Подтверждено",
  },
  online_payment_modal: {
    redirect: "Вы будете перенаправлены на страницу оплаты",
    btn_pay: "Перейти к оплате",
    btn_cancel: "Отмена",
    redirecting: "Перенаправление на страницу оплаты...",
  },
  pay_methods: {
    cash: "Наличные",
    card: "Карта",
    online: "Онлайн",
    pay_with_cash: "Оплатить наличными",
    pay_with_card: "Оплатить картой",
    pay_with_online: "Оплатить онлайн",
  },
  validations: {
    required: "Обязательное поле",
    phone: "Введите правильный номер телефона",
    email: "Введите действительный адрес электронной почты",
  },
  calendar: {
    date: "Дата",
  },
  phone_country_picker: {
    title: "Выберите регион телефона",
    subtitle: "Выберите код вашего номера телефона из списка ниже",
    placeholder: "Поиск по названию страны...",
    select: "Выбрать",
  },
  menu_lobby: {
    welcome: "Добро пожаловать",
    select_type: "Пожалуйста, выберите тип вашего заказа",
    search_ph: "Блюда, меню, категории...",
    our_menus: "Наши меню",
  },
  call_waiter: {
    title: "Нужна помощь?",
    subtitle: "Позовите официанта за помощью",
    button: "Позвать",
    sent: "Ваш запрос был отправлен",
  },
  scan_qrcode: {
    title: "Подключитесь к столу",
    subtitle: "Сканируйте QR-код на столе",
    button: "Сканировать",

    modal_title: "Сканирование QR-кода",
    modal_subtitle: "QR код расположен на столе",

    modal_cant_scan: `Не можете отсканировать QR-код?`,
    modal_enter_code: "Введите цифровой код ниже",
  },
  verify_location: {
    title: "Гео-локация",
    subtitle: "Нам нужно проверить ваше местоположение",
    button: "Подтвердить",
    verified: "Подтверждено",
  },
  menu_product: {
    badge_spicy: "Острый",
    badge_veggie: "Вегетарианский",
    badge_alcohol: "Алкоголь",
    variants: "Варианты",
    modificators: "Модификаторы",
    variants_single: "Выберите один из предложенных вариантов",
    variants_min: "Минимум",
    variants_max: "Максимум",
    required: "Обязательно",
    modificators_any: "Выберите любое количество предлагаемых опций",
    comment_title: "Ваш комментарий",
    comment_ph: "Введите сюда...",
    show_more: "Показать больше",
    show_less: "Показать меньше",
  },
  cart: {
    title: "Корзина",
    order_type: "Тип заказа",
    delivery_price: "Стоимость доставки",
    min_order: "Минимальная сумма заказа",
    place_order: "Оформить заказ",
    products: "Блюда",
    unavailable_item: "Этот продукт недоступен в ",
    add_more: "Закажите еще из меню",
    comment_title: "Особые требования",
    comment_desc:
      "Есть ли у Вас какие-нибудь особые требования, аллергии или диетические ограничения?",
    comment_ph: "Напишите здесь...",
    utensils: "Приборы",
    amount: "Количество",
    alcohol_alert:
      "Я подтверждаю, что мне исполнилось 21 год и я могу заказывать товары, содержащие алкоголь",
  },
  geo_verification: {
    title: "Геолокация",
    desc: "Нам необходимо проверить Ваше геолокацию, чтобы продолжить заказ",
    please: "Пожалуйста, разрешите доступ к Вашей геолокации",
    verify: "Проверить мою геолокацию",
    dismiss: "Отменить",
    deny_title: "Похоже, Вы запретили доступ к Вашему местоположению",
    deny_subtitle: "Пожалуйста, перезагрузите страницу и попробуйте еще раз",
    reload: "Перезагрузить страницу",
    busy: "Проверка Вашей геолокации...",
    confirmed: "Местоположение подтверждено",
    out_of_range: "Заказ недоступен в Вашем местоположении",
  },
  order_product_card: {
    product_total: "Итого",
    remains: "Остаток:",
  },
  order_page: {
    title: "Текущий заказ",
    your_bill: "Ваш счет",
    select_all: "Выбрать все",
  },
  order_payment_navbar: {
    none_selected: "Выберите продукты, чтобы произвести оплату",
    out_of_range: "Ваш адрес находится за пределами зоны доставки",
    fill_in: "Пожалуйста, заполните все обязательные поля",
    slider_disabled: "Оплатить",
    slider_active: "Проведите, чтобы оплатить",
    total_to_pay: "Всего к оплате",
    including_fees: "Включая коммиссии",
    left_unpaid: "осталось неоплаченным",
  },
  pay_method_modal: {
    title: "Способ оплаты",
    subtitle: "Выберите, как вы хотите оплатить",
    descriptions: {
      cash: "Вы будете оплачивать наличными на месте",
      card: "Вы будете оплачивать картой на месте",
      online: "Вы будете оплачивать онлайн",
    },
  },
  leave_tips: {
    title: "Вы можете оставить чаевые, если хотите",
    thank_you: "Спасибо!",
  },
  personal_tips: {
    title: "Оставьте чаевые",
    role_ph: "Официант",
  },
  receipt: {
    total_paid: "Всего оплачено ",
    including_taxes: "В том числе налоги",
    service_provider: "Поставщик услуг",
    tech_details: "Технические детали",
    order_number: "Номер заказа",
    order_id: "ID заказа",
    date_and_time: "Дата и время",
  },
  payment_details: {
    title: "Детали платежа",
    refunded: "Возвращено",
    paid: "Оплачено",
    unpaid: "Не оплачено",
  },
  archive: {
    active_order: "Текущий заказ",
    archived_order: "Архивированный заказ",
    empty_title: "Здесь пока ничего нет.",
    empty_subtitle:
      "Вы можете заказать из меню.<br>Ваш заказ появится здесь после того, как заведение его примет.",
    open_menu: "Открыть меню",
    title: "Заказы",
    subtitle_1: "Здесь вы можете найти свой активный и выполненные заказы",
  },
  main_navbar: {
    menu: "Меню",
    orders: "Заказы",
    home: "Главная",
  },
  error_modal: {
    title_generic: "Что-то пошло не так",
    title_payment: "Оплата не удалась",
    title_order: "Создать заказ не удалось",
    title_not_found: "Страница не найдена",
    error_code: "Код ошибки",
    please_reload: "Пожалуйста, перезагрузите страницу и попробуйте еще раз",
    btn_back: "Назад",
    btn_reload: "Перезагрузить",
    btn_home: "Домой",
  },
  split_payment: {
    title: "Этот счет будет разделен на {n} платежа",
    payment: "Платеж",
    desc: "Мы удержим средства до тех пор, пока все платежи не будут сделаны. Если все платежи не будут завершены в течение 15 минут после первого платежа, все средства будут автоматически возвращены.",
  },
  pending_order: {
    title: "Ожидающий заказ",
    your_order: "Ваш заказ",
    pending: "Ожидает",
    declined: "Отклонен",
    desc: "Ваш заказ отправлен и ожидает подтверждения. Как только он будет подтвержден, вы увидите его в разделе «Заказы».",
  },
  cancelled_modal: {
    title: "Ваш заказ отменен",
    reason: "Причина",
    your_order: "Ваш заказ",
    reason_of_cancel: "Причина отмены",
  },
  closed_banner: {
    title_cafe: "Заведение закрыто",
    title_delivery: "Доставка в настоящее время недоступна",
    title_takeaway: "Takeway в настоящее время недоступен",
    title_menu: "Меню закрыто",
    desc_cafe:
      "Заведение в настоящее время закрыто, но вы можете оформить предзаказ на момент октрытия.",
    desc_delivery:
      "Режим доставки временно недоступен (до {date}). Пожалуйста, попробуйте позже.",

    desc_takeaway:
      'Режим "С собой" временно недоступен (до {date}). Пожалуйста, попробуйте позже.',
    desc_menu:
      "Меню в настоящее время закрыто, но вы можете оформить предзаказ для момента когда оно опять станет работать.",
    until: "до",
  },
  split_payment_timeout: {
    title: "Тайм-аут разделения платежа",
    desc: "Вы не завершили все платежи в течение 15 минут с момента первого платежа. Все средства будут автоматически возвращены в ближайшие минуты.",
  },
  delivery_details: {
    title: "Детали доставки",
    courier_instructions: "Инструкции для курьера",
    contactless: "Безконтактный",
    delivery_in: "Доставить в",
    enter_time: "Укажите время",
  },
  takeaway_details: {
    title: "Детали заказа на вынос",
    takeaway_in: "Заказ на вынос",
  },
  client_details: {
    title: "Ваши контакты",
    name: "Имя",
    phone: "Телефон",
    email: "Электронная почта",
    verified: "Подтверждено",
    change: "Изменить",
    name_ph: "Иван",
  },
  select_address: {
    title: "Адрес доставки",
    subtitle: "Выберите правильный адрес для доставки",
    add_new: "Добавить адрес",
  },
  address_types: {
    home: "Дом",
    work: "Работа",
    other: "Другое",
  },
  edit_address: {
    title: "Адрес доставки",
    address: "Адрес",
    street_ph: "Улица",
    house_ph: "Номер дома",
    place_type: "Тип места",
    place_type_desc: "Тип места поможет курьеру найти адрес",
    place_type_ph: "Выберите тип места",
    address_details: "Подробности адреса",
    address_details_desc: "Детальная информация поможет нам найти вас позже",
    search_ph: "Найдите свой адрес",
    search_house_ph: "Найти дом на ",
    empty_text: "Начните вводить для поиска",
    no_results_title: "Ничего не найдено",
    no_results_subtitle: "Пожалуйста, попробуйте другой адрес",
    enter_address: "Введите адрес",
    save: "Сохранить адрес",
    use_once: "Использовать в качестве одноразового адреса",
    out_of_range: "Вне зоны доставкиs",
  },
  address_place_types: {
    house: "Дом",
    flat: "Квартира",
    office: "Офис",
    other: "Другое",
  },
  address_place_details: {
    entrance: "Вход / Лестница",
    door: "Название / Номер двери",
    instructions: "Инструкции для курьера",
    floor: "Этаж",
    flat: "Квартира",
    details: "Адресные данные",
    building: "Название здания",
    how_to_enter: "Как войти в здание?",
    enter_bell: "Дверной звонок / домофон",
    enter_code: "Дверной код",
    enter_open: "Дверь открыта",
    enter_other: "Другое",
    door_code: "Название / номер на домофоне",
    where_to_deliver: "Куда доставить заказ?",
    deliver_reception: "На ресепшн",
    deliver_office: "В офис",
    company_name: "Название компании",
    optional: "Необязательно",
  },
  select_address_type: {
    title: "Сохранить адрес",
    subtitle: "Выберите правильную метку для вашего адреса",
  },
  order_time_modal: {
    title_delivery: "Время доставки",
    title_takeaway: "Время выноса",
    subtitle_delivery: "Выберите подходящее время доставки",
    subtitle_takeaway: "Выберите подходящее время выноса",
    asap: "Как можно скорее",
    in: "через",
    scheduled_time: "Запланированное время",
    today: "Сегодня",
    tomorrow: "Завтра",
    schedule_short: "Запланировано",
  },
  mode_names: {
    inside: "В заведении",
    takeaway: "Самовывоз",
    preorder: "Самовывоз",
    delivery: "Доставка",
    reserve: "Бронирование",
  },
  system_loader: {
    phrases_menu: [
      "Загрузка...",
      "Загрузка меню...",
      "Подготовка меню...",
      "Подготовка еды...",
      "Подготовка напитков...",
      "Создание фотографий блюд...",
      "Печать меню...",
      "Загрузка чего-то...",
      "Подождите, мы что-то делаем...",
      "Печатаем меню для вас...",
    ],
    phrases_order: [
      "Загрузка...",
      "Создание заказа...",
      "Передача заказа на исполнение...",
      "Отправка заказа...",
    ],
  },
  menu_search: {
    welcome: "Введите запрос чтобы начать поиск",
    nothing_found: "Ничего не найдено",
    try_words: "Пожалуйста, попробуйте другие слова",
  },
  preview: {
    product_navbar: "Вы можете сделать заказ в самом заведении",
  },
  desktop_blocker: {
    title: "Эта страница доступна только на мобильных устройствах",
    description:
      "Пожалуйста, отсканируйте QR-код на мобильном устройстве чтобы продолжить",
  },
  delivery_tariff: {
    title: "Доставка",
    price: "Цена:",

    partner: `Этот заказ будет доставлен третьей стороной-партнером. Стоимость доставки будет рассчитана отдельно в соответствии с тарифами партнера. Вы должны будете оплатить стоимость доставки наличными на месте.`,

    modal_title: "Способ доставки",
    modal_subtitle: "Выберите, как вы хотите оплатить",
  },
};
