<template>
  <BaseSimpleModal
    modalClass="phone-picker-modal"
    :title="$t('phone_country_picker.title')"
    :subtitle="$t('phone_country_picker.subtitle')"
    :showHeader="true"
    @backdropClick="$emit('close')"
    @close="$emit('close')"
    :showClose="false"
  >
    <template #header>

    </template>

    <!-- <BaseSearchInput
      v-model="search"
      size="md"
      :placeholder="$t('phone_country_picker.placeholder')"
    /> -->

    <BasePhoneInputPicker
      :useBuiltInSearch="true"
      :searchValue="search ? search.toLowerCase() : ''"
      :defaultCountry="country"
      :defaultPhone="phone"
      @select="(v) => (selected = v)"
    />

    <!-- <div class="picker-footer">

    </div> -->
    <template #footer>
      <BasePushButton
        variant="ghost"
        textColor="content-base-secondary"
        size="lg"
        @click="$emit('close')"
        class="w-100"
      >
        {{ $t("common_actions.cancel") }}
      </BasePushButton>
      <BasePushButton
        variant="fill"
        textColor="content-base-inverse-primary"
        bgColor="background-base-inverse-primary-10"
        size="lg"
        @click="onSelected"
        class="w-100"
      >
        {{ $t("common_actions.confirm") }}
      </BasePushButton>
    </template>
  </BaseSimpleModal>
</template>

<script>
// import FoundationModal from './FoundationModal.vue'

export default {
  props: {
    country: String,
    phone: String
  },
  data() {
    return {
      selected: 'UA',
      search: null
    }
  },
  methods: {
    onSelected() {
      this.$emit('selected', this.selected)
      this.$emit('close')
    }
  },
  components: {
    // FoundationModal
  }
}
</script>

<style scoped>
.picker-footer {
  padding: 16px;
  background: var(--qrw-background-base-secondary);
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
</style>

<style>
.phone-picker-modal {
  background: var(--qrw-background-base-primary) !important;
}
</style>
