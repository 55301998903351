<template>
  <div class="app-payment-details">
    <BaseDivider color="border-base-tertiary" />
    <div class="qrw-label-xs bold qrw-text-content-base-tertiary">
      {{ $t("payment_details.title") }}
    </div>
    <template v-for="(item, index) in uiPaymentDetails">
      <div
        class="payment-card"
        :key="index"
      >
        <div class="pay-method-name gap-row-1xs">
          <div class="qrw-label-md bold qrw-text-content-base-primary gap-column-1xs">
            <BaseIcon
              size="sm"
              :icon="payMethodIcons[item.payMethod]"
            />
            {{ $t(`pay_methods.${item.payMethod}`) }}
          </div>
          <div class="qrw-label-sm qrw-text-content-base-tertiary">
            {{ item.date | formatDatetime }}
          </div>
        </div>

        <div class="gap-row-1xs status">
          <PriceTag
            class="qrw-label-md qrw-text-content-base-primary"
            :value="item.amount"
          />
          <BaseLabelBadge
            size="xs"
            primaryColor="background-warning-default"
            secondaryColor="content-base-inverse-primary"
            variant="filled"
            v-if="item.status === 'refunded'"
          >
            {{ $t("payment_details.refunded") }}
          </BaseLabelBadge>
          <BaseLabelBadge
            size="xs"
            primaryColor="background-error-default"
            secondaryColor="content-base-inverse-primary"
            variant="filled"
            v-else-if="item.status === 'unpaid'"
          >
            {{ $t("payment_details.unpaid") }}
          </BaseLabelBadge>
          <BaseLabelBadge
            size="xs"
            primaryColor="background-success-default"
            secondaryColor="content-base-inverse-primary"
            variant="filled"
            v-else-if="item.status == 'paid'"
          >
            {{ $t("payment_details.paid") }}
          </BaseLabelBadge>
        </div>
      </div>
      <BaseDivider
        color="border-base-tertiary"
        dashed
        :key="`divider_${index}`"
        v-if="index != uiPaymentDetails.length - 1"
      />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    order: Object
  },
  computed: {
    payMethodIcons() {
      return {
        cash: 'bank-note',
        card: 'credit-card',
        online: 'wallet'
      }
    },
    uiPaymentDetails() {
      const withStatuses = ['cash', 'card', 'online', 'bonus'].reduce((acc, payMethod) => {
        if (!this.order) return acc
        const sum = this.order.payment_sums[payMethod]
        if (sum) {
          acc[payMethod] = {
            status:
              this.order.payment_statuses && this.order.payment_statuses[payMethod]
                ? this.order.payment_statuses[payMethod]
                : 'paid',
            amount: sum,
            date: this.order.paid_at
          }
        }
        return acc
      }, {})

      return Object.entries(withStatuses).map(([payMethod, details]) => ({
        payMethod,
        ...details
      }))
    },
    currency() {
      return this.$store.state.currency
    }
  }
}
</script>

<style scoped>
.app-payment-details {
  display: flex;
  padding: 0 16px;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  row-gap: 16px;
}

.payment-card {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  justify-content: space-between;
}

.status {
  justify-content: flex-end;
  align-items: flex-end;
}

.pay-method-name {
  justify-content: center;
}
</style>
