import axios from "axios";

const root = axios.create({
  baseURL: "/api/superapp",
  timeout: 60 * 1000,
});

async function makeRequest(method, path, params, data) {
  const response = await root({
    method,
    url: path,
    params,
    data,
  });

  if (!response.data.success) {
    throw new Error(response.data.error || `Unknown error at ${path}`);
  }

  return response.data;
}

export default {
  get: async (path, params) => {
    return makeRequest("GET", path, params);
  },
  post: async (path, params) => {
    return makeRequest("POST", path, null, params);
  },
};
