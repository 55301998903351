import Base from "../base";

export default {
  create: ({
    name,
    email,
    phone,
    cafeId,
    verificationToken,
    address,
    clientId,
  }) =>
    Base.post(`/clients/create`, {
      name,
      email,
      phone,
      cafe_id: cafeId,
      verification_token: verificationToken,
      super_address: address,
      client_id: clientId,
    }),
  getProfile: (clientId) => Base.get(`/clients/profile/${clientId}`),
};
