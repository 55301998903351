export default {
  home: {
    about: "About us",
    address: "Address",
    socials: "Socials",

    website: "Website",

    get_directions: "Get directions",
  },
  contacts: {
    title: "Contacts",
    phone: "Phone",
    mail: "Mail",
    website: "Website",

    visit: "Visit",
    call: "Call",
    copy: "Copy",
  },
  schedule: {
    weekdays: {
      mon: "Monday",
      tue: "Tuesday",
      wed: "Wednesday",
      thu: "Thursday",
      fri: "Friday",
      sat: "Saturday",
      sun: "Sunday",
    },

    open_now: "Open",
    closes_in: "Closes in",

    closed_now: "Closed",
    opens_in: "Opens in",

    day_off: "Day off",
    one_time_off: "One-time day off",

    schedule: "Schedule",
  },
  units: {
    hours: "h.",
    minutes: "m.",
    positions: "0 positions | 1 position | {count} positions",
    items: "0 items | 1 item | {count} items",
    orders: "0 orders | 1 order | {count} orders",
    g: "g",
    kg: "kg",
    l: "l",
    ml: "ml",
    min: "min",
  },
  legals: {
    privacy_policy: "Privacy Policy",
    cookies: "Cookies",
    terms_of_use: "Terms of use",
    public_offert: "Public offert",

    view: "View",
  },
  reviews: {
    home_title: "Reviews",

    max_value: "Out of 5",
  },
  menu_grid: {
    list: "List",
  },
  product: {
    description: "Description",
  },
  common_actions: {
    confirm: "Confirm",
    order: "Order",
    cancel: "Cancel",
    reserve: "Reserve",
    next: "Next",
    pay: "Pay",
    search: "Search",
    view_cart: "View cart",
    add: "Add",
    remove: "Remove",
    close: "Close",
    view: "View",
  },
  reserve: {
    persons: "Persons",

    reservation: "Reservation",

    thanks_1: "Thank you!",
    thanks_2: "Your reservation has been made",

    date: "Date",
    time: "Time",

    download_pdf: "Download PDF",
    add_to_calendar: "Add to calendar",

    deposit: "Deposit",
    paid: "Paid",
  },
  checkout: {
    contact_details: "Contact details",
    confirmation: "Confirmation",
    name: "Name",
    first_name: "First name",
    last_name: "Last name",
    phone: "Phone",
    email: "Email",
    comment: "Comment",
    contact: "Contact",
    service: "Service",
    date: "Date",
    time: "Time",
  },
  promocode: {
    title: "Have a promocode?",
    subtitle: "Enter it below",
    placeholder: "Enter promo",
  },
  total: {
    subtotal: "Subtotal",
    discount: "Discount",
    service_fee: "Service fee",
    delivery_fee: "Delivery fee",
    products_total: "Products total",
    tip: "Tip",
    total: "Total",
  },
  comment: {
    title: "Your note",
  },
  phone_verify_modal: {
    title: "Phone number verification",

    enter: "Enter code from SMS",
    sent_to: "Code sent to ",

    no_code: "Didn't receive the code?",
    resend_btn: "Send again",
    error: "Invalid code",
    success: "Verified",
  },
  online_payment_modal: {
    redirect: "You will be redirected to payment page",
    btn_pay: "Proceed to payment",
    btn_cancel: "Cancel",
    redirecting: "Redirecting to payment page...",
  },
  pay_methods: {
    cash: "Cash",
    card: "Card",
    online: "Online",

    pay_with_cash: "Pay in cash",
    pay_with_card: "Pay by card",
    pay_with_online: "Pay online",
  },
  validations: {
    required: "Required field",
    phone: "Enter correct phone number",
    email: "Enter valid email",
  },
  calendar: {
    date: "Date",
  },
  phone_country_picker: {
    title: "Select phone region",
    subtitle: "Choose your code from the list below",
    placeholder: "Search by country name...",
    select: "Select",
  },
  menu_lobby: {
    welcome: "Welcome",
    select_type: "Please, select your order type",

    search_ph: "Dishes, menus, categories...",

    our_menus: "Our menus",
  },
  call_waiter: {
    title: "Need help?",
    subtitle: "Call waiter for help",

    button: "Call",
    sent: "Your request has been sent",
  },
  scan_qrcode: {
    title: "Connect your table",
    subtitle: "Scan the QR-code on the table",
    button: "Scan",

    modal_title: "Scan the QR-code",
    modal_subtitle: "QR code is located on the table",

    modal_cant_scan: `Can't scan the QR code?`,
    modal_enter_code: "Enter the digital code below",
  },
  verify_location: {
    title: "Geo-location",
    subtitle: "We need to verify your location",
    button: "Verify",

    verified: "Verified",
  },
  menu_product: {
    badge_spicy: "Spicy",
    badge_veggie: "Veggie",
    badge_alcohol: "Alcohol",

    variants: "Variants",
    modificators: "Modificators",

    variants_single: "Select one of the provided variants",

    variants_min: "Min",
    variants_max: "Max",

    required: "Required",

    modificators_any: "Select any amount of provided additions",

    comment_title: "Your comment",
    comment_ph: "Type here...",

    show_more: "Show more",
    show_less: "Show less",
  },
  cart: {
    title: "Cart",
    order_type: "Order type",

    delivery_price: "Delivery price",
    min_order: "Minimal order sum",

    place_order: "Place an order",

    products: "Products",

    unavailable_item: "This product is not available in ",

    add_more: "Order more from menu",

    comment_title: "Special requirements",
    comment_desc:
      "Do you have any special requests, allergies or dietary restrictions?",
    comment_ph: "Type here...",
    utensils: "Utensils",
    amount: "Amount",

    alcohol_alert:
      "I confirm that I am 21+ years old and can order alcohol-containing items",
  },
  geo_verification: {
    title: "Geo-verification",
    desc: "We need to verify your geolocation to proceed the order",
    please: "Please, provide access to your geolocation",

    verify: "Verify my geolocation",
    dismiss: "Dismiss",

    deny_title: "Looks like you denied access to your location",
    deny_subtitle: "Please, reload the page and try again",

    reload: "Reload page",

    busy: "Verifying your geolocation...",

    confirmed: "Location confirmed",

    out_of_range: "Service is unavailable at your location",
  },
  order_product_card: {
    product_total: "Product total",
    remains: "Remains:",
  },
  order_page: {
    title: "Current order",
    your_bill: "Your bill",
    select_all: "Select all",
  },
  order_payment_navbar: {
    none_selected: "Select products to make payment",
    out_of_range: "Your address is out of delivery range",
    fill_in: "Please fill in all required fields",

    slider_disabled: "Pay",
    slider_active: "Swipe to pay",

    total_to_pay: "Total to pay",
    including_fees: "Including fees",

    left_unpaid: "left unpaid",
  },
  pay_method_modal: {
    title: "Payment method",
    subtitle: "Choose how you would like to pay",

    descriptions: {
      cash: "You will pay in cash at the place",
      card: "You will pay with card at the place",
      online: "You will pay online",
    },
  },
  leave_tips: {
    title: "You can leave a tip if you want",
    thank_you: "Thank you!",
  },
  personal_tips: {
    title: "Leave tips",
    role_ph: "Waiter",
  },
  receipt: {
    total_paid: "Total paid",
    including_taxes: "Including taxes",
    service_provider: "Service provider",
    tech_details: "Technical details",
    order_number: "Order number",
    order_id: "Order ID",
    date_and_time: "Date and time",
  },
  payment_details: {
    title: "Payment details",
    refunded: "Refunded",
    paid: "Paid",
    unpaid: "Unpaid",
  },
  archive: {
    active_order: "Current order",
    archived_order: "Archived order",

    empty_title: "Nothing here yet",
    empty_subtitle:
      "You can order from menu.<br>Your order will appear here after cafe accepts it.",
    open_menu: "Open menu",

    title: "Orders",
    subtitle_1: "Here you can find your active and archived orders",
  },
  main_navbar: {
    menu: "Menu",
    orders: "Orders",
    home: "Home",
  },
  error_modal: {
    title_generic: "Something went wrong",
    title_payment: "Payment failed",
    title_order: "Unable to create an order",
    title_not_found: "Page does not exist",

    error_code: "Error code",

    please_reload: "Please, reload the page and try again",

    btn_back: "Back",
    btn_reload: "Reload",
    btn_home: "Home",
  },
  split_payment: {
    title: "This bill will be splitted into {n} payments",
    payment: "Payment",
    desc: "We will hold the funds until all payments are made. If all payments are not completed within 15 minutes from the time of the first payment, all funds will be automatically returned.",
  },
  pending_order: {
    title: "Pending order",
    your_order: "Your order",
    pending: "Pending",
    declined: "Declined",

    desc: "Your order has been sent and waits for an acceptation. Once, it will be accepted you will see it active inside “Orders” page.",
  },
  cancelled_modal: {
    title: "Your order was declined",
    reason: "Reason",
    your_order: "Your order",
    reason_of_cancel: "Decline reason",
  },
  closed_banner: {
    title_cafe: "Cafe is currently closed",
    title_delivery: "Delivery is currently unavailable",
    title_takeaway: "Takeway is currently unavailable",
    title_menu: "Menu is closed",

    desc_cafe:
      "The cafe is currently closed, but you can place an advance order for when it is operational.",
    desc_delivery:
      "Delivery mode is temporarily unavailable (until {date}). Please, try again later.",
    desc_takeaway:
      "Takeaway mode is temporarily unavailable (until {date}). Please, try again later.",
    desc_menu:
      "The menu is currently closed, but you can place an advance order for when it is operational again.",

    until: "until",
  },
  split_payment_timeout: {
    title: "Split payment timeout",
    desc: "You have not completed all payments within 15 minutes from the time of the first payment. All funds will be automatically returned in near minutes.",
  },
  delivery_details: {
    title: "Delivery details",
    courier_instructions: "Instructions for courier",
    contactless: "Contactless",
    delivery_in: "Delivery in",
    enter_time: "Select time",
  },
  takeaway_details: {
    title: "Takeaway details",
    takeaway_in: "Takeaway in",
  },
  client_details: {
    title: "Your contacts",
    name: "Name",
    phone: "Phone",
    email: "Email",
    verified: "Verified",
    change: "Change",
    name_ph: "John Doe",
  },
  select_address: {
    title: "Delivery Address",
    subtitle: "Choose right address for delivery",
    add_new: "Add address",
  },
  address_types: {
    home: "Home",
    work: "Work",
    other: "Other",
  },
  edit_address: {
    title: "Delivery Address",
    address: "Address",

    street_ph: "Street and building",
    house_ph: "House number",

    place_type: "Place type",
    place_type_desc: "Place type will help the courier find the address",
    place_type_ph: "Select place type",

    address_details: "Address Details",
    address_details_desc: "Detailed information will help us find you later",

    search_ph: "Find your address",
    empty_text: "Type above to start searching",
    no_results_title: "Nothing found",
    no_results_subtitle: "Please, try another address",
    enter_address: "Enter address",
    search_house_ph: "Find house on ",

    save: "Save address",
    use_once: "Use as one-time address",
    out_of_range: "Out of delivery range",
  },
  address_place_types: {
    house: "House",
    flat: "Flat",
    office: "Office",
    other: "Other",
  },
  address_place_details: {
    entrance: "Entrance / Stairs",
    door: "Name / Number at door",
    instructions: "Instructions for courier",
    floor: "Floor",
    flat: "Flat",
    details: "Address details",
    building: "Building name",

    how_to_enter: "How to enter the building?",
    enter_bell: "Door bell / intercom",
    enter_code: "Door code",
    enter_open: "Door is open",
    enter_other: "Other",

    door_code: "Name / number on the intercom",

    where_to_deliver: "Where to deliver the order?",
    deliver_reception: "To reception",
    deliver_office: "To office",

    company_name: "Company name",

    optional: "Optional",
  },
  select_address_type: {
    title: "Save Address",
    subtitle: "Select right label for your address",
  },
  order_time_modal: {
    title_delivery: "Delivery Time",
    title_takeaway: "Takeaway Time",
    subtitle_delivery: "Choose the right time for delivery",
    subtitle_takeaway: "Choose the right time for takeaway",
    asap: "As soon as possible",
    in: "in",
    scheduled_time: "Scheduled time",
    today: "Today",
    tomorrow: "Tomorrow",
    schedule_short: "Scheduled",
  },
  mode_names: {
    inside: "Eat In",
    takeaway: "Takeaway",
    preorder: "Takeaway",
    delivery: "Delivery",
    reserve: "Reserve",
  },
  system_loader: {
    phrases_menu: [
      "Loading...",
      "Loading menu...",
      "Preparing menu...",
      "Preparing food...",
      "Preparing drinks...",
      "Making dish photos...",
      "Printing menu...",
      "Loading something...",
      "Wait while we work...",
      "Printing menu just for you...",
    ],
    phrases_order: [
      "Loading...",
      "Creating order...",
      "Firing the order...",
      "Telling the chef about new order...",
    ],
  },
  menu_search: {
    welcome: "Type above to start searching",
    nothing_found: "Nothing found",
    try_words: "Please try different words",
  },
  preview: {
    product_navbar: "You can make an order on the spot",
  },
  desktop_blocker: {
    title: "This page is only available on mobile devices",
    description: "Please, scan the QR-code with your phone to continue",
  },
  delivery_tariff: {
    title: "Delivery",
    price: "Price:",

    partner: `This order will be delivered by the third-party partner. The delivery price will be calculated separately due to the partner's tariffs. You will have to pay the delivery price in cash at the place.`,

    modal_title: "Delivery method",
    modal_subtitle: "Choose how would you like to pay",
  },
};
