<template>
  <BaseSimpleModal
    :showTitle="false"
    :showHeader="false"
    :fullHeight="false"
    :showFooter="false"
    @backdropClick="$emit('close')"
  >
    <div class="pay-modal-header">
      <div class="qrw-heading-sm bold qrw-text-content-base-primary">
        {{ $t("pay_method_modal.title") }}
      </div>
      <div class="qrw-paragraph-md qrw-text-content-base-secondary">
        {{ $t("pay_method_modal.subtitle") }}
      </div>
    </div>
    <div class="pay-methods">
      <div
        class="pay-method-card"
        :class="{ active: method === selectedMethod }"
        v-for="method in enabledMethods"
        @click="selectedMethod = method"
        :key="method"
      >
        <div class="card-title">
          <BaseIcon
            size="sm2"
            :icon="payMethodIcons[method]"
          />
          <div class="qrw-label-lg bold w-100">
            {{ $t("pay_methods." + method) }}
          </div>
          <BaseBetaRadioButton
            :value="selectedMethod == method"
            size="lg"
            class="card-toggle"
          />
        </div>

        <div class="qrw-label-sm card-description">
          {{ $t("pay_method_modal.descriptions." + method) }}
        </div>
      </div>
    </div>

    <div class="payment-modal-footer qrw-app-bottom-container">
      <BaseDivider color="border-base-tertiary" />
      <div class="gap-column-xs payment-confirm-buttons w-100">
        <BasePushButton
          variant="ghost"
          textColor="content-base-secondary"
          size="lg"
          @click="$emit('close')"
          class="w-100"
        >
          {{ $t("common_actions.cancel") }}
        </BasePushButton>
        <BasePushButton
          variant="fill"
          textColor="content-base-inverse-primary"
          bgColor="background-base-inverse-primary-10"
          size="lg"
          @click="onConfirm"
          class="w-100"
        >
          {{ $t("common_actions.confirm") }}
        </BasePushButton>
      </div>
    </div>
  </BaseSimpleModal>
</template>

<script>
export default {
  props: {
    payMethods: Object,
    value: String
  },
  data() {
    return {
      selectedMethod: null
    }
  },
  methods: {
    onConfirm() {
      this.$emit('input', this.selectedMethod)

      this.$emit('close')
    }
  },
  mounted() {
    this.selectedMethod = this.value || this.enabledMethods[0] || null
  },
  computed: {
    allPayMethods() {
      return ['cash', 'card', 'online']
    },
    payMethodIcons() {
      return {
        cash: 'bank-note',
        card: 'credit-card',
        online: 'globe'
      }
    },
    enabledMethods() {
      return this.allPayMethods.reduce((acc, method) => {
        if (this.payMethods[method]) {
          acc.push(method)
        }
        return acc
      }, [])
    }
  }
}
</script>

<style scoped>
.pay-modal-header {
  display: flex;
  padding: 16px 16px;
  box-sizing: border-box;
  row-gap: 8px;
  background: var(--qrw-background-base-secondary-08);
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pay-methods {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px;
  background: var(--qrw-background-base-secondary-10);
}

.pay-method-card {
  border-radius: 12px;
  display: flex;
  padding: 16px;
  row-gap: 8px;
  flex-direction: column;
  border: 1px solid var(--qrw-border-base-tertiary);
  color: var(--qrw-content-base-tertiary);

  position: relative;
}

.pay-method-card.active {
  border: 1px solid var(--qrw-border-success-primary);
  box-shadow: var(--qrw-shadow-card-light);
}

.pay-method-card.active .card-title {
  color: var(--qrw-content-base-primary);
}

.pay-method-card.active .card-description {
  color: var(--qrw-content-base-secondary);
}

.card-title {
  display: flex;
  column-gap: 8px;
  align-items: center;
  color: var(--qrw-content-base-secondary);
}

.payment-modal-footer {
  display: flex;
  flex-direction: column;
  row-gap: 0;
  width: 100%;
  box-sizing: border-box;
  background: var(--qrw-background-base-secondary-08);
}

.payment-confirm-buttons {
  display: flex;
  column-gap: 16px;
  padding: 12px 16px;
  box-sizing: border-box;
  justify-content: space-between;
}
</style>
