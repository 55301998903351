<template>
  <div class="app-menu-page">
    <div class="menu-page-top-container qrw-app-top-sticky" v-qrw-class-on-scroll="{
      limit: 0,
      cssClass: 'qrw-app-top-container'
    }" v-qrw-is-header>
      <ClosedBanner :type="closureFlag" v-if="closureFlag" />
      <MenuPageHeader :menu="selectedMenu" @switchLanguage="showLanguageSwitchUI" @back="onBackClicked"
        @action="openSearch" />
    </div>

    <div class="menu-content" v-qrw-under-header v-qrw-above-footer>
      <div class="search-section" @click="openSearch">
        <div class="qrw-label-lg qrw-text-content-base-primary bold">
          {{ $t("common_actions.search") }}
        </div>
        <BaseSearchInput :placeholder="$t('menu_lobby.search_ph')" size="md" />
      </div>
      <div class="category-card-wrapper" :class="{
      'first-category': index === 0
    }" v-for="(category, index) in filteredCategories" :key="category._id">
        <MenuCategoryCard :menu="selectedMenu" :category="category" @productBuy="onProductBuy"
          @productRemove="onProductRemove" />
        <!-- <div class="category-divider-block" :key="`divider_${category._id}`">
          <BaseDivider color="border-base-tertiary" class="category-divider" />
        </div> -->
      </div>
      <CallWaiterBlock noBackground v-if="mode === 'inside'" />
    </div>

    <transition name="bottom-navbar-appear">
      <div class="qrw-app-bottom-container qrw-app-bottom-sticky" v-qrw-is-footer>
        <MenuCarouselNavbar />
        <MainNavbar activeTab="menu" :sticky="false" />
      </div>

    </transition>

    <LanguagePickerModal @close="languagePickerShown = false" v-if="languagePickerShown" />
  </div>
</template>

<script>
import MenuPageHeader from '@/components/header/MenuPageHeader.vue'
import MenuCategoryCard from '@/components/menu/MenuCategoryCard.vue'

import MainNavbar from '@/components/navbar/MainNavbar.vue'
import CallWaiterBlock from '@/components/menu/CallWaiterBlock.vue'
import MenuCarouselNavbar from '@/components/navbar/MenuCarouselNavbar.vue'

import ClosedBanner from '@/components/header/ClosedBanner.vue'

import LanguagePickerModal from '@/components/modals/LanguagePickerModal.vue'

export default {
  components: {
    MenuPageHeader,
    MenuCategoryCard,
    CallWaiterBlock,
    MainNavbar,
    ClosedBanner,
    MenuCarouselNavbar,
    LanguagePickerModal
  },
  data () {
    return {
      languagePickerShown: false
    }
  },
  computed: {
    selectedMenu () {
      if (this.$store.state.menus.length === 1) return this.$store.state.menus[0]
      return this.$store.state.menus.find(m => m._id === this.$route.params.menu_id)
    },
    cartItems () {
      return this.$store.getters['cart/items']
    },
    cartTotal () {
      return this.$store.getters['cart/total']
    },
    shouldShowCartNavbar () {
      return this.cartItems.length > 0
    },
    closureFlag () {
      if (this.$store.getters.isCafeClosed) {
        return 'cafe'
      }

      if (this.$store.getters.isSystemClosed) {
        return this.$store.state.mode === 'delivery' ? 'delivery' : 'takeaway'
      }

      if (this.$store.getters.isMenuClosed(this.selectedMenu._id)) {
        return 'menu'
      }

      return null
    },
    cafeClosedFlag () {
      return this.$store.getters.isCafeClosed
    },
    mode () {
      return this.$store.state.mode
    },
    categoriesWithProducts () {
      return this.selectedMenu.categories
        .map(category => category._id)
        .filter(id => this.selectedMenu.products.filter(product => product.category_id === id).length > 0)
    },
    filteredCategories () {
      return this.selectedMenu.categories.filter(category => this.categoriesWithProducts.includes(category._id))
    }
  },
  methods: {
    onBackClicked () {
      this.$router.push(this.$store.getters.buildRouterNavigateObject('lobby'))
    },
    onProductBuy (product) { },
    onProductRemove (product) { },
    shareMenuLink () {
      window.navigator.share({
        title: this.selectedMenu.tag,
        url: window.location.href
      })
    },
    openSearch () {
      this.$router.push(this.$store.getters.buildRouterNavigateObject('search'))
    },
    showLanguageSwitchUI () {
      this.languagePickerShown = true
    }
  },
  mounted () {
    this.$store.commit('setPageWaypoint', {
      pageName: 'menu',
      path: this.$route.fullPath
    })
  }
}
</script>

<style scoped>
.menu-content {
  background: var(--qrw-background-base-secondary-10);
}

.category-divider-block {
  height: 16px;
  width: 100%;
  min-height: 16px;
  display: flex;
  align-items: flex-end;
}

.category-divider {
  min-height: 4px !important;
  height: 4px !important;
}

.category-card-wrapper {
  padding: 16px 0;
}

.first-category {
  padding-top: 4px !important;
}

.search-section {
  padding: 16px;
  background: var(--qrw-background-base-secondary);
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
</style>
