<template>
  <BaseLanguagePicker
    :value="$i18n.locale"
    @input="onLocaleChanged"
    :include="supportedLanguages"
    :uiLocale="uiLocale"
    @close="$emit('close')"
    :showClose="false"
  />
</template>

<script>
import { getSupportedLanguages, getBrowserLanguages } from "@/i18n";

import moment from "moment";

export default {
  methods: {
    onLocaleChanged(loc) {
      this.$i18n.locale = loc;

      moment.locale(this.$i18n.locale);

      window.localStorage.setItem("preferred_locale", loc);
    },
  },
  computed: {
    supportedLanguages() {
      let allLanguages = ["en"];

      if (this.$store.state.menus && this.$store.state.menus.length) {
        const unmerged = [
          "en",
          ...getBrowserLanguages().map((lang) => lang.split("-")[0]),
        ];

        for (const menu of this.$store.state.menus) {
          unmerged.push(menu.original_locale);

          if (menu.locales) {
            unmerged.push(...menu.locales);
          }
        }

        allLanguages = Array.from(new Set(unmerged));
      }

      const result = allLanguages.filter((lang) =>
        getSupportedLanguages().includes(lang)
      );

      console.log(
        `[UITranslationsManager] Filtered languages: ${allLanguages.join()}, supported: ${getSupportedLanguages().join()} => ${result.join()}`
      );

      return result;
    },
    uiLocale() {
      const uiLocales = ["en", "ru", "uk"];

      return uiLocales.includes(this.$i18n.locale) ? this.$i18n.locale : "en";
    },
  },
};
</script>

<style></style>
