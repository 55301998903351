<template>
  <BaseSimpleModal
    :fullHeight="false"
    :showHeader="false"
    :showFooter="false"
    @backdropClick="close"
  >
    <div class="error-body">
      <div class="error-header">
        <BaseIcon
          icon="info-circle"
          class="qrw-text-content-error-primary"
          size="sm2"
        />
        <div class="header-title qrw-heading-xs bold qrw-text-content-error-primary">
          {{ $t("split_payment_timeout.title") }}
        </div>
        <!-- <div class="qrw-paragraph-sm qrw-text-content-base-tertiary">
          {{ $t('error_modal.error_code') }}: {{ code }}
        </div> -->
      </div>
      <BaseDivider color="border-base-tertiary" />
      <SplitPaymentBanner
        contentsOnly
        :splits="splits"
      />
      <BaseDivider color="border-base-tertiary" />
      <div class="qrw-label-md qrw-text-content-base-primary hint-text">
        {{$t('split_payment_timeout.desc')}}
      </div>
    </div>

    <div class="split-timeout-footer gap-row-zero qrw-app-bottom-container">
      <BaseDivider color="border-base-tertiary" />
      <div class="button-container">
        <BasePushButton
          variant="ghost"
          borderColor="border-base-tertiary"
          textColor="content-base-secondary"
          size="lg"
          @click="close"
          class="w-100"
        >
          {{ $t("common_actions.close") }}
        </BasePushButton>
      </div>
    </div>
  </BaseSimpleModal>
</template>

<script>
import SplitPaymentBanner from '@/components/order/SplitPaymentBanner.vue'

export default {
  components: {
    SplitPaymentBanner
  },
  data() {
    return {
      splits: []
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  },
  computed: {
    order() {
      return this.$store.state.order
    }
  },
  mounted() {
    this.splits = this.order.split_payments
  }
}
</script>

<style scoped>
.error-body {
  background: var(--qrw-background-error-primary);
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.split-timeout-footer {
  background: var(--qrw-background-error-primary);
  display: flex;
}

.error-header {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  justify-content: center;
  align-items: center;
}

.hint-text {
  width: 100%;
  text-align: center;
}

.header-title {
  width: 100%;
  text-align: center;
}

.button-container {
  display: flex;
  box-sizing: border-box;
  padding: 12px 16px;
  row-gap: 8px;
}
</style>
