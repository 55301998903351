<template>
  <BaseSimpleModal
    :fullHeight="false"
    :showFooter="false"
    :showHeader="false"
    modalClass="qrw-fit-height-modal"
    @backdropClick="$emit('close')"
  >
    <div class="gap-row-sm geo-modal-body">
      <div class="geo-texts">
        <BaseIcon
          size="sm2"
          icon="globe"
          class="qrw-text-content-base-primary"
        />
        <div class="qrw-text-content-base-primary qrw-heading-sm bold geo-centrify">
          {{ $t("geo_verification.title") }}
        </div>
        <div class="qrw-paragraph-sm qrw-text-content-base-tertiary">
          {{ $t("geo_verification.desc") }}
        </div>
      </div>

      <BaseDivider color="border-base-tertiary" />

      <template v-if="uiState === 'default'">
        <div class="qrw-label-md qrw-text-content-base-primary geo-centrify">
          {{ $t("geo_verification.please") }}
        </div>
      </template>

      <template v-else-if="uiState === 'deny'">
        <div class="gap-row-sm">
          <div class="qrw-label-lg qrw-text-content-error-primary geo-centrify">
            {{ $t("geo_verification.deny_title") }}
          </div>

          <div class="qrw-label-md qrw-text-content-base-secondary geo-centrify">
            {{ $t("geo_verification.deny_subtitle") }}
          </div>
        </div>
      </template>

      <template v-else-if="uiState === 'busy'">
        <div class="gap-row-1xs geo-centrify qrw-text-content-base-secondary">
          <BaseLoaderIcon size="sm2" />
          <div class="qrw-label-md geo-centrify">
            {{ $t("geo_verification.busy") }}
          </div>
        </div>
      </template>

      <template v-else-if="uiState === 'success'">
        <div class="gap-row-1xs geo-centrify">
          <BaseIcon
            icon="check-circle"
            size="sm2"
            class="qrw-text-content-success-primary"
          />

          <div class="qrw-label-md bold qrw-text-content-base-primary geo-centrify">
            {{ $t("geo_verification.confirmed") }}
          </div>
        </div>
      </template>

      <template v-else-if="uiState === 'out_of_range'">
        <div class="gap-row-1xs geo-centrify">
          <BaseIcon
            icon="x-circle"
            size="sm2"
            class="qrw-text-content-error-primary"
          />

          <div class="qrw-label-md qrw-text-content-error-primary geo-centrify">
            {{ $t("geo_verification.out_of_range") }}
          </div>
        </div>
      </template>

      <BaseDivider color="border-base-tertiary" />

      <div class="gap-row-xs">
        <BasePushButton
          variant="fill"
          bgColor="background-base-inverse-primary"
          textColor="content-base-inverse-primary"
          size="lg"
          @click="requestGeolocation"
          class="w-100"
          v-if="uiState === 'default'"
        >
          {{ $t("geo_verification.verify") }}
        </BasePushButton>

        <BasePushButton
          variant="outlined"
          textColor="content-base-primary"
          borderColor="border-base-tertiary"
          size="lg"
          @click="reloadPage"
          class="w-100"
          v-if="uiState === 'deny'"
        >
          {{ $t("geo_verification.reload") }}
        </BasePushButton>

        <BasePushButton
          variant="ghost"
          textColor="content-base-secondary"
          size="lg"
          @click="$emit('close')"
          class="w-100"
        >
          {{
            ["default", "busy", "deny"].includes(uiState)
              ? $t("geo_verification.dismiss")
              : $t("common_actions.close")
          }}
        </BasePushButton>
      </div>
    </div>
  </BaseSimpleModal>
</template>

<script>
import { requestGeolocationAsPromise } from '@/utils/geolocation'

import InsideAPI from '@/api/inside'

export default {
  data() {
    return {
      uiState: 'default'
    }
  },
  methods: {
    async requestGeolocation() {
      this.uiState = 'busy'

      try {
        const pos = await requestGeolocationAsPromise()

        // TODO: add backend logic later

        // console.log(pos)

        const { result } = await InsideAPI.verifyGeolocation(this.$store.state.inside.access_code, pos.lat, pos.lon)

        if (!result.passed) {
          this.uiState = 'out_of_range'
        } else {
          await new Promise((resolve, reject) => setTimeout(resolve, 500))

          this.uiState = 'success'

          setTimeout(() => {
            this.$emit('confirmed', result.geolocation_token)
            this.$emit('close')
          }, 1500)
        }
      } catch (err) {
        this.uiState = 'deny'
      }
    },
    reloadPage() {
      window.location.reload()
    }
  }
}
</script>

<style scoped>
.geo-texts {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  justify-content: center;
  align-items: center;
}

.geo-modal-body {
  padding: 16px;
}

.geo-centrify {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
