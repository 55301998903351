import Base from "../base";

export default {
  prepare: (cafeLink, mode, cartId) =>
    Base.get(`/preorder/${cafeLink}/prepare/${mode}`, { cart_id: cartId }),
  create: (cafeLink, data) => Base.post(`/preorder/${cafeLink}/create`, data),
  searchAddresses: (cafeLink, search, language) =>
    Base.get(`/delivery/${cafeLink}/search-addresses`, { search, language }),
  getOrder: (orderId) => Base.get(`/preorders/${orderId}`),
  estimateTariffs: (cafeLink, address, lat, lon) =>
    Base.get(`/delivery/${cafeLink}/estimate-tariffs`, { address, lat, lon }),
};
