<template>
  <div class="app-foundation-modal-wrapper">
    <div class="app-foundation-modal-backdrop" @click="$emit('backdropClick')"></div>

    <div class="app-foundation-modal" :class="{
      [modalClass]: modalClass,
      'full-height': fullHeight
    }">
      <div class="modal-header" v-if="showHeader">
        <div class="qrw-heading-xs bold qrw-text-content-base-primary w-100">
          {{ title }}
        </div>
        <BaseIconButton
          icon="x"
          variant="ghost"
          textColor="content-base-secondary"
          size="md"
          @click="$emit('close')"
        />
      </div>
      <div class="modal-body">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
const preventDefaultScroll = (e) => {
  e.preventDefault()
}

export default {
  props: {
    title: String,
    showHeader: {
      type: Boolean,
      default: true
    },
    modalClass: String,
    lockScroll: {
      type: Boolean,
      default: true
    },
    fullHeight: {
      type: Boolean,
      default: true
    }
  },
  beforeDestroy () {
    if (this.lockScroll) {
      window.removeEventListener('touchmove', preventDefaultScroll)
      window.document.body.removeEventListener(
        'touchmove',
        preventDefaultScroll
      )

      document.body.style.overflow = 'scroll'
    }
  },
  mounted () {
    if (this.lockScroll) {
      document.body.style.overflow = 'hidden'

      // window.addEventListener('touchmove', preventDefaultScroll, {
      //   passive: false
      // })
      // window.document.body.addEventListener('touchmove', preventDefaultScroll, {
      //   passive: false
      // })
    }
  }
}
</script>

<style scoped>
.app-foundation-modal-backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;

  top: 0;
  left: 0;

  z-index: 50;
  background: var(--qrw-background-base-inverse-primary-06);
  backdrop-filter: blur(2px);
}

.app-foundation-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: var(--qrw-shadow-modal);
  border-radius: 24px 24px 0 0;
  background: var(--qrw-background-base-secondary);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 55;
  width: 100%;
}

.app-foundation-modal.full-height {
  height: calc(100vh * 0.8);
}

.modal-header {
  background: var(--qrw-overlay-a80);
  backdrop-filter: blur(20px);
  padding: 12px 12px 12px 24px;
  display: flex;
  flex-direction: row;
  row-gap: 12px;
  align-items: center;
}

.modal-body {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
