import TelemetryAPI from "@/api/telemetry";

let telemetryId = null;
let pending = [];
let pendingGestures = [];

let syncTimerId = null;

let lastCafeId = null;

const Events = {
  OpenPage: "open_page",
  ChangeLocale: "change_locale",
  MakeOrder: "make_order",
  CallService: "call_serivce",
  ViewCategory: "view_category",
  ViewProduct: "view_product",
  ClickRecommendation: "click_recommendation",
  BuyProduct: "buy_product",
  SendOrder: "send_order",
  CancelOrder: "cancel_order",
  OrderUpdate: "order_update",
  GiveGeolocation: "give_geolocation",
  StartPaying: "start_paying",
  ChoosePayMethod: "choose_pay_method",
  ViewTutorial: "view_tutorial",
  UseActiveOrder: "use_active_order",
  SwitchMenu: "switch_menu",
  SendCreateRequest: "send_create_request",
};

function syncGestures() {
  if (pendingGestures.length === 0) return;

  TelemetryAPI.writeGestures(telemetryId, pendingGestures)
    .then((data) => {
      console.log(`[Telemetry] Synced ${pendingGestures.length} gestures.`);
      pendingGestures = [];
    })
    .catch((error) => {
      console.error(`[Telemetry] Syncing gestures failed: ${error}`);
      pendingGestures = [];
    });
}

function init(cafeId, mode, platform) {
  if (telemetryId && !lastCafeId) {
    lastCafeId = cafeId;
    TelemetryAPI.changeTarget(telemetryId, cafeId, mode).catch((err) => {
      console.log(`Telemetry changeTraget error: ${err}`);
    });
    return;
  }

  TelemetryAPI.initSession({
    cafe_id: cafeId,
    mode,
    platform,
    device: {
      screen_width: window.screen.width,
      screen_height: window.screen.height,
    },
  })
    .then((response) => {
      telemetryId = response.data.id;

      pending.forEach((ev) => sendEvent(ev));
      pending = [];

      window.rrwebRecord({
        emit(event) {
          pendingGestures.push(event);
        },
      });

      lastCafeId = cafeId;

      if (syncTimerId != null) clearInterval(syncTimerId);

      syncTimerId = setInterval(syncGestures, 5 * 1000);
    })
    .catch((error) => {
      console.error(`[Telemetry] Error while creating session: ${error}`);
    });
}

function sendEvent(ev) {
  TelemetryAPI.writeEvent(telemetryId, ev.type, ev.payload, ev.timestamp)
    .then((ok) => {
      // ok
    })
    .catch((error) => {
      console.error(`[Telemetry] Event sync error: ${error}`);
    });
}

function emit(type, payload) {
  if (!telemetryId) {
    pending.push({
      type,
      payload,
      timestamp: Date.now(),
    });
    return;
  }

  sendEvent({
    type,
    payload,
    timestamp: Date.now(),
  });
}

function getTelemetryId() {
  return telemetryId;
}

function addMilestone(type) {
  TelemetryAPI.setMilestone(telemetryId, type);
}

export default {
  Events,
  init,
  emit,
  getTelemetryId,
  addMilestone,
};
