<template>
  <!-- <div class="scroll-transition-header"> -->
  <transition
    name="appear"
    v-if="!isShowingSecondaryHeader || useSameComponent"
  >
    <slot
      name="primary"
      :transitionData="{ isSwitched: isShowingSecondaryHeader }"
    />
  </transition>

  <transition name="appear" v-else>
    <slot name="secondary" />
  </transition>
  <!-- </div> -->
</template>

<script>
import {
  setAdaptiveFrozen,
  updateAdaptiveHeaderHeight
} from '@/utils/adaptiveUi.js'

export default {
  data () {
    return {
      isShowingSecondaryHeader: false,
      lastScrollPosition: 0,
      isCheckingStopped: false
    }
  },
  props: {
    scrollLimit: {
      type: Number,
      default: 2
    },
    heightDifference: {
      type: Number,
      default: 0
    },
    useSameComponent: {
      type: Boolean,
      default: false
    },
    useStatic: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isShowingSecondaryHeader (value) {
      setAdaptiveFrozen(value)

      this.$emit('switch', this.isShowingSecondaryHeader)
    }
  },
  methods: {
    getScrollTarget () {
      return window.document.documentElement
    },
    onWindowScroll () {
      const scrollValue = this.getScrollTarget().scrollTop

      if (scrollValue === 0) {
        console.log('STH: resetting...')
        if (this.useStatic) {
          setAdaptiveFrozen(true)
          this.$nextTick(() => {
            updateAdaptiveHeaderHeight(0)
          })

          setTimeout(() => {
            setAdaptiveFrozen(false)
          }, 400)
        }
        this.isShowingSecondaryHeader = false
        return
      }

      if (this.isCheckingStopped) {
        return
      }

      if (scrollValue > this.scrollLimit) {
        if (this.useSameComponent) updateAdaptiveHeaderHeight(this.scrollLimit)
        setAdaptiveFrozen(true)
        this.isShowingSecondaryHeader = true
        this.isCheckingStopped = true
        setTimeout(() => {
          this.isCheckingStopped = false
        }, 500)
      } else {
        setAdaptiveFrozen(true)
        if (this.useStatic) {
          this.$nextTick(() => {
            updateAdaptiveHeaderHeight(0)
          })

          setTimeout(() => {
            setAdaptiveFrozen(false)
          }, 400)
        }

        this.isShowingSecondaryHeader = false
      }
    }
  },
  mounted () {
    window.addEventListener('scroll', this.onWindowScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onWindowScroll)
    setAdaptiveFrozen(false)
  }
}
</script>

<style scoped>
.appear-enter-active {
  animation: appear 0.3s;
}
.appear-leave-active {
  animation: appear 0.3s reverse;
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
