<template>
  <li
    class="splide__slide"
    style="width: 100vw"
    v-if="hasData"
  >
    <slot />
    <div class="slide-spacer"></div>
  </li>
</template>

<script>
export default {
  computed: {
    hasData() {
      return !!this.$slots.default
    }
  }
}
</script>

<style scoped>
.slide-spacer {
  width: 100%;
  height: 24px;
  display: none;
}
</style>
