<template>
  <div
    class="category-scroller"
    :class="{
    'top-state': useTopState
  }"
    ref="scroller"
    v-if="!loading"
  >
    <BaseTabButton
      v-for="(category, index) in categories"
      :key="index"
      size="xs"
      @click="navigateToCategory(category)"
      :ref="`button${index}`"
      :active="index == activeCategoryIndex"
    >
      {{ getCategoryName(category) }}
    </BaseTabButton>
  </div>
  <div
    class="category-scroller"
    v-else
  >
    <div
      class="category-loading-tab"
      v-for="n in 5"
      :key="n"
    >
      <SkeletonLoader
        width="64px"
        height="16px"
      />
    </div>
  </div>
</template>

<script>
import { executeRelativeSmoothScroll } from '@/utils/index.js'

import { getAdaptiveFooterHeight, getAdaptiveHeaderHeight } from '@/utils/adaptiveUi'

const AVERAGE_SCROLL_OFFSET_BIAS = 124 // height of card with image only

export default {
  props: {
    view: String,
    loading: {
      type: Boolean,
      default: false
    },
    menu: Object
  },
  data() {
    return {
      scrollValue: null,

      activeCategoryIndex: 0,

      pauseCategoryDetection: false,
      scrollingToCategoryIndex: 0,

      useTopState: true,

      shouldSkipFirstScroll: true
    }
  },
  watch: {
    activeCategoryIndex() {
      if (this.activeCategoryIndex >= 0 && !this.pauseCategoryDetection) {
        this.$nextTick(this.centrifyTabs)
      }
    }
  },
  methods: {
    getScrollContainer() {
      return window.document.documentElement
    },
    getCategoryName(category) {
      return this.$store.getters.localize('category_name', category._id)
    },
    updateScrollValue() {
      if (this.pauseCategoryDetection) return

      this.scrollValue = this.getScrollContainer().scrollTop

      this.useTopState = this.scrollValue < 20

      this.activeCategoryIndex = this.findActiveIndex()
    },
    centrifyTabs() {
      const targetCard = this.$refs[`button${this.activeCategoryIndex}`][0].$el

      const targetScrollPosition = Math.max(
        0,
        targetCard.offsetLeft - this.$refs.scroller.offsetWidth / 2 + targetCard.offsetWidth / 2
      )

      if (this.shouldSkipFirstScroll) {
        this.$refs.scroller.scrollLeft = targetScrollPosition
        this.shouldSkipFirstScroll = false
        return
      }

      executeRelativeSmoothScroll(this.$refs.scroller, targetScrollPosition, 350)
    },
    navigateToCategory(category) {
      const offset = this.uiOffsets[category._id] || 0

      const newCategoryIndex = this.categories.indexOf(category)

      this.pauseCategoryDetection = true
      this.activeCategoryIndex = newCategoryIndex

      let absoluteScrollOffset = offset - getAdaptiveHeaderHeight() - 16

      if (newCategoryIndex === 0) {
        absoluteScrollOffset = 0
      }

      this.centrifyTabs()

      executeRelativeSmoothScroll(this.getScrollContainer(), absoluteScrollOffset, 350, 'scrollTop', () => {
        this.pauseCategoryDetection = false
      })
    },
    findActiveIndex() {
      if (this.categories.length <= 1) return 0
      if (this.pauseCategoryDetection) {
        return this.activeCategoryIndex
      }

      const totalMenuViewportHeight = window.screen.height - getAdaptiveFooterHeight()

      return this.categories.findLastIndex((category, index) => {
        let currentCategoryOffset = this.uiOffsets[category._id] || 0

        if (index === 0) {
          // first category always starts at the top of the page
          currentCategoryOffset = 0
        }

        const localBias = AVERAGE_SCROLL_OFFSET_BIAS + getAdaptiveFooterHeight()

        if (index >= this.categories.length - 2) {
          // if (this.view === 'grid') {
          //   return (
          //     this.scrollValue + totalMenuViewportHeight >=
          //     currentCategoryOffset + AVERAGE_SCROLL_OFFSET_BIAS
          //   )
          // } else {
          //   return (
          //     this.scrollValue >=
          //     currentCategoryOffset - AVERAGE_SCROLL_OFFSET_BIAS
          //   )
          // }

          return this.scrollValue + totalMenuViewportHeight >= currentCategoryOffset + AVERAGE_SCROLL_OFFSET_BIAS
        }

        const nextCategoryOffset = this.uiOffsets[this.categories[index + 1]._id] || 0

        return (
          this.scrollValue >= currentCategoryOffset - localBias && this.scrollValue <= nextCategoryOffset - localBias
        )
      })
    }
  },
  computed: {
    categories() {
      if (this.loading) return []

      return this.$store.getters.categoriesWithProducts(this.menu || this.$store.getters.firstMenu)
    },
    uiOffsets() {
      return this.$store.state.uiCategoriesOffsets[this.view]
    }
  },
  mounted() {
    if (!this.loading) {
      this.$nextTick(() => {
        window.addEventListener('scroll', this.updateScrollValue)
        this.scrollValue = 0
      })
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.updateScrollValue)
  }
}
</script>

<style scoped>
.category-scroller {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  overflow-x: scroll;
  padding-left: 16px;
  padding-right: 16px;

  padding-top: 12px;
  padding-bottom: 12px;

  transition: padding 0.35s;
}

.category-tab {
  white-space: nowrap;
  color: var(--qrw-content-base-secondary);
  border-radius: 8px;
  transition: 0.35s all;
}

.category-tab.active {
  background: var(--qrw-background-base-inverse-secondary);
  color: var(--qrw-content-base-inverse-primary);
  box-shadow: var(--qrw-shadow-foundation-1dp);
  animation: active 0.35s linear;
}

.category-tab.md {
  padding: 6px 12px;
  transition: 0.35s all;
}

.category-tab.sm {
  padding: 4px 8px;
  transition: 0.35s all;
  border-radius: 6px;
}

.category-loading-tab {
  padding: 6px 12px;
}

.top-state {
  padding-top: 12px;
  padding-bottom: 12px;
  transition: padding 0.35s;
}

@keyframes active {
  0% {
    color: var(--qrw-content-base-secondary);
    color: var(--qrw-content-base-inverse-primary);
    opacity: 0.7;
  }
  50% {
    color: var(--qrw-content-base-inverse-primary);
  }
  100% {
    background: var(--qrw-background-base-inverse-secondary);
    color: var(--qrw-content-base-inverse-primary);
    opacity: 1;
  }
}
</style>
