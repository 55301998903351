import { render, staticRenderFns } from "./GeoVerificationModal.vue?vue&type=template&id=327ae337&scoped=true&"
import script from "./GeoVerificationModal.vue?vue&type=script&lang=js&"
export * from "./GeoVerificationModal.vue?vue&type=script&lang=js&"
import style0 from "./GeoVerificationModal.vue?vue&type=style&index=0&id=327ae337&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "327ae337",
  null
  
)

export default component.exports