<template>
  <div class="app-cancelled-order-navbar-wrapper">
    <BaseDivider color="border-error-tertiary" />
    <div class="app-cancelled-order-navbar">
      <div class="gap-row-2xs">
        <div class="qrw-label-sm qrw-text-content-error-primary">
          {{ $t("pending_order.your_order") }}
        </div>
        <div class="qrw-label-md bold qrw-text-content-error-primary">
          {{ $t('pending_order.declined') }}
        </div>

      </div>

      <BasePushButton
        variant="fill"
        @click="showModal"
        bgColor="background-base-inverse-primary"
        textColor="content-base-inverse-primary"
        size="md"
        class="view-order-button"
      >
        {{ $t("common_actions.view") }}
      </BasePushButton>
    </div>

    <portal>
      <CancelledOrderModal
        :list="order.list"
        :reason="order.reason"
        @close="closeModal"
        v-if="modalShown"
      />
    </portal>
  </div>
</template>

<script>
import CancelledOrderModal from '@/components/modals/CancelledOrderModal.vue'

export default {
  data() {
    return {
      modalShown: false
    }
  },
  methods: {
    showModal() {
      this.modalShown = true
    },
    closeModal() {
      this.modalShown = false
      this.$store.commit('setOrder', null)
    }
  },
  computed: {
    order() {
      return this.$store.state.order
    }
  },
  components: {
    CancelledOrderModal
  }
}
</script>

<style scoped>
.app-cancelled-order-navbar-wrapper {
  display: flex;
  flex-direction: column;
}

.view-order-button {
  width: 122px;
  min-width: 122px;
}

.app-cancelled-order-navbar {
  display: flex;
  background: var(--qrw-background-error-secondary);
  padding: 8px 16px;
  align-items: center;
  column-gap: 24px;
  justify-content: space-between;
}
</style>
