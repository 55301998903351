export function requestGeolocationAsPromise () {
  return new Promise((resolve, reject) => {
    window.navigator.geolocation.getCurrentPosition((pos) => {
      resolve({
        lat: pos.coords.latitude,
        lon: pos.coords.longitude
      })
    }, (err) => {
      reject(err)
    }, {
      enableHighAccuracy: true
    })
  })
}
