<template>
  <div
    class="cafe-address"
    v-if="address"
  >
    <div class="gap-row-xs">
      <div class="qrw-text-content-base-primary qrw-label-lg bold">
        {{ $t("home.address") }}
      </div>

      <!-- <iframe
        style="border: 0"
        class="map-tile-frame"
        loading="lazy"
        allowfullscreen
        referrerpolicy="no-referrer-when-downgrade"
        :src="`https://www.google.com/maps/embed/v1/place?key=AIzaSyBAdY6IgAPnG8WIvP4_ddO_TcWBvlXnoVE
            &q=${address}`"
      >
      </iframe> -->

      <!-- <div
        class="map-tile-frame"
        id="qrw_map"
      ></div> -->
      <MapFrame
        :zoom="16"
        class="map-tile-frame"
        markerIcon="map-cafe-marker"
        :location="location"
      />

      <div class="qrw-text-content-base-secondary qrw-label-md">
        {{ address }}
      </div>
    </div>

    <a
      :href="addressDirectionsUrl"
      target="_blank"
      style="text-decoration: none"
    >
      <BasePushButton
        size="md"
        class="get-directions-btn bold w-100"
        icon="route-filled"
        bold
        variant="primary"
        bgColor="background-base-inverse-primary"
        textColor="content-base-inverse-primary"
      >
        {{ $t("home.get_directions") }}
      </BasePushButton>
    </a>
  </div>
</template>

<script>
import MapFrame from '@/components/common/MapFrame.vue'

export default {
  props: {
    address: String,
    location: Object
  },
  components: {
    MapFrame
  },
  methods: {
    initMap() {
      const map = window.L.map('qrw_map', {
        dragging: false,
        scrollWheelZoom: false,
        tapHold: false,
        touchZoom: false,
        zoomControl: false
      }).setView([this.location.lat, this.location.lon], 17)

      window.L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      }).addTo(map)

      const markerIcon = window.L.icon({
        iconUrl: '/images/map-cafe-marker.png',
        iconSize: [24, 32]
      })

      window.L.marker([this.location.lat, this.location.lon], {
        icon: markerIcon
      }).addTo(map)
    }
  },
  computed: {
    addressDirectionsUrl() {
      return `https://www.google.com/maps/dir/current+location/${this.address}`
    }
  },
  mounted() {
    this.initMap()
  }
}
</script>

<style scoped>
.address-title {
  display: flex;
  flex-direction: column;
  column-gap: 6px;
}

.address-value {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.cafe-address {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 16px;
}

.address-tile {
  width: 45%;
  object-fit: cover;
  border-radius: 12px;
}

.map-tile-frame {
  width: 100%;
  height: 200px;
  min-height: 200px;
  max-height: 200px;
  border-radius: 12px;
}
</style>
