<template>
  <div
    class="app-receipt-page"
    v-if="order"
  >
    <!-- <ReceiptPageHeader
      :date="order.closed_at"
      v-qrw-is-header
      class="qrw-app-top-container"
    /> -->
    <ScrollTransitionHeader :scrollLimit="10">
      <template #primary>
        <ProductPageHeader
          :imagePresent="false"
          @back="goBack"
          @share="shareReceiptLink"
          v-qrw-is-header
        />
      </template>

      <template #secondary>
        <ReceiptPageHeader
          :date="order.closed_at"
          v-qrw-is-header
        />
      </template>
    </ScrollTransitionHeader>

    <div
      class="app-receipt-page-container"
      v-qrw-under-header
      v-qrw-above-footer
    >
      <div class="receipt-header">
        <BaseWorkspaceAvatar
          class="receipt-cafe-avatar"
          :imageUrl="cafe.image"
        />

        <div class="gap-row-1xs">
          <div class="qrw-heading-sm bold qrw-text-content-base-primary">
            {{ cafe.name }}
          </div>
          <!-- <div
            class="qrw-label-md qrw-text-content-base-tertiary"
          >
            {{ cafe.address }}
          </div> -->
        </div>
        <div class="gap-column-xs w-100">
          <div class="gap-row-xs w-100">
            <div class="hero-row">
              <BaseIcon
                size="xs"
                icon="calendar"
              />
              <div class="qrw-label-sm">
                {{ headerSubtitleDate }}
              </div>
            </div>
            <div class="hero-row">
              <BaseIcon
                size="xs"
                icon="clock"
              />
              <div class="qrw-label-sm">
                {{ (order.confirmed_at || order.date) | formatTime }}
              </div>
            </div>
            <div
              class="hero-row"
              v-if="order.guests_count"
            >
              <BaseIcon
                size="xs"
                icon="utensils"
              />
              <div class="qrw-label-sm">
                {{
                  order.guests_count || 0
                }}
              </div>
            </div>
          </div>
          <div
            class="gap-row-xs w-100"
            v-if="mode === 'inside'"
          >
            <div class="hero-row">
              <BaseIcon
                size="xs"
                icon="marker-pin"
              />
              <div class="qrw-label-sm">
                {{
                  cafe.sections[order.section].name || "#" + (order.section + 1)
                }}
              </div>
            </div>
            <div class="hero-row">
              <BaseIcon
                size="xs"
                icon="table"
              />
              <div class="qrw-label-sm">
                {{ order.table_name }}
              </div>
            </div>
          </div>
          <div
            class="gap-row-xs w-100"
            v-else
          >
            <div class="hero-row">
              <BaseIcon
                size="xs"
                icon="marker-pin"
              />
              <div class="qrw-label-sm">
                {{
                  order.client.address.split(',').slice(0,1).join()
                }}
              </div>
            </div>
            <div class="hero-row">
              <BaseIcon
                size="xs"
                icon="clock-fast-forward"
              />
              <div
                class="qrw-label-sm"
                v-if="order.takeaway_date"
              >
                {{ order.takeaway_date | formatDatetime }}
              </div>
              <div
                class="qrw-label-sm"
                v-else
              >
                {{$t('order_time_modal.asap')}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <BaseDivider color="border-base-tertiary" />
      <div
        class="receipt-note"
        v-if="order.comment"
      >
        <div class="qrw-label-lg bold qrw-text-content-base-primary">
          {{$t('comment.title') }}
        </div>
        <div class="qrw-paragraph-md qrw-text-content-base-primary">
          {{ order.comment }}
        </div>
      </div>

      <div
        class="cancelled-block-wrapper"
        v-if="isOrderCancelled"
      >
        <div class="cancelled-block">
          <div class="qrw-label-sm qrw-text-content-error-tertiary">
            {{$t('cancelled_modal.reason_of_cancel')}}
          </div>
          <div class="qrw-paragraph-md qrw-text-content-error-primary">
            {{ order.reason }}
          </div>
        </div>
      </div>

      <!-- <div class="receipt-repeat-row">
      <BasePushButton
        class="w-100"
        variant="primary"
        bgColor="background-base-inverse-primary"
        size="md"
        textColor="content-base-inverse-primary"
      >
        Repeat order
      </BasePushButton>
    </div> -->

      <div class="receipt-bill">
        <div class="qrw-heading-xs bold qrw-text-content-base-primary">
          {{ $t("order_page.your_bill") }}
        </div>
        <div class="bill-container">
          <img src="/images/cheque-top-bar.svg" />
          <template v-for="(item, index) in order.list">
            <BillProductCard
              :product="item"
              :key="item._id"
            />
            <BaseDivider
              color="border-base-tertiary"
              src="/images/cheque-middle-bar.svg"
              :key="'divider' + item._id"
              v-if="index != order.list.length - 1"
            />
          </template>
          <GenericTotal
            class="bill-total-container"
            :alreadyPaid="true"
            :value="{
              sum: order.sum || order.total,
              tip: order.tip_amount,
              discount: order.discount,
              discount_value: order.discount_value || order.discount,
              total: order.total
            }"
          />
          <img src="/images/cheque-bottom-bar.svg" />
        </div>

        <BillPaymentDetails :order="order" />

        <div class="service-provider">
          <BaseDivider color="border-base-tertiary" />
          <div class="qrw-label-xs bold qrw-text-content-base-tertiary">
            {{ $t("receipt.service_provider") }}
          </div>
          <div class="gap-row-txs">
            <div class="qrw-label-md qrw-text-content-base-primary">
              {{ cafe.name }}
            </div>
            <div class="qrw-label-md qrw-text-content-base-primary">
              {{ cafe.address }}
            </div>
            <div
              class="qrw-label-md qrw-text-content-base-primary"
              v-if="cafe.contact_phone"
            >
              {{ cafe.contact_phone }}
            </div>
          </div>

        </div>
      </div>

      <div class="tech-details">
        <div class="qrw-label-lg bold qrw-text-content-base-primary">
          {{ $t("receipt.tech_details") }}
        </div>
        <div class="tech-details-row gap-column-txs qrw-text-content-base-secondary">
          <div class="qrw-label-md">
            {{ $t("receipt.order_number") }}
          </div>
          <div class="qrw-label-md">{{ mode.toUpperCase() }} {{ order.serial_order_id }}</div>
        </div>
        <div class="tech-details-row gap-column-txs qrw-text-content-base-secondary">
          <div class="qrw-label-md">
            {{ $t("receipt.order_id") }}
          </div>
          <div class="qrw-label-md">{{ order.local_id }}</div>
        </div>
        <div class="tech-details-row gap-column-txs qrw-text-content-base-secondary">
          <div class="qrw-label-md">
            {{ $t("receipt.date_and_time") }}
          </div>
          <div class="qrw-label-md">
            {{ (order.closed_at || order.confirmed_at) | formatDatetime }}
          </div>
        </div>
        <div class="tech-details-row gap-column-txs qrw-text-content-base-secondary">
          <div class="qrw-label-md">
            {{ $t("receipt.service_provider") }}
          </div>
          <div class="qrw-label-md">qrwaiter.com.ua</div>
        </div>
      </div>
    </div>

    <div
      class="qrw-app-bottom-sticky qrw-app-bottom-container"
      v-qrw-is-footer
    >
      <UnpaidOrderNavbar />
      <MainNavbar
        :sticky="false"
        activeTab="order"
      />
    </div>
  </div>
  <PageLoader v-else />
</template>

<script>
import BillProductCard from '@/components/order/BillProductCard.vue'
import BillPaymentDetails from '@/components/order/BillPaymentDetails.vue'
import ReceiptPageHeader from '@/components/header/ReceiptPageHeader.vue'

import GenericTotal from '@/components/order/GenericTotal.vue'

import PageLoader from '@/components/common/PageLoader.vue'

import InsideAPI from '@/api/inside'
import DeliveryAPI from '@/api/delivery'

import moment from 'moment'

import UnpaidOrderNavbar from '../components/navbar/UnpaidOrderNavbar.vue'

import ProductPageHeader from '../components/header/ProductPageHeader.vue'

import MainNavbar from '@/components/navbar/MainNavbar.vue'

import ScrollTransitionHeader from '@/components/header/ScrollTransitionHeader.vue'

export default {
  data() {
    return {
      order: null
    }
  },
  computed: {
    cafe() {
      return this.$store.state.cafe
    },
    currency() {
      return this.$store.state.currency
    },
    headerSubtitleDate() {
      if (!this.order) return ''

      return moment(this.order.confirmed_at || this.order.date).format('DD MMM YYYY')
    },
    mode() {
      return this.$store.state.mode
    },
    isOrderCancelled() {
      return this.order && this.order.status === 'cancelled'
    }
  },
  methods: {
    async fetchOrder() {
      try {
        const apiMethod = this.mode === 'inside' ? InsideAPI.getOrder : DeliveryAPI.getOrder

        const { order } = await apiMethod(this.$route.params.order_id)

        this.order = order
      } catch (err) {
        this.$router.replace(this.$store.getters.buildRouterNavigateObject('orders'))
      }
    },
    goBack() {
      this.$router.push(this.$store.getters.buildRouterNavigateObject('orders'))
    },
    shareReceiptLink() {
      window.navigator.share({
        title: this.mode.toUpperCase(),
        url: window.location.href
      })
    }
  },
  mounted() {
    this.fetchOrder()

    this.$store.commit('setPageWaypoint', {
      pageName: 'order',
      path: this.$route.fullPath
    })
  },
  components: {
    BillProductCard,
    BillPaymentDetails,
    ReceiptPageHeader,
    PageLoader,
    UnpaidOrderNavbar,
    MainNavbar,
    ProductPageHeader,
    ScrollTransitionHeader,
    GenericTotal
  }
}
</script>

<style scoped>
.receipt-header {
  display: flex;
  flex-direction: column;
  padding: 16px;
  row-gap: 16px;
  box-sizing: border-box;
  width: 100%;
}

.receipt-row {
  display: flex;
  column-gap: 12px;
  align-items: center;
}

.receipt-note {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
}

.receipt-bill {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
}

.receipt-repeat-row {
  display: flex;
  padding: 16px 24px;
  column-gap: 16px;
  box-sizing: border-box;
  width: 100%;
}

.bill-container {
  display: flex;
  flex-direction: column;
  /* box-shadow: var(--qrw-shadow-foundation-1dp); */
}

.bill-total-container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: var(--qrw-background-base-tertiary-10);
}

.total-row {
  display: flex;
  justify-content: space-between;
  column-gap: 16px;
  color: var(--qrw-content-base-secondary);
}

.service-provider,
.tech-details {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  row-gap: 16px;
  box-sizing: border-box;
  width: 100%;
}

.tech-details {
  padding: 16px;
}

.tech-details-row {
  justify-content: space-between;
}

.top-cheque-divider {
  width: 100%;
  height: 4px;
  background: var(--qrw-background-base-tertiary-10);
}

.bottom-cheque-divider {
  width: 100%;
  height: 4px;

  background: var(--qrw-background-base-primary-10);
}

.top-cheque-divider img,
.bottom-cheque-divider img {
  width: 100%;
  display: block;
}

.receipt-cafe-avatar {
  box-shadow: var(--qrw-shadow-card-light);
  transition: 0.3s all;
}

.hero-row {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  color: var(--qrw-content-base-secondary);
  align-items: center;
}

.cancelled-block {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  background: var(--qrw-background-error-tertiary);
  padding: 12px 16px;
  border-radius: 12px;
  width: 100%;
  box-sizing: border-box;
}

.cancelled-block-wrapper {
  display: flex;
  padding: 12px 16px;
  width: 100%;
  box-sizing: border-box;
}
</style>
