<template>
  <div class="gap-column-xs">
    <div
      class="row-text"
      :class="{
        'active': value
    }"
    >
      <BaseIcon
        size="sm"
        :icon="value ? activeIcon : icon"
      />
      <div
        class="qrw-label-md"
        :class="{
        bold: value
      }"
      >
        {{text}}
      </div>
    </div>

    <BaseBetaToggle
      size="sm"
      :value="value"
      @input="nv => $emit('input', nv)"
      class="row-toggle"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    text: String,
    icon: String,
    activeIcon: String
  }
}
</script>

<style scoped>
.row-text {
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  align-items: center;
  color: var(--qrw-content-base-secondary);
  flex: 1;
}

.row-text.active {
  color: var(--qrw-content-base-primary);
}
</style>
