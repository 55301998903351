<template>
  <div class="app-alcohol-alert-wrapper">
    <BaseDivider color="border-brand-tertiary" />
    <div
      class="app-alcohol-alert"
      @click="onInput"
    >
      <BaseBetaCheckbox
        cell="check"
        size="md"
        :value="value"
      />
      <div
        class="qrw-label-md qrw-text-content-base-secondary"
        :class="{'bold qrw-text-content-base-primary': value}"
      >
        <span class="qrw-text-content-error-primary">*</span>
        {{ $t('cart.alcohol_alert') }}
      </div>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    value: Boolean
  },
  methods: {
    onInput() {
      this.$emit('input', !this.value)
    }
  }
}
</script>

<style scoped>
.app-alcohol-alert-wrapper {
  display: flex;
  flex-direction: column;
}

.app-alcohol-alert {
  background-color: var(--qrw-background-brand-primary);
  padding: 12px 16px;
  display: flex;
  column-gap: 6px;
  align-items: flex-start;
  box-sizing: border-box;
}
</style>
