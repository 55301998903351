<template>
  <div class="menu-product-list-card">
    <!-- v-touch:swipe.right="onCardSwipedRight" v-touch:swipe.left="onCardSwipedLeft" -->
    <!-- <div class="product-card-left-trigger" @click="onCardSwipedRight"></div> -->

    <transition name="fade">
      <div
        v-if="!isShowingAddedTrigger"
        class="product-card-swiper"
        :class="{ added: isProductInCart }"
      ></div>
    </transition>

    <transition name="swiper">
      <div
        class="product-card-swiper swiped left"
        v-if="isShowingAddedTrigger"
      >
        <BaseIcon
          size="sm2"
          icon="plus-circle"
        />
      </div>
    </transition>

    <div
      class="product-list-card-content"
      :class="{
        'being-removed': cardAnimating,
      }"
      @click="openProductPage"
    >
      <div class="product-list-card-info">
        <div class="gap-column-xs">
          <div class="gap-row-txs w-100">
            <div class="info-titles">
              <div class="gap-row-2xs">
                <div class="gap-column-1xs product-name-row">
                  <BaseLabelBadge
                    variant="filled"
                    size="xs"
                    class="qrw-label-xs bold counter-badge"
                    primaryColor="background-success-default"
                    secondaryColor="content-base-inverse-primary"
                    v-if="isProductInCart"
                  >
                    {{ productCartCount }}
                  </BaseLabelBadge>
                  <div
                    class="product-name qrw-text-content-base-primary qrw-paragraph-sm bold"
                    ref="productName"
                  >
                    {{ productName }}
                  </div>
                </div>

                <div
                  class="product-description qrw-paragraph-sm qrw-text-content-base-secondary"
                  :style="productDescriptionLineStyles"
                  v-if="productDescription && productNameLinesCount < 3"
                >
                  {{ productDescription }}
                </div>
              </div>
            </div>
            <div
              class="price"
              v-if="!discountedPrice"
            >
              <PriceTag
                class="qrw-text-content-base-primary qrw-label-sm bold"
                :value="productDisplayPrice"
              />
            </div>
            <div
              class="price"
              v-else
            >
              <PriceTag
                class="qrw-text-content-error-primary qrw-label-sm bold"
                :value="discountedPrice"
              />
              <PriceTag
                :spacing="0"
                class="qrw-text-content-base-tertiary qrw-label-xs line-through"
                :value="productDisplayPrice"
              />
            </div>
          </div>
          <img
            :src="productImage"
            class="product-list-card-image"
            v-if="productImage"
          />
        </div>

        <div
          class="info-badges"
          v-if="
            product.is_excise ||
            product.is_spicy ||
            product.base_weight ||
            product.waiting_time
          "
        >
          <BaseIconBadge
            size="sm"
            primaryColor="background-brand-secondary"
            secondaryColor="content-brand-primary"
            icon="wine"
            rounded
            v-if="product.is_excise"
          />
          <BaseIconBadge
            size="sm"
            primaryColor="background-error-secondary"
            secondaryColor="content-error-primary"
            icon="pepper-filled"
            v-if="product.is_spicy"
            rounded
          />
          <BaseLabelBadge
            class="product-badge"
            size="sm"
            rounded
            primaryColor="background-base-tertiary-10"
            secondaryColor="content-base-secondary"
            v-if="product.base_weight"
          >
            {{ product.base_weight | formatWeight }}
            {{ $t("units." + product.unit) }}
          </BaseLabelBadge>
          <BaseLabelBadge
            class="product-badge"
            size="sm"
            icon="clock"
            rounded
            primaryColor="background-base-tertiary-10"
            secondaryColor="content-base-secondary"
            v-if="product.waiting_time"
          >
            {{ product.waiting_time }} {{ $t("units.min") }}
          </BaseLabelBadge>
        </div>
      </div>
    </div>

    <transition name="swiper-right">
      <div
        class="product-card-swiper swiped right"
        v-if="isShowingRemoveTrigger"
      >
        <BaseIcon
          size="sm2"
          icon="x-circle"
        />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    product: Object
  },
  data() {
    return {
      isShowingAddedTrigger: false,
      isShowingRemoveTrigger: false,

      cardAnimating: false,
      productNameLinesCount: 0,

      tempAdded: false
    }
  },
  methods: {
    onCardSwipedRight() {
      if (this.isProductInCart) return

      this.isShowingAddedTrigger = true
      this.tempAdded = true

      this.$emit('buy', this.product)

      setTimeout(() => {
        this.isShowingAddedTrigger = false
      }, 600)
    },
    onCardSwipedLeft() {
      if (!this.isProductInCart) return

      this.isShowingRemoveTrigger = true
      this.cardAnimating = true

      this.tempAdded = false

      this.$emit('remove', this.product)

      setTimeout(() => {
        this.isShowingRemoveTrigger = false
      }, 600)

      setTimeout(() => {
        this.cardAnimating = false
      }, 5000)
    },
    openProductPage() {
      this.$emit('openProductPage', this.product)
    },
    computeProductLinesCount() {
      const PRODUCT_NAME_LINE_HEIGHT = 20

      if (!this.$refs.productName) {
        this.productNameLinesCount = 1
      }

      this.productNameLinesCount = Math.ceil(this.$refs.productName.offsetHeight / PRODUCT_NAME_LINE_HEIGHT)
    }
  },
  mounted() {
    this.$nextTick(this.computeProductLinesCount)
  },
  computed: {
    productImage() {
      return this.$store.getters.getProductCachedImage(this.product._id)
    },
    currency() {
      return this.$store.state.currency
    },
    productDisplayPrice() {
      return (
        this.product.base_price +
        (this.product.price_groups || [])
          .filter(pg => (pg.single_select || pg.min_count) && pg.items.length)
          .reduce((acc, it) => acc + it.items[0].price, 0)
      )
    },
    discountedPrice() {
      return null
      // return Math.random() < 0.3 ? this.productDisplayPrice * 0.8 : null
    },
    isProductInCart() {
      return this.productCartCount > 0
    },
    productCartCount() {
      return this.$store.getters['cart/getProductCountInCart'](this.product)
    },
    productDescriptionLineStyles() {
      const MAX_LINES_COUNT = 3

      return {
        '-webkit-line-clamp': MAX_LINES_COUNT - this.productNameLinesCount,
        'line-clamp': MAX_LINES_COUNT - this.productNameLinesCount
      }
    },
    productName() {
      return this.$store.getters.localize('product_name', this.product._id)
    },
    productDescription() {
      return this.$store.getters.localize('product_description', this.product._id)
    }
  }
}
</script>

<style scoped>
.menu-product-list-card {
  display: flex;
  position: relative;
}

.product-card-left-trigger {
  position: absolute;
  width: 64px;
  min-width: 64px;
  height: 100px;
  align-self: center;
  background: transparent;
  left: 0;
  z-index: 1;
}

.product-card-swiper {
  height: calc(100% - 16px - 16px);
  width: 4px;
  background: var(--qrw-background-base-tertiary-10);
  /* background: red; */

  border-radius: 0 4px 4px 0;

  position: absolute;

  align-self: center;
  align-items: center;

  left: 0;
}

/* Animations related to Vue transitions */

@keyframes swiper {
  0% {
    width: 0px;
    margin-left: -64px;
  }
  100% {
    width: 64px;
    margin-left: 0;
  }
}

/* We need additional animation for the card-content, because adding a container to right does not move the card-content */
@keyframes card-swiped {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -64px;
  }
  100% {
    margin-left: 0;
  }
}

@keyframes swiper-right {
  0% {
    width: 0px;
  }
  100% {
    width: 64px;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.swiper-enter-active {
  animation: swiper 0.5s;
}
.swiper-leave-active {
  animation: swiper 0.5s reverse;
}

.swiper-right-enter-active {
  animation: swiper-right 0.5s;
}
.swiper-right-leave-active {
  animation: swiper-right 0.5s reverse;
}

.fade-enter-active {
  animation: fade 1.75s;
}
.fade-leave-active {
  animation: fade 1.75s reverse;
}

.product-card-swiper.added {
  background: var(--qrw-background-success-default);
}

.product-card-swiper.swiped {
  width: 64px;
  min-width: 64px;
  position: static;
  align-self: center;
  align-items: center;
  display: flex;
  transform-origin: 0% 50%;
  justify-content: center;
}

.product-card-swiper.swiped.left {
  background: var(--qrw-background-success-tertiary);
  color: var(--qrw-content-success-primary);
}

.product-card-swiper.swiped.right {
  background: var(--qrw-background-error-tertiary);
  color: var(--qrw-content-error-primary);

  border-radius: 4px 0 0 4px;
}

/* End of animations */

.product-list-card-content {
  display: flex;
  column-gap: 16px;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  flex-shrink: 0;
}

.product-list-card-content.being-removed {
  animation: card-swiped 1s;
}

.product-list-card-info {
  display: flex;
  flex-direction: column;
  flex: 1;
  row-gap: 16px;
  overflow: hidden;
  justify-content: space-between;
  height: 100%;
}

.price {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
}

.product-list-card-image {
  width: 122px;
  max-width: 122px;
  min-width: 122px;
  box-shadow: var(--qrw-shadow-card-light);
  border-radius: 6px;
  object-fit: cover;
  display: block;
  height: 92px;
  max-height: 92px;
  min-height: 92px;
}

.info-titles {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  height: 64px;
  max-height: 64px;
}

.info-badges {
  display: flex;
  flex-direction: row;
  column-gap: 6px;
}

.product-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  word-wrap: break-word;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.product-description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-wrap: break-word;
  visibility: visible;
}

.product-badge {
  font-weight: 500;
}

.counter-badge {
  padding: 1px 5px !important;
  /* width: 16px; */
  height: 16px;
  /* max-width: 16px; */
  box-sizing: border-box;
}

.product-name-row {
  align-items: flex-start;
}
</style>
