import { render, staticRenderFns } from "./CartComment.vue?vue&type=template&id=07b5803a&scoped=true&"
import script from "./CartComment.vue?vue&type=script&lang=js&"
export * from "./CartComment.vue?vue&type=script&lang=js&"
import style0 from "./CartComment.vue?vue&type=style&index=0&id=07b5803a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07b5803a",
  null
  
)

export default component.exports