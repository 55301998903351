<template>
  <div
    class="app-map-frame"
    ref="map"
  >
    <img
      :src="mapImageSource"
      class="map-layer"
    >

    <img
      :src="`/images/${markerIcon}.svg`"
      class="map-marker"
    >
  </div>

</template>

<script>
const FALLBACK_LOCATION = {
  lat: 50.4501,
  lon: 30.5234
}

export default {
  props: {
    location: Object,
    markerIcon: {
      type: String,
      default: 'map-marker'
    },
    zoom: {
      type: Number,
      default: 17
    }
  },
  data() {
    return {
      mapImageSource: null
    }
  },
  methods: {
    initOsmMap() {
      const targetLocation = this.location || FALLBACK_LOCATION

      const map = window.L.map('qrw_map_frame', {
        dragging: false,
        scrollWheelZoom: false,
        tapHold: false,
        touchZoom: false,
        zoomControl: false
      }).setView([targetLocation.lat, targetLocation.lon], this.zoom)

      // eslint-disable-next-line no-template-curly-in-string
      window.L.tileLayer('https://a.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      }).addTo(map)

      const markerIcon = window.L.icon({
        iconUrl: `/images/${this.markerIcon}.svg`,
        iconSize: [32, 46]
      })

      window.L.marker([targetLocation.lat, targetLocation.lon], {
        icon: markerIcon
      }).addTo(map)
    },
    initMapBoxMap() {
      const mapEl = this.$refs.map

      const sizePart = `${mapEl.clientWidth}x${mapEl.clientHeight}`

      const accessToken = `pk.eyJ1IjoicXJ3YWl0ZXIiLCJhIjoiY2xnd3pvazRqMDU5cDNrbnpzdmEwM3VmdCJ9.d5IXtSFU6ukIP094IfG1og`
      const locationPart = `${this.location.lon},${this.location.lat},${this.zoom},0`
      this.mapImageSource = `https://api.mapbox.com/styles/v1/qrwaiter/clioqqgpa004901p778ifg5k6/static/${locationPart}/${sizePart}?access_token=${accessToken}`
    }
  },
  mounted() {
    this.$nextTick(this.initMapBoxMap)
  }
}
</script>

<style scoped>
.app-map-frame {
  width: 100%;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
}

.app-map-frame .map-layer {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.app-map-frame .map-marker {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
</style>
