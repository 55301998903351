<template>
  <div class="call-waiter-wrapper">
    <BaseDivider color="border-base-tertiary" />
    <div
      class="call-waiter-block"
      :class="{
      'with-background': !noBackground,
    }"
    >
      <div class="gap-row-1xs w-100">
        <div class="qrw-label-md bold qrw-text-content-base-primary">
          {{ $t("call_waiter.title") }}
        </div>
        <div class="qrw-paragraph-sm qrw-text-content-base-secondary">
          {{ cooldownSeconds ? $t('call_waiter.sent') : $t("call_waiter.subtitle") }}
        </div>
      </div>
      <BasePushButton
        class="call-button"
        borderColor="border-base-secondary"
        variant="outlined"
        size="md"
        data-testid="call-waiter-button"
        textColor="content-base-primary"
        @click="callWaiter"
        :disabled="cooldownSeconds > 0"
      >
        {{ cooldownSeconds ? `0:${cooldownSeconds}` : $t("call_waiter.button") }}
      </BasePushButton>
    </div>
  </div>

</template>

<script>
import InsideAPI from '@/api/inside'

let globalCooldown = 0

export default {
  name: 'CallWaiterBlock',
  props: {
    noBackground: Boolean
  },
  data() {
    return {
      cooldownSeconds: 0
    }
  },
  methods: {
    startCooldown() {
      const interval = setInterval(() => {
        this.cooldownSeconds -= 1
        globalCooldown = this.cooldownSeconds

        if (this.cooldownSeconds === 0) {
          clearInterval(interval)
          globalCooldown = 0
        }
      }, 1000)
    },
    async callWaiter() {
      try {
        await InsideAPI.callWaiter(this.$store.state.inside.access_code)
        this.cooldownSeconds = 59
        this.startCooldown()
      } catch (e) {
        console.error(e)
      }
    }
  },
  computed: {
    cooldownText() {
      return this.cooldownSeconds ? `0:${this.cooldownSeconds.toString().padStart(2, '0')}` : ''
    }
  },
  mounted() {
    if (globalCooldown > 0) {
      this.cooldownSeconds = globalCooldown
      this.startCooldown()
    }
  }
}
</script>

<style scoped>
.call-waiter-block {
  display: flex;
  padding: 16px 16px;
  flex-direction: row;
  column-gap: 16px;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
}

.with-background {
  background: var(--qrw-background-base-tertiary-10);
}

.call-button {
  width: 122px;
  white-space: nowrap;
  min-width: 122px;
}

.call-waiter-wrapper {
  display: flex;
  flex-direction: column;
}
</style>
