import moment from "moment";

export function getPrettyTimeLeftUntil(targetDate) {
  const diff = moment(targetDate).diff(moment());
  return diff > 0 ? moment.utc(diff).format("mm:ss") : "00:00";
}

export function getEuropeanWeekDaysKeys() {
  return ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
}

export function weekDayToWeekdayKey(zeroIndexedDay) {
  const dayNames = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

  return dayNames[zeroIndexedDay];
}

export function executeSmoothScroll(elementY, duration, finishedCallback) {
  const startingY = window.pageYOffset;
  const diff = elementY - startingY;
  let start;

  // Bootstrap our animation - it will get called right before next frame shall be rendered.
  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    // Elapsed milliseconds since start of scrolling.
    const time = timestamp - start;
    // Get percent of completion in range [0, 1].
    const percent = Math.min(time / duration, 1);

    window.scrollTo(0, startingY + diff * percent);

    // Proceed with animation as long as we wanted it to.
    if (time <= duration) {
      window.requestAnimationFrame(step);
    } else {
      window.requestAnimationFrame(() => {
        if (finishedCallback) finishedCallback();
      });
    }
  });
}

export function saveCafeBasedData(type, id, data) {
  window.localStorage.setItem(
    `cafe_based_cache:${type}`,
    JSON.stringify({
      id,
      data,
    })
  );
}

export function getCafeBasedData(type, id) {
  const value = window.localStorage.getItem(`cafe_based_cache:${type}`);

  if (!value) return null;

  const parsed = JSON.parse(value);

  if (parsed.id !== id) return null;

  return parsed.data;
}

export function eraseCafeBasedData(type) {
  window.localStorage.removeItem(`cafe_based_cache:${type}`);
}

export function executeRelativeSmoothScroll(
  element,
  to,
  duration,
  property,
  callback
) {
  const start = element[property || "scrollLeft"];
  const change = to - start;
  let currentTime = 0;
  const increment = 20;

  // t = current time
  // b = start value
  // c = change in value
  // d = duration
  const easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const animateScroll = () => {
    currentTime += increment;
    const val = easeInOutQuad(currentTime, start, change, duration);
    element[property || "scrollLeft"] = val;
    if (currentTime <= duration) {
      setTimeout(animateScroll, increment);
    } else {
      element[property || "scrollLeft"] = to;
      if (callback) callback();
    }
  };
  animateScroll();
}

export function debounce(fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    timeoutID = setTimeout(function () {
      fn();
    }, delay);
  };
}
