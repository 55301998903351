<template>
  <div class="menu-lobby-header">
    <div class="lobby-header-intro qrw-transition-animation-sm"
      :class="{ collapsed, 'with-switcher': shouldShowSwitcher }">
      <div class="lobby-header-rows w-100 qrw-transition-animation-sm" :class="{ collapsed }">
        <div :class="{
        'qrw-heading-xs': !collapsed,
        'qrw-label-lg': collapsed
      }" class="qrw-transition-animation-sm bold qrw-text-content-base-primary">
          {{ cafe.name }}
        </div>
        <div class="qrw-label-sm qrw-text-content-base-secondary lobby-subtitle" v-if="!collapsed">
          <!-- {{ $t("menu_lobby.select_type") }} -->
          {{ modeTitle }}
          <template v-if="mode === 'inside' || (mode === 'preview' && insideData.access_code)">
            <BaseIcon icon="dot" size="xs" />
            {{ insideData.section_name }} {{ insideData.table_name }}
          </template>

        </div>
        <div class="qrw-label-xs qrw-text-content-base-secondary lobby-subtitle" v-else>
          {{ modeTitle }}
          <BaseIcon icon="dot" size="xs" v-if="mode === 'inside'" /> {{ insideData.section_name }} {{
        insideData.table_name }}
        </div>
      </div>

      <FlagIcon @click="$emit('switchLanguage')" />
    </div>
    <div class="lobby-header-switcher" v-if="shouldShowSwitcher">
      <BaseSwitcherBar
        style="border-radius:10px; background:var(--qrw-background-base-tertiary); padding: 4px; width: 100%;"
        v-model="mode" tabWidth="100%" size="sm" :tabs="modeTabs" />

      <ClosedBanner :type="mode === 'delivery' ? 'delivery' : 'takeaway'" rounded v-if="isSystemClosed" />
    </div>
    <BaseDivider color="border-base-tertiary" />
  </div>
</template>

<script>
import ClosedBanner from '@/components/header/ClosedBanner.vue'

export default {
  props: {
    collapsed: Boolean
  },
  components: {
    ClosedBanner
  },
  data () {
    return {
      applyTopStyle: false
    }
  },
  methods: {
    checkScroll () {
      const windowScrollY = window.document.documentElement.scrollTop

      this.applyTopStyle = windowScrollY > 20
    }
  },
  mounted () {
    window.addEventListener('scroll', this.checkScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.checkScroll)
  },
  computed: {
    cafe () {
      return this.$store.state.cafe
    },
    insideData () {
      return this.$store.state.inside
    },
    modeTitle () {
      return this.$store.getters.modeTitle
    },
    cafeClosedFlag () {
      return this.$store.getters.isCafeClosed
    },
    isSystemClosed () {
      return this.$store.getters.isSystemClosed
    },
    shouldShowSwitcher () {
      const enabledModesCount = this.modeTabs.length

      return enabledModesCount > 1 && this.mode !== 'inside'
    },
    mode: {
      get () {
        return this.$store.state.mode
      },
      set (v) {
        if (v === 'preview') {
          this.$router.replace({
            name: 'preview_lobby',
            params: {
              cafe_link: this.mixinCafeLinkCode
            }
          })
          this.$store.commit('setMode', v)
          return
        }

        this.$router.replace({
          name: 'preorder_lobby',
          params: {
            cafe_link: this.mixinCafeLinkCode,
            mode: v
          }
        })
        this.$store.commit('setMode', v)
      }
    },
    modeTabs () {
      const tabs = []

      if (this.cafe.modes.delivery) {
        tabs.push({
          value: 'delivery',
          text: this.$store.getters.specificModeTitle('delivery')
        })
      }

      if (this.cafe.modes.preorder) {
        tabs.push({
          value: 'takeaway',
          text: this.$store.getters.specificModeTitle('takeaway')
        })
      }

      if (this.cafe.modes.inside || this.cafe.modes.preview) {
        tabs.push({
          value: 'preview',
          text: this.$store.getters.specificModeTitle('inside')
        })
      }

      return tabs
    }
  }
}
</script>

<style scoped>
.menu-lobby-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.lobby-header-intro {
  padding: 16px;
  display: flex;
  flex-direction: row;
  column-gap: 16px;

  align-items: center;
  box-sizing: border-box;
}

.lobby-header-intro.collapsed {
  padding: 12px 16px 8px 16px;
}

.lobby-header-intro.with-switcher {
  padding-bottom: 0;
}

.lobby-header-switcher {
  padding: 16px 16px 16px 16px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  row-gap: 16px;
}

.lobby-subtitle {
  align-items: center;
  display: flex;
}

.lobby-header-rows {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.lobby-header-rows.collapsed {
  row-gap: 6px;
}
</style>
