<template>
  <div
    class="menu-lobby-card"
    @click="$emit('click')"
  >
    <div class="gap-row-1xs w-100">
      <div class="qrw-label-lg bold qrw-text-content-base-primary">
        {{ localizedTag }}
      </div>
      <div
        class="qrw-label-sm qrw-text-content-base-tertiary"
        v-if="!menuTimeLimit"
      >
        {{ $tc("units.positions", menu.products.length) }}
      </div>
      <div
        class="menu-schedule-info"
        :class="{
        'qrw-text-content-base-tertiary': !menuTimeLimit.closed,
        'qrw-text-content-error-primary': menuTimeLimit.closed
      }"
        v-else
      >
        <div class="timed-menu">
          <BaseIcon
            icon="clock"
            size="xs"
          />
          <div class="qrw-label-sm">{{ menuTimeLimit.text }}</div>
        </div>
        <BaseDivider
          color="border-base-secondary"
          vertical
          style="height: 16px;"
          v-if="menuTimeLimit.closed"
        />
        <div
          class="qrw-label-sm"
          v-if="menuTimeLimit.closed"
        >{{ $t("schedule.closed_now") }}</div>
      </div>

    </div>
    <BaseIconButton
      icon="arrow-right"
      variant="ghost"
      textColor="content-base-primary"
      size="md"
    />
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    menu: Object
  },
  computed: {
    menuTimeLimit() {
      if (!this.menu.schedule_pair) return null

      return {
        text: `${moment(this.menu.schedule_pair.start).format('HH:mm')} - ${moment(this.menu.schedule_pair.end).format(
          'HH:mm'
        )}`,
        closed: this.$store.getters.isMenuClosed(this.menu._id)
      }
    },
    localizedTag() {
      return this.$store.getters.localizeMenuTitle(this.menu._id, this.$i18n.locale)
    }
  }
}
</script>

<style scoped>
.menu-lobby-card {
  width: 100%;
  padding: 16px;
  border-radius: 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  background: var(--qrw-background-base-primary-10);
  align-items: center;
  box-shadow: var(--qrw-shadow-card-light);
}

.timed-menu {
  align-items: center;
  display: flex;
  column-gap: 4px;
}

.menu-schedule-info {
  display: flex;
  column-gap: 8px;
  align-items: center;
}
</style>
