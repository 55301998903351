var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-product-list-card"},[_c('transition',{attrs:{"name":"fade"}},[(!_vm.isShowingAddedTrigger)?_c('div',{staticClass:"product-card-swiper",class:{ added: _vm.isProductInCart }}):_vm._e()]),_c('transition',{attrs:{"name":"swiper"}},[(_vm.isShowingAddedTrigger)?_c('div',{staticClass:"product-card-swiper swiped left"},[_c('BaseIcon',{attrs:{"size":"sm2","icon":"plus-circle"}})],1):_vm._e()]),_c('div',{staticClass:"product-list-card-content",class:{
      'being-removed': _vm.cardAnimating,
    },on:{"click":_vm.openProductPage}},[_c('div',{staticClass:"product-list-card-info"},[_c('div',{staticClass:"gap-column-xs"},[_c('div',{staticClass:"gap-row-txs w-100"},[_c('div',{staticClass:"info-titles"},[_c('div',{staticClass:"gap-row-2xs"},[_c('div',{staticClass:"gap-column-1xs product-name-row"},[(_vm.isProductInCart)?_c('BaseLabelBadge',{staticClass:"qrw-label-xs bold counter-badge",attrs:{"variant":"filled","size":"xs","primaryColor":"background-success-default","secondaryColor":"content-base-inverse-primary"}},[_vm._v(" "+_vm._s(_vm.productCartCount)+" ")]):_vm._e(),_c('div',{ref:"productName",staticClass:"product-name qrw-text-content-base-primary qrw-paragraph-sm bold"},[_vm._v(" "+_vm._s(_vm.productName)+" ")])],1),(_vm.productDescription && _vm.productNameLinesCount < 3)?_c('div',{staticClass:"product-description qrw-paragraph-sm qrw-text-content-base-secondary",style:(_vm.productDescriptionLineStyles)},[_vm._v(" "+_vm._s(_vm.productDescription)+" ")]):_vm._e()])]),(!_vm.discountedPrice)?_c('div',{staticClass:"price"},[_c('PriceTag',{staticClass:"qrw-text-content-base-primary qrw-label-sm bold",attrs:{"value":_vm.productDisplayPrice}})],1):_c('div',{staticClass:"price"},[_c('PriceTag',{staticClass:"qrw-text-content-error-primary qrw-label-sm bold",attrs:{"value":_vm.discountedPrice}}),_c('PriceTag',{staticClass:"qrw-text-content-base-tertiary qrw-label-xs line-through",attrs:{"spacing":0,"value":_vm.productDisplayPrice}})],1)]),(_vm.productImage)?_c('img',{staticClass:"product-list-card-image",attrs:{"src":_vm.productImage}}):_vm._e()]),(
          _vm.product.is_excise ||
          _vm.product.is_spicy ||
          _vm.product.base_weight ||
          _vm.product.waiting_time
        )?_c('div',{staticClass:"info-badges"},[(_vm.product.is_excise)?_c('BaseIconBadge',{attrs:{"size":"sm","primaryColor":"background-brand-secondary","secondaryColor":"content-brand-primary","icon":"wine","rounded":""}}):_vm._e(),(_vm.product.is_spicy)?_c('BaseIconBadge',{attrs:{"size":"sm","primaryColor":"background-error-secondary","secondaryColor":"content-error-primary","icon":"pepper-filled","rounded":""}}):_vm._e(),(_vm.product.base_weight)?_c('BaseLabelBadge',{staticClass:"product-badge",attrs:{"size":"sm","rounded":"","primaryColor":"background-base-tertiary-10","secondaryColor":"content-base-secondary"}},[_vm._v(" "+_vm._s(_vm._f("formatWeight")(_vm.product.base_weight))+" "+_vm._s(_vm.$t("units." + _vm.product.unit))+" ")]):_vm._e(),(_vm.product.waiting_time)?_c('BaseLabelBadge',{staticClass:"product-badge",attrs:{"size":"sm","icon":"clock","rounded":"","primaryColor":"background-base-tertiary-10","secondaryColor":"content-base-secondary"}},[_vm._v(" "+_vm._s(_vm.product.waiting_time)+" "+_vm._s(_vm.$t("units.min"))+" ")]):_vm._e()],1):_vm._e()])]),_c('transition',{attrs:{"name":"swiper-right"}},[(_vm.isShowingRemoveTrigger)?_c('div',{staticClass:"product-card-swiper swiped right"},[_c('BaseIcon',{attrs:{"size":"sm2","icon":"x-circle"}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }