<template>
  <div class="cafe-contacts">
    <div class="qrw-label-lg bold qrw-text-content-base-primary">
      {{ $t("contacts.title") }}
    </div>
    <div class="contacts-row" v-if="cafe.contact_phone">
      <div class="gap-row-1xs w-100">
        <div class="qrw-label-sm qrw-text-content-base-tertiary">
          {{ $t("contacts.phone") }}
        </div>
        <div class="qrw-label-md qrw-text-content-base-primary">
          {{ cafe.contact_phone }}
        </div>
      </div>

      <BasePushButton
        variant="primary"
        class="contact-btn"
        textColor="content-base-secondary"
        bgColor="background-base-tertiary-10"
        size="sm"
        icon="phone-call"
        @click="callByPhone"
      >
        {{ $t("contacts.call") }}
      </BasePushButton>
    </div>
    <BaseDivider color="border-base-tertiary" v-if="cafe.contact_email" />
    <div class="contacts-row" v-if="cafe.contact_email">
      <div class="gap-row-1xs w-100">
        <div class="qrw-label-sm qrw-text-content-base-tertiary">
          {{ $t("contacts.mail") }}
        </div>
        <div class="qrw-label-md qrw-text-content-base-primary">
          {{ cafe.contact_email }}
        </div>
      </div>

      <BasePushButton
        variant="primary"
        class="contact-btn"
        textColor="content-base-secondary"
        bgColor="background-base-tertiary-10"
        size="sm"
        icon="copy"
        @click="copyEmail"
      >
        {{ $t("contacts.copy") }}
      </BasePushButton>
    </div>
    <BaseDivider
      color="border-base-tertiary"
      v-if="cafe.contact_email || cafe.social.website"
    />
    <div class="contacts-row" v-if="cafe.social.website">
      <div class="gap-row-1xs w-100">
        <div class="qrw-label-sm qrw-text-content-base-tertiary">
          {{ $t("contacts.website") }}
        </div>
        <div class="qrw-label-md qrw-text-content-base-primary">
          {{ cafe.social.website }}
        </div>
      </div>

      <BasePushButton
        variant="primary"
        class="contact-btn"
        textColor="content-base-secondary"
        bgColor="background-base-tertiary-10"
        size="sm"
        icon="link-external"
        @click="visitWebsite"
      >
        {{ $t("contacts.visit") }}
      </BasePushButton>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cafe: Object
  },
  methods: {
    callByPhone () {
      window.location.href = `tel:${this.cafe.contact_phone}`
    },
    copyEmail () {
      navigator.clipboard.writeText(this.cafe.contact_email)
    },
    visitWebsite () {
      window.location.href = this.cafe.social.website
    }
  }
}
</script>

<style scoped>
.cafe-contacts {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  padding: 16px;
  box-sizing: border-box;
  width: 100%;
}

.contacts-row {
  display: flex;
  flex-direction: row;
  column-gap: 24px;
  align-items: center;
}
.contact-btn {
  width: 122px;
  min-width: 122px;
}
</style>
