<template>
  <div class="address-search-wrapper">
    <div
      class="address-search-backdrop"
      @click="$emit('close')"
    ></div>
    <div class="address-search">
      <div class="search-container">
        <BaseSearchInput
          v-model="search"
          ref="search"
          size="md"
          :placeholder="mode === 'house' ? $t('edit_address.search_house_ph') + form.street_name  : $t('edit_address.search_ph')"
          class="w-100"
        />
        <BaseIconButton
          size="md"
          icon="x-close"
          variant="ghost"
          textColor="content-base-secondary"
          @click="$emit('close')"
        />
      </div>
      <BaseDivider color="border-base-tertiary" />
      <div
        class="empty-state qrw-label-md bold qrw-text-content-base-tertiary"
        v-if="!results.length && !search"
      >
        {{$t('edit_address.empty_text')}}
      </div>
      <div
        class="empty-state"
        v-if="!results.length && search && !busy"
      >
        <div class="gap-row-1xs no-results">
          <div class="qrw-label-md bold qrw-text-content-base-tertiary">
            {{$t('edit_address.no_results_title')}}
          </div>
          <div class="qrw-paragraph-md qrw-text-content-base-tertiary">
            {{$t('edit_address.no_results_subtitle')}}
          </div>
        </div>
      </div>
      <div
        class="address-list"
        v-else-if="results.length"
      >
        <div
          class="address-item"
          v-for="(item,index) in results"
          @click="onItemSelect(item)"
          :key="
          index"
        >
          <div class="address-icon">
            <BaseIcon
              icon="marker-pin"
              size="sm2"
              :class="{
                unreachable: !item.is_reachable
              }"
            />
          </div>
          <div class="gap-row-2xs">
            <div class="qrw-label-md qrw-text-content-base-primary">
              {{item.street}}
            </div>
            <div class="qrw-label-xs qrw-text-content-base-tertiary">
              {{ item.city}}, {{item.country}} <span
                class="qrw-text-content-error-primary"
                v-if="!item.is_reachable"
              >
                ({{$t('edit_address.out_of_range')}})
              </span>
            </div>
          </div>
        </div>
        <div class="powered-by">
          <img
            src="/images/by-google.svg"
            alt=""
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeliveryAPI from '@/api/delivery'

export default {
  data() {
    return {
      results: [],
      busy: false,
      search: null,

      timeout: 0
    }
  },
  props: {
    street: String,
    form: Object,
    mode: {
      type: String,
      default: 'street'
    }
  },
  watch: {
    search() {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(this.performSearch.bind(this), 300)
    }
  },
  methods: {
    onItemSelect(item) {
      if (!item.is_reachable) return

      // TODO: use normal address from server
      this.$emit('input', item)
      this.$emit('close')
    },
    async performSearch() {
      if (!this.search) {
        this.results = []
        return
      }

      const searchQuery =
        this.form.street_name && this.mode === 'house' ? `${this.form.street_name}, ${this.search}` : this.search

      this.busy = true

      const { results } = await DeliveryAPI.searchAddresses(this.mixinCafeLinkCode, searchQuery, this.$i18n.locale)

      this.results = results
      this.busy = false
    }
  },
  mounted() {
    if (this.mode === 'street' && (this.form.street || this.form.street_name)) {
      this.search = this.street || this.form.street_name
    }

    this.$nextTick(() => {
      this.$refs.search.getInputElement().focus()
    })

    document.body.style.overflow = 'hidden'
  },
  beforeDestroy() {
    document.body.style.overflow = 'scroll'
  }
}
</script>

<style scoped>
.address-search-wrapper {
}

.address-search-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(2px);
  background: var(--qrw-background-base-inverse-primary-02);
  z-index: 20;
}

.address-search {
  position: fixed;
  z-index: 25;
  top: 0;
  left: 0;
  width: 100%;

  background: var(--qrw-background-base-secondary-10);
}

.search-container {
  display: flex;
  padding: 12px 16px;
  column-gap: 16px;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 16px;
  row-gap: 16px;
}

.address-list {
  display: flex;
  flex-direction: column;
}

.address-item {
  display: flex;
  flex-direction: 12px;
  padding: 16px;
  box-sizing: border-box;
  column-gap: 8px;
  border-bottom: 1px solid var(--qrw-border-base-tertiary);
}

.address-icon {
  display: flex;
  padding: 7px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  background: var(--qrw-background-base-tertiary);
  color: var(--qrw-content-base-primary);
  width: fit-content;
}

.powered-by {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.no-results {
  text-align: center;
}

.unreachable {
  color: var(--qrw-content-error-primary);
}
</style>
